<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <!--PACK DE BATERIA-->
                        <div class="divConImagen">
                            <iframe style="height: 100%;width: 100%;"
                                frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                                allow="autoplay; fullscreen; xr-spatial-tracking;accelerometer" xr-spatial-tracking
                                execution-while-out-of-viewport execution-while-not-rendered web-share
                                [src]="iframeSrc" web-share>
                            </iframe>
                        </div>
                        <table class="tablainfobateria">
                            <tbody>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.model[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.modelopack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.capacity[this.state.indiceTranslate]}} </td>
                                    <td style="text-align: right">{{this.dataTable.capacidadpack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.carga[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.cargapack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.quimica[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.quimica}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.volumen[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.volumenpack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.peso[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.pesopack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.densidadener[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.densidadenergiapack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.temperatura[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.temperaturapack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablapack.arquitectura[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.arquitecturapack}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Consumo de BTMS -->
                            <ejs-chart title={{this.dataTBat.graficoconsumpbtms.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="this.axisX" [primaryYAxis]="this.axisY" [height]="alto"
                                [zoomSettings]="zoom" [palettes]="colorScheme.domain" (window:resize)="onResize($event)"
                                [tooltip]="tooltip" [animations]="animations" style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series *ngFor="let chart of this.state.listaDatosEnergia; index as i;"
                                        [dataSource]="chart.datos" type="Line" xName="name" yName="value"
                                        [name]="this.state.NameCyclesListOnlyCC[i]" legendShape="Rectangle" [animation]="animation">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 3 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <!--CELDAS DE BATERIA-->
                        <div class="divConImagen">
                            <iframe style="height: 100%;width: 100%;"
                                frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                                allow="autoplay; fullscreen; xr-spatial-tracking;accelerometer" xr-spatial-tracking
                                execution-while-out-of-viewport execution-while-not-rendered web-share
                                [src]="iframeCeldaSrc" web-share>
                            </iframe>
                        </div>
                        <table class="tablainfobateria">
                            <tbody>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.model[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.modelo}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.capacity[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.capacidad}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.quimica[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.quimica}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.dimensiones[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.dimenciones}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.peso[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.peso}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.densidadener[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.densidadenergia}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.ciclos[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.ciclos}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.temperatura[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.temperatura}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.arquitectura[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.arquitecturapack}}</td>
                                </tr>
                                <tr>
                                    <td>{{this.dataTBat.infotablacelda.proveedor[this.state.indiceTranslate]}}</td>
                                    <td style="text-align: right">{{this.dataTable.proveedorcelda}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTBat.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTBat.menu.rbpaquete[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" cssClass="e-info"
                            (click)="irImgBateria()"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTBat.menu.rbcelda[this.state.indiceTranslate]}} name="default"
                            style="margin-bottom: 10px;" cssClass="e-info" (click)="irImgCelda()"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTBat.menu.rbconsumobtms[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" checked="true" cssClass="e-info"
                            (click)="irGraficoBateria()"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>