import { Component, ElementRef, OnInit, } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { datosTraducidos } from 'src/app/auxiliar_components/datatranslate/faketranslate';
import { ChartComponent ,ILoadedEventArgs, IPointRenderEventArgs, ChartAllModule,ISeriesRenderEventArgs ,  ErrorBarService,RangeAreaSeriesService} from '@syncfusion/ej2-angular-charts';
import { CalidadService } from 'src/app/shared/data/calidad.service';


@Component({
  selector: 'app-dashboard-ingresos',
  templateUrl: './dashboard-ingresos.component.html',
  styleUrls: ['./dashboard-ingresos.component.scss'],
  providers: [ErrorBarService,RangeAreaSeriesService],


})
export class DashboardIngresosComponent implements OnInit {

  colorScheme = ['#000096', '#008000', '#4bc8fe', '#800000', '#F5821E']

  constructor(private elRef: ElementRef, public state: StateService, public calidad: CalidadService) { }

  //* Variables de Menus y Graficos
  public selectedChart: number = 3;
  public selectedCostTicket: any;
  public selectedCostIndex: any = 0;
  public selectedTiempo: any = 1;
  public isSelectedTiempo = true;
  public indices = this.state.indiceTranslate
  public dataTIR = datosTraducidos.DashboardsGraph.ingresos
  public colorList = []
  public errorBar: Object = {visible: true, type: 'Percentage', verticalError: 10 , color:'#F5821E',errorBarCap:{ length:10, width:3, color:'#F5821E'}};
  public errorBarDos: Object = {visible: true, type: 'Percentage', verticalError: 10 , color:'#F5821E',errorBarCap:{ length:10, width:3, color:'#F5821E'}};
  public opacity: number = 0.4;
  public border: Object = { width: 2 };
  //public tooltip = { enable: true };
  public tooltip?: Object;
  public marker = { visible: true, width: 10, height: 10 }
  public valor= 1
  



  //* Variables Datos
  private dataIngreso = this.state.dataIngreso["data"]
  public dataChartIngresoTotalHora: any = []
  public dataChartIngresoAcumuladoDiario: any = []
  public dataChartProyecionIngreso: any = []
  public listaPreciosTicket = this.state.dataIngreso["costosticket"]
  public listaPreciosTicketLabel = this.state.dataIngreso["labelcostoticket"]
  public Moneda = this.state.dataIngreso["moneda"][0]
  public simboloMoneda = this.state.dataIngreso["simbolo"][0]
  public tiempoProyeccion = this.dataTIR.graphproyeccioningreso.mensual[this.state.indiceTranslate]
  public labelAxisX=this.dataTIR.graphproyeccioningreso.mes[this.state.indiceTranslate]

  //* Variables de Filtro

  ngOnInit(): void {
    this.selectedChart = 0;
    this.selectedCostIndex = 0;
    this.selectedTiempo = 1;
    this.isSelectedTiempo = true
    this.selectedCostTicket = this.listaPreciosTicket[this.selectedCostIndex]
    this.colorList = this.colorScheme.slice(0, this.listaPreciosTicketLabel.length)
    this.CargaDatos(this.selectedCostTicket, this.dataIngreso)
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
    this.tooltip = {
      enable: true,
      format: '${point.x} : ${point.y} ±10.00% ' + this.dataTIR.graphproyeccioningreso.incertidumbrelabel[this.state.indiceTranslate] + this.valor,
};
    setTimeout(() => {
      this.selectedChart = 3
    }, 1500);
    return;
  }

  //* Funciones
  CargaDatos(selectedTicket, data) {
    this.dataChartIngresoTotalHora = []
    this.dataChartIngresoAcumuladoDiario = []

    let auxDataIngreso = data.filter(item => item["costoticket"] == selectedTicket)
    for (let i = 0; i < auxDataIngreso.length; i++) {

      let elemento = auxDataIngreso[i];
      let listDataIngresoTotalHora = []
      let listDataIngresoAcumuladoDiario = []

      //? Llenado de datos Ingreso Acumulado Diario
      for (let j = 0; j < elemento["ingresoacumuladox"].length; j++) {
        listDataIngresoAcumuladoDiario.push({ "x": elemento["ingresoacumuladox"][j], "y": elemento["ingresoacumuladoy"][j] })
      }
      //? Llenado de datos Total de Ingreso por Hora
      for (let k = 0; k < elemento["tiempohoras"].length; k++) {
        listDataIngresoTotalHora.push({ "x": elemento["tiempohoras"][k], "y": elemento["ingresohora"][k], "z": elemento["ticketsvendidos"][k] })
      }
      this.dataChartIngresoAcumuladoDiario = listDataIngresoAcumuladoDiario
      this.dataChartIngresoTotalHora = listDataIngresoTotalHora
    }
  }
  CargarDatosIngresoProyeccion(selectedTicket, selectecTiempo, data) {
    this.dataChartProyecionIngreso = []
    let auxDataIngreso = data.filter(item => item["costoticket"] == selectedTicket)
    for (let i = 0; i < auxDataIngreso.length; i++) {
      let listDataProyeccionIngreso = []
      let elemento = auxDataIngreso[i];
      switch (selectecTiempo) {
        // Anual
        case 1:
          this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.mensual[this.state.indiceTranslate]
          for (let n = 0; n < elemento["timeyear"].length; n++) {
            listDataProyeccionIngreso.push({ "x": elemento["timeyear"][n], "y": elemento["ingresoyear"][n], "z": elemento["ingresoyearacum"][n],"neto":elemento["netoanual"][n],"high":elemento["netomargenanual"][n],"low":elemento["netomargenbajoanual"][n] })
          }
          break;
        // Mensual
        case 2:
          this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.diario[this.state.indiceTranslate]
          for (let m = 0; m < elemento["timemount"].length; m++) {
            listDataProyeccionIngreso.push({ "x": elemento["timemount"][m], "y": elemento["ingresomount"][m], "z": elemento["ingresomountacum"][m],"neto":elemento["netomes"][m],"high":elemento["netomargenmes"][m],"low":elemento["netomargenbajomes"][m] })
          }
          break;
        // Diario
        case 3:
          this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.anual[this.state.indiceTranslate]
          for (let l = 0; l < elemento["timehour"].length; l++) {
            listDataProyeccionIngreso.push({ "x": elemento["timehour"][l], "y": elemento["ingresoday"][l], "z": elemento["ingresodayacum"][l],"neto":elemento["netohora"][l],"high":elemento["netomargenhora"][l],"low":elemento["netomargenbajohora"][l] })
          }
          break;
        default:
          break;
      }
      this.dataChartProyecionIngreso = listDataProyeccionIngreso
    }
  }

  irIngresoTotalHora() {
    this.selectedChart = 1;
    this.isSelectedTiempo = false;
  }
  irIngresoAcumulado() {
    this.selectedChart = 2;
    this.isSelectedTiempo = false;
  }
  irProyeccionIngreso() {
    this.selectedChart = 3;
    this.isSelectedTiempo = true;
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)

  }
  irTiempoAnual() {
    this.selectedTiempo = 1
    this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.anual[this.state.indiceTranslate]
    this.labelAxisX=this.dataTIR.graphproyeccioningreso.mes[this.state.indiceTranslate]
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  irTiempoMensual() {
    this.selectedTiempo = 2
    this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.mensual[this.state.indiceTranslate]
    this.labelAxisX=this.dataTIR.graphproyeccioningreso.dia[this.state.indiceTranslate]
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  irTiempoDiario() {
    this.selectedTiempo = 3
    this.tiempoProyeccion = this.dataTIR.graphproyeccioningreso.anual[this.state.indiceTranslate]
    this.labelAxisX=this.dataTIR.graphproyeccioningreso.anio[this.state.indiceTranslate]
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  cambiarFiltroTicket(indice) {
    this.selectedCostIndex = indice
    this.selectedCostTicket = this.listaPreciosTicket[this.selectedCostIndex]
    this.CargaDatos(this.selectedCostTicket, this.dataIngreso)
    if (this.isSelectedTiempo == true) {
      this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
    }
  }
  descargarCSV(){
    //this.calidad.getCSV()
  }
  public seriesRender(args: ISeriesRenderEventArgs)  {
    this.valor +=1;
}
  //* Disenio de Aristas
  //?  Grafico Ingreso Total Hora
  public primaryXAxisITH: Object = {
    title: 'Hora del día',
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    maximum: 25,
  };
  public primaryYAxisITH: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: 'Ingreso por hora' + ' (' + this.Moneda + ')',
    minimum: 0,
  };

  public axisITH: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    name: 'yAxis',
    labelFormat: '{value}',
    title: 'Tickets vendidos',
    minimum: 0,
  }];

  public chartArea: Object = {
    border: {
      width: 0
    }
  };


  //?  Grafico Ingreso Acumulado
  public primaryXAxisIA: Object = {
    title: 'Hora del día',
    valueType: 'DateTime'
  };
  public primaryYAxisIA: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: 'Ingresos acumulados' + ' (' + this.Moneda + ')',
    minimum: 0,
  };

  //? Grafico de Proyeccion  Ingreso
  public XAxisIngresos: Object = {
    title: this.dataTIR.graphproyeccioningreso.ejex[this.state.indiceTranslate],
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    labelFormat:'{value}'+ '°',
    //maximum: 13,
  };
  public YAxisIngresos: Object = {
    lineStyle: { width: 0 },
    valueType:'Double',
    labelFormat : '{value}' + this.simboloMoneda,
    //labelFormat: 'c2',
    title: this.dataTIR.graphproyeccioningreso.ejey[this.state.indiceTranslate] + ' (' + this.Moneda + ')',
    minimum: 0,
  };
}
