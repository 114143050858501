<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.sohnew==true && this.estaCompleto==true"
                        style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Descarga y Recarga 100%-->
                            <ejs-chart #myDOMElement1 id="descargarrecarga100"
                                title={{this.dataTEC.graficodc100.titulo[this.state.indiceTranslate]}}
                                [tooltip]='tooltip' style="width:100%;height:100%; "
                                [primaryXAxis]='axisxDescargaRecarga' [primaryYAxis]="axisyDescargaRecarga"
                                [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                (chartMouseClick)='chartMouseClick($event)'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let potencia of this.listaBusesName; index as i"
                                        [dataSource]="this.listaPlotDescargaRecarga[i]" type="Line" xName="axisx"
                                        yName="axisy" name="{{potencia}}" legendShape='Rectangle'
                                        style="width:100%;height:100%; ">
                                    </e-series>
                                    <e-series [dataSource]="this.line[0]" type="Line" xName="axisx" yName="axisy"
                                        name={{this.leyendasoc}} legendShape='Rectangle'
                                        style="width:100%;height:100%; " dashArray="5">
                                    </e-series>
                                    <e-series [dataSource]="this.linetamaniobateria" type="Line" xName="axisx"
                                        yName="axisy" name="Tamaño Bateria" legendShape='Rectangle'
                                        style="width:100%;height:100%; " dashArray="5">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.sohnew==false && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <!--Descarga y Recarga (SoH Crítico)-->
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement2 id="descargarrecargacritico"
                                title={{this.dataTEC.graficodccritico.titulo[this.state.indiceTranslate]}}
                                [tooltip]='tooltip' style="width:100%;height:100%; "
                                [primaryXAxis]='axisxDescargaRecarga' [primaryYAxis]="axisyDescargaRecarga"
                                [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                (chartMouseClick)='chartMouseClick2($event)'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let potencia of this.listaBusesName; index as i"
                                        [dataSource]="this.listaPlotDescargaRecargaCrit[i]" type="Line" xName="axisx"
                                        yName="axisy" name="{{potencia}}" legendShape='Rectangle'
                                        style="width:100%;height:100%; ">
                                    </e-series>
                                    <e-series [dataSource]="this.linecritic[0]" type="Line" xName="axisx" yName="axisy"
                                    name={{this.leyendasoc}} legendShape='Rectangle' style="width:100%;height:100%; "
                                        dashArray="5">
                                    </e-series>
                                    <e-series [dataSource]="this.linetamaniobateriacritic" type="Line" xName="axisx"
                                        yName="axisy" name="Tamaño Bateria" legendShape='Rectangle'
                                        style="width:100%;height:100%; " dashArray="5">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 3 && this.sohnew==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">

                            <!-- Energia Disponible SoH 100%-->
                            <ejs-chart #myDOMElement3 id="energiadisponible100" [primaryXAxis]="state.axis3[0]"
                                [primaryYAxis]="state.axis3[1]" [height]="alto" (window:resize)="onResize($event)"
                                [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
                                title={{this.dataTEC.graficoed100.titulo[this.state.indiceTranslate]}}
                                style="width:100%;height:100%;">
                                <e-series-collection style="width:100%;height:100%;">
                                    <!--<e-series-->
                                    <!--    *ngFor="let busname of dataSer.allRouteProperties[state.currentRoute].busesid; index as i;"-->
                                    <!--    [dataSource]="state.THEDATA[2][3].datos[busname]" type="Line" xName="name"-->
                                    <!--    yName="value"-->
                                    <!--    name={{this.dataTEC.graficoed100.label1[this.state.indiceTranslate]}}-->
                                    <!--    legendShape='Rectangle'></e-series>-->
                                    <e-series [dataSource]="this.dataTiempoCarga['distancia']" type="Line" xName="axisx"
                                        yName="axisy" name={{this.dataTEC.graficoed100.label1[this.state.indiceTranslate]}} legendShape='Rectangle'
                                        style="width:100%;height:100%;"></e-series>
                                    <e-series [dataSource]="state.THEDATA[2][2].datos" type="Line" xName="name"
                                       yName="value" [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle'
                                       style="width:100%;height:100%;" dashArray="5"></e-series>
                                    <e-series [dataSource]="state.THEDATA[2][5].datos" type="Line" xName="name"
                                       yName="value" [name]="state.THEDATA[2][5].nombre" legendShape='Rectangle'
                                       style="width:100%;height:100%;" dashArray="5"></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 4 && this.sohnew==false && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--Energia Disponible Critico-->
                            <ejs-chart #myDOMElement4 id="energiadisponiblecritico" [primaryXAxis]="state.axis3[0]"
                                [primaryYAxis]="state.axis3[1]" [height]="alto" (window:resize)="onResize($event)"
                                [zoomSettings]='zoom' [palettes]='colorScheme.domain' [tooltip]='tooltip'
                                title={{this.dataTEC.graficoedcritico.titulo[this.state.indiceTranslate]}}
                                style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series [dataSource]="this.dataTiempoCarga['distanciacritic']" type="Line" xName="axisx"
                                        yName="axisy" name={{this.dataTEC.graficoedcritico.label1[this.state.indiceTranslate]}} legendShape='Rectangle'
                                        style="width:100%;height:100%;"></e-series>
                                    <e-series [dataSource]="state.THEDATA[2][2].datos" type="Line" xName="name"
                                        yName="value" [name]="state.THEDATA[2][2].nombre" legendShape='Rectangle'
                                        dashArray="5"></e-series>
                                    <e-series [dataSource]="state.THEDATA[2][5].datos" type="Line" xName="name"
                                        yName="value" [name]="state.THEDATA[2][5].nombre" legendShape='Rectangle'
                                        style="width:100%;height:100%;" dashArray="5"></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 5  && this.sohnew==true && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!-- Tiempo de Carga -->
                            <ejs-chart #myDOMElement5 id='chartTiempoCarga'
                                title={{this.dataTEC.graficotr.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                style="width:100%;height:100%; " [primaryXAxis]='axisxTiempoCarga'
                                [primaryYAxis]="axisyTiempoCarga" [zoomSettings]='zoom' [palettes]='colorScheme.domain'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let potencia of this.listaBusesName; index as i"
                                        [dataSource]="this.listaCargaNueva[i]" type='Scatter' xName='axisx'
                                        yName='axisy' name="{{potencia}}"
                                        [marker]="i>=5?this.markerBurbuja:this.markerRectangle"
                                        style="width:100%;height:100%; "> </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 6  && this.sohnew==false && this.estaCompleto==true"
                        style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!-- Tiempo de Carga Critico-->
                            <ejs-chart #myDOMElement5 id='chartTiempoCarga'
                                title={{this.dataTEC.graficotrcritic.titulo[this.state.indiceTranslate]}}
                                [tooltip]='tooltip' style="width:100%;height:100%; " [primaryXAxis]='axisxTiempoCarga'
                                [primaryYAxis]="axisyTiempoCarga" [zoomSettings]='zoom' [palettes]='colorScheme.domain'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let potencia of this.listaBusesName; index as i"
                                        [dataSource]="this.listaCargaNuevaCritic[i]" type='Scatter' xName='axisx'
                                        yName='axisy' name="{{potencia}}"
                                        [marker]="i>=5?this.markerBurbuja:this.markerRectangle"
                                        style="width:100%;height:100%; "> </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTEC.menu.titulothrid[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTEC.menu.sohnew[this.state.indiceTranslate]}} name="filtroSOH"
                            style="margin-bottom: 10px;" (click)="cargarDataNueva()" cssClass="e-info"
                            checked="true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.sohend[this.state.indiceTranslate]}} name="filtroSOH"
                            style="margin-bottom: 10px;" (click)="cargarDataFinvida()"
                            cssClass="e-info"></ejs-radiobutton>


                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTEC.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTEC.menu.descrecar100[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irDescargaRecarga()" cssClass="e-info"
                            checked="true" *ngIf="this.sohnew==true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.descrecarcritico[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irDescargaRecargaCritico()"
                            cssClass="e-info" *ngIf="this.sohnew==false" checked="true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.energiadispo100[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irEnergiaDisponible()"
                            cssClass="e-info" *ngIf="this.sohnew==true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.energiadispocritico[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irEnergiaDisponibleCritico()"
                            cssClass="e-info" *ngIf="this.sohnew==false"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.tiemprecarga[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irTiempoRecarga()" cssClass="e-info"
                            *ngIf="this.sohnew==true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTEC.menu.tiemprecargacritic[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irTiempoRecargaCritico()"
                            cssClass="e-info" *ngIf="this.sohnew==false"></ejs-radiobutton>
                        <div *ngIf="this.mostrarFiltroBoton">
                            <h2 style="text-align: center; margin-top: 20px">
                                {{this.dataTEC.menu.titulosecond[this.state.indiceTranslate]}}</h2>
                            <div style="display: flex;align-items: center;flex-direction: row;"
                                *ngFor="let potencia of this.listaPotencia; index as i">
                                <ejs-radiobutton label="{{potencia}} kW" name="radioSitioCarga"
                                    [checked]="this.selectedFiltroBoton==i? true : false " style="margin-bottom: 10px;"
                                    (click)="cambiarFiltroPotencia(i)" cssClass="e-info"></ejs-radiobutton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>