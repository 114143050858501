import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { datosTraducidos } from 'src/app/auxiliar_components/datatranslate/faketranslate';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
@Component({
  selector: 'app-dashboard-economico',
  templateUrl: './dashboard-economico.component.html',
  styleUrls: ['./dashboard-economico.component.scss']
})
export class DashboardEconomicoComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  colorScheme = ['#000096', '#88898C', '#F5821E', '#800000', '#4bc8fe']

  constructor(private elRef: ElementRef, public state: StateService) { }
  // * Variables Default Menu
  public sohnew: boolean;
  public selectedChart: number = 0;
  public selectedCost: any;
  public selectedCostIndex: any;
  public dataTIR = datosTraducidos.DashboardsGraph.economico.menu
  public colorList = []
  public indices = this.state.indiceTranslate


  // * Variables Datos
  public listaPotencia = this.state.dataEconomico["potencia"]
  private dataEconomico = this.state.dataEconomico["data"]
  public dataChartCostoHora: any = []
  public dataChartCostotiempo: any = []
  public dataChartCostoDiario: any = []
  public dataChartCostoMensual: any = []
  public dataChartCostoAnual: any = []
  public dataChartCosto: any = []
  public selectedPotencia :any;
  public selectedPotenciaindex :any;
  public selectedTiempo: any = 1;
  public isSelectedTiempo = false;
  public tiempoCostoLabel = 'anual'
  public listaPrecios = this.state.dataEconomico["costos"]
  public listaPreciosLabel = this.state.dataEconomico["labelcosto"]
  public Moneda = this.state.dataEconomico["moneda"][0]
  public simboloMoneda = this.state.dataEconomico["simbolo"][0]


  //* Carga de Valores previo renderización de Componente

   ngOnInit(){
    this.sohnew = true; // SoH es nuevo = true ->  critic False
    this.isSelectedTiempo = false;
    this.selectedCostIndex = 0
    this.selectedPotenciaindex = 0
    this.selectedTiempo=1
    this.selectedCost = this.listaPrecios[this.selectedCostIndex]
    this.colorList = this.colorScheme.slice(0, this.listaPreciosLabel.length)
    this.selectedPotencia = this.listaPotencia[this.selectedPotenciaindex]
    this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
    setTimeout(() => {
      this.selectedChart = 1
    }, 1200);

  }


  CargaDatosInit(sohnew, costo, data) {
    let auxDataSoh: any
    if (sohnew == true) {
      auxDataSoh = data.filter(item => item["critic"] == false)
    } else {
      auxDataSoh = data.filter(item => item["critic"] == true)
    }
    let auxDataCosto = auxDataSoh.filter(item => item["costo"] == costo)

    // * Datasets para Graficos
    this.dataChartCostoHora = []
    this.dataChartCostotiempo = []
    for (let i = 0; i < auxDataCosto.length; i++) {
      let elemento = auxDataCosto[i];
      let listDataCostoTiempo = []
      let listDataCostoHora = []
      for (let j = 0; j < elemento["costoacumuladox"].length; j++) {
        listDataCostoTiempo.push({ "x": elemento["costoacumuladox"][j], "y": elemento["costoacumuladoy"][j] })
      }
      for (let k = 0; k < elemento["tiempohoras"].length; k++) {
        listDataCostoHora.push({ "x": elemento["tiempohoras"][k], "y": elemento["costohora"][k], "z": elemento["eventoscargahora"][k] })
      }
      this.dataChartCostotiempo.push(listDataCostoTiempo)
      this.dataChartCostoHora.push(listDataCostoHora)
    }

  }

  CargaDatosCostos(costo, data, tiempo, potencia) {
    this.dataChartCosto = []
    let listDataCosto = []
    let auxData: any
    auxData = data.filter(item => !item["critic"] == true && item["costo"] == costo && item["potencia"] == potencia)
    for (let i = 0; i < auxData.length; i++) {
      let elemento = auxData[i];
      switch (tiempo) {
        case 1:
          //Anual
          this.tiempoCostoLabel = 'anual'
          for (let n = 0; n < elemento["timeyear"].length; n++) {
            listDataCosto.push({ "x": elemento["timeyear"][n], "y": elemento["costoyear"][n], "z": elemento["costoyearacum"][n] })
          }
          break;
        case 2:
          //Mensual
          this.tiempoCostoLabel = 'mensual'
          for (let n = 0; n < elemento["timemount"].length; n++) {
            listDataCosto.push({ "x": elemento["timemount"][n], "y": elemento["costomount"][n], "z": elemento["costomountacum"][n] })
          }
          break;
        case 3:
          //Diario
          this.tiempoCostoLabel = 'diario'
          for (let n = 0; n < elemento["timehour"].length; n++) {
            listDataCosto.push({ "x": elemento["timehour"][n], "y": elemento["costoday"][n], "z": elemento["costodayacum"][n] })
          }
          break;
        default:
          break;
      }
      this.dataChartCosto = listDataCosto
    }
  }
  // * Funciones Filtros
  cargarDataNueva(soh: boolean) {
    this.sohnew = soh
    this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
  }

  irCostoHora() {
    this.selectedChart = 1
    this.isSelectedTiempo =false;
  }

  irCostoEnergia() {
    this.selectedChart = 2
    this.isSelectedTiempo =false;
  }

  irProyeccionCosto(){
    this.selectedChart = 3  
    this.isSelectedTiempo =true;
  }

  cambiarFiltroCost(cost: number) {
    this.selectedCostIndex = cost
    this.selectedCost = this.listaPrecios[this.selectedCostIndex]
    this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
    this.CargaDatosCostos(this.selectedCost,this.dataEconomico,this.selectedTiempo,this.selectedPotencia)

  }

  cambiarPotencia(i){
    this.selectedPotenciaindex = i
    this.selectedPotencia =this.listaPotencia[this.selectedPotenciaindex]
    this.CargaDatosCostos(this.selectedCost,this.dataEconomico,this.selectedTiempo,this.selectedPotencia)
  }

  irTiempoAnual() {
    this.selectedTiempo = 1
    this.tiempoCostoLabel = 'Anual'
    this.CargaDatosCostos(this.selectedCost,this.dataEconomico,this.selectedTiempo,this.selectedPotencia)
  }
  irTiempoMensual() {
    this.selectedTiempo = 2
    this.tiempoCostoLabel = 'Mensual'
    this.CargaDatosCostos(this.selectedCost,this.dataEconomico,this.selectedTiempo,this.selectedPotencia)
  }
  irTiempoDiario() {
    this.selectedTiempo = 3
    this.tiempoCostoLabel = 'Diario'
    this.CargaDatosCostos(this.selectedCost,this.dataEconomico,this.selectedTiempo,this.selectedPotencia)
  }
  // * Diseño de Aristas
  // ? Aristas : Costo de energía por hora
  public primaryXAxis: Object = {
    title: this.dataTIR.graphcostohora.ejex[this.indices],
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    maximum: 25,
  };
  public primaryYAxis: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: this.dataTIR.graphcostohora.ejey1[this.indices] + ' (' + this.Moneda + ')',
    minimum: 0,
  };
  public axis: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    name: 'yAxis',
    labelFormat: '{value}',
    title: this.dataTIR.graphcostohora.ejey2[this.indices],
    minimum: 0,
  }];

  public chartArea: Object = {
    border: {
      width: 0
    }
  };

  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }

  // ? Aristas : Costo Energia Acumulado
  public primaryXAxisCT: Object = {
    title: this.dataTIR.graphcostoacumulado.ejex[this.indices],
    valueType: 'DateTime'
  };
  public primaryYAxisCT: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: this.dataTIR.graphcostoacumulado.ejey1[this.indices] + ' (' + this.Moneda + ')',
    minimum: 0,
  };

    //? Grafico de Proyeccion  Ingreso
    public XAxisCostosTiempo: Object = {
      title: 'Proyeccion en tiempo',
      valueType: 'Double',
      interval: 1,
      minimum: 0,
      //maximum: 13,
    };
    public YAxisCostosTiempo: Object = {
      lineStyle: { width: 0 },
      valueType:'Double',
      labelFormat : '{value}' + this.simboloMoneda,
      //labelFormat: 'c2',
      title: 'Costo de Energía' + ' (' + this.Moneda + ')',
      minimum: 0,
    };
}
