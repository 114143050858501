<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.selectedChart==1" style="width:99%;height:100%; ">
                        <ejs-chart  style='display:block;' useGroupingSeparator="true" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
                            title={{this.dataTIR.graphcostohora.title[this.indices]}} [chartArea]='chartArea' [axes]='axis' [palettes]='this.colorList'
                            [tooltip]='tooltip' style="width:100%;height:100%; ">
                            <e-series-collection style="width:100%;height:100%;">
                                <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                    [dataSource]='this.dataChartCostoHora[i]' type='Column' xName='x' yName='y'
                                    name='{{this.dataTIR.graphcostohora.ejexlabel[this.indices]}} {{potencia}} kW)' width=2>
                                </e-series>
                                <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                    [dataSource]='this.dataChartCostoHora[i]' type='Line' xName='x' yName='z'
                                    name='{{this.dataTIR.graphcostohora.ejey1label[this.indices]}}{{potencia}} kW)' width=2 yAxisName='yAxis'
                                    [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                    </div>
                    <div *ngIf="this.selectedChart==2" style="width:99%;height:100%; ">
                        <ejs-chart style='display:block;' useGroupingSeparator="true" [primaryXAxis]='primaryXAxisCT'
                            [primaryYAxis]='primaryYAxisCT' title="{{this.dataTIR.graphcostoacumulado.title[this.indices]}}" [chartArea]='chartArea'
                            [palettes]='this.colorList' [tooltip]='tooltip' style="width:100%;height:100%; ">
                            <e-series-collection style="width:100%;height:100%;">
                                <e-series *ngFor="let potencia of this.listaPotencia; index as i"
                                    [dataSource]='this.dataChartCostotiempo[i]' type='Line' xName='x' yName='y'
                                    name='{{this.dataTIR.graphcostoacumulado.ejey1label[this.indices]}} {{potencia}} kW' width=2>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                    </div>
                    <div *ngIf="this.selectedChart==3" style="width:99%;height:100%; ">
                        <ejs-chart style='display:block;' useGroupingSeparator="true" [primaryXAxis]='XAxisCostosTiempo'
                            [primaryYAxis]='YAxisCostosTiempo' title="{{this.dataTIR.graphproyeccioncostos.title[this.indices]}}" [chartArea]='chartArea' 
                            [palettes]='this.colorList' [tooltip]='tooltip' style="width:100%;height:100%;">
                            <e-series-collection style="width:100%;height:100%;">
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="normal" type='StackingColumn' xName='x' yName='energia' name='{{this.dataTIR.graphproyeccioncostos.energialabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="normal" type='StackingColumn' xName='x' yName='mantenimiento' name='{{this.dataTIR.graphproyeccioncostos.mantenimientolabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="normal" type='StackingColumn' xName='x' yName='operacion' name='{{this.dataTIR.graphproyeccioncostos.operacionlabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="normal" type='StackingColumn' xName='x' yName='legales' name='{{this.dataTIR.graphproyeccioncostos.legaleslabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="accum" type='StackingColumn' xName='x' yName='energiaaccum' name='{{this.dataTIR.graphproyeccioncostos.energiaaccumlabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="accum" type='StackingColumn' xName='x' yName='mantenimientoaccum' name='{{this.dataTIR.graphproyeccioncostos.mantenimientoaccumlabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="accum" type='StackingColumn' xName='x' yName='operacionaccum' name='{{this.dataTIR.graphproyeccioncostos.operacionaccumlabel[this.indices]}}'></e-series>
                                <e-series [dataSource]='this.dataChartCosto' stackingGroup="accum" type='StackingColumn' xName='x' yName='legalesaccum' name='{{this.dataTIR.graphproyeccioncostos.legalesaccumlabel[this.indices]}}'></e-series>
                            </e-series-collection>
                        </ejs-chart>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">                    
                    <div style="width:100%; display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 8px">{{this.dataTIR.grafico[this.indices]}}</h2>
                        <!--<ejs-radiobutton label="{{this.dataTIR.rbtcostoenergiahora[this.indices]}}" name="filtroChart" style="margin-bottom: 10px;"-->
                        <!--    (click)="irCostoHora()" checked="false" cssClass="e-info"></ejs-radiobutton>-->
                        <!--<ejs-radiobutton label="{{this.dataTIR.rbtcostoenergiaacumulado[this.indices]}}" name="filtroChart" style="margin-bottom: 10px;"-->
                        <!--    (click)="irCostoEnergia()" cssClass="e-info"></ejs-radiobutton>-->
                        <ejs-radiobutton label="{{this.dataTIR.graphproyeccioncostos.title[this.indices]}}" name="filtroChart" style="margin-bottom: 10px;"
                            (click)="irProyeccionCosto()" cssClass="e-info" checked="true"></ejs-radiobutton>
                    </div>
                    <div style="width:100%;display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 8px">{{this.dataTIR.preciolable[this.indices]}}</h2>
                        <div style="display: flex;align-items: center;flex-direction: row;"
                            *ngFor="let costo of this.listaPreciosLabel; index as i">
                            <ejs-radiobutton label={{costo}} name="filtroCost"
                                [checked]="this.selectedCostIndex==i? true : false " style="margin-bottom: 10px;"
                                (click)="cambiarFiltroCost(i)" cssClass="e-info"></ejs-radiobutton>
                        </div>
                    </div>
                    <div *ngIf="this.isSelectedTiempo==false" style="width:100%; display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 8px">
                            {{this.dataTIR.soh[this.indices]}}</h2>
                        <ejs-radiobutton label="{{this.dataTIR.sohnew[this.indices]}}" name="filtroSOH" style="margin-bottom: 10px;"
                            (click)="cargarDataNueva(true)" checked="true" cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton class="radiobuttonseleccion" label="{{this.dataTIR.sohend[this.indices]}}" name="filtroSOH"
                            style="margin-bottom: 10px;" (click)="cargarDataNueva(false)"
                            cssClass="e-info"></ejs-radiobutton>
                    </div>
                    <div *ngIf="this.isSelectedTiempo==true" style="width:100%;display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 5px">{{this.dataTIR.potencialabel[this.indices]}}</h2>
                        <div style="display: flex;align-items: center;flex-direction: row;"
                            *ngFor="let potencia of this.listaPotencia; index as i">
                            <ejs-radiobutton label='{{potencia}} kW' name="filtroPotencia"
                                [checked]="this.selectedPotenciaindex==i? true : false " style="margin-bottom: 10px;"
                                (click)="cambiarPotencia(i)" cssClass="e-info"></ejs-radiobutton>
                        </div>
                    </div>
                    <div *ngIf="this.isSelectedTiempo==true" style="width:100%; display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 5px">{{this.dataTIR.tiempoproyeccion[this.indices]}}</h2>
                        <h4 style="text-align: center">{{this.dataTIR.incertidumbrelabel[this.indices]}}: ± 10,00%</h4>
                        <ejs-radiobutton label="{{this.dataTIR.graphproyeccioncostos.rdbfindevida[this.indices]}}" name="filtroChartTiempo" style="margin-bottom: 8px;"
                            (click)="irTiempoFindeVida()" [checked]="this.selectedTiempo==1? true : false " cssClass="e-info"></ejs-radiobutton>  
                        <ejs-radiobutton label="{{this.dataTIR.graphproyeccioncostos.rdbanual[this.indices]}}" name="filtroChartTiempo" style="margin-bottom: 8px;"
                            (click)="irTiempoAnual()" [checked]="this.selectedTiempo==2? true : false "  cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="{{this.dataTIR.graphproyeccioncostos.rdbmensual[this.indices]}}" name="filtroChartTiempo" style="margin-bottom: 8px;"
                            (click)="irTiempoMensual()" [checked]="this.selectedTiempo==3? true : false " cssClass="e-info"></ejs-radiobutton> 
                        <ejs-radiobutton label="{{this.dataTIR.graphproyeccioncostos.rdbdiario[this.indices]}}" name="filtroChartTiempo" style="margin-bottom: 8px;"
                            (click)="irTiempoDiario()" [checked]="this.selectedTiempo==4? true : false " cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>