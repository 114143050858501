import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { datosTraducidos } from 'src/app/auxiliar_components/datatranslate/faketranslate';
import { ChartComponent, ILoadedEventArgs, IPointRenderEventArgs, ChartAllModule,StackingColumnSeriesService, ErrorBarService,BarSeriesService, StackingBarSeriesService, CategoryService } from '@syncfusion/ej2-angular-charts';
@Component({
  selector: 'app-dashboard-economico',
  templateUrl: './dashboard-economico.component.html',
  styleUrls: ['./dashboard-economico.component.scss'],
  providers: [ErrorBarService,BarSeriesService, StackingBarSeriesService, CategoryService,StackingColumnSeriesService],
})
export class DashboardEconomicoComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  colorScheme = ['#000096', '#88898C', '#F5821E', '#800000']

  constructor(private elRef: ElementRef, public state: StateService) { }
  // * Variables Default Menu
  public sohnew: boolean;
  public selectedChart: number = 0;
  public selectedCost: any;
  public selectedCostIndex: any;
  public dataTIR = datosTraducidos.DashboardsGraph.economico.menu
  public colorList = []
  public indices = this.state.indiceTranslate
  //public errorBar: Object = {visible: true, verticalError: 'error' };
  public errorBar: Object = { visible: false, type: 'Percentage', verticalError: 10 };
  public errorBarDos: Object = { visible: false, type: 'Percentage', verticalError: 10 };



  // * Variables Datos
  public listaPotencia = this.state.dataTipoEconomico["potencia"]
  //private dataEconomico = this.state.dataEconomico["data"]
  private dataEconomico = this.state.dataTipoEconomico["data"]
  public dataChartCostoHora: any = []
  public dataChartCostotiempo: any = []
  public dataChartCostoDiario: any = []
  public dataChartCostoMensual: any = []
  public dataChartCostoAnual: any = []
  public dataChartCosto: any = []
  public selectedPotencia: any;
  public selectedPotenciaindex: any;
  public selectedTiempo: any = 1;
  public isSelectedTiempo = false;
  public tiempoCostoLabel = 'mensual'
  public listaPrecios = this.state.dataTipoEconomico["costosticket"]
  public listaPreciosLabel = this.state.dataTipoEconomico["labelcostoticket"]
  public Moneda = this.state.dataTipoEconomico["moneda"][0]
  public simboloMoneda = this.state.dataTipoEconomico["simbolo"][0]
  public categoria = this.state.dataTipoEconomico["categoria"]


  //* Carga de Valores previo renderización de Componente

  ngOnInit() {
    this.sohnew = true; // SoH es nuevo = true ->  critic False
    this.isSelectedTiempo = true;
    this.selectedCostIndex = 0
    this.selectedPotenciaindex = 0
    this.selectedTiempo = 1
    this.selectedCost = this.listaPrecios[this.selectedCostIndex]
    this.colorList = this.colorScheme
    this.selectedPotencia = this.listaPotencia[this.selectedPotenciaindex]
    //this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
    setTimeout(() => {
      this.selectedChart = 3
    }, 1200);

  }


  CargaDatosInit(sohnew, costo, data) {
    let auxDataSoh: any
    if (sohnew == true) {
      auxDataSoh = data.filter(item => item["critic"] == false)
    } else {
      auxDataSoh = data.filter(item => item["critic"] == true)
    }
    let auxDataCosto = auxDataSoh.filter(item => item["costo"] == costo)

    // * Datasets para Graficos
    this.dataChartCostoHora = []
    this.dataChartCostotiempo = []
    for (let i = 0; i < auxDataCosto.length; i++) {
      let elemento = auxDataCosto[i];
      let listDataCostoTiempo = []
      let listDataCostoHora = []
      for (let j = 0; j < elemento["costoacumuladox"].length; j++) {
        listDataCostoTiempo.push({ "x": elemento["costoacumuladox"][j], "y": elemento["costoacumuladoy"][j] })
      }
      for (let k = 0; k < elemento["tiempohoras"].length; k++) {
        listDataCostoHora.push({ "x": elemento["tiempohoras"][k], "y": elemento["costohora"][k], "z": elemento["eventoscargahora"][k] })
      }
      this.dataChartCostotiempo.push(listDataCostoTiempo)
      this.dataChartCostoHora.push(listDataCostoHora)
    }

  }

  CargaDatosCostos(costo, data, tiempo, potencia) {
    this.dataChartCosto = []
    let listDataCosto = []
    let auxData: any
    auxData = data.filter(item => !item["critic"] == true && item["costo"] == costo && item["potencia"] == potencia)
    //   "energia",    "mantenimiento",    "operacion",    "legales"
    for (let i = 0; i < auxData.length; i++) {
      let singleDataEconomico = auxData[i];
      switch (tiempo) {
        // Fin de vida
        case 1:
          this.tiempoCostoLabel = 'Fin de vida'
          for (let n = 0; n < singleDataEconomico["tiempofindevida"].length; n++) {
            listDataCosto.push({
              "x": singleDataEconomico["tiempofindevida"][n],
              "energia": singleDataEconomico["yfindevida"][0][n],
              "mantenimiento": singleDataEconomico["yfindevida"][1][n],
              "operacion": singleDataEconomico["yfindevida"][2][n],
              "legales": singleDataEconomico["yfindevida"][3][n],
              "energiaaccum": singleDataEconomico["yacumfindevida"][0][n],
              "mantenimientoaccum": singleDataEconomico["yacumfindevida"][1][n],
              "operacionaccum": singleDataEconomico["yacumfindevida"][2][n],
              "legalesaccum": singleDataEconomico["yacumfindevida"][3][n],
            })
          }
          break;
        // Anual
        case 2:
          this.tiempoCostoLabel = 'Anual'
          for (let n = 0; n < singleDataEconomico["tiempoanual"].length; n++) {
            listDataCosto.push({
              "x": singleDataEconomico["tiempoanual"][n],
              "energia": singleDataEconomico["yanual"][0][n],
              "mantenimiento": singleDataEconomico["yanual"][1][n],
              "operacion": singleDataEconomico["yanual"][2][n],
              "legales": singleDataEconomico["yanual"][3][n],
              "energiaaccum": singleDataEconomico["yacumanual"][0][n],
              "mantenimientoaccum": singleDataEconomico["yacumanual"][1][n],
              "operacionaccum": singleDataEconomico["yacumanual"][2][n],
              "legalesaccum": singleDataEconomico["yacumanual"][3][n],
            })
          }
          break;
        // Mensual
        case 3:
          this.tiempoCostoLabel = 'Mensual'
          for (let n = 0; n < singleDataEconomico["tiempomensual"].length; n++) {
            listDataCosto.push({
              "x": singleDataEconomico["tiempomensual"][n],
              "energia": singleDataEconomico["ymensual"][0][n],
              "mantenimiento": singleDataEconomico["ymensual"][1][n],
              "operacion": singleDataEconomico["ymensual"][2][n],
              "legales": singleDataEconomico["ymensual"][3][n],
              "energiaaccum": singleDataEconomico["yacummensual"][0][n],
              "mantenimientoaccum": singleDataEconomico["yacummensual"][1][n],
              "operacionaccum": singleDataEconomico["yacummensual"][2][n],
              "legalesaccum": singleDataEconomico["yacummensual"][3][n],
            })
          }
          break;
        // Diario
        case 4:
          this.tiempoCostoLabel = 'Diario'
          for (let n = 0; n < singleDataEconomico["tiempodiario"].length; n++) {
            listDataCosto.push({
              "x": singleDataEconomico["tiempodiario"][n],
              "energia": singleDataEconomico["ydiario"][0][n],
              "mantenimiento": singleDataEconomico["ydiario"][1][n],
              "operacion": singleDataEconomico["ydiario"][2][n],
              "legales": singleDataEconomico["ydiario"][3][n],
              "energiaaccum": singleDataEconomico["yacumdiario"][0][n],
              "mantenimientoaccum": singleDataEconomico["yacumdiario"][1][n],
              "operacionaccum": singleDataEconomico["yacumdiario"][2][n],
              "legalesaccum": singleDataEconomico["yacumdiario"][3][n],
            })
          }
          break;
      }
    }
    this.dataChartCosto = listDataCosto
  }
  // * Funciones Filtros
  cargarDataNueva(soh: boolean) {
    this.sohnew = soh
    this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
  }

  irCostoHora() {
    this.selectedChart = 1
    this.isSelectedTiempo = false;
  }

  irCostoEnergia() {
    this.selectedChart = 2
    this.isSelectedTiempo = false;
  }

  irProyeccionCosto() {
    this.selectedChart = 3
    this.isSelectedTiempo = true;
  }

  cambiarFiltroCost(cost: number) {
    this.selectedCostIndex = cost
    this.selectedCost = this.listaPrecios[this.selectedCostIndex]
    this.CargaDatosInit(this.sohnew, this.selectedCost, this.dataEconomico)
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)

  }

  cambiarPotencia(i) {
    this.selectedPotenciaindex = i
    this.selectedPotencia = this.listaPotencia[this.selectedPotenciaindex]
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
  }

  irTiempoAnual() {
    this.selectedTiempo = 2
    this.tiempoCostoLabel = 'mensual'
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
  }
  irTiempoMensual() {
    this.selectedTiempo = 3
    this.tiempoCostoLabel = 'diario'
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
  }
  irTiempoDiario() {
    this.selectedTiempo = 4
    this.tiempoCostoLabel = 'horas'
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
  }
  irTiempoFindeVida() {
    this.selectedTiempo = 1
    this.tiempoCostoLabel = 'fin de vida'
    this.CargaDatosCostos(this.selectedCost, this.dataEconomico, this.selectedTiempo, this.selectedPotencia)
  }
  // * Diseño de Aristas
  // ? Aristas : Costo de energía por hora
  public primaryXAxis: Object = {
    title: this.dataTIR.graphcostohora.ejex[this.indices],
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    maximum: 25,
  };
  public primaryYAxis: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: this.dataTIR.graphcostohora.ejey1[this.indices] + ' (' + this.Moneda + ')',
    minimum: 0,
  };
  public axis: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    name: 'yAxis',
    labelFormat: '{value}',
    title: this.dataTIR.graphcostohora.ejey2[this.indices],
    minimum: 0,
  }];

  public chartArea: Object = {
    border: {
      width: 0
    }
  };

  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }

  // ? Aristas : Costo Energia Acumulado
  public primaryXAxisCT: Object = {
    title: this.dataTIR.graphcostoacumulado.ejex[this.indices],
    valueType: 'DateTime'
  };
  public primaryYAxisCT: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: this.dataTIR.graphcostoacumulado.ejey1[this.indices] + ' (' + this.Moneda + ')',
    minimum: 0,
  };

  //? Grafico de Proyeccion  Ingreso
  public XAxisCostosTiempo: Object = {
    title: this.dataTIR.graphproyeccioncostos.ejex[this.indices],
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    //maximum: 13,
  };
  public YAxisCostosTiempo: Object = {
    lineStyle: { width: 0 },
    valueType: 'Double',
    labelFormat: '{value}' + this.simboloMoneda,
    //labelFormat: 'c2',
    title: this.dataTIR.graphproyeccioncostos.ejey[this.indices] + ' (' + this.Moneda + ')',
    minimum: 0,
  };


}
