import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalstorageService } from '../storage/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class StateService implements OnDestroy {
  public isActiveUser= false
  public datosCollection:any
  public datosCurrentCollection:any
  public currentCity : any;
  public dictTranslate:any
  public indiceTranslate:any
  public activeGlobal:any;
  public listaRuta:any;
  public listaOperacion:any;
  public listaTamanio:any;
  public listaConector:any;
  public listaConectorValue : any
  public listaConectorName : any
  public listaConectorViable : any
  public listaPantografo:any;
  public listaPantografoValue : any
  public listaPantografoName : any
  public listaPantografoViable : any
  public currentButtomGraph:any
  public currentButtonLedger:any
  public currentButtonAnimation : any
  public mostrarPortafolio:any =false;
  public confbusSelected:any;
  public coleccionDP:any;
  public currentOnlyRoute:any;
  public rutaDP:any;
  public servicioDP:any;
  public tamaniobusDP:any;
  public cargadorDP:any;
  public hvacDP:any;
  public ciclomanejoDP:any;
  public pasajerosDP:any;
  public currentOperacion:any;
  public metodoCarga:any;
  public bateria_bus :any;
  public tamaniobateriabus :any;
  public listaDepotMapa : any = []
  public listaDepotDemo : any = []
  public listaMenuConfiguracionBus: any = []
  public listaResumen:any=[]
  public listaPuntosCarga:any=[]
  public listaPuntosCargaCritic:any=[]
  public listaConsumoMayorPendiente:any=[]
  public listaComparacionesData: any = []
  public listaComparacionesDataCritic: any = []
  public listaEstrategiaCargaData: any = []
  public listaComparacionPotencia :any;  
  public listaComparacionEnergia :any; 
  public listaComparacionBus :any; 
  public listaCargadoresPotencia : any;
  public listaMicroSimulacion: any = []
  public listaDataCronograma: any = []
  public listaEstadosDashboard:any
  public listaDatosEnergiaRenovable:any=[]
  public listaCatalogoBateria:any=[]
  public listaChargerShared:any
  public listaChargerSharedCritic:any
  
  // Datos Animacion Ruta
  public cadenabusquedaanimacion:any
  public listaDatosTabla : any = []
  public listaDatosAnimacionRuta :any =[]
  public listaDatosAnimacionRutaResumen :any =[]
  public listaMarkerAnimacionRuta :any =[]
  public listaMarkerIntervalos : any = []
  public listaHoraInicioAnimacion: any = []
  public listaHoraInicioCargaAnimacion: any = []
  public listaHoraFinalCargaAnimacion: any = []
  public intervaloanimacion:any
  public intervaloanimacioncoord:any
  public listaDatosEnergia :any =[]
  public coleccionActual=''

  public listaNameCollection :any=[]
  private BusNames: any = [];
  private AllCoordinates = [];
  private AllLocations = [];
  private AllStops = [];
  private AllRoutesShown = false;
  private IsAnimationON = false;
  public IsCollectionON = false;
  private EventsON = false;
  private ChargingSitesON = false;
  private AreBusOptionsShown = false;
  private AnimationSpeed = 100;
  private AreAnimationsDone = 2;
  private ChosenSeries = 0;
  private _currentCollection = '';
  private _currentLocation = [0, 0];
  private _currentChart = 0;
  private _currentRoute = 'None';
  private _currentRoutePosition = -1;
  private _currentType = '';
  private _currentBusSize = '';
  private _currentPax = 'LOW';
  private _currentTime = '5AM';
  private _currentHVAC = 'hvacON';
  private _currentBattery = '';
  private _currentServices = 1;
  private _currentStats = 6;
  private _currentVelocity = 2;
  private _currentBusDistance = 0;
  private _minimumConsumption = '';
  private _maximumConsumption = '';
  private _initialAuto = '';
  private _endAuto = 0;
  private _currentMenu = 0;
  private TheAltitude: any = [];
  private _THEDATA: any[][] = [];
  private _THEDATACOPY: any[][] = [];
  private RAWDATA: any = {};
  private RAWROUTEPROPERTIES: any = {};
  private RAWINPUTS: any = {};
  private RoutesAnimationData: any = {};
  private PowerGaugeValue: any = {};
  private PowerGaugePercentage: any = {};
  private SOCGaugeValue: any = {};
  private SOCGaugeMax: number = 100.0;
  private DistanceGaugeMax: number = 10000;
  private DistanceGaugeValue: any = {};
  private DistanceGaugePercentage: any = {};
  private DistanceChartValue: any = [];
  private ShowPowerGauge: any = [false, ''];
  private IsChargingGauge: any = {};
  private ShowAllBusesAnimation: boolean = false;
  private ShowAllChargersAnimation: boolean = false;
  private ShowSingleBusAnimation: any = '';
  private AnimationAlerts: any = {};
  private PuntoDeCarga: any = {};
  private InicioDeCarga: any = {};
  private EndDeCarga: any = {};
  private CurrentAnimationTime: any =
    new Date().toLocaleTimeString(undefined, { hour: 'numeric' });
  private Trajects: any = {};
  private ShowAnimButtons = false;
  private CurrentChargersNames = [];
  private ChargersMaxValue = 0;
  private ChargerAssignedBus: any = {};
  private ChargerInit: any = {};
  private ChargerEnd: any = {};
  private ChargedEnergy: any = {};
  private AccumChargedEnergy: any = {};
  private TotalCostChargedEnergy: any = {};
  private LostEnergy: any = {};
  private TotalAccumEnergy: number = 0;
  private TotalCostEnergy: number = 0;
  private ShowChargersData = false;
  private SelectedCharger = '';
  private DataForSpeedStops: any = {};
  public DataForPassCicle: any = [];
  public dataInputs:any
  private CurrentCycle: number = 0;
  private CyclesListOnlyCC: any = [];
  public  NameCyclesListOnlyCC: any = [];
  private RouteStops: number[] = [];
  private ShowCharginSiteChart = false;
  private Chart1 = false;
  private Chart2 = false;
  private Chart3 = false;
  private Chart4 = false;
  private Chart5 = false;
  private Chart6 = false;
  private Chart8 = false;
  private Info = false;
  private InMap = false;
  private Mayor: any = ['', '', '', '', ''];
  private Menor: any = ['', '', '', '', ''];
  private UpdateStats = '';
  private IsActivated5AM = true;
  private IsActivated1PM = true;
  private IsActivated5PM = true;
  private _selectedPoint: any = null;
  private WhichButton = 1;
  private Abierto = false;
  private One = ['#D6D8E1', '#D6D8E1','#D6D8E1'];
  private BusInterval: any = [];
  private BusIntervalTime: any = [];
  private PrimaryXAxis: any = {};
  private Axis1: any = [];
  private Axis2: any = [];
  private Axis3: any = [];
  private Axis4: any = [];
  private Axis5: any = [];
  private Axis6: any = [];
  private Axis7: any = [];
  private Axis8: any = [];
  private CardPosition = { x: 0, y: 0 };
  private BusOptionsPosition = { x: 0, y: 0 };
  private Processing = false;
  private ChartMapLongitude: any = [];
  private ChartMapLatitude: any = [];
  private ChartMapLongitudeCycles: any = [];
  private ChartMapLatitudeCycles: any = [];
  private ChartMapPointIndex = [-1, -1];
  private ShowBusesOptions = false;
  private ShowVehicleProperties = false;
  private VehicleProperties: any = {};
  public listaBusesAnimacion:any=[]
  public listaDatosBateriaTabla:any
  public listaDatosAmbiente:any
  public listaDatosContable:any
  public listaColeccionesGuardada:any=[]


  private IconoVisible = true;
  private MaxEneConsumption = 0;
  private MaxDistConsumption = 0;
  private MaxDistDiscriminated = 0;
  private MaxEneDiscriminated = 0;
  private MaxEneAvailable = 0;
  private MaxVelocity = 0;
  private MaxTime = 0;
  private MaxAcumTime = 0;
  private MaxKilometer = 0;
  private MaxStatsConsumption = 0;
  private MaxAvgVelocity = 0;
  private _CollectionStatistics: any = {};
  public tamanioBateriaValue=0
  public seleccionNav = 1;
  public id_model_bus=""
  public typeName=""
  public configuracioModeloBus=""
  public uid_model_bus=""
  public vehicle_platform_data:any
  public show_info_bus=false
  public enable_info_bus=false
  public dataRating:any
  public isChargerShared = false
  public backendURL = "https://logiosplanbackend.com/"
  //public backendURL = "http://localhost:8000/"
  public dataAllRouteProerties:any
  public dataEconomico:any
  public listaCostoAnualEconomico :any
  public listaCostoMensualEconomico :any
  public listaCostoDiarioEconomico :any
  public listaCostoLabelEconomico :any
  public listaCostoMonedaEconomico :any
  public listaLabelTicketEconomico :any
  public dataIngreso:any
  public dataTipoEconomico:any
  public listaIngresoAnual :any
  public listaIngresoMensual :any
  public listaIngresoDiario :any
  public listaIngresoLabel :any
  public listaIngresoMoneda :any
  public get CollectionStatistics() {
    return this._CollectionStatistics;
  }
  public set CollectionStatistics(value) {
    this._CollectionStatistics = value;
  }
  private _SohCritico = 0;
  public get SohCritico(): any {
    return this._SohCritico;
  }
  public set SohCritico(value: any) {
    this._SohCritico = value;
  }
  private _PotenciaCarga = 0;
  public get PotenciaCarga(): any {
    return this._PotenciaCarga;
  }
  public set PotenciaCarga(value: any) {
    this._PotenciaCarga = value;
  }
  public dataChargersInfo: any = []
  private _dataChargerPotencia: any = [];
  public get dataChargerPotencia(): any {
    return this._dataChargerPotencia;
  }
  public set dataChargerPotencia(value: any) {
    this._dataChargerPotencia = value;
  }
  private _dataChargerEnergia: any = [];
  public get dataChargerEnergia(): any {
    return this._dataChargerEnergia;
  }
  public set dataChargerEnergia(value: any) {
    this._dataChargerEnergia = value;
  }

  // private IsSuperUser = false;

  currentRouteChange: Subject<string> = new Subject<string>();
  currentTypeChange: Subject<string> = new Subject<string>();
  currentPaxChange: Subject<string> = new Subject<string>();
  currentHVACChange: Subject<string> = new Subject<string>();
  currentBusSizeChange: Subject<string> = new Subject<string>();
  currentBatteryChange: Subject<string> = new Subject<string>();
  currentChartChange: Subject<number> = new Subject<number>();
  currentCollectionChange: Subject<string> = new Subject<string>();
  currentUpdateStatsChange: Subject<string> = new Subject<string>();
  currentLocationChange: Subject<any> = new Subject<any>();
  currentPointIndexChange: Subject<any> = new Subject<any>();
  currentAllCoordinatesChange: Subject<any> = new Subject<any>();
  currentAllStopsChange: Subject<any> = new Subject<any>();
  currentAllLocationsChange: Subject<string> = new Subject<string>();
  animationChange: Subject<any> = new Subject<any>();
  allSeriesUp: Subject<any> = new Subject<any>();
  stopAnimationEvent: Subject<void> = new Subject<void>();
  updateAlertsOrderEvent: Subject<void> = new Subject<void>();
  showDetailsEvent: Subject<any> = new Subject<any>();

  private CurrentBusConfigurations: any = {};
  WhichProcessing: number = 0;
  public _aux_route = 'None';
  public get aux_route(): string {
    return this._aux_route;
  }
  public set aux_route(value: string) {
    this._aux_route = value;
  }

  public EstacionesxRuta = {
    '101op2': ["CANTA CALLAO", "Canta Callao - NS", "Jose Olaya - NS", "HIPÓDROMO", "PRIMERA DE PRO"],
    '101op3': ["CANTA CALLAO", "Canta Callao - NS", "Jose Olaya - NS"],
    '101op4': ["CANTA CALLAO", "Canta Callao - NS", "Jose Olaya - NS"],
    '102': ["VENTANILLA", "CONTROL", "PATIO VENTANILLA"],
    '103op2': ["NONE"],
    '103op3': ["NONE"],
    '103op4': ["PATIO BOLOGNESI"],
    '103op5': ["PATIO BOLOGNESI"],
    '103op6': ["PATIO BOLOGNESI"],
    '104': ["PATIO PRIMERA", "PRIMERA DE PRO"],
    '107op2': ["PATIO PRIMERA"],
    '107op3': ["NONE"],
    '107op4': ["NONE"],
    '107op5': ["HIPÓDROMO", "PRIMERA DE PRO", "PATIO PRIMERA"],
    '108': ["VENTANILLA", "TINICO", "PATIO VENTANILLA"],
    '110': ["VENTANILLA", "HIPÓDROMO", "PATIO VENTANILLA"]
  }
  ShowDetails: boolean;
  ShowDetailsVel: boolean;
  ShowDetailsEne: boolean;

  //Variables Menu Lateral y SideBar
  public collectionSelected = false;

  constructor(/*private lStore: LocalstorageService*/) {
  }

  /* setBusConfiguration(route: string, datos: any): void{
    this.currentBusConfigurations[route] = datos;
  }

  clearBusConfiguration(): void{
    this.currentBusConfigurations = {};
  } */

  get currentBusConfigurations(): any {
    return this.CurrentBusConfigurations;
  }

  set currentBusConfigurations(bI: any) {
    this.CurrentBusConfigurations = bI;
  }

  get chartMapPointIndex(): any {
    return this.ChartMapPointIndex;
  }

  set chartMapPointIndex(bI: any) {
    this.ChartMapPointIndex = bI;
  }

  get chartMapLatitude(): any {
    return this.ChartMapLatitude;
  }

  set chartMapLatitude(bI: any) {
    this.ChartMapLatitude = bI;
  }

  get chartMapLongitude(): any {
    return this.ChartMapLongitude;
  }

  set chartMapLongitude(bI: any) {
    this.ChartMapLongitude = bI;
  }

  get chartMapLongitudeCycles(): any {
    return this.ChartMapLongitudeCycles;
  }

  set chartMapLongitudeCycles(bI: any) {
    this.ChartMapLongitudeCycles = bI;
  }

  get chartMapLatitudeCycles(): any {
    return this.ChartMapLatitudeCycles;
  }

  set chartMapLatitudeCycles(bI: any) {
    this.ChartMapLatitudeCycles = bI;
  }

  get cardPosition(): any {
    return this.CardPosition;
  }

  set cardPosition(bI: any) {
    this.CardPosition = bI;
  }

  get busOptionsPosition(): any {
    return this.BusOptionsPosition;
  }

  set busOptionsPosition(bI: any) {
    this.BusOptionsPosition = bI;
  }

  get one(): any {
    return this.One;
  }

  get maxStatsConsumption(): number {
    return this.MaxStatsConsumption;
  }

  set maxStatsConsumption(bI: number) {
    this.MaxStatsConsumption = bI;
  }

  get maxAvgVelocity(): number {
    return this.MaxAvgVelocity;
  }

  set maxAvgVelocity(bI: number) {
    this.MaxAvgVelocity = bI;
  }

  get maxKilometer(): number {
    return this.MaxKilometer;
  }

  set maxKilometer(bI: number) {
    this.MaxKilometer = bI;
  }

  get maxVelocity(): number {
    return this.MaxVelocity;
  }

  set maxVelocity(bI: number) {
    this.MaxVelocity = bI;
  }

  get maxTime(): number {
    return this.MaxTime;
  }

  set maxTime(bI: number) {
    this.MaxTime = bI;
  }

  get maxAccumTime(): number {
    return this.MaxAcumTime;
  }

  set maxAccumTime(bI: number) {
    this.MaxAcumTime = bI;
  }

  get maxEneAvailable(): number {
    return this.MaxEneAvailable;
  }

  set maxEneAvailable(bI: number) {
    this.MaxEneAvailable = bI;
  }

  get maxEneDiscriminated(): number {
    return this.MaxEneDiscriminated;
  }

  set maxEneDiscriminated(bI: number) {
    this.MaxEneDiscriminated = bI;
  }

  get maxDistDiscriminated(): number {
    return this.MaxDistDiscriminated;
  }

  set maxDistDiscriminated(bI: number) {
    this.MaxDistDiscriminated = bI;
  }

  get maxEneConsumption(): number {
    return this.MaxEneConsumption;
  }

  set maxEneConsumption(bI: number) {
    this.MaxEneConsumption = bI;
  }

  get maxDistConsumption(): number {
    return this.MaxDistConsumption;
  }

  set maxDistConsumption(bI: number) {
    this.MaxDistConsumption = bI;
  }

  get axis1(): any {
    return this.Axis1;
  }

  set axis1(bI: any) {
    this.Axis1 = bI;
  }

  get axis2(): any {
    return this.Axis2;
  }

  set axis2(bI: any) {
    this.Axis2 = bI;
  }

  get axis3(): any {
    return this.Axis3;
  }

  set axis3(bI: any) {
    this.Axis3 = bI;
  }

  get axis4(): any {
    return this.Axis4;
  }

  set axis4(bI: any) {
    this.Axis4 = bI;
  }

  get axis5(): any {
    return this.Axis5;
  }

  set axis5(bI: any) {
    this.Axis5 = bI;
  }

  get axis6(): any {
    return this.Axis6;
  }

  set axis6(bI: any) {
    this.Axis6 = bI;
  }

  get axis7(): any {
    return this.Axis7;
  }

  set axis7(bI: any) {
    this.Axis7 = bI;
  }

  get axis8(): any {
    return this.Axis8;
  }

  set axis8(bI: any) {
    this.Axis8 = bI;
  }

  get busNames(): any {
    return this.BusNames;
  }

  set busNames(bI: any) {
    this.BusNames = bI;
  }

  get allCoordinates(): any {
    return this.AllCoordinates;
  }

  set allCoordinates(bI: any) {
    this.AllCoordinates = bI;
  }

  get allLocations(): any {
    return this.AllLocations;
  }

  set allLocations(bI: any) {
    this.AllLocations = bI;
  }

  get allStops(): any {
    return this.AllStops;
  }

  set allStops(bI: any) {
    this.AllStops = bI;
  }

  get allRoutesShown(): boolean {
    return this.AllRoutesShown;
  }

  set allRoutesShown(bI: boolean) {
    this.AllRoutesShown = bI;
  }

  get showBusesOptions(): boolean {
    return this.ShowBusesOptions;
  }

  set showBusesOptions(bI: boolean) {
    this.ShowBusesOptions = bI;
  }

  get showVehicleProperties(): boolean {
    return this.ShowVehicleProperties;
  }

  set showVehicleProperties(bI: boolean) {
    this.ShowVehicleProperties = bI;
  }

  get vehicleProperties(): boolean {
    return this.VehicleProperties;
  }

  set vehicleProperties(bI: boolean) {
    this.VehicleProperties = bI;
  }

  get isAnimationON(): boolean {
    return this.IsAnimationON;
  }

  set isAnimationON(bI: boolean) {
    this.IsAnimationON = bI;
  }



  get isEventsON(): boolean {
    return this.EventsON;
  }

  set isEventsON(bI: boolean) {
    this.EventsON = bI;
  }

  get isChargingSitesON(): boolean {
    return this.ChargingSitesON;
  }

  set isChargingSitesON(bI: boolean) {
    this.ChargingSitesON = bI;
  }

  get showDetails(): boolean {
    return this.ShowDetails;
  }

  set showDetails(bI: boolean) {
    this.ShowDetails = bI;
  }

  get showDetailsVel(): boolean {
    return this.ShowDetailsVel;
  }

  set showDetailsVel(bI: boolean) {
    this.ShowDetailsVel = bI;
  }

  get showDetailsEne(): boolean {
    return this.ShowDetailsEne;
  }

  set showDetailsEne(bI: boolean) {
    this.ShowDetailsEne = bI;
  }

  get areBusOptionsShown(): boolean {
    return this.AreBusOptionsShown;
  }

  set areBusOptionsShown(bI: boolean) {
    this.AreBusOptionsShown = bI;
  }

  get animationSpeed(): number {
    return this.AnimationSpeed;
  }

  set animationSpeed(bI: number) {
    this.AnimationSpeed = bI;
  }

  get areAnimationsDone(): number {
    return this.AreAnimationsDone;
  }

  set areAnimationsDone(bI: number) {
    this.AreAnimationsDone = bI;
  }

  get chosenSeries(): number {
    return this.ChosenSeries;
  }

  set chosenSeries(bI: number) {
    this.ChosenSeries = bI;
  }

  /* get isSuperUser(): boolean {
    return this.IsSuperUser;
  }

  set isSuperUser(su: boolean) {
    this.IsSuperUser = su;
  } */

  get processing(): boolean {
    return this.Processing;
  }

  set processing(bI: boolean) {
    this.Processing = bI;
  }

  get whichprocessing(): number {
    return this.WhichProcessing;
  }

  set whichprocessing(bI: number) {
    this.WhichProcessing = bI;
  }

  get iconoVisible(): boolean {
    return this.IconoVisible;
  }

  set iconoVisible(bI: boolean) {
    this.IconoVisible = bI;
  }

  get busIntervalTime(): any {
    return this.BusIntervalTime;
  }

  set busIntervalTime(bI: any) {
    this.BusIntervalTime = bI;
  }

  get busInterval(): any {
    return this.BusInterval;
  }

  set busInterval(bI: any) {
    this.BusInterval = bI;
  }

  changeBusIntervalAtIndex(index: number, val: number): void {
    this.BusInterval[index] = val;
  }

  get primaryXAxis(): any {
    return this.PrimaryXAxis;
  }

  changeOneAtIndex(index: number, data: string): void {
    this.One[index] = data;
  }

  getOneAtIndex(index: number): string {
    return this.One[index];
  }

  set updateStats(y: string) {
    this.UpdateStats = y;
  }

  get updateStats(): string {
    return this.UpdateStats;
  }

  changeChart(nr: number): void {
    this.currentChartChange.next(nr);
  }

  changeUpdateStats(nr: string): void {
    this.currentUpdateStatsChange.next(nr);
  }

  changeRoute(nr: string): void {
    this.currentRouteChange.next(nr);
  }

  changeCollection(nr: string): void {
    this.currentCollectionChange.next(nr);
  }

  changeLocation(nr: any): void {
    this.currentLocationChange.next(nr);
  }

  changePointIndex(nr: any): void {
    this.currentPointIndexChange.next(nr);
  }

  changeType(nr: string): void {
    this.currentTypeChange.next(nr);
  }

  changePax(nr: string): void {
    this.currentPaxChange.next(nr);
  }

  changeHVAC(nr: string): void {
    this.currentHVACChange.next(nr);
  }

  changeBusSize(nr: string): void {
    this.currentBusSizeChange.next(nr);
  }

  changeBattery(nr: string): void {
    this.currentBatteryChange.next(nr);
  }

  changeAllCoordinates(nr: string): void {
    this.currentAllCoordinatesChange.next(nr);
  }

  changeAllStops(nr: string): void {
    this.currentAllStopsChange.next(nr);
  }


  changeAllLocations(nr: string): void {
    this.currentAllLocationsChange.next(nr);
  }

  changeAnimation(nr: any): void {
    this.animationChange.next(nr);
  }

  changeSeriesUp(nr: any): void {
    this.allSeriesUp.next(nr);
  }

  changeDetailsEvent(nr: any): void {
    this.showDetailsEvent.next(nr);
  }

  eventStopAnimation(): void {
    this.stopAnimationEvent.next();
  }

  eventOrderAlerts(): void {
    this.updateAlertsOrderEvent.next();
  }

  public addMaxBusAtIndex(data: string, index: number): void {
    this.Mayor[index] = data;
  }

  public getMaxBusAtIndex(index: number): any {
    return this.Mayor[index];
  }

  public addMinBusAtIndex(data: string, index: number): void {
    this.Menor[index] = data;
  }

  public getMinBusAtIndex(index: number): any {
    return this.Menor[index];
  }

  /* get mayor(): string {
    return this.Mayor;
  }

  set mayor(c: string) {
    this.Mayor = c;
  }

  get menor(): string {
    return this.Menor;
  }

  set menor(c: string) {
    this.Menor = c;
  } */

  get abierto(): boolean {
    return this.Abierto;
  }

  set abierto(c: boolean) {
    this.Abierto = c;
  }

  get isActivated5AM(): boolean {
    return this.IsActivated5AM;
  }

  set isActivated5AM(c: boolean) {
    this.IsActivated5AM = c;
  }

  get isActivated1PM(): boolean {
    return this.IsActivated1PM;
  }

  set isActivated1PM(c: boolean) {
    this.IsActivated1PM = c;
  }

  get isActivated5PM(): boolean {
    return this.IsActivated5PM;
  }

  set isActivated5PM(c: boolean) {
    this.IsActivated5PM = c;
  }

  get inMap(): boolean {
    return this.InMap;
  }

  set inMap(c: boolean) {
    this.InMap = c;
  }

  get chart1(): boolean {
    return this.Chart1;
  }

  set chart1(c: boolean) {
    this.Chart1 = c;
  }

  get chart2(): boolean {
    return this.Chart2;
  }

  set chart2(c: boolean) {
    this.Chart2 = c;
  }

  get chart3(): boolean {
    return this.Chart3;
  }

  set chart3(c: boolean) {
    this.Chart3 = c;
  }

  get chart4(): boolean {
    return this.Chart4;
  }

  set chart4(c: boolean) {
    this.Chart4 = c;
  }

  get chart5(): boolean {
    return this.Chart5;
  }

  set chart5(c: boolean) {
    this.Chart5 = c;
  }

  get chart6(): boolean {
    return this.Chart6;
  }

  set chart6(c: boolean) {
    this.Chart6 = c;
  }

  get info(): boolean {
    return this.Info;
  }

  set info(c: boolean) {
    this.Info = c;
  }

  get selectedPoint(): any {
    return this._selectedPoint;
  }

  set selectedPoint(sp: any) {
    this._selectedPoint = sp;
  }

  get THEDATACOPY(): any {
    return this._THEDATACOPY;
  }

  set THEDATACOPY(dc: any) {
    this._THEDATACOPY = dc;
  }

  get THEDATA(): any {
    return this._THEDATA;
  }

  set THEDATA(TD: any) {
    this._THEDATA = TD;
  }

  get rawData(): any {
    return this.RAWDATA;
  }

  set rawData(TD: any) {
    this.RAWDATA = TD;
  }

  get rawRouteProperties(): any {
    return this.RAWROUTEPROPERTIES;
  }

  set rawRouteProperties(TD: any) {
    this.RAWROUTEPROPERTIES = TD;
  }

  get rawInputs(): any {
    return this.RAWINPUTS;
  }

  set rawInputs(TD: any) {
    this.RAWINPUTS = TD;
  }

  get routesAnimationData(): any {
    return this.RoutesAnimationData;
  }

  set routesAnimationData(TD: any) {
    this.RoutesAnimationData = TD;
  }

  get routesAnimationDataString(): any {
    return JSON.stringify(this.RoutesAnimationData);
  }


  get powerGaugeValue(): any {
    return this.PowerGaugeValue;
  }

  set powerGaugeValue(TD: any) {
    this.PowerGaugeValue = TD;
  }

  get powerGaugePercentage(): any {
    return this.PowerGaugePercentage;
  }

  set powerGaugePercentage(TD: any) {
    this.PowerGaugePercentage = TD;
  }

  get socGaugeValue(): any {
    return this.SOCGaugeValue;
  }

  set socGaugeValue(TD: any) {
    this.SOCGaugeValue = TD;
  }

  get socGaugeMax(): any {
    return this.SOCGaugeMax;
  }

  set socGaugeMax(TD: any) {
    this.SOCGaugeMax = TD;
  }

  get distanceGaugeValue(): any {
    return this.DistanceGaugeValue;
  }

  set distanceGaugeValue(TD: any) {
    this.DistanceGaugeValue = TD;
  }

  get distanceGaugePercentage(): any {
    return this.DistanceGaugePercentage;
  }

  set distanceGaugePercentage(TD: any) {
    this.DistanceGaugePercentage = TD;
  }

  get distanceChartValue(): any {
    return this.DistanceChartValue;
  }

  set distanceChartValue(TD: any) {
    this.DistanceChartValue = TD;
  }

  get isChargingGauge(): any {
    return this.IsChargingGauge;
  }

  set isChargingGauge(TD: any) {
    this.IsChargingGauge = TD;
  }

  get animationAlerts(): any {
    return this.AnimationAlerts;
  }

  set animationAlerts(TD: any) {
    this.AnimationAlerts = TD;
  }

  get puntoDeCarga(): any {
    return this.PuntoDeCarga;
  }

  set puntoDeCarga(TD: any) {
    this.PuntoDeCarga = TD;
  }

  get inicioDeCarga(): any {
    return this.InicioDeCarga;
  }

  set inicioDeCarga(TD: any) {
    this.InicioDeCarga = TD;
  }

  get finDeCarga(): any {
    return this.EndDeCarga;
  }

  set finDeCarga(TD: any) {
    this.EndDeCarga = TD;
  }

  get chargerAssignedBus(): any {
    return this.ChargerAssignedBus;
  }

  set chargerAssignedBus(TD: any) {
    this.ChargerAssignedBus = TD;
  }

  get chargerInit(): any {
    return this.ChargerInit;
  }

  set chargerInit(TD: any) {
    this.ChargerInit = TD;
  }

  get chargerEnd(): any {
    return this.ChargerEnd;
  }

  set chargerEnd(TD: any) {
    this.ChargerEnd = TD;
  }

  get chargedEnergy(): any {
    return this.ChargedEnergy;
  }

  set chargedEnergy(TD: any) {
    this.ChargedEnergy = TD;
  }

  get accumChargedEnergy(): any {
    return this.AccumChargedEnergy;
  }

  set accumChargedEnergy(TD: any) {
    this.AccumChargedEnergy = TD;
  }

  get totalCostChargedEnergy(): any {
    return this.TotalCostChargedEnergy;
  }

  set totalCostChargedEnergy(TD: any) {
    this.TotalCostChargedEnergy = TD;
  }

  get lostEnergy(): any {
    return this.LostEnergy;
  }

  set lostEnergy(TD: any) {
    this.LostEnergy = TD;
  }

  get totalAccumEnergy(): any {
    return this.TotalAccumEnergy;
  }

  set totalAccumEnergy(TD: any) {
    this.TotalAccumEnergy = TD;
  }

  get totalCostEnergy(): any {
    return this.TotalCostEnergy;
  }

  set totalCostEnergy(TD: any) {
    this.TotalCostEnergy = TD;
  }

  get trajects(): any {
    return this.Trajects;
  }

  set trajects(TD: any) {
    this.Trajects = TD;
  }

  get currentAnimationTime(): any {
    return this.CurrentAnimationTime;
  }

  set currentAnimationTime(TD: any) {
    this.CurrentAnimationTime = TD;
  }

  get showAllBusesAnimation(): any {
    return this.ShowAllBusesAnimation;
  }

  set showAllBusesAnimation(TD: any) {
    this.ShowAllBusesAnimation = TD;
  }

  get showAllChargersAnimation(): any {
    return this.ShowAllChargersAnimation;
  }

  set showAllChargersAnimation(TD: any) {
    this.ShowAllChargersAnimation = TD;
  }

  get showAnimButtons(): any {
    return this.ShowAnimButtons;
  }

  set showAnimButtons(TD: any) {
    this.ShowAnimButtons = TD;
  }

  get dataForSpeedStops(): any {
    return this.DataForSpeedStops;
  }

  set dataForSpeedStops(TD: any) {
    this.DataForSpeedStops = TD;
  }

  get currentCycle(): any {
    return this.CurrentCycle;
  }

  set currentCycle(TD: any) {
    this.CurrentCycle = TD;
  }

  get cyclesListOnlyCC(): any {
    return this.CyclesListOnlyCC;
  }

  set cyclesListOnlyCC(TD: any) {
    this.CyclesListOnlyCC = TD;
  }

  get routeStops(): any {
    return this.RouteStops;
  }

  set routeStops(TD: any) {
    this.RouteStops = TD;
  }

  get selectedCharger(): any {
    return this.SelectedCharger;
  }

  set selectedCharger(TD: any) {
    this.SelectedCharger = TD;
  }

  get showChargersData(): any {
    return this.ShowChargersData;
  }

  set showChargersData(TD: any) {
    this.ShowChargersData = TD;
  }

  get chargersMaxValue(): any {
    return this.ChargersMaxValue;
  }

  set chargersMaxValue(TD: any) {
    this.ChargersMaxValue = TD;
  }

  get currentChargersNames(): any {
    return this.CurrentChargersNames;
  }

  set currentChargersNames(TD: any) {
    this.CurrentChargersNames = TD;
  }

  get showSingleBusAnimation(): any {
    return this.ShowSingleBusAnimation;
  }

  set showSingleBusAnimation(TD: any) {
    this.ShowSingleBusAnimation = TD;
  }

  get distanceGaugeMax(): any {
    return this.DistanceGaugeMax;
  }

  set distanceGaugeMax(TD: any) {
    this.DistanceGaugeMax = TD;
  }

  get showPowerGauge(): any {
    return this.ShowPowerGauge;
  }

  set showPowerGauge(TD: any) {
    this.ShowPowerGauge = TD;
  }

  get theAltitude(): any {
    return this.TheAltitude;
  }

  set theAltitude(TD: any) {
    this.TheAltitude = TD;
  }


  get showCharginSiteChart(): boolean {
    return this.ShowCharginSiteChart;
  }

  set showCharginSiteChart(TD: boolean) {
    this.ShowCharginSiteChart = TD;
  }

  public setDataAtIndex(data: any, index: number): void {
    this._THEDATA[index] = data;
    // this._THEDATACOPY[index] = data;
  }

  /*  public setDataCopyAtIndex(data: any, index: number): void {
     this._THEDATACOPY[index] = data;
     // this._THEDATACOPY[index] = data;
   } */

  public getDataAtIndex(index: number): any {
    return this._THEDATA[index];
  }

  /* public getDataCopyAtIndex(index: number): any {
    return this._THEDATACOPY[index];
  } */

  public isCurrentChart(x: number): boolean {
    return this._currentChart === x;
  }

  set currentServices(servi: number) {
    this._currentServices = servi;
  }

  get currentServices(): number {
    return this._currentServices;
  }

  set currentMenu(menu: number) {
    this._currentMenu = menu;
  }

  get currentMenu(): number {
    return this._currentMenu;
  }

  get whichButon(): number {
    return this.WhichButton;
  }

  set whichButton(menu: number) {
    this.WhichButton = menu;
  }

  set currentBusDistance(bd: number) {
    this._currentBusDistance = bd;
  }

  get currentBusDistance(): number {
    return this._currentBusDistance;
  }

  set currentStats(stats: number) {
    this._currentStats = stats;
  }

  get currentStats(): number {
    return this._currentStats;
  }

  set currentVelocity(vel: number) {
    this._currentVelocity = vel;
  }

  get currentVelocity(): number {
    return this._currentVelocity;
  }

  set currentChart(y: number) {
    this._currentChart = y;
  }

  get currentChart(): number {
    return this._currentChart;
  }

  set initialAuto(y: string) {
    this._initialAuto = y;
  }

  get initialAuto(): string {
    return this._initialAuto;
  }

  set endAuto(y: any) {
    this._endAuto = y;
  }

  get endAuto(): any {
    return this._endAuto;
  }

  set minimumConsumption(y: string) {
    this._minimumConsumption = y;
  }

  get minimumConsumption(): string {
    return this._minimumConsumption;
  }

  set maximumConsumption(y: string) {
    this._maximumConsumption = y;
  }

  get maximumConsumption(): string {
    return this._maximumConsumption;
  }

  set currentBattery(y: string) {
    this._currentBattery = y;
  }

  get currentBattery(): string {
    return this._currentBattery;
  }

  set currentPax(y: string) {
    this._currentPax = y;
  }

  get currentPax(): string {
    return this._currentPax;
  }

  get currentRoute(): string {
    return this._currentRoute;
  }

  set currentRoute(nr: string) {
    this._currentRoute = nr;
  }

  get currentRoutePosition(): number {
    return this._currentRoutePosition;
  }

  set currentRoutePosition(nr: number) {
    this._currentRoutePosition = nr;
  }

  get currentCollection(): string {
    return this._currentCollection;
  }

  set currentCollection(nr: string) {
    this._currentCollection = nr;
  }

  get currentLocation(): any {
    return this._currentLocation;
  }

  set currentLocation(nr: any) {
    this._currentLocation = nr;
  }

  get currentType(): string {
    return this._currentType;
  }

  set currentType(nt: string) {
    this._currentType = nt;
  }

  get currentBusSize(): string {
    return this._currentBusSize;
  }

  set currentBusSize(nt: string) {
    this._currentBusSize = nt;
  }

  get currentTime(): string {
    return this._currentTime;
  }

  set currentTime(nt: string) {
    this._currentTime = nt;
  }

  get currentHVAC(): string {
    return this._currentHVAC;
  }

  set currentHVAC(nt: string) {
    this._currentHVAC = nt;
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy: cleaning up state service...');
  }
}
