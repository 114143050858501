<div class="demo_wrap" (click)="onSidenavClose()" *ngIf="this.state.abierto">
  <div class="myBack"></div>
</div>

<div style="
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0px;
  ">
  <img style="
      height: 70px;
      text-align: center;
      align-items: center;
      vertical-align: center;
      padding: 0px;
      margin: 0px;
      border: 0px;
    " src="../../../assets/Mirati-blanco.png" alt="The Logios Logo" />
</div>
<!-- </section> -->
<div *ngIf="state.currentMenu === 3" class="example-container44">
  <div *ngIf="state.seleccionNav==2">
    <br />
    <p style="margin: 10px;">
      <!-- <mat-label>Ruta</mat-label> (selectionChange)="setCurrentCollection($event)" -->
      <mat-form-field appearance="standard" class="mat-white">
        <mat-label
          style="font-size: 14px;">{{this.dictMenuBotonesConfBus.sltrutas[this.state.indiceTranslate]}}</mat-label>
        <mat-select panelClass="my-panel" [(ngModel)]="selectedRuta" (selectionChange)="asignarRuta($event)">
          <mat-option *ngFor="let cole of state.listaRuta; index as i" [value]="cole" (mouseover)="dibujarRuta(cole)">
            {{cole}}
          </mat-option>
        </mat-select>
        <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
      </mat-form-field>
    </p>

    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="this.servicioCHTittle">Seleccione una operación</p>
    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="!this.servicioCHTittle"></p>
    <p style="margin: 10px;">
      <!-- <mat-label>Servicio</mat-label> (selectionChange)="setCurrentCollection($event)" -->
      <mat-form-field appearance="standard" class="mat-white">
        <mat-label
          style="font-size: 14px;">{{this.dictMenuBotonesConfBus.sltoperacion[this.state.indiceTranslate]}}</mat-label>
        <mat-select panelClass="my-panel" [(ngModel)]=" this.operacionseleccionada"
          (selectionChange)="asignarServicio($event)" [disabled]="!this.servicioCH">
          <mat-option *ngFor="let cole of state.listaOperacion" [value]="cole">
            {{cole}}
          </mat-option>

        </mat-select>
        <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
      </mat-form-field>
    </p>
    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="this.tamanioCHTittle">Seleccione un tamaño de bus</p>
    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="!this.tamanioCHTittle"></p>
    <p style="margin: 10px;">
      <!-- <mat-label>Tamanio</mat-label> (selectionChange)="setCurrentCollection($event)" -->
      <mat-form-field appearance="standard" class="mat-white">
        <mat-label
          style="font-size: 14px;">{{this.dictMenuBotonesConfBus.slttamaniobus[this.state.indiceTranslate]}}</mat-label>
        <mat-select panelClass="my-panel" [(ngModel)]="selectedTamanio" (selectionChange)="asignarTamanio($event)"
          [disabled]="!this.tamanioCH">
          <mat-option *ngFor="let cole of state.listaTamanio" [value]="cole">
            {{cole}} {{this.dictMenuBotonesConfBus.medida[this.state.indiceTranslate]}}
          </mat-option>
        </mat-select>
        <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
      </mat-form-field>
    </p>
    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="this.cargadorCHTittle">Seleccione un metodo de carga
    </p>
    <p style="color: red;font-size: 1.0em;height: 12px;;" *ngIf="!this.cargadorCHTittle"></p>
    <br>
    <mat-expansion-panel [expanded]="false" *ngIf="authService.userData.user.authCollections.length > 1"
      [disabled]="!this.cargadorCH" style="margin-top: 10px;">
      <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
        <mat-panel-title>
          <mat-icon style="width: 35px; color: #000096;">bolt</mat-icon>
          {{this.dictMenuBotonesConfBus.modelobus.title[this.state.indiceTranslate]}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <br />
      <p style="margin: 10px;">
        <!-- <mat-label>Ruta</mat-label> (selectionChange)="setCurrentCollection($event)" -->
        <mat-form-field appearance="standard" class="mat-white">
          <mat-label
            style="font-size: 14px;">{{this.dictMenuBotonesConfBus.modelobus.sltconector[this.state.indiceTranslate]}}</mat-label>
          <mat-select panelClass="my-panel" [(ngModel)]="selectedNoc" (selectionChange)="Buscar('Overnight')"
            style="color: white">
            <mat-option *ngFor="let cole of state.listaConectorValue; let i = index"
              [value]="state.listaConectorValue[i]">
              <p *ngIf="state.listaConectorViable[i]" style="color: black;">{{state.listaConectorName[i]}}</p>
              <p *ngIf="!state.listaConectorViable[i]" style="color: grey;">{{state.listaConectorName[i]}} -
                {{this.dictMenuBotonesConfBus.modelobus.optrecomendacion[this.state.indiceTranslate]}}</p>
            </mat-option>
          </mat-select>
          <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
        </mat-form-field>
      </p>
      <p style="margin: 10px;">
        <!-- <mat-label>Ruta</mat-label> (selectionChange)="setCurrentCollection($event)" -->
        <mat-form-field appearance="standard" class="mat-white">
          <mat-label
            style="font-size: 14px;">{{this.dictMenuBotonesConfBus.modelobus.sltpantografo[this.state.indiceTranslate]}}</mat-label>
          <mat-select panelClass="my-panel" [(ngModel)]="selectedOpp" (selectionChange)="Buscar('Opportunity')"
            style="color: white">
            <mat-option *ngFor="let cole of state.listaPantografoValue; let i = index"
              [value]="state.listaPantografoValue[i]">
              <p *ngIf="state.listaPantografoViable[i]" style="color: black;">{{state.listaPantografoName[i]}}</p>
              <p *ngIf="!state.listaPantografoViable[i]" style="color: grey;">{{state.listaPantografoName[i]}} -
                {{this.dictMenuBotonesConfBus.modelobus.optrecomendacion[this.state.indiceTranslate]}}</p>
            </mat-option>
          </mat-select>
          <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
        </mat-form-field>
      </p>
    </mat-expansion-panel>
  </div>
</div>
<div fxLayout class="example-container44" *ngIf="state.currentMenu === 1">
  <ul fxLayout="column" fxLayoutGap="0px" class="navigation-items">
    <li>
      <div fxLayout class="cb" (click)="pres(1)" [style.background-color]="first" [style.color]="color1"
        routerLink="/visualization/dash-overlay">
        <div class="example-button-container1">
          <mat-icon inline="true" [svgIcon]="dash"></mat-icon>
          {{this.dictMenuBotonesInicio.dash[this.state.indiceTranslate]}}
        </div>
      </div>
    </li>
    <li>
      <div fxLayout class="cb" (click)="pres(2)" [style.background-color]="second" [style.color]="color2">
        <div class="example-button-container1">
          <mat-icon inline="true" [svgIcon]="quienes"></mat-icon>
          {{this.dictMenuBotonesInicio.quienessomos[this.state.indiceTranslate]}}
        </div>
      </div>
    </li>
    <li>
      <div fxLayout class="cb" (click)="pres(3)" [style.background-color]="third" [style.color]="color3"
        routerLink="/user-info">
        <div class="example-button-container1">
          <mat-icon inline="true" [svgIcon]="usuario"></mat-icon>
          Usuario
        </div>
      </div>
    </li>
    <li *ngIf="authService.isSuperUser()">
      <div fxLayout class="cb" (click)="pres(6)" [style.background-color]="sixth" [style.color]="color6"
        routerLink="/user-list">
        <div class="example-button-container1">
          <mat-icon>local_library</mat-icon>
          {{this.dictMenuBotonesInicio.listaclientes[this.state.indiceTranslate]}}
        </div>
      </div>
    </li>
    <li *ngIf="authService.isSuperUser()">
      <div fxLayout class="cb" (click)="pres(7)" [style.background-color]="seventh" [style.color]="color7"
        routerLink="/collections-list">
        <div class="example-button-container1">
          <mat-icon>collections</mat-icon>
          {{this.dictMenuBotonesInicio.colecciones[this.state.indiceTranslate]}}
        </div>
      </div>
    </li>
    <li>
      <div fxLayout class="cb" (click)="pres(5)" [style.background-color]="fifth" [style.color]="color5">
        <div class="example-button-container1">
          <mat-icon inline="true" svgIcon="sali-blanco"></mat-icon>
          <!-- <a (click)="salir()"> Salir</a> -->
          {{this.dictMenuBotonesInicio.salir[this.state.indiceTranslate]}}
        </div>
      </div>
    </li>
  </ul>
</div>
<div style=" text-align: center; position: fixed; bottom: 1%; justify-content: center; width: 300px;"
  *ngIf="state.currentMenu === 1">
  <button (click)="cleanCache()" type="submit" style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;" mat-raised-button>
    {{this.dictMenuBotonesInicio.limpiarcache[this.state.indiceTranslate]}}
  </button>
</div>
<div *ngIf="state.currentMenu === 2" class="example-container44">
  <!-- <mat-expansion-panel></mat-expansion-panel> -->
  <mat-accordion multi>

    <mat-expansion-panel [expanded]="true" *ngIf="authService.userData.user.authCollections.length > 1">
      <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
        <mat-panel-title>
          <mat-icon style="width: 35px; color: #000096;">commute</mat-icon>
          {{this.dictMenuBotonesColecciones.titulocolecciones[this.state.indiceTranslate]}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <br />

      <p>
        <!-- <mat-label>Ruta</mat-label> -->
        <mat-form-field appearance="standard" class="mat-white">
          <mat-label
            style="font-size: 14px;">{{this.dictMenuBotonesColecciones.subttcolecciones[this.state.indiceTranslate]}}</mat-label>
          <mat-select panelClass="my-panel anchocoleccion" [(ngModel)]="state.currentCollection"
            (selectionChange)="setCurrentCollection($event)">
            <mat-option *ngFor="let cole of this.state.listaNameCollection" [value]="cole">
              {{cole}}
            </mat-option>
          </mat-select>
          <!-- <mat-hint align="end">Seleccione una ruta</mat-hint> -->
        </mat-form-field>
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div *ngIf="state.currentMenu === 4" class="example-containerGraph">
  <div *ngIf="state.listaEstadosDashboard.operaciones==true">
    <div *ngIf="state.currentButtomGraph==1;else botonOperacionesDf">
      <button (click)="irOperaciones()" type="submit"
        style="font-family: 'Poppins' !important; background-color: white !important; color: #000096 !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px 10px 10px 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo_tiempo_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnoperacion[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonOperacionesDf>
      <button (click)="irOperaciones()" type="submit"
        style="font-family: 'Poppins' !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px 10px 10px 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo_tiempo_blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnoperacion[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.rendimiento==true">
    <div *ngIf="state.currentButtomGraph==2;else botonRendimientoDF">
      <button (click)="irRendimiento()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px 10px 10px 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consupo-por-km_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnrendimiento[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonRendimientoDF>
      <button (click)="irRendimiento()" type="submit"
        style=" font-family: 'Poppins' !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px 10px 10px 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consupo-por-km_blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnrendimiento[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.estrategiacarga==true">
    <div *ngIf="state.currentButtomGraph==3;else botonEstrategiaDF">
      <button (click)="irEstrategiaCarga()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="energia-disponible_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnestrategiacarga[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonEstrategiaDF>
      <button (click)="irEstrategiaCarga()" type="submit"
        style=" font-family: 'Poppins' !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="energia-disponible_blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnestrategiacarga[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.energiarenovable==true">
    <div *ngIf="state.currentButtomGraph==9;else botonEnergiaRenovable">
      <button (click)="irEnergiaRenovable()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="energiarenovable-azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnenergiareno[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonEnergiaRenovable>
      <button (click)="irEnergiaRenovable()" type="submit"
        style=" font-family: 'Poppins' !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="energiarenovable-blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnenergiareno[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.interaccionred==true">
    <div *ngIf="state.currentButtomGraph==4;else botonInteraccionDF">
      <button (click)="irInteracionRed()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo de energia_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btninteraccionred[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonInteraccionDF>
      <button (click)="irInteracionRed()" type="submit"
        style=" font-family: 'Poppins' !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo de energia_blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btninteraccionred[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.energiaintegral==true">
    <div *ngIf="state.currentButtomGraph==11;else botonEnergiaIntegral">
      <button (click)="irEnergiaIntegral()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo de energia_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnenergiaintegral[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonEnergiaIntegral>
      <button (click)="irEnergiaIntegral()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo de energia_blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnenergiaintegral[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.microsimulacion==true">
    <div *ngIf="state.currentButtomGraph==6;else botonMicroSimu">
      <button (click)="irMicroSimu()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo_discriminado_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnmicrosimulacion[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonMicroSimu>
      <button (click)="irMicroSimu()" type="submit"
        style=" font-family: 'Poppins' !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="consumo_discriminado_banco"></mat-icon>{{this.dictMenuBotonesDashboard.btnmicrosimulacion[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.contable==true">
    <div *ngIf="state.currentButtomGraph==8;else botonContable">
      <button (click)="irContable()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="contable-azul"></mat-icon>{{this.dictMenuBotonesDashboard.btncontable[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonContable>
      <button (click)="irContable()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="contable-blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btncontable[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.resumen==true">
    <div *ngIf="state.currentButtomGraph==5;else botonResumenDF">
      <button (click)="irResumenRuta()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="potencia_tiempo_azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnresumen[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonResumenDF>
      <button (click)="irResumenRuta()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="potencia_tiempo"></mat-icon>{{this.dictMenuBotonesDashboard.btnresumen[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.bateria==true">
    <div *ngIf="state.currentButtomGraph==7;else botonBaterias">
      <button (click)="irBateria()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="bateria-azul"></mat-icon>{{this.dictMenuBotonesDashboard.btnbateria[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonBaterias>
      <button (click)="irBateria()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="bateria-blanco"></mat-icon>{{this.dictMenuBotonesDashboard.btnbateria[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.optimizacion==true">
    <div *ngIf="state.currentButtomGraph==10;else botonCatalogoBaterias">
      <button (click)="irOptimizacionBateria()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="optimizacion-blue"></mat-icon>{{this.dictMenuBotonesDashboard.btnoptimizacion[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonCatalogoBaterias>
      <button (click)="irOptimizacionBateria()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="optimizacion-white"></mat-icon>{{this.dictMenuBotonesDashboard.btnoptimizacion[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.economico==true">
    <div *ngIf="state.currentButtomGraph==12;else botonCatalogoBaterias">
      <button (click)="irEconomico()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="economico-blue"></mat-icon>{{this.dictMenuBotonesDashboard.btneconomico[this.state.indiceTranslate]}}
      </button>
    </div>
    <ng-template #botonCatalogoBaterias>
      <button (click)="irEconomico()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="economico-white"></mat-icon>{{this.dictMenuBotonesDashboard.btneconomico[this.state.indiceTranslate]}}
      </button>
    </ng-template>
  </div>
  <div *ngIf="state.listaEstadosDashboard.ingreso==true">
    <div *ngIf="state.currentButtomGraph==13;else botonIngreso">
      <button (click)="irIngreso()" type="submit"
        style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
        border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="income-blue"></mat-icon>Ingresos
      </button>
    </div>
    <ng-template #botonIngreso>
      <button (click)="irIngreso()" type="submit"
        style=" font-family: 'Poppins' !important;
      border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
        <mat-icon style="width: 35px;" inline="true"
          svgIcon="income-white"></mat-icon>Ingresos
      </button>
    </ng-template>
  </div>

</div>
<div *ngIf="state.currentMenu === 6" class="example-containerGraph">
  <div *ngIf="state.currentButtonAnimation==1;else botoAnimacionRuta">
    <button (click)="irAnimacionRuta()" type="submit"
      style=" font-family: 'Poppins' !important; background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="play_azul"></mat-icon>{{this.dictMenuBotonesAnimacion.btnanimacionruta[this.state.indiceTranslate]}}
    </button>

  </div>
  <ng-template #botoAnimacionRuta>
    <button (click)="irAnimacionRuta()" type="submit"
      style=" font-family: 'Poppins' !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="animarruta_blanco"></mat-icon>{{this.dictMenuBotonesAnimacion.btnanimacionruta[this.state.indiceTranslate]}}
    </button>
  </ng-template>
  <div *ngIf="state.currentButtonAnimation==2;else botonAnimacionCargadores">
    <button (click)="irAnimacionCargadores()" type="submit"
      style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="play_azul"></mat-icon>{{this.dictMenuBotonesAnimacion.btnanimacioncargadores[this.state.indiceTranslate]}}
    </button>
  </div>
  <ng-template #botonAnimacionCargadores>
    <button (click)="irAnimacionCargadores()" type="submit"
      style=" font-family: 'Poppins' !important;
  border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="play_blanco"></mat-icon>{{this.dictMenuBotonesAnimacion.btnanimacioncargadores[this.state.indiceTranslate]}}
    </button>
  </ng-template>

  <div *ngIf="state.currentButtonAnimation==3;else botonAnimacionEventos">
    <button (click)="irLedgerIota()" type="submit"
      style=" font-family: 'Poppins' !important;background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="play_azul"></mat-icon>{{this.dictMenuBotonesAnimacion.btnledgereventos[this.state.indiceTranslate]}}
    </button>
  </div>
  <ng-template #botonAnimacionEventos>
    <button (click)="irLedgerIota()" type="submit"
      style=" font-family: 'Poppins' !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true"
        svgIcon="animarruta_blanco"></mat-icon>{{this.dictMenuBotonesAnimacion.btnledgereventos[this.state.indiceTranslate]}}
    </button>
  </ng-template>
</div>
<div *ngIf="state.currentMenu === 7" class="example-containerGraph">
  <!--<div *ngIf="state.currentButtonLedger==1;else botonLedgerIota">
    <button (click)="irLedgerIota()" type="submit"
      style=" font-family: 'Poppins' !important; background-color: white !important; color: #000096 !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true" svgIcon="energia-disponible_azul"></mat-icon>Iota Ledger
    </button>

  </div>
  <ng-template #botonLedgerIota>
    <button (click)="irLedgerIota()" type="submit"
      style=" font-family: 'Poppins' !important;
border-radius: 25px;  text-align: center; align-self: center;margin: 10px;width: 80%;display: flex;justify-content: flex-start;" mat-raised-button>
      <mat-icon style="width: 35px;" inline="true" svgIcon="energia-disponible_blanco"></mat-icon>Iota Ledger
    </button>
  </ng-template>-->
</div>