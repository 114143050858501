<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Comparativa Longitud-->
                            <ejs-chart title={{this.dataTOp.graficocl.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisxConsumoCritRuta" [primaryYAxis]="axisyConsumoCritRuta"
                                [height]="alto" (window:resize)="onResize($event)" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series [dataSource]="state.THEDATA[6]" type="Column" xName="name" yName="value"
                                        legendShape='Rectangle'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!-- Velocidad por Paradas -->
                            <ejs-chart title={{this.dataTOp.graficovp.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisCeroVP" [primaryYAxis]="axisUnoVP" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" [animations]="animations" style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
                                        [dataSource]='this.state.dataForSpeedStops' type='Column' xName='name'
                                        [yName]='elchart' [name]='this.state.NameCyclesListOnlyCC[i]'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 3 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!-- Ciclos de Manejo-->
                            <ejs-chart #chartvel2 title={{this.dataTOp.graficocm.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisDosVP" [primaryYAxis]="axisUnoVP" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" [animations]="animations" *ngIf="state.currentVelocity === 2"
                                (chartMouseClick)='chartMouseClick($event)' style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series *ngFor="let chart of state.THEDATA[3]; index as i;"
                                        [dataSource]="chart.averagespeed" type="Line" xName="name" yName="value"
                                        legendShape="Rectangle" [animation]="animation" [name]="chart.nombre">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 5 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <div style="height: 100%; overflow-x: auto;">
                                <ejs-grid #grid [dataSource]='databus' [allowGrouping]="true" allowSorting="true"
                                    (dataBound)='dataBound($event)' (created)='created()' (load)='load()'
                                    [groupSettings]="groupOptions" [toolbar]='toolbar' [searchSettings]='searchSettings'
                                    [sortComparer]="sortComparer" [groupComparer]="groupComparer" autoFitColumns='false'
                                    columnWidth="auto">
                                    <e-columns>
                                        <!--this.dictHeader.hruta[this.state.indiceTranslate]-->
                                        <e-column width="50" field='BusID'
                                            [headerText]=this.dataTOp.tablacronograma.busid[this.state.indiceTranslate]
                                            textAlign="Center"></e-column>
                                        <e-column width="50" field='Service'
                                            [headerText]=this.dataTOp.tablacronograma.servicio[this.state.indiceTranslate]
                                            textAlign="Center"></e-column>
                                        <e-column width="150" field='Depot'
                                            [headerText]=this.dataTOp.tablacronograma.deposito[this.state.indiceTranslate]></e-column>
                                        <e-column width="80" field='Leaves'
                                            [headerText]=this.dataTOp.tablacronograma.salida[this.state.indiceTranslate]></e-column>
                                        <e-column width="80" field='Arrives'
                                            [headerText]=this.dataTOp.tablacronograma.llegada[this.state.indiceTranslate]
                                            textAlign="Center"></e-column>
                                    </e-columns>
                                </ejs-grid>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 4 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--CONSUMO POR PARADAS-->
                            <ejs-chart #chartvel1 id="chart-container" title="Consumo por Paradas"
                                [primaryXAxis]="axisxCP" [primaryYAxis]="axisyCP" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" (pointClick)="pointClick($event)" style="width:100%;height:100%;"
                                [animations]="animations">
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
                                        [dataSource]='this.state.THEDATA[10]' type='Column' xName='name'
                                        [yName]='elchart' [name]='elchart' [visible]="visible[i]">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 6 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--PASAJEROS PARADAS CICLO  - ocupacion de pasajeros -->
                            <ejs-chart title={{this.dataTOp.graficopp.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisXPP" [primaryYAxis]="axisYPP" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [animations]="animations" style="width:100%;height:100%;" [tooltip]="tooltip"
                                (chartMouseClick)="onBarClick($event)">
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
                                        [dataSource]='this.state.DataForPassCicle' type='Column' xName='name'
                                        [yName]='elchart' [name]='this.state.NameCyclesListOnlyCC[i]' [visible]="i==0?true:false"
                                        [tooltipMappingName]='this.dataSer.NameStops[i]'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 7 && this.estaCompleto" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--TEMPERATURA AMBIENTE-->
                            <ejs-chart title={{this.dataTOp.graficoenv.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisXAmb" [primaryYAxis]="axisYAmb" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [animations]="animations" style="width:100%;height:100%;" [tooltip]="tooltip"
                                (chartMouseClick)="onBarClick($event)">
                                <e-axes>
                                    <e-axis name='axisY2Amb2' opposedPosition='true'
                                        title={{this.dataTOp.graficoenv.ejey2[this.state.indiceTranslate]}}
                                        [titleStyle]='{ fontFamily: "Poppins", fontSize: "24px"}'
                                        [majorGridLines]='{ width: 0 }' [minorGridLines]='{ width: 0 }'
                                        [labelStyle]='{ fontFamily: "Poppins", fontSize: "24px"}'>
                                    </e-axis>
                                </e-axes>
                                <e-series-collection>
                                    <e-series [dataSource]='this.state.listaDatosAmbiente' type='Line' xName='x'
                                        yName='yt' name={{this.dataTOp.graficoenv.ejey[this.state.indiceTranslate]}}>
                                    </e-series>
                                    <e-series [dataSource]='this.state.listaDatosAmbiente' type='Line' xName='x'
                                        yName='yr' name={{this.dataTOp.graficoenv.ejey2[this.state.indiceTranslate]}}
                                        yAxisName='axisY2Amb2'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTOp.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTOp.menu.ciclosmanejo[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irPromVelocidad()" checked="true"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTOp.menu.comparativalong[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irComparativaLong()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTOp.menu.velocidadstop[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irVelocidadStop()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTOp.menu.cronograma[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irCronograma()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTOp.menu.ppasajeros[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irPasajerosCiclos()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTOp.menu.ambiente[this.state.indiceTranslate]}} name="default"
                            style="margin-bottom: 10px;" (click)="irAmbiente()" cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>