<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 1 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Consumo Critico por Operacion-->
                            <ejs-chart title={{this.dataTRend.graficocco.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisxComparativaLong" [primaryYAxis]="axisyComparativaLong"
                                [height]="alto" (window:resize)="onResize($event)" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" style="width:100%;height:100%;">
                                <!--[legendSettings]="legendSettings"-->
                                <e-series-collection>
                                    <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn"
                                        xName="ruta" yName="baselineconsumption" name="Baseline"></e-series>
                                    <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn"
                                        xName="ruta" yName="passconsumption"
                                        name={{this.dataTRend.graficocco.label1[this.state.indiceTranslate]}}></e-series>
                                    <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn"
                                        xName="ruta" yName="hvacconsumption"
                                        name={{this.dataTRend.graficocco.label2[this.state.indiceTranslate]}}></e-series>
                                    <e-series [dataSource]="state.CollectionStatistics" type="StackingColumn"
                                        xName="ruta" yName="impactbtms"
                                        name={{this.dataTRend.graficocco.label3[this.state.indiceTranslate]}}></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 2 && this.estaCompleto==true" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--Consumo de Energia -->
                            <ejs-chart title={{this.dataTRend.graficoce.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="state.axis1[0]" [primaryYAxis]="state.axis1[1]" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" [animations]="animations" style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series *ngFor="let chart of state.THEDATA[0]; index as i;"
                                        [dataSource]="chart.datos" type="Line" xName="name" yName="value"
                                        [name]="chart.nombre" legendShape="Rectangle" [animation]="animation">
                                    </e-series>
                                    <e-series *ngFor="let naranjas of state.THEDATA[0]; index as k;"
                                        [dataSource]='naranjas.orangepoints' type='Bubble' xName='x' yName='y'
                                        size='0.1' tooltipFormat='Batería al 20%'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 3 && this.estaCompleto==true" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!-- Consumo Discriminado -->
                            <ejs-chart title={{this.dataTRend.graficocd.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="state.axis2[0]" [primaryYAxis]="state.axis2[1]" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]='zoom' [palettes]='colorScheme.domain'
                                [tooltip]='tooltip' [animations]="animations" style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series [dataSource]="state.THEDATA[7][2].datos" type="StackingArea" xName="name"
                                        yName="value" [name]="this.label3CD" [animation]="animation"></e-series>
                                    <e-series [dataSource]="state.THEDATA[7][1].datos" type="StackingArea" xName="name"
                                        yName="value" [name]="this.label2CD" [animation]="animation"></e-series>
                                    <e-series [dataSource]="state.THEDATA[7][0].datos" type="StackingArea" xName="name"
                                        yName="value" [name]="this.label1CD" [animation]="animation"></e-series>
                                    <e-series [dataSource]="state.THEDATA[7][3].datos" type="StackingArea" xName="name"
                                        yName="value" name="BTMS" [animation]="animation"></e-series>
                                </e-series-collection> </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 4 && this.estaCompleto==true" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--REGENERACIÓN Y PENDIENTE CAMBIO-->
                            <ejs-chart #chart10 id="chart-container"
                                title={{this.dataTRend.graficoryp.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="state.axis1[0]" [primaryYAxis]="state.axis1[1]" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]='colorScheme.domain'
                                [tooltip]="tooltip" [axes]='axis' [animations]="animations"
                                style="width:100%;height:100%;" [allowFiltering]='false'>
                                <e-series-collection>
                                    <e-series *ngFor="let chart of state.listaConsumoMayorPendiente; index as i;"
                                        [dataSource]="chart.datos" type="Line" xName="name" yName="value"
                                        name={{this.dataTRend.graficoryp.label1[this.state.indiceTranslate]}}
                                        legendShape="Rectangle" [animation]="animation"
                                        tooltipFormat='Consumo <br /> <b>${point.x} : ${point.y}</b>'>
                                    </e-series>
                                    <e-series *ngFor="let chart2 of state.listaConsumoMayorPendiente; index as j;"
                                        [dataSource]="chart2.regen" type="Line" xName="name" yName="value"
                                        [animation]="animation"
                                        name={{this.dataTRend.graficoryp.label2[this.state.indiceTranslate]}}
                                        tooltipFormat='{{this.dataTRend.graficoryp.label2[this.state.indiceTranslate]}} <br /> <b>${point.x} : ${point.y}</b>'>
                                    </e-series>
                                    <e-series *ngFor="let chart3 of state.listaConsumoMayorPendiente; index as k;"
                                        [dataSource]="chart3.slope" yAxisName="yAxis" type='Area' xName='name'
                                        yName='value' opacity=0.5 border-color='transparent'
                                        tooltipFormat='Pendiente <br /> <b>${point.x} : ${point.y}</b>'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 5 && this.estaCompleto==true" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--CONSUMO POR Kilómetro-->
                            <ejs-chart #chart2 id="chart-container" [primaryXAxis]="state.axis5[0]"
                                [primaryYAxis]="state.axis5[1]" [height]='alto' (window:resize)="onResize($event)"
                                [palettes]='colorScheme.domain' [tooltip]='tooltip'
                                title={{this.dataTRend.graficock.titulo[this.state.indiceTranslate]}}
                                style="width:100%;height:100%;">
                                <e-series-collection>
                                    <e-series [dataSource]="state.THEDATA[1]" type="Column" xName="hora"
                                        yName="type"></e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart == 6 && this.estaCompleto==true" style="width:100%;height:100%;">
                        <div class="e-panel-container">
                            <!--Consumo por Paradas-->
                            <ejs-chart #chartvel1 id="chart-container"
                                title={{this.dataTRend.graficocp.titulo[this.state.indiceTranslate]}}
                                [primaryXAxis]="axisxCP" [primaryYAxis]="axisyCP" [height]="alto"
                                (window:resize)="onResize($event)" [zoomSettings]="zoom" [palettes]="colorScheme.domain"
                                [tooltip]="tooltip" (pointClick)="pointClick($event)" style="width:100%;height:100%;"
                                [animations]="animations">
                                <e-series-collection>
                                    <e-series *ngFor="let elchart of this.state.cyclesListOnlyCC; index as i;"
                                        [dataSource]='this.state.THEDATA[10]' type='Column' xName='name'
                                        [yName]='elchart' [name]='this.state.NameCyclesListOnlyCC[i]'>
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">
                            {{this.dataTRend.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTRend.menu.consumenergia[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irConsumoEnergia()" checked="true"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTRend.menu.regenpendiente[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irRegePendCambio()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTRend.menu.consumdiscrimi[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irConsumoDiscriminado()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTRend.menu.consumkm[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irConsumoParada()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTRend.menu.consumcriticopera[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irConsumoCritRuta()" 
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTRend.menu.consumstop[this.state.indiceTranslate]}}
                            name="default" style="margin-bottom: 10px;" (click)="irVelocidadPromedioServicio()"
                            cssClass="e-info"></ejs-radiobutton>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>