import { Component, ElementRef, OnInit, } from '@angular/core';
import { StateService } from 'src/app/shared/state/state.service';
import { datosTraducidos } from 'src/app/auxiliar_components/datatranslate/faketranslate';
import { AnyPaint } from 'mapbox-gl';

@Component({
  selector: 'app-dashboard-ingresos',
  templateUrl: './dashboard-ingresos.component.html',
  styleUrls: ['./dashboard-ingresos.component.scss']
})
export class DashboardIngresosComponent implements OnInit {

  colorScheme = ['#000096', '#88898C', '#F5821E', '#800000', '#4bc8fe']

  constructor(private elRef: ElementRef, public state: StateService) { }

  //* Variables de Menus y Graficos
  public selectedChart: number = 0;
  public selectedCostTicket: any;
  public selectedCostIndex: any = 0;
  public selectedTiempo: any = 1;
  public isSelectedTiempo = false;
  public indices = this.state.indiceTranslate
  public dataTIR = datosTraducidos.DashboardsGraph.economico.menu
  public colorList = []

  //* Variables Datos
  private dataIngreso = this.state.dataIngreso["data"]
  public dataChartIngresoTotalHora: any = []
  public dataChartIngresoAcumuladoDiario: any = []
  public dataChartProyecionIngreso: any = []
  public listaPreciosTicket = this.state.dataIngreso["costosticket"]
  public listaPreciosTicketLabel = this.state.dataIngreso["labelcostoticket"]
  public Moneda = this.state.dataIngreso["moneda"][0]
  public simboloMoneda = this.state.dataIngreso["simbolo"][0]
  public tiempoProyeccion = 'Anual'

  //* Variables de Filtro

  ngOnInit(): void {
    this.selectedChart = 0;
    this.selectedCostIndex = 0;
    this.selectedTiempo = 1;
    this.isSelectedTiempo = false
    this.selectedCostTicket = this.listaPreciosTicket[this.selectedCostIndex]
    this.colorList = this.colorScheme.slice(0, this.listaPreciosTicketLabel.length)
    this.CargaDatos(this.selectedCostTicket, this.dataIngreso)
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
    setTimeout(() => {
      this.selectedChart = 1
    }, 1500);
    return;
  }

  //* Funciones
  CargaDatos(selectedTicket, data) {
    this.dataChartIngresoTotalHora = []
    this.dataChartIngresoAcumuladoDiario = []

    let auxDataIngreso = data.filter(item => item["costoticket"] == selectedTicket)
    for (let i = 0; i < auxDataIngreso.length; i++) {

      let elemento = auxDataIngreso[i];
      let listDataIngresoTotalHora = []
      let listDataIngresoAcumuladoDiario = []

      //? Llenado de datos Ingreso Acumulado Diario
      for (let j = 0; j < elemento["ingresoacumuladox"].length; j++) {
        listDataIngresoAcumuladoDiario.push({ "x": elemento["ingresoacumuladox"][j], "y": elemento["ingresoacumuladoy"][j] })
      }
      //? Llenado de datos Total de Ingreso por Hora
      for (let k = 0; k < elemento["tiempohoras"].length; k++) {
        listDataIngresoTotalHora.push({ "x": elemento["tiempohoras"][k], "y": elemento["ingresohora"][k], "z": elemento["ticketsvendidos"][k] })
      }
      this.dataChartIngresoAcumuladoDiario = listDataIngresoAcumuladoDiario
      this.dataChartIngresoTotalHora = listDataIngresoTotalHora
    }
  }
  CargarDatosIngresoProyeccion(selectedTicket, selectecTiempo, data) {
    this.dataChartProyecionIngreso = []
    let auxDataIngreso = data.filter(item => item["costoticket"] == selectedTicket)
    for (let i = 0; i < auxDataIngreso.length; i++) {
      let listDataProyeccionIngreso = []
      let elemento = auxDataIngreso[i];
      switch (selectecTiempo) {
        // Anual
        case 1:
          this.tiempoProyeccion = 'anual'
          for (let n = 0; n < elemento["timeyear"].length; n++) {
            listDataProyeccionIngreso.push({ "x": elemento["timeyear"][n], "y": elemento["ingresoyear"][n], "z": elemento["ingresoyearacum"][n],"neto":elemento["netoanual"][n] })
          }
          break;
        // Mensual
        case 2:
          this.tiempoProyeccion = 'mensual'
          for (let m = 0; m < elemento["timemount"].length; m++) {
            listDataProyeccionIngreso.push({ "x": elemento["timemount"][m], "y": elemento["ingresomount"][m], "z": elemento["ingresomountacum"][m],"neto":elemento["netomes"][m] })
          }
          break;
        // Diario
        case 3:
          this.tiempoProyeccion = 'diario'
          for (let l = 0; l < elemento["timehour"].length; l++) {
            listDataProyeccionIngreso.push({ "x": elemento["timehour"][l], "y": elemento["ingresoday"][l], "z": elemento["ingresodayacum"][l],"neto":elemento["netohora"][l] })
          }
          break;
        default:
          break;
      }
      this.dataChartProyecionIngreso = listDataProyeccionIngreso
    }
  }

  irIngresoTotalHora() {
    this.selectedChart = 1;
    this.isSelectedTiempo = false;
  }
  irIngresoAcumulado() {
    this.selectedChart = 2;
    this.isSelectedTiempo = false;
  }
  irProyeccionIngreso() {
    this.selectedChart = 3;
    this.isSelectedTiempo = true;
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)

  }
  irTiempoAnual() {
    this.selectedTiempo = 1
    this.tiempoProyeccion = 'anual'
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  irTiempoMensual() {
    this.selectedTiempo = 2
    this.tiempoProyeccion = 'mensual'
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  irTiempoDiario() {
    this.selectedTiempo = 3
    this.tiempoProyeccion = 'diario'
    this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
  }
  cambiarFiltroTicket(indice) {
    this.selectedCostIndex = indice
    this.selectedCostTicket = this.listaPreciosTicket[this.selectedCostIndex]
    this.CargaDatos(this.selectedCostTicket, this.dataIngreso)
    if (this.isSelectedTiempo == true) {
      this.CargarDatosIngresoProyeccion(this.selectedCostTicket, this.selectedTiempo, this.dataIngreso)
    }
  }

  //* Disenio de Aristas
  //?  Grafico Ingreso Total Hora
  public primaryXAxisITH: Object = {
    title: 'Hora del día',
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    maximum: 25,
  };
  public primaryYAxisITH: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: 'Ingreso por hora' + ' (' + this.Moneda + ')',
    minimum: 0,
  };

  public axisITH: Object = [{
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    rowIndex: 0, opposedPosition: true,
    lineStyle: { width: 0 },
    name: 'yAxis',
    labelFormat: '{value}',
    title: 'Tickets vendidos',
    minimum: 0,
  }];

  public chartArea: Object = {
    border: {
      width: 0
    }
  };

  public tooltip = { enable: true };
  public marker = { visible: true, width: 10, height: 10 }

  //?  Grafico Ingreso Acumulado
  public primaryXAxisIA: Object = {
    title: 'Hora del día',
    valueType: 'DateTime'
  };
  public primaryYAxisIA: Object = {
    lineStyle: { width: 0 },
    labelFormat: '{value} ' + this.simboloMoneda,
    title: 'Ingresos acumulados' + ' (' + this.Moneda + ')',
    minimum: 0,
  };

  //? Grafico de Proyeccion  Ingreso
  public XAxisIngresos: Object = {
    title: 'Proyeccion en tiempo',
    valueType: 'Double',
    interval: 1,
    minimum: 0,
    //maximum: 13,
  };
  public YAxisIngresos: Object = {
    lineStyle: { width: 0 },
    valueType:'Double',
    labelFormat : '{value}' + this.simboloMoneda,
    //labelFormat: 'c2',
    title: 'Ingresos Tickets' + ' (' + this.Moneda + ')',
    minimum: 0,
  };
}
