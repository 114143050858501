<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.selectedChart==1" style="width:99%;height:100%; ">
                        <ejs-chart useGroupingSeparator="true" style='display:block;' [primaryXAxis]='primaryXAxisITH' [primaryYAxis]='primaryYAxisITH'
                        title='Total de Ingreso por Hora'[chartArea]='chartArea' [axes]='axisITH' [palettes]='this.colorList'
                        [tooltip]='tooltip' style="width:100%;height:100%; ">
                        <e-series-collection style="width:100%;height:100%;">
                            <e-series [dataSource]='this.dataChartIngresoTotalHora' type='Column' xName='x' yName='y'
                                name='Ingresos por hora' width=2>
                            </e-series>
                            <e-series [dataSource]='this.dataChartIngresoTotalHora' type='Line' xName='x' yName='z'
                                name='Total Ticket Vendidos ' width=2 yAxisName='yAxis'
                                [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    </div>
                    <div *ngIf="this.selectedChart==2" style="width:99%;height:100%; ">
                        <ejs-chart  useGroupingSeparator="true" style='display:block;' [primaryXAxis]='primaryXAxisIA'
                        [primaryYAxis]='primaryYAxisIA' title="Total de Ingreso Acumulado" [chartArea]='chartArea'
                        [palettes]='this.colorList' [tooltip]='tooltip' style="width:100%;height:100%; ">
                        <e-series-collection style="width:100%;height:100%;">
                            <e-series [dataSource]='this.dataChartIngresoAcumuladoDiario' type='Line' xName='x' yName='y'
                                name='Ingreso Diario' width=2>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    </div>
                    <div *ngIf="this.selectedChart==3" style="width:99%;height:100%; ">
                        <ejs-chart useGroupingSeparator="true" style='display:block;' [primaryXAxis]='XAxisIngresos'
                        [primaryYAxis]='YAxisIngresos' title="Proyección ingreso" [chartArea]='chartArea'
                        [palettes]='this.colorScheme' [tooltip]='tooltip' style="width:100%;height:100%; ">
                        <e-series-collection style="width:100%;height:100%;">
                            <e-series [dataSource]='this.dataChartProyecionIngreso' type='Column' xName='x' yName='y'
                                name='Ingreso {{this.tiempoProyeccion}}' width=2>
                            </e-series>
                            <e-series [dataSource]='this.dataChartProyecionIngreso' type='Column' xName='x' yName='z'
                                name='Ingreso {{this.tiempoProyeccion}} acumulado'width=2>
                            </e-series>
                            <e-series [dataSource]='this.dataChartProyecionIngreso' type='Line' xName='x' yName='neto'
                                name='Ingreso {{this.tiempoProyeccion}} neto'width=2 [marker]="this.marker">
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%; display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">Gráficos</h2>
                        <ejs-radiobutton label="Ingreso total por hora" name="filtroChart" style="margin-bottom: 10px;"
                            (click)="irIngresoTotalHora()" checked="true" cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Ingreso acumulado diario" name="filtroChart" style="margin-bottom: 10px;"
                            (click)="irIngresoAcumulado()" cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Proyeccion ingreso" name="filtroChart" style="margin-bottom: 10px;"
                            (click)="irProyeccionIngreso()" cssClass="e-info"></ejs-radiobutton>                      
                    </div>
                    <div style="width:100%;display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">Precio de tickets</h2>
                        <div style="display: flex;align-items: center;flex-direction: row;"
                            *ngFor="let ticket of this.listaPreciosTicketLabel; index as i">
                            <ejs-radiobutton label={{ticket}} name="filtroTicket"
                                [checked]="this.selectedCostIndex==i? true : false " style="margin-bottom: 10px;"
                                (click)="cambiarFiltroTicket(i)" cssClass="e-info"></ejs-radiobutton>
                        </div>
                    </div>
                    <div *ngIf="this.isSelectedTiempo==true" style="width:100%; display: flex;flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 20px">Tiempos de Proyección</h2>
                        <ejs-radiobutton label="Anual" name="filtroChartTiempo" style="margin-bottom: 10px;"
                            (click)="irTiempoAnual()" [checked]="this.selectedTiempo==1? true : false "  cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Mensual" name="filtroChartTiempo" style="margin-bottom: 10px;"
                            (click)="irTiempoMensual()" [checked]="this.selectedTiempo==2? true : false " cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label="Diario" name="filtroChartTiempo" style="margin-bottom: 10px;"
                            (click)="irTiempoDiario()" [checked]="this.selectedTiempo==3? true : false " cssClass="e-info"></ejs-radiobutton>                      
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>