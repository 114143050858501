import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsComponent } from './maps/maps.component';
import { VisualRoutingModule } from './visual-routing/visual-routing.module';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MaterialModule } from '../auxiliar_components/material/material.module';
// import { OverlayModule } from '@angular/cdk/overlay';
import { AvailableEnergyComponent } from './charts/available-energy/available-energy.component';
import { VelocityProfileComponent } from './charts/velocity-profile/velocity-profile.component';
import { ConsumptionDistanceComponent } from './charts/consumption-distance/consumption-distance.component';
import { ConsumptionKilometerComponent } from './charts/consumption-kilometer/consumption-kilometer.component';
import { AverageVelocityComponent } from './charts/average-velocity/average-velocity.component';
import { StatisticsComponent } from './charts/statistics/statistics.component';
import { ChartsNavigationComponent } from './charts-navigation/charts-navigation.component';
import { DashOverlayComponent } from './dash-overlay/dash-overlay.component';
import { InfoRouteComponent } from './charts/info-route/info-route.component';
import { DiscriminatedComponent } from './charts/discriminated/discriminated.component';
import { NewCardComponent } from './charts/new-card/new-card.component';

import { ChartModule, DateTimeCategoryService, DateTimeService, ScrollBarService, ScatterSeriesService, StepLineSeriesService } from '@syncfusion/ej2-angular-charts';
import {
  StripLineService,
  CategoryService,
  LegendService,
  TooltipService,
  ZoomService,
  AreaSeriesService,
  DataLabelService,
  LineSeriesService,
  ColumnSeriesService,
  BarSeriesService,
  StackingColumnSeriesService,
  StackingAreaSeriesService,
  StackingStepAreaSeriesService,
  SplineSeriesService,
  BubbleSeriesService,
  MultiColoredLineSeriesService
} from '@syncfusion/ej2-angular-charts';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DialogModule } from '@syncfusion/ej2-angular-popups';


import { ConsumptionTimeComponent } from './charts/consumption-time/consumption-time.component';
import { RegeneracionComponent } from './charts/regeneracion/regeneracion.component';
import { BusesOptionsComponent } from './buses-options/buses-options.component';
import { FormsModule } from '@angular/forms';
import { PowerTimeComponent } from './charts/power-time/power-time.component';
import { AnnotationsService, CircularGaugeModule } from '@syncfusion/ej2-angular-circulargauge';
import { GaugePowerComponent } from './animation-chargers/gauge-power/gauge-power.component';
import { GaugeSocComponent } from './animation-card/gauge-soc/gauge-soc.component';
import { LinearGaugeModule } from '@syncfusion/ej2-angular-lineargauge';
import { GaugeDistanceComponent } from './animation-card/gauge-distance/gauge-distance.component';
import { BusAnimationListComponent } from './animation-card/bus-animation-list/bus-animation-list.component';
import { IsChargingIconsComponent } from './animation-card/is-charging-icons/is-charging-icons.component';
import { AlertListComponent } from './animation-card/alert-list/alert-list.component';
import { SingleBusViewComponent } from './animation-card/single-bus-view/single-bus-view.component';
import { GaugeDistanceSingleComponent } from './animation-card/gauge-distance-single/gauge-distance-single.component';
import { PuntoCargaComponent } from './animation-card/punto-carga/punto-carga.component';
import { InicioCargaComponent } from './animation-card/inicio-carga/inicio-carga.component';
import { FinCargaComponent } from './animation-card/fin-carga/fin-carga.component';
import { AnimationBarComponent } from './animation-card/animation-bar/animation-bar.component';
import { AlertsBusNamesComponent } from './animation-card/alerts-bus-names/alerts-bus-names.component';
import { ChargerNamesComponent } from './animation-chargers/charger-names/charger-names.component';
import { ChargingPointComponent } from './animation-chargers/charging-point/charging-point.component';
import { ChargerStateComponent } from './animation-chargers/charger-state/charger-state.component';
import { AssignedBusComponent } from './animation-chargers/assigned-bus/assigned-bus.component';
import { ChargeBeginComponent } from './animation-chargers/charge-begin/charge-begin.component';
import { ChargeEndComponent } from './animation-chargers/charge-end/charge-end.component';
import { PowerCeilingComponent } from './animation-chargers/power-ceiling/power-ceiling.component';
import { ChargedEnergyComponent } from './animation-chargers/charged-energy/charged-energy.component';
import { ChargeLostComponent } from './animation-chargers/charge-lost/charge-lost.component';
import { PersonInChargeComponent } from './animation-chargers/person-in-charge/person-in-charge.component';
import { AlertSentComponent } from './animation-chargers/alert-sent/alert-sent.component';
import { AccumEnergyComponent } from './animation-chargers/accum-energy/accum-energy.component';
import { CostComponent } from './animation-chargers/cost/cost.component';
import { BusTypeComponent } from './animation-card/bus-type/bus-type.component';
import { ChargerEventsComponent } from './iota-events/charger-events/charger-events.component';
import { ChargerIdsComponent } from './iota-events/charger-ids/charger-ids.component';
import { IndividualEventComponent } from './iota-events/individual-event/individual-event.component';
import { EnergyTimeComponent } from './charts/energy-time/energy-time.component';
import { VehiclePropertiesComponent } from './vehicle-properties/vehicle-properties.component';
import { SelectedVehiclesComponent } from './selected-vehicles/selected-vehicles.component';
import { ChargersdataComponent } from './charts/chargersdata/chargersdata.component';
import { ChargersCardComponent } from './charts/chargers-card/chargers-card.component';
import { ChargersAccumComponent } from './charts/chargers-accum/chargers-accum.component';
import { ConsumptionStopsComponent } from './charts/consumption-stops/consumption-stops.component';
import { ChargingTimeComponent } from './charts/charging-time/charging-time.component';
import { StopsChargersComponent } from './stops-chargers/stops-chargers.component';
import { ChargingSitesComponent } from './charts/charging-sites/charging-sites.component';
import { NewChartPowerComponent } from './charts/new-chart-power/new-chart-power.component';
import { BusTimeComponent } from './charts/bus-time/bus-time.component';
import { BusLedgerComponent } from './charts/bus-ledger/bus-ledger.component';
import { ChargersTableComponent } from './iota-events/chargers-table/chargers-table.component';
import { DashboardsyncComponent } from './dashboardsync/dashboardsync.component';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChargerComparisonComponent } from './charts/charger-comparison/charger-comparison.component';
import { ComparisonEnergyComponent } from './charts/comparison-energy/comparison-energy.component';
import { ComparisonPowerComponent } from './charts/comparison-power/comparison-power.component';
import { ComparisonBusComponent } from './charts/comparison-bus/comparison-bus.component';
import { DashboardEstadisticasComponent } from './charts/dashboard-estadisticas/dashboard-estadisticas.component';
import { DashboardInteraccionredComponent } from './charts/dashboard-interaccionred/dashboard-interaccionred.component';
import { DashboardEstrategiacargaComponent } from './charts/dashboard-estrategiacarga/dashboard-estrategiacarga.component';
import { DashboardOperacionesComponent } from './charts/dashboard-operaciones/dashboard-operaciones.component';
import { DashboardRendimientoComponent } from './charts/dashboard-rendimiento/dashboard-rendimiento.component';
import { ButtonModule,RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DashboardMicrosimulacionesComponent } from './charts/dashboard-microsimulaciones/dashboard-microsimulaciones.component';
import { AnimacionrutaComponent } from './charts/animacionruta/animacionruta.component';
import { DashboardBateriaComponent } from './charts/dashboard-bateria/dashboard-bateria.component';
import { ContableComponent } from './charts/contable/contable.component';
import { DashboardEnergiarenovableComponent } from './charts/dashboard-energiarenovable/dashboard-energiarenovable.component';
import { DashboardOptimizacionbateriaComponent } from './charts/dashboard-optimizacionbateria/dashboard-optimizacionbateria.component';
import { CardInfoBusComponent } from './charts/card-info-bus/card-info-bus.component';
import { ChargerSharedComponent } from './charts/charger-shared/charger-shared.component';
import { DashboardEconomicoComponent } from './charts/dashboard-economico/dashboard-economico.component';
import { DashboardIngresosComponent } from './charts/dashboard-ingresos/dashboard-ingresos.component';


@NgModule({
  declarations: [
    MapsComponent,
    AvailableEnergyComponent,
    VelocityProfileComponent,
    ConsumptionDistanceComponent,
    ConsumptionKilometerComponent,
    AverageVelocityComponent,
    StatisticsComponent,
    ChartsNavigationComponent,
    DashOverlayComponent,
    InfoRouteComponent,
    DiscriminatedComponent,
    NewCardComponent,
    ConsumptionTimeComponent,
    RegeneracionComponent,
    BusesOptionsComponent,
    PowerTimeComponent,
    GaugePowerComponent,
    GaugeSocComponent,
    GaugeDistanceComponent,
    BusAnimationListComponent,
    IsChargingIconsComponent,
    AlertListComponent,
    SingleBusViewComponent,
    GaugeDistanceSingleComponent,
    PuntoCargaComponent,
    InicioCargaComponent,
    FinCargaComponent,
    AnimationBarComponent,
    AlertsBusNamesComponent,
    ChargerNamesComponent,
    ChargingPointComponent,
    ChargerStateComponent,
    AssignedBusComponent,
    ChargeBeginComponent,
    ChargeEndComponent,
    PowerCeilingComponent,
    ChargedEnergyComponent,
    ChargeLostComponent,
    PersonInChargeComponent,
    AlertSentComponent,
    AccumEnergyComponent,
    CostComponent,
    BusTypeComponent,
    ChargerEventsComponent,
    ChargerIdsComponent,
    IndividualEventComponent,
    EnergyTimeComponent,
    VehiclePropertiesComponent,
    SelectedVehiclesComponent,
    ChargersdataComponent,
    ChargersCardComponent,
    ChargersAccumComponent,
    ConsumptionStopsComponent,
    ChargingTimeComponent,
    StopsChargersComponent,
    ChargingSitesComponent,
    NewChartPowerComponent,
    BusTimeComponent,
    BusLedgerComponent,
    ChargersTableComponent,
    DashboardsyncComponent,
    ChargerComparisonComponent,
    ComparisonEnergyComponent,
    ComparisonPowerComponent,
    ComparisonBusComponent,
    DashboardEstadisticasComponent,
    DashboardInteraccionredComponent,
    DashboardEstrategiacargaComponent,
    DashboardOperacionesComponent,
    DashboardRendimientoComponent,
    DashboardMicrosimulacionesComponent,
    AnimacionrutaComponent,
    DashboardBateriaComponent,
    ContableComponent,
    DashboardEnergiarenovableComponent,
    DashboardOptimizacionbateriaComponent,
    CardInfoBusComponent,
    ChargerSharedComponent,
    DashboardEconomicoComponent,
    DashboardIngresosComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    ButtonModule,
    RadioButtonModule,
    VisualRoutingModule,
    GridModule,
    DialogModule,
    // NgxChartsModule,
    MaterialModule,
    // OverlayModule,
    ChartModule,
    FormsModule,
    CircularGaugeModule,
    LinearGaugeModule,
    DashboardLayoutModule,
    DropDownButtonModule,
    DropDownListModule,
    
  ],
  providers: [
    CategoryService,
    StepLineSeriesService,
    MultiColoredLineSeriesService,
    LegendService,
    TooltipService,
    DataLabelService,
    LineSeriesService,
    ZoomService,
    AreaSeriesService,
    StripLineService,
    ColumnSeriesService,
    BarSeriesService,
    StackingColumnSeriesService,
    StackingAreaSeriesService,
    StackingStepAreaSeriesService,
    SplineSeriesService,
    ScrollBarService,
    BubbleSeriesService,
    DateTimeService,
    DateTimeCategoryService,
    AnnotationsService,
    ScatterSeriesService,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VisualizationModule {}
