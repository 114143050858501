export const datosTraducidos = {
    "urlimages": {
        "iconimagen": ["../../../../assets/estc.png", "../../../../assets/entc.png"]
    },
    "cargas": {
        "loadcoleccion": ["Cargando datos de la colección...", "Loading collection data..."],
        "loadcoleccion2": ["Cargando datos de rutas, horarios, simulaciones...", "Loading route data, schedules, simulations..."]
    },
    "InicioSesion": {
        "auth": ["AUTENTICARSE", "AUTHENTICATE"],
        "errorinvalid": ["No ha sido activado o su usuario y/o contraseña son incorrectos", "You have not been activated or your user and/or password are incorrect"],
        "alertemail": ["Ingrese un correo válido", "Enter a valid email address"],
        "alertpasswd": ["Ingrese una contraseña", "Enter a password"],
        "recordar": ["Recuerdame", "Save it"],
        "recoverpw": ["Recuperar contraseña", "Recover password"],
        "longin": ["Ingresar", "Log in"],
        "singin": ["Registrarse", "Sign in"],
        "correo": ["Correo", "Email"],
        "pw": ["Contraseña", "Password"]
    },
    "Registro": {
        "registro": ["REGISTRARSE", "REGISTER"],
        "successregister": ["¡Usuario registrado con éxito!", "Successfully logged in!"],
        "nombrecontacto": ["Nombre de contacto", "Contact name"],
        "organizacion": ["Organización", "Organization"],
        "correo": ["Correo", "Email"],
        "rptcorreo": ["Repita el correo", "Repeat the email"],
        "password": ["Contraseña", "Password"],
        "rptpassword": ["Repita la contraseña", "Repeat the password"],
        "cel": ["Teléfono", "Phon number"],
        "direccion": ["Dirección", "Address"],
        "pais": ["País", "Country"],
        "estado": ["Estado", "State"],
        "ciudad": ["Ciudad", "City"],
        "errorform": ["Formulario inválido", "Invalid form"],
        "registrar": ["Registrarse", "Sign up"],
        "inicio": ["Inicio", "Home"]
    },
    "Menu": {
        "MenuInicio": {
            "titulohover": ["Menu Inicio", "Home Menu"],
            "dash": ["Dash", "Dash"],
            "quienessomos": ["Quiénes somos", "About us"],
            "listaclientes": ["Lista de clientes", "Customer list"],
            "colecciones": ["Colecciones de datos", "Data collections"],
            "salir": ["Salir", "Log off"],
            "limpiarcache": ["Limpiar cache", "Clean cache"]
        },
        "Coleccion": {
            "titulohover": ["Colecciones", "Collections"],
            "titulocolecciones": ["Colecciones", "Collections"],
            "subttcolecciones": ["Colección actual", "Current collection"],
            "tamanio": ["Tamaño Batería", "Battery Size"]
        },
        "Portafolio": {
            "titlehover": ["Análisis comparativo buses", "Bus comparative analysis"],
            "mensajevacio": ["No hay Buses seleccionados.", "No Buses selected."],
            "mensajeindicacion": ["Arrastre una columna para agrupar", "Drag a column to group"],
            "tablaportafolio": {
                "hruta": ["Ruta", "Route"],
                "hmodelobus": ["Modelo Bus", "Bus Model"],
                "hbusesanalizados": ["N° Buses Analizados", "N° Buses Analyzed"],
                "hsitioscarga": ["N° Sitios de Carga", "N° Charging Sites"],
                "hcargadores": ["N° de Cargadores", "No. of Chargers"],
                "hlistasitiocarga": ["Lista de Sitios de Carga", "List of Charging Sites"],
                "hrangocritico": ["Rango Crítico", "Critical Range"],
                "hrangonecesario": ["Rango Necesario", "Required Range"],
                "hsohminimo": ["SoH Mínimo", "Soh Minimum"],
                "hconsumoenergia": ["Consumo de Energía", "Energy Consumption"],
                "hconsumototalenergia": ["Consumo Total de Energía", "Total Energy Consumption"],
                "hpotenciamaxima": ["Potencia Máxima", "Maximum Power"],
                "mensaje": ["No hay Buses seleccionados", "There are no buses selected"]
            },
            "graficos":{
                "consumoespe":["Consumo específico ponderado de flota (kWh/km)","Fleet average specific consumption (kWh/km)"],
                "minimocargadores":["Mínimo de cargadores requeridos","Minimum number required chargers"],
                "vidautilbateria":["Proyección de vida útil de batería (años)","Projected battery useful life (years)"],
                "demandaenergia":["Demanda de energía por flota (kWh/día)","Fleet energy demand (kWh/day)"],
                "demandapotencia":["Pico de demanda de potencia (kW)","Peak power demand (kW)"],
                "porceenergirenova":["Porcentaje de energía utilizada","Percentage of energy used"],
                "eventocargar":["Eventos de carga","Charging event"],
                "sizebateria":["Tamaño batería (kWh)","Battery size (kWh)"],
                "promusocarga":["Uso de cargadores (hr/día)","Charger utilization (hr/day)"],
                "titulomenu":["Rutas de Buses","Bus Routes"],
                "titulotiposcarga":["Tipos de Carga","Load Types"],
                "titulolistabuse":["Lista de Buses","Bus List"],
            }
        },
        "Menuconfiguracionanalizis": {
            "titlehover": ["Configuración de Análisis", "Analysis Configuration"],
            "sltrutas": ["Ruta", "Route"],
            "sltoperacion": ["Operación", "Operations"],
            "slttamaniobus": ["Tamaño bus", "Bus size"],
            "medida": ["metros", "meters"],
            "modelobus": {
                "title": ["Modelo de bus", "Bus model"],
                "sltconector": ["Carga por conector", "Charging by connector"],
                "sltpantografo": ["Carga por pantógrafo", "Charging by pantograph"],
                "optrecomendacion": ["No recomendable", "Not recommended"]
            }
        },
        "Dashboard": {
            "titledashboard": ["Dashboards", "Dashboards"],
            "btnoperacion": ["Operaciones", "Operations"],
            "btnrendimiento": ["Rendimiento", "Performance"],
            "btnestrategiacarga": ["Estrategia de carga", "Charging strategy"],
            "btninteraccionred": ["Energía Individual", "Individual Energy"],
            "btnmicrosimulacion": ["Micro simulación", "Micro simulation"],
            "btncontable": ["Contable", "Accounting"],
            "btnresumen": ["Resumen", "Summary"],
            "btnbateria": ["Batería", "Battery"],
            "btnenergiareno": ["Energía renovable", "Renewable energy"],
            "btnoptimizacion": ["Optimización", "Optimization"],
            "btneconomico": ["Económico", "Economic"],
            "btnenergiaintegral": ["Energía Integral", "Integral Energy"]

        },
        "Animacion": {
            "titleanimacion": ["Animación", "Animation"],
            "btnanimacionruta": ["Animación ruta", "Route animation"],
            "btnanimacioncargadores": ["Animación cargadores", "Charger Animation"],
            "btnledgereventos": ["Ledger de eventos", "Event Ledger"]
        },
        "Optimizacionredcarga": {
            "titleoptredcarga": ["Optimización red carga", "Load network optimization"]
        }

    },
    "DashboardsGraph": {
        "operaciones": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "comparativalong": ["Comparativa de Rutas", "Comparison of Route"],
                "velocidadstop": ["Velocidad por Paradas", "Speed by Stops"],
                "ciclosmanejo": ["Ciclos de Manejo", "Driving Cycles"],
                "cronograma": ["Cronograma", "Schedule"],
                "ppasajeros": ["Ocupacion de Pasajeros", "Passenger Occupancy"],
                "ambiente": ["Ambiente", "Environment"],

            },
            "graficocl": {
                "titulo": ["Comparativa de Rutas", "Comparison of Route"],
                "ejey": ["Distancia (km)", "Distance (km)"],
                "ejex": ["Ruta", "Route"]
            },
            "graficovp": {
                "titulo": ["Velocidad por Paradas", "Speed by Stops"],
                "ejey": ["Velocidad (km/h)", "Speed (km/h)"],
                "ejex": ["Paradas", "Stops"]
            },
            "graficocm": {
                "titulo": ["Ciclos de Manejo", "Driving Cycles"],
                "ejey": ["Velocidad (km/h)", "Speed (km/h)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "tablacronograma": {
                "busid": ["Bus ID", "Bus ID"],
                "servicio": ["Servicio", "Service"],
                "deposito": ["Estación", "Depot"],
                "salida": ["Salida", "Leaves"],
                "llegada": ["Llegada", "Arrives"]
            },
            "graficopp": {
                "titulo": ["Ocupacion de Pasajeros", "Passenger Occupancy"],
                "ejey": ["Porcentaje de Ocupación (%)", "Occupancy Percentage (%)"],
                "ejex": ["Paradas", "Stops"]
            },
            "graficoenv": {
                "titulo": ["Temperatura Ambiente", "Environment Temperature"],
                "ejey": ["Temperatura (C°)", "Temperature (C°)"],
                "ejey2": ["Radiación (W/m²)", "Radiation (W/m²)"],
                "ejex": ["Hora del día", "Time of day"]
            },
        },
        "rendimiento": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "consumcriticopera": ["Consumo Crítico por Operación", "Critical Consumption by Operation"],
                "consumenergia": ["Consumo de Energía", "Energy Consumption"],
                "consumdiscrimi": ["Consumo Discriminado", "Discriminated Consumption"],
                "regenpendiente": ["Regeneración y Pendiente", "Regeneration and Slope"],
                "consumkm": ["Consumo por Kilometro", "Consumption per Kilometer"],
                "consumstop": ["Consumo por Paradas", "Consumption per Stops"]
            },
            "graficocco": {
                "titulo": ["Consumo Crítico por Operación", "Critical Consumption by Operation"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Operación", "Operation"],
                "label1": ["Impacto de Pasajeros", "Passenger Impact"],
                "label2": ["Impacto de HVAC", "HVAC Impact"],
                "label3": ["Impacto de BTMS", "BTMS Impact"]
            },
            "graficoce": {
                "titulo": ["Consumo de Energía", "Energy Consumption"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Distancia (km)", "Distance (km)"]
            },
            "graficocd": {
                "titulo": ["Consumo Discriminado", "Discriminated Consumption"],
                "ejeydiu": ["Energía (kWh)", "Energy (kWh)"],
                "ejexdiu": ["Distancia (km)", "Distance (km)"],
                "ejeyover": ["Energía por día (kWh)", "Energy per day (kWh)"],
                "ejexover": ["Distancia por día (km)", "Distance per day (km)"],
                "label1": ["HVAC", "HVAC"],
                "label2": ["Pasajeros", "Passenger"],
                "label3": ["Baseline", "Baseline"]
            },
            "graficoryp": {
                "titulo": ["Regeneración y Pendiente", "Regeneration and Slope"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Distancia (km)", "Distance (km)"],
                "ejey2": ["Pendiente (°)", "Slope (°)"],
                "label1": ["Consumo Máximo", "Maximum Consumption"],
                "label2": ["Regeneración", "Regeneration"]
            },
            "graficock": {
                "titulo": ["Consumo por Kilometro", "Consumption per Kilometer"],
                "ejey": ["Consumo (kWh/km)", "Consumption (kWh/km)"],
                "ejex": ["Servicio", "Service"],
            },
            "graficocp": {
                "titulo": ["Consumo por Paradas", "Consumption per Stops"],
                "ejey": ["Consumo (kWh)", "Consumption (kWh)"],
                "ejex": ["Paradas", "Stops"]
            }
        },
        "estrategiacarga": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "titulosecond": ["Potencia Nominal", "Nominal Power"],
                "titulothrid": ["SoH Batería", "Battery SoH"],
                "sohnew": ["Nueva", "New"],
                "sohend": ["Fin de vida", "End of life"],
                "descrecar100": ["Descarga y Recarga (SoH 100%)", "Discharging and Recharging (100% SoH)"],
                "descrecarcritico": ["Descarga y Recarga (SoH Crítico)", "Discharging and Recharging (Critical SoH)"],
                "energiadispo100": ["Energía Disponible (SoH 100%)", "Available Energy (100% SoH)"],
                "energiadispocritico": ["Energía Disponible (SoH Crítico)", "Available Energy (Critical SoH)"],
                "tiemprecarga": ["Tiempo de Recarga", "Recharge Time"],
                "tiemprecargacritic": ["Tiempo de Recarga Crítico", "Recharge Time (Critical SoH)"]
            },
            "graficodc100": {
                "titulo": ["Descarga y Recarga (SoH 100%)", "Discharging and Recharging (SoH 100%)"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficodccritico": {
                "titulo": ["Descarga y Recarga (SoH Crítico)", "Discharging and Recharging (Critical SoH)"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficoed100": {
                "titulo": ["Energía Disponible (SoH 100%)", "Available Energy (100% SoH)"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Distancia (km)", "Distance (km)"],
                "label1": ["Energía Disponible Instantanea", "Instant Available Energy"]
            },
            "graficoedcritico": {
                "titulo": ["Energía Disponible (SoH Crítico)", "Available Energy (Critical SoH)"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Distancia (km)", "Distance (km)"],
                "label1": ["Energía Disponible Instantanea", "Instant Available Energy"]
            },
            "graficotr": {
                "titulo": ["Tiempo de Recarga", "Recharge Time"],
                "ejey": ["Tiempo de Recarga (minutos)", "Recharge Time (minutes)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficotrcritic": {
                "titulo": ["Tiempo de Recarga (SoH Crítico)", "Recharge Time (Critical SoH)"],
                "ejey": ["Tiempo de Recarga (minutos)", "Recharge Time (minutes)"],
                "ejex": ["Hora del día", "Time of day"]
            }
        },
        "interaccionred": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "titulosecond": ["Sitio de Carga", "Charge Site"],
                "titulothrid": ["SoH Batería", "Battery SoH"],
                "sohnew": ["Nueva", "New"],
                "sohend": ["Fin de vida", "End of life"],
                "demandenergia": ["Demanda de Energía", "Energy Demand"],
                "demandpotencia": ["Demanda de Potencia", "Power Demand"],
                "numerobusescargand": ["Número de Buses Cargando", "Number of Buses Charging"],
                "cargadoresreque": ["Cargadores Requeridos", "Required Chargers"],
                "demandenergiahora": ["Demanda de Energía por Hora", "Power Demand per Hour"]
            },
            "graficode": {
                "titulo": ["Energía de Red vs Hora del Día", "Grid Power vs Time of Day"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficodp": {
                "titulo": ["Potencia vs Hora del día", "Power vs Time of day"],
                "ejey": ["Potencia (kW)", "Power (kW)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficobc": {
                "titulo": ["Buses Cargando vs Hora del Día", "Buses Loading vs Time of Day"],
                "ejey": ["Número de Buses Cargando", "Number of Buses Charging"],
                "ejex": ["Hora del Día", "Time of Day"]
            },
            "graficocr": {
                "titulo": ["Número Cargadores vs Potencia", "Number of Chargers vs Charger Nominal Power"],
                "ejey": ["Número de Cargadores", "Number of Chargers"],
                "ejex": ["Potencia (kW)", "Charger Nominal Power (kW)"],
                "label1": ["Carga Diurna", "Diurnal Charge"],
                "label2": ["Carga Nocturna", "Night Charge"]
            },
            "graficodeh": {
                "titulo": ["Demanda de Energía por Hora", "Power Demand per Hour"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Hora del día", "Time of day"]
            }
        },
        "microsimulacion": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "titulosecond": ["Potencia Nominal", "Nominal Power"],
                "titulothird": ["Leyenda", "Legend"],
                "estadobuses": ["Estado de Bus", "Bus Status"],
                "estadocargadores": ["Estado de Cargadores", "Charger Status"],
                "utilizacioncargadores": ["Utilización de Cargadores", "Utilization Chargers"],
                "listaleyenda": {
                    "cargando": ["Cargando", "Charging"],
                    "standby": ["Standby", "Standby"],
                    "enservicio": ["En servicio", "In service"],
                    "atraso": ["Atraso", "Delay"],
                    "conector1": ["Conector 1", "Connector 1"],
                    "conector2": ["Conector 2", "Connector 2"]
                }
            },
            "graficoeb": {
                "titulo": ["Estado de Bus", "Bus Status"],
                "ejey": ["Bus ID", "Bus ID"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficoec": {
                "titulo": ["Estado de Cargadores", "Charger Status"],
                "ejey": ["Cargador ID", "Chargers ID"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "graficouc": {
                "titulo": ["Utilización de Cargadores", "Utilization Chargers"],
                "ejey": ["Hora de Uso", "Hour of Use"],
                "ejex": ["Cargador ID", "Chargers ID"],
                "label1": ["Carga 100% de potencia", "100% power load"],
                "label2": ["Carga 100% de potencia transitoria entre conectores", "100% transient power load between connectors"],
                "label3": ["Carga control de tappering", "Load tappering control"]
            }
        },
        "resumen": {
            "info1": {
                "ciudad": ["Ciudad", "City"],
                "ruta": ["Ruta", "Route"],
                "longitud": ["Longitud", "Length"],
                "operacion": ["Operación", "Operation"],
                "servicio": ["Servicios", "Services"],
                "opmedicion": ["Servicios diarios", "Daily services"],
                "distanciadiaria": ["Distancia diaria", "Daily distance"]
            },
            "info2": {
                "medida1": ["Número de unidades", "Number of units"],
                "medida2": ["metros", "meter"],
                "largo": ["Largo", "Size"],
                "medida3": ["Método de carga", "Charging method"],
                "medida4": ["Ocupación máxima", "Maximum occupancy"]

            },
            "info3": {
                "medida": ["Capacidad", "Capacity"],
                "medida1": ["kWh", "kWh"],
                "medida2": ["Estado de salud crítico", "Critical SoH"],
                "medida3": ["Química", "Chemistry"],
                "medida4": ["Ciclos de descarga anual", "Charging cycles per year"]
            },
            "info4": {
                "medida": ["Potencia instalada", "Nameplate capacity"],
                "medida1": ["MW", "MW"],
                "medida2": ["Generación", "Generation"],
                "medida3": ["Superficie", "Surface area"],
            },
            "info5": {
                "medida": ["GEI", "GHG"],
                "header1": ["Bus", "Bus"],
                "header2": ["Flota", "Fleet"],
                "data1": ["CO2 reducido/día", "CO2 mitigation/day"],
                "data2": ["CO2 reducido/año", "CO2 mitigation/year"],
                "data3": ["Tokenización", "Tokenization"],

            },
            "infografico1": {
                "graficode": ["Demanda de Energía (kWh)", "Energy Demand (kWh)"],
                "graficodp": ["Demanda de Potencia (kW)", "Power Demand (kW)"],
                "graficoc": ["Cargadores", "Chargers"],
                "graficorc": ["Conectores Requeridos", "Required Connectors"],
            },
            "infografico2": {
                "title": ["Consumo por Kilómetro", "Consumption per Kilometer"],
                "ejey": ["Consumo (kWh/km)", "Consumption (kWh/km)"],
                "ejex": ["Servicio", "Service"]
            }
        },
        "bateria": {
            "menu": {
                "titulo": ["Gráficos", "Graphs"],
                "rbconsumobtms": ["Consumo BTMS", "Consumption BTMS"],
                "rbpaquete": ["Paquete de Baterías", "Battery Packs"],
                "rbcelda": ["Celda de Baterías", "Battery Cell"]
            },
            "graficoconsumpbtms": {
                "titulo": ["Consumo BTMS", "Consumption BTMS"],
                "ejex": ["Distancia (Km)", "Distance (Km)"],
                "ejey": ["Energía  (kWh)", "Energy (kWh)"],
            },
            "infotablapack": {
                "model": ["Modelo", "Model"],
                "capacity": ["Energía (kWh)", "Energy (kWh)"],
                "carga": ["Capacidad (A-hr)", "Capacity (A-hr)"],
                "quimica": ["Química", "Chemistry"],
                "volumen": ["Volumen (m³)", "Volume (m³)"],
                "peso": ["Peso (Kg)", "Weight (Kg)"],
                "densidadener": ["Densidad energía (Wh/Kg)", "Energy density (Wh/Kg)"],
                "temperatura": ["Temperatura (°C)", "Temperature (°C)"],
                "arquitectura": ["Arquitectura", "Architecture"],
            },
            "infotablacelda": {
                "model": ["Modelo", "Model"],
                "capacity": ["Capacidad (Ah)", "Capacity(Ah)"],
                "quimica": ["Química", "Chemistry"],
                "dimensiones": ["Dimensiones (L, A, H, mm)", "Dimensions (L, A, H, mm)"],
                "peso": ["Peso (Kg)", "Weight (Kg)"],
                "densidadener": ["Densidad energía (Wh/Kg)", "Energy density (Wh/Kg)"],
                "ciclos": ["Vida en ciclos", "Cycle life"],
                "temperatura": ["Temperatura de operación (°C)", "Operating temperature (°C)"],
                "arquitectura": ["Arquitectura", "Architecture"],
                "proveedor": ["Proveedor", "Provider"],
            }
        },
        "tablaanimacion": {
            "bus": ["Bus", "Bus"],
            "estado": ["Estado", "Status"],
            "horainicio": ["Hora inicio", "Start time"],
            "tiempotranscurrido": ["Tiempo transcurrido", "Elapsed time"],
            "distancia": ["Distancia (km)", "Distance (km)"],
            "distanciacarga": ["Distancia a carga (km)", "Distance to load(km)"],
            "autonomia": ["Autonomía (km)", "Autonomy (Km)"],
            "energiaremanente": ["Energía remanente (kWh)", "Remaining energy (kWh)"],
            "energiadisponible": ["Energía disponible (kWh)", "Available energy (kWh)"],
            "soc": ["SoC (%)", "SoC (%)"],
            "regeneracionbus": ["Regeneración energía bus interno (kWh)", "Regeneration energy internal bus (kWh)"],
            "sitiocarga": ["Sitio de carga", "Charging site"],
            "conector": ["Conector", "Connector"],
            "equipo": ["Equipo", "Equipment"],
            "btnstart": ["Iniciar Animación", "Start Animation"],
            "btnshow": ["Mostrar Buses", "Show Buses"],
            "btnclose": ["Terminar Animación", "End Amination"],
            "btnhide": ["Ocultar", "Hide"],
            "btnreset": ["Reinicar Animación", "Reset Animation"],
            "btnstop": ["Detener Animación", "Stop Animation"],
            "estadocarga": ["Cargando", "Loading"],
            "estadotransit": ["Tránsito", "Transit"],
            "TRbusser": ["Buses en servicio : ", "Buses in services : "],
            "TRbuscharg": ["Buses cargando : ", "Charging buses : "],
            "TRbusstand": ["Buses en standby : ", "Buses in standby : "],
            "TRdistanace": ["Distancia recorrida (Km) : ", "Distance traveled (Km) : "],
            "TRchargersfree": ["Cargadores disponibles : ", "Available chargers : "],
            "TRchargersfull": ["Cargadores ocupados : ", "Busy chargers : "],
            "TRenergycs": ["Energía consumida, servicio (kWh) : ", "Energy consumed, service (kWh) : "],
            "TRenergycr": ["Energía consumida, recargas (kWh) : ", "Energy consumed, recharging (kWh) : "],
            "TRenergyc": ["Costo en energía (S/.) : ", "Energy cost (S/.) : "],
            "TRbussoc": ["Buses con SoC crítico : ", "Critical SoC buses : "],
            "THid": ["Bus id : ", "Bus id : "],
            "THstate": ["Estado : ", "Status : "],
            "THhorainit": ["Hora inicio : ", "Start timeday : "],
            "THdistance": ["Distancia (Km) : ", "Distance (Km) : "],
            "THsitiocarga": ["A sitio de carga (Km) : ", "To charging site (Km) : "],
            "THautonomia": ["Autonomía (km) : ", "Autonomy (Km) : "],
            "THregeneracion": ["Regeneración (kWh) : ", "Regeneration (kWh) : "],
            "THsoc": ["SoC (%) : ", "SoC (%) : "],
            "THenergiadip": ["Energía disponible (kWh) : ", "Available energy (kWh) : "],
            "THsitiocargaenergia": ["A sitio de carga (kWh) : ", "To charging site (kWh) : "],

        },
        "contable": {
            "menu": {
                "sitiocarga": ["Sitio de carga", "Charging site"],
                "graph": ["Gráficos", "Graphs"],
                "opcone": ["Consumo total de energía", "Total energy consumption"],
                "opcicloanualservi": ["Ciclos Anuales por Servicio", "Annual Cycles per Service"],
                "opvidautilservi": ["Vida Útil por Servicio", "Cycle Life per Service"]
            },
            "consumte": {
                "title": ["Demanda en la red anual", "Anual network demand"],
                "ejex": ["Año", "Year"],
                "ejey": ["Demanda energía (MWh)", "Energy demand (MWh)"]
            },
            "cicloanualser": {
                "title": ["Ciclos anuales por servicio", "Annual cycles per service"],
                "ejex": ["Servicio del Día", "Day Services"],
                "ejey": ["Ciclos Anuales", "Annual Cycles"]
            },
            "vidautilser": {
                "title": ["Vida útil por servicio", "Cycle life per service"],
                "ejey": ["Vida Útil (años)", "Cycle Life (years)"],
                "ejex": ["Servicio del Día", "Day Services"]
            }
        },
        "optimizacion": {
            "dataopt": {
                "celdasserie": ["Celdas en Serie", "Cells in Series"],
                "celdasparalelo": ["Celdas en Paralelo", "Cells in Parallel"],
                "numceldas": ["Número de Celdas", "Number of Cells"],
                "pesobateria": ["Peso de Batería (Kg)", "Battery Weight (Kg)"],
                "tamaniobateria": ["Energía (kWh)", "Energy (kWh)"],
                "anios": ["Vida Útil (años)", "Lifespan (years)"],
                "ciclos": ["Ciclos Anuales", "Annual Cycles"],
                "numeromodulos": ["Número de Modulos", "Number of Modules"],
                "proveedorpack": ["Proveedor Modulo", "Module  Supplier"],
                "referenciapack": ["Referencia Modulo", "Module  Reference"],
                "arquitecturamodel": ["Arquitectura de Modulos", "Module Architecture"], "energiapack": ["Energía Modulo (kWh)", "Module Energy (kWh)"],
                "pesopack": ["Peso Modulo (Kg)", "Module Weight (Kg)"],
                "dimensionespack": ["Dimensiones Modulo (mm)", "Module Dimensions (mm)"],
                "energiatotal": ["Energía Total (kWh)", "Total Energy (kWh)"],
                "pesototal": ["Peso Total (Kg)", "Total Weight (Kg)"],
                "volumentotal": ["Volumen Total (L)", "Total Volume (L)"],
                "arquitecturacelda": ["Arquitectura de Celdas", "Cell Architecture"],
            },
            "sitiocarga": ["Sitio de Carga", "Charging Site"],
            "rdbTBO": ["Tabla Batería Optimizadas", "Optimized Battery Table"],
            "rdbTMC": ["Tabla Modulos Comerciales", "Commercial Modules Table"],
        },
        "energiarenovable": {
            "menu": {
                "grafico": ["Gráficos", "Graphs"],
                "rbttenergianeta": ["Energía neta disponible", "Total available energy"],
                "rbtdisponibilidadpo": ["Disponibilidad de potencia", "Power availability"],
                "tbrenergiarenovableacum": ["Energía renovable acumulada", "Accumulated renewable energy"]
            },
            "energianeta": {
                "title": ["Energía neta disponible", "Total available energy"],
                "ejey": ["Energía (kWh)", "Energy (kWh)"],
                "ejex": ["Hora del día", "Time of day"]
            },
            "disponibilidadpot": {
                "title": ["Disponibilidad de potencia", "Power availability"],
                "ejey": ["Potencia (kW)", "Power (kW)"],
                "ejex": ["Hora del día", "Time of day"],
                "ejex1": ["Potencia utilizada", "Used power"],
                "ejex2": ["Potencia disponible", "Available power"]
            },
            "energia": {
                "title": ["Energía renovable acumulada", "Accumulated renewable energy"],
                "ejey": ["Energía (MWh)", "Energy (MWh)"],
                "ejex": ["Hora del día", "Time of day"],
                "ejex1": ["Energía utilizada", "Used energy"],
                "ejex2": ["Energía excedente", "Over energy"]
            }
        },
        "economico":{
            "menu":{
                "soh":["SoH","SoH"],
                "sohnew": ["Nueva", "New"],
                "sohend": ["Fin de vida", "End of life"],
                "grafico": ["Gráficos", "Graphs"],
                "rbtcostoenergiahora":["Costo energía por hora","Energy cost per hour"],
                "rbtcostoenergiaacumulado":["Costo energía acumulado","Accumulated energy cost"],
                "preciolable":["Precio Energía","Energy Price"],
                "graphcostohora":{
                    "title":["Costo total de energía por hora","Total energy cost per hour"],
                    "ejex":["Hora del día","Time of day"],
                    "ejexlabel":["Costo (cargador ","Cost (chargers "],
                    "ejey1":["Costo de Energía","Cost of Energy"],
                    "ejey1label":["Eventos de Carga (cargador ","Load Events (chargers "],
                    "ejey2":["Nro Eventos de Carga","No. of Load Events"],
                },
                "graphcostoacumulado":{
                    "title":["Costo energía acumulado","Accumulated energy cost"],
                    "ejex":["Hora del día","Time of day"],
                    "ejey1":["Costo de Energía","Cost of Energy"],
                    "ejey1label":["Equipo","Equipment"]
                }
            }
        }
    },
    "TarjetaBusInfo": {
        "plataformavehicular": {
            "titulo": ["Plataforma Vehicular", "Vehicle Platform"],
            "rfevehiculo": ["Referencia Vehículo", "Vehicle Reference"],
            "tipocarga": ["Tipo de Carga", "Charging Type"],
            "pesoplataforma": ["Peso de la Plataforma", "Platform Weigth"],
            "tamaniovehiculo": ["Tamaño Vehículo", "Vehicle Size"],
            "pass": ["Pasajeros", "Passengers"],
            "sillas": ["Sillas", "Seats"],
            "consumoaux": ["Consumo Auxiliar", "Auxiliary Consumption"],
        },
        "trenpotencia": {
            "titulo": ["Tren de Potencia", "Powertrain"],
            "tipomotor": ["Tipo Motor", "Motor Type"],
            "conf": ["Configuración", "Configuration"],
            "pesomotor": ["Peso Motor", "Motor Weight"],
            "potencianominal": ["Potencia Nominal", "Nominal Power"],
            "maxrpm": ["Máxima RPM", "Maximum RPM"],
            "maxtorque": ["Máximo Torque", "Maximum Torque"],
            "gearbox": ["Gearbox", "Gearbox"],
        },
        "almacenamientoenergetico":{
            "titulo": ["Almacenamiento Energético", "Energy Storage"],
            "quimicabat": ["Química Batería", "Battery Chemistry"],
            "energia": ["Energía", "Energy"],
            "capacidad": ["Capacidad", "Capacity"],
            "maxcorriente": ["Máxima Corriente", "Maximum Current"],
            "maxvoltaje": ["Voltaje Máximo", "Maximum Voltage"],
            "pesobateria": ["Peso Batería", "Battery Weight"],
        }
    }
}
