import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { StateService } from '../state/state.service';
import { AuthService } from '../auth/auth-service.service';
import {  throwError } from 'rxjs';
import { CollectionsService } from '../collections/collections-service.service';
// import { LocalstorageService } from '../storage/localstorage.service';
import { DexieService } from '../dexie/dexie.service';
import { IotaService } from '../iota/iota.service';
import { MapService } from 'src/app/shared/map/map.service';


@Injectable({
  providedIn: 'root'
})
export class CalidadService {
  private mainUrl = this.state.backendURL
  constructor(
    private http: HttpClient,
    private state: StateService,
    private auth: AuthService,
    private collec: CollectionsService,
    // private lStore: LocalstorageService,
    private dexieDB: DexieService,
    private iota: IotaService,
    public mapser: MapService
  ) {
  }
  
  handleError(error: HttpErrorResponse): any {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
  public async getEstadosDashboard(cadena1: string): Promise<void> {
    console.log("Entrando getEstadosDashboard", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'estadodashboardquery/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaEstadosDashboard = results[0]
    console.log("Resultado EstadosDashboard", this.state.listaEstadosDashboard)
    return;
  }

  public async getCatalogoBateria(cadena1: string): Promise<void> {
    console.log("Entrando getCatalogoBateria", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'getcatalogobateria/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaCatalogoBateria = results
    console.log("Resultado CatalogoBateria", this.state.listaCatalogoBateria)
    return;
  }

  public async getRatingData(cadena1: string): Promise<void> {
    console.log("Entrando getRatingData", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "id": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'ratingbuses/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.dataRating = results
    console.log("Resultado getRatingData", this.state.dataRating)
    return;
  }

  public async sendActivity(coleccion: any, usuarios:any): Promise<void> {

    console.log("Entrando a History User Activity", coleccion, usuarios)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    let datenow = new Date().toLocaleString()
    const query = {
      "usuarios":usuarios,
      "coleccion":coleccion,
      "date":datenow,
    };
    try {
      results = await this.http.post(this.mainUrl + 'registerhistory/', query, {
        responseType: 'json',
        headers,
      })
        .toPromise();
      console.log("Registro de actividad exitoso")
    } catch (error) {
      console.error(error)
    }
    return;
  }

  public async getEconomicoData(economicoid: string): Promise<void> {
    console.log("Entrando getEconomicoData", economicoid)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": economicoid,
    };
    results = await this.http.post(this.mainUrl + 'geteconomico/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.dataEconomico = results
    this.state.listaCostoAnualEconomico =this.state.dataEconomico["costoanual"]
    this.state.listaCostoMensualEconomico =this.state.dataEconomico["costomensual"]
    this.state.listaCostoDiarioEconomico =this.state.dataEconomico["costodiario"]
    this.state.listaCostoLabelEconomico =this.state.dataEconomico["costos"]
    this.state.listaCostoMonedaEconomico =this.state.dataEconomico["simbolo"]
    this.state.listaLabelTicketEconomico =this.state.dataEconomico["labelcosto"]
    console.log("Resultado Economico", this.state.dataEconomico)
    return;
  }

  public async getIngresoData(ingresoid: string): Promise<void> {
    console.log("Entrando getIngresoData", ingresoid)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": ingresoid,
    };
    results = await this.http.post(this.mainUrl + 'getingreso/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.dataIngreso = results
    this.state.listaIngresoAnual =this.state.dataIngreso["ingresoanual"]
    this.state.listaIngresoMensual =this.state.dataIngreso["ingresomensual"]
    this.state.listaIngresoDiario =this.state.dataIngreso["ingresodiario"]
    this.state.listaIngresoLabel =this.state.dataIngreso["labelcostoticket"]
    this.state.listaIngresoMoneda =this.state.dataIngreso["simbolo"]
    console.log("Resultado Ingreso", this.state.dataIngreso)
    return;
  }

  public async getCSV(): Promise<void> {
    this.http.get('http://localhost:8000/getexcel?STRID=AeroLimaV2_R1-Bus15Global_Overnight_9m_255kWh_hvacON_passHIGH', { 
      responseType: 'blob' 
    }).subscribe(blob => {
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'datostres.xlsx';
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    });
  }

  public async getTipoEconomico(economicoid: string): Promise<void> {
    console.log("Entrando getTipoEconomico", economicoid)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": economicoid,
    };
    results = await this.http.post(this.mainUrl + 'gettipoeconomico/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.dataTipoEconomico = results    
    console.log("Resultado Tipo Economico", this.state.dataTipoEconomico)
    return;
  }

}

