<section style="overflow: hidden; visibility: visible;">

  <!-- ////////////////////// Interacción con la red ///////////////////// -->
  <div id="blubbles" *ngIf="visibleButtons && !animationIsPlaying" cdkDrag>

    <div class="bubble" id="largeestra">

      <button *ngIf="visibleButtons && !animationIsPlaying" class="botonGNew botonG10"
        [style.background]="backgroundarray[10]" (click)="newactivate(10)" type="button"
        [disabled]="mystate.currentBattery === '' || mystate.currentPax === '' || mystate.currentHVAC === ''">

        <mat-icon inline="true" [svgIcon]="
                    this.mystate.currentChart === 0
                      ? 'consumo de energia_blanco'
                      : activadoarray[10]
                      ? 'consumo de energia_blanco'
                      : 'consumo de energia_azul'">
        </mat-icon>
      </button>

    </div>
    <div class="subtitulos large">
      Interacción de la Red
    </div>
  </div>

  <!-- ////////////////////// Estrategia de carga ///////////////////// -->
  <div id="estrategiacarga" *ngIf="visibleButtons && !animationIsPlaying" cdkDrag>

    <div class="bubble" id="largeestra">
      <button *ngIf="visibleButtons && !animationIsPlaying" class="botonGNew botonG10"
        [style.background]="backgroundarray[2]" (click)="newactivate(2)" type="button"
        [disabled]="mystate.currentBattery === '' || mystate.currentPax === '' || mystate.currentHVAC === ''">

        <mat-icon inline="true" [svgIcon]="
        this.mystate.currentChart === 0
          ? 'energia-disponible_blanco'
          : activadoarray[2]
          ? 'energia-disponible_blanco'
          : 'energia-disponible_azul'
      "></mat-icon>
      </button>

    </div>
    <div class="subtitulos large">
      Estrategia de carga
    </div>
  </div>

  <div id="autonobub" *ngIf="visibleButtons && !animationIsPlaying" cdkDrag>

    <div class="bubble" id="largeestra">

      <button *ngIf="visibleButtons && !animationIsPlaying" class="botonGNew botonG10"
        [style.background]="backgroundarray[7]" (click)="newactivate(7)" type="button"
        [disabled]="mystate.currentBattery === '' || mystate.currentPax === '' || mystate.currentHVAC === ''">
        <mat-icon inline="true" [svgIcon]="
        this.mystate.currentChart === 0
          ? 'consupo-por-km_blanco'
          : activadoarray[7]
          ? 'consupo-por-km_blanco'
          : 'consupo-por-km_azul'
      "></mat-icon>
      </button>

    </div>
    <div class="subtitulos large">
      Rendimiento
    </div>
  </div>

  <!-- ////////////////////// Operaciones ///////////////////// -->
  <div id="operacionbub" *ngIf="visibleButtons && !animationIsPlaying" cdkDrag>

    <div class="bubble" id="largeestra">

      <button *ngIf="visibleButtons && !animationIsPlaying" class="botonGNew botonG10"
        [style.background]="backgroundarray[20]" (click)="newactivate(20)" type="button">

        <mat-icon inline="true" [svgIcon]="
        this.mystate.currentChart === 0
          ? 'consumo_tiempo_blanco'
          : activadoarray[20]
          ? 'consumo_tiempo_blanco'
          : 'consumo_tiempo_azul'
      "></mat-icon>
      </button>

    </div>
    <div class="subtitulos large">
      Operaciones
    </div>
  </div>

  <!-- ////////////////////// Resumen ///////////////////// -->
  <div id="dashboard" *ngIf="visibleButtons && !animationIsPlaying" cdkDrag>

    <div class="bubble" id="largeestra">
      <button *ngIf="visibleButtons && !animationIsPlaying" class="botonGNew botonG10"
        [style.background]="backgroundarray[16]" (click)="newactivate(16)" type="button">

        <mat-icon inline="true" [svgIcon]="
        this.mystate.currentChart === 0
          ? 'potencia_tiempo'
          : activadoarray[16]
          ? 'potencia_tiempo'
          : 'potencia_tiempo_azul'
      "></mat-icon>
      </button>
    </div>
    <div class="subtitulos large">
      Resumen Ruta
    </div>
  </div>

  <button style="right: 1%;
  position: fixed;
  top: 95%; opacity: 0;" type="button">

    <mat-icon inline="true" [svgIcon]="'estadisticas_blanco'"></mat-icon>
  </button>

  <mat-icon [ngClass]="mystate.showDetails ? 'animation botonDetallesBlue' : 'animation botonDetallesWhite'"
    [matTooltip]="'Detalles'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    (click)="showDetailsButtons()"
    *ngIf="!visibleButtons && mystate.currentRoute !== 'None' && !mystate.showDetailsVel && !mystate.showDetailsEne"
    [svgIcon]="mystate.showDetails ? 'potencia_tiempo_azul' : 'potencia_tiempo'">
  </mat-icon>

  <mat-icon [ngClass]="mystate.showDetailsVel ? 'animation botonDetallesVelBlue' : 'animation botonDetallesVelWhite'"
    [matTooltip]="'Detalles Velocidad'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    (click)="showDetailsVelEne('vel')" *ngIf="mystate.showDetails && !mystate.showDetailsEne"
    [svgIcon]="mystate.showDetailsVel ? 'potencia_tiempo_azul' : 'potencia_tiempo'">
  </mat-icon>

  <mat-icon [ngClass]="mystate.showDetailsEne ? 'animation botonDetallesVelWhite' : 'animation botonDetallesWhite'"
    [matTooltip]="'Disminuir ciclo'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    (click)="disminuirCiclo()" *ngIf="mystate.showDetailsVel || mystate.showDetailsEne"
    [svgIcon]="!mystate.showDetails ? 'play_azulizq' : 'play_blancoizq'">
  </mat-icon>

  <mat-icon
    [ngClass]="mystate.showDetailsEne ? 'animation botonDetallesEneAumWhite' : 'animation botonDetallesEneWhite'"
    [matTooltip]="'Aumentar ciclo'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    (click)="aumentarCiclo()" *ngIf="mystate.showDetailsVel || mystate.showDetailsEne"
    [svgIcon]="!mystate.showDetails ? 'play_azul' : 'play_blanco'">
  </mat-icon>

  <buttom [ngClass]="mystate.showDetailsEne ?  'animation botonCicloEne' : 'animation botonCiclo'"
    [matTooltip]="'Ciclo actual'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    *ngIf="mystate.showDetailsVel || mystate.showDetailsEne"> {{mystate.cyclesListOnlyCC[mystate.currentCycle]}}
  </buttom>

  <div style="display: flex; flex-direction: column; bottom: 15px; left: 185px; position: absolute;
  background-color: white; padding: 5px; box-shadow: 5px 10px 18px rgba(0, 0, 0, .2); border-radius: 5%;"
    *ngIf="mystate.showDetailsVel || mystate.showDetailsEne">
    <div style="display: flex;  font-size: 10px;" *ngIf="this.mystate.showDetailsVel">
      <div class="botonrojo" style="background: blue;"></div>
      {{bluemessage}}
    </div>
    <div style="display: flex;  font-size: 10px;" *ngIf="this.mystate.showDetailsEne">
      <div class="botonrojo" style="background: purple;"></div>
      {{greenmessage}}
    </div>
    <div style="display: flex;  font-size: 10px;" *ngIf="this.mystate.showDetailsVel">
      <div class="botonrojo" style="background: yellow;"></div>
      {{yellowmessage}} <span style="font-size: 14px; margin-left: 3px; margin-right: 3px;">&le;</span>
      {{yellowmessage2}}
    </div>
    <div style="display: flex;  font-size: 10px;" *ngIf="this.mystate.showDetailsEne">
      <div class="botonrojo" style="background: red;"></div>
      {{yellowmessage}} <span style="font-size: 14px; margin-left: 3px; margin-right: 3px;">&le;</span>
      {{yellowmessage2}}
    </div>
    <div style="display: flex;  font-size: 10px;" *ngIf="this.mystate.showDetailsEne">
      <div class="botonrojo" style="background: orange;"></div>
      {{orangemessage}} <span style="font-size: 14px; margin-left: 3px; margin-right: 3px;">&le;</span>
      {{orangemessage2}}
    </div>
    <div style="display: flex; font-size: 10px;" *ngIf="this.mystate.showDetailsVel">
      <div class="botonrojo" style="background: red;"></div>
      {{redmessage}}
    </div>
    <div style="display: flex; font-size: 10px;" *ngIf="this.mystate.showDetailsEne">
      <div class="botonrojo" style="background: yellow;"></div>
      {{redmessage}} <span style="font-size: 14px; margin-left: 3px; margin-right: 3px;">&le;</span> {{redmessage2}}
    </div>
    <div style="display: flex; font-size: 10px;" *ngIf="this.mystate.showDetailsEne">
      <div class="botonrojo" style="background: green;"></div>
      {{purplemessage}}
    </div>
  </div>

  <mat-icon [ngClass]="mystate.showDetailsEne ? 'animation botonDetallesEneBlue' : 'animation botonDetallesEneWhite'"
    [matTooltip]="'Detalles Energía'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    (click)="showDetailsVelEne('ene')"
    *ngIf="mystate.showDetails && !mystate.showDetailsVel && mystate.currentBattery !== ''"
    [svgIcon]="mystate.showDetailsEne ? 'potencia_tiempo_azul' : 'potencia_tiempo'">
  </mat-icon>

  <mat-icon class="animationsecond botonstop" inline="true" [matTooltip]="'Detener animación'"
    matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    *ngIf="mystate.isAnimationON && mystate.currentChart !== 0" (click)="stopAnimation()">
    stop</mat-icon>



  <mat-icon [ngClass]="animationIsPlaying ? 'animationsecond botonplayBlue' : 'animationsecond botonplayWhite'"
    inline="true" [matTooltip]="'Animar ciclo'" matTooltipClass="custom-tooltip" [matTooltipPosition]="'right'"
    *ngIf="mystate.isAnimationON && mystate.currentChart !== 0" (click)="playPauseAnimation()">
    {{playIcon}}</mat-icon>





  <!----------------------------------------- REAL CONTENT -------------------------------->
  <app-card-info-bus></app-card-info-bus>
  <div #MyDOMElement17 *ngIf="mystate.currentChart === 17">
    <app-dashboardsync></app-dashboardsync>
  </div>
  <div #MyDOMElement18 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 18">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          COMPARACIÓN CONSUMO
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-comparison-bus [grande]="grande" style="width: 100% !important;" #childBusTime></app-comparison-bus>
    </div>
  </div>
  <div #MyDOMElement19 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 19">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">
      <div class="laNouvelle">
        <h2>
          BUSES CARGANDO
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-comparison-energy [grande]="grande" style="width: 100% !important;" #comparisionBus></app-comparison-energy>
    </div>
  </div>
  <div #MyDOMElement20 *ngIf="mystate.currentChart === 20">
    <app-charger-comparison></app-charger-comparison>
  </div>
  <div #MyDOMElement22 *ngIf="mystate.currentChart === 22">
    <app-bus-ledger></app-bus-ledger>
  </div>
  <div #myDOMElement16 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 16">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">
      <div class="laNouvelle">
        <h2>
          BUSES CARGANDO
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-bus-time [grande]="grande" style="width: 100% !important;" #childBusTime></app-bus-time>
    </div>
  </div>
  <div #myDOMElement1 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 1">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          CONSUMO DE ENERGÍA
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-consumption-distance [grande]="grande" style="width: 100% !important;" #childConsum>
      </app-consumption-distance>
    </div>
  </div>

  <div #myDOMElement8 *ngIf="mystate.currentChart === 8">
    <app-dashboard-rendimiento></app-dashboard-rendimiento>
  </div>
  <div #myDOMElement2 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 2">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>

    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          CONSUMO POR KILÓMETRO
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-consumption-kilometer [grande]="grande" style="width: 100% !important;" #childKilo>
      </app-consumption-kilometer>
    </div>
  </div>
  <div #myDOMElement3 *ngIf="this.mystate.currentChart === 3">
    <app-dashboard-estrategiacarga></app-dashboard-estrategiacarga>
  </div>
  <div #MyDOMElement9 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 9">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>

    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          {{ mystate.currentStats === 2? 'DESCARGA Y RECARGA':'DESCARGA Y RECARGA (100% SOH)' }}
        </h2>
        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
            <button style="
                border-radius: 25px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="setCurrentChargeAndDischarge(1)">
              100% SOH
            </button>
            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="setCurrentChargeAndDischarge(2)">
              SoH Crítico
            </button>
          </div>
        </div>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-consumption-time [grande]="grande" style="width: 100% !important;" #childTime></app-consumption-time>
    </div>
  </div>

  <div #MyDOMElement9 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 15">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>

    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          TIEMPO DE CARGA
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-charging-time [grande]="grande" style="width: 100% !important;" #childTCharge></app-charging-time>
    </div>
  </div>

  <div #myDOMElement4 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 4">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>

    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          {{ mystate.currentVelocity === 1 ? 'VELOCIDAD POR PARADAS' : 'PROMEDIO VELOCIDAD' }}
        </h2>
        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
            <button style="
                border-radius: 25px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="setCurrentVelocity(2)">
              Promedio
            </button>
            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="setCurrentVelocity(1)">
              Por Paradas
            </button>

          </div>
        </div>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-velocity-profile [grande]="grande" style="width: 100% !important;" #childVelocity></app-velocity-profile>
    </div>
  </div>
  <div #MyDOMElement21 *ngIf="mystate.currentChart === 21">
    <app-dashboard-operaciones></app-dashboard-operaciones>
  </div>
  <div #MyDOMElement24 *ngIf="mystate.currentChart === 28">
    <app-dashboard-microsimulaciones></app-dashboard-microsimulaciones>
  </div>
  <div #MyDOMElement6 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 6">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>

    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          {{ mystate.currentStats === 6 ? 'CONSUMO CRÍTICO POR RUTA' : 'COMPARATIVA LONGITUD' }}
        </h2>

        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="getData(6)">
              Consumo
            </button>
            <button style="
                border-radius: 25px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="getData(4)">
              Longitud
            </button>
          </div>
        </div>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-statistics [grande]="grande" style="width: 100% !important;" #childStatistics></app-statistics>
    </div>
  </div>

  <div #myDOMElement10 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 10">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          REGENERACIÓN Y PENDIENTE CAMBIO
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>
      <app-new-chart-power [grande]="grande" style="width: 100% !important;" #childTopo></app-new-chart-power>
    </div>
  </div>

  <div #myDOMElement14 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.currentChart === 14">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          CONSUMO POR PARADA
        </h2>
        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-consumption-stops [grande]="grande" style="width: 100% !important;" #childPorParada></app-consumption-stops>
    </div>
  </div>
  <div #myDOMElement11 *ngIf="this.mystate.currentChart === 11">
    <app-charger-comparison></app-charger-comparison>
  </div>
  <div #myDOMElement12 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'"
    *ngIf="this.mystate.currentChart === 12">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          {{ mystate.currentStats === 1 ? 'ENERGÍA RED VS TIEMPO' : 'ENERGÍA RED VS TIEMPO' }}
        </h2>

        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
          </div>
        </div>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-energy-time [grande]="grande" style="width: 100% !important;" #childEnergy></app-energy-time>
    </div>
  </div>

  <div #myDOMElement13 [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'"
    *ngIf="this.mystate.currentChart === 13">
    <app-new-card *ngIf="!grande"></app-new-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">

        <h2>
          {{mystate.currentStats === 1 ? 'DEMANDA EN LA RED' : 'DEMANDA POR HORA'}}
        </h2>

        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
                font-size: 10px;
              " mat-raised-button (click)="impactGetData(1)">
              Demanda en la red
            </button>

            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
                font-size: 10px;
              " mat-raised-button (click)="impactGetData(2)">
              Demanda por hora
            </button>
          </div>
        </div>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-chargers-accum [grande]="grande" style="width: 100% !important;" #childChargersAccum></app-chargers-accum>
    </div>
  </div>

  <div #myDOMChargers [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.showChargersData">
    <app-chargers-card *ngIf="!grande"></app-chargers-card>
    <div class="vl" *ngIf="!grande"></div>
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          {{cadenachargerschart}}
        </h2>

        <div class="laNueve">
          <div style="align-self: right; " *ngIf="mystate.iconoVisible">
            <button style="
                border-radius: 25px;
                margin-right: 5px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="chargersGetData(1)">
              Potencia
            </button>
            <button style="
                border-radius: 25px;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
              " mat-raised-button (click)="chargersGetData(2)">
              Energía
            </button>
          </div>
        </div>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-chargersdata [grande]="grande" style="width: 100% !important;" #childchargers></app-chargersdata>
    </div>
  </div>

  <div [ngClass]="grande ? 'tarjetaNuevaGrande' : 'tarjetaNueva'" *ngIf="mystate.showCharginSiteChart">
    <div style="display:flex; flex-direction: column; width: 100% !important;">

      <div class="laNouvelle">
        <h2>
          Sitios de Carga
        </h2>

        <div>
          <mat-icon *ngIf="mystate.iconoVisible" style="align-self: right; cursor: pointer; font-size: 16px;"
            (click)="downloadChart()">
            cloud_download</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && !grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            launch</mat-icon>
          <mat-icon *ngIf="mystate.iconoVisible && grande" style="cursor: pointer; font-size: 16px;"
            (click)="grande = !grande">
            close</mat-icon>
          <mat-spinner *ngIf="!mystate.iconoVisible" diameter="30"></mat-spinner>
        </div>
      </div>

      <app-charging-sites [grande]="grande"></app-charging-sites>
    </div>
  </div>
  <app-info-route *ngIf="mystate.currentChart === 0 && mystate.currentBattery !== '' && visibleButtons">
  </app-info-route>

  <div class="centrado" *ngIf="mystate.currentChart === 0 && mystate.showBusesOptions && !visibleButtons">
    <app-buses-options></app-buses-options>
  </div>

  <div class="centrado" *ngIf="mystate.currentChart === 0 && mystate.isChargingSitesON && !visibleButtons">
    <app-stops-chargers></app-stops-chargers>
  </div>

  <div class="centrado" *ngIf="mystate.currentChart === 0 && mystate.showVehicleProperties && !visibleButtons && false">
    <app-vehicle-properties></app-vehicle-properties>
  </div>

  <div class="centrado" *ngIf="mystate.currentChart === 0 && mystate.showVehicleProperties && !visibleButtons">
    <app-bus-ledger></app-bus-ledger>
  </div>

  <div class="demo_wrap" (click)="showBusesOptions()"
    *ngIf="mystate.showBusesOptions && mystate.currentRoute !== 'None'">
    <div class="myBack"></div>
  </div>

  <div class="animInfo1" *ngIf="animationIsPlayingAllRoutes === 1 && !mystate.showAllChargersAnimation">
    <div style="display: flex; flex-direction: row; background-color: #000096; font-family: 'Poppins' !important;">
      <app-alerts-bus-names></app-alerts-bus-names>
      <app-alert-list></app-alert-list>
    </div>

  </div>

  <div class="animInfo2" *ngIf="mystate.showSingleBusAnimation != ''" cdkDrag>
    <div style="display: flex; flex-direction: row; background-color: #000096; font-family: 'Poppins' !important;">
      <app-single-bus-view></app-single-bus-view>
    </div>
  </div>

  <div *ngIf="animationIsPlayingAllRoutes === 1 && mystate.showAllBusesAnimation"
    style="display: flex; flex-direction: column; align-items: center;  position: absolute; font-family: 'Poppins' !important;
    width: 79%; height: 90%; top: 5%; left: 5%; text-align: center;  border-radius: 15px;  background-color: #000096; padding: 10px;">

    <div
      style="display: flex; flex-direction: row; background-color: #000096; align-content: space-around; font-family: 'Poppins' !important; ">

      <app-bus-animation-list></app-bus-animation-list>
      <app-bus-type></app-bus-type>
      <app-is-charging-icons></app-is-charging-icons>
      <app-gauge-soc></app-gauge-soc>
      <app-gauge-distance></app-gauge-distance>
      <app-gauge-distance-single></app-gauge-distance-single>
      <app-punto-carga></app-punto-carga>
      <app-inicio-carga></app-inicio-carga>
      <app-fin-carga></app-fin-carga>
    </div>
    <app-animation-bar
      style="width: inherit; border: 2px solid white; background-color: #000096; height: 50px !important; display:  flex; align-content: flex-start; font-family: 'Poppins' !important;
    color: white; position: absolute; bottom: 0% !important;  font-size: 17px; font-weight: bold; margin-bottom: 5px !important; text-shadow: 1px 1px 2px black;">
    </app-animation-bar>
  </div>

  <div *ngIf="animationIsPlayingAllRoutes === 1 && mystate.showAllChargersAnimation"
    style="display: flex; flex-direction: column; align-items: center;  position: absolute; font-family: 'Poppins' !important; overflow-y: auto;
    width: 90%; height: 87%; top: 2%; left: 5%; text-align: center;  border-radius: 15px;  background-color: #000096; padding: 10px;" cdkDrag>

    <div
      style="display: flex; flex-direction: row; background-color: #000096; align-content: space-around; font-family: 'Poppins' !important; ">
      <app-charger-names></app-charger-names>
      <app-charging-point></app-charging-point>
      <app-charger-state></app-charger-state>
      <app-assigned-bus></app-assigned-bus>
      <app-charge-begin></app-charge-begin>
      <app-charge-end></app-charge-end>
      <app-gauge-power></app-gauge-power>
      <app-power-ceiling></app-power-ceiling>
      <app-charged-energy></app-charged-energy>
      <app-accum-energy></app-accum-energy>
      <app-cost></app-cost>
      <app-charge-lost></app-charge-lost>
    </div>

  </div>


  <app-animation-bar *ngIf="animationIsPlayingAllRoutes === 1 && mystate.showAllChargersAnimation"
    style="width: inherit; border: 2px solid white; background-color: #000096; height: 50px !important; display:
      flex; align-content: flex-start; font-family: 'Poppins' !important;  color: white; position: fixed; left: 110px ;
      bottom: 0% !important;  width: 90% !important; font-size: 17px; font-weight: bold; margin-bottom: 5px !important; text-shadow: 1px 1px 2px black;">
  </app-animation-bar>


  <div *ngIf="mystate.isEventsON" class="centrado">
    <app-chargers-table></app-chargers-table>
  </div>
  <div #MyDOMElement23 *ngIf="mystate.currentChart === 23">
    <app-chargers-table></app-chargers-table>
  </div>

  <div #MyDOMElement25 *ngIf="mystate.currentChart === 25">
    <app-animacionruta></app-animacionruta>
  </div>

  <div #MyDOMElement26 *ngIf="mystate.currentChart === 29">
    <app-dashboard-bateria></app-dashboard-bateria>
  </div>
  <div #MyDOMElement27 *ngIf="mystate.currentChart === 30">
    <app-contable></app-contable>
  </div>
  <div #MyDOMElement28 *ngIf="mystate.currentChart === 31">
    <app-dashboard-energiarenovable></app-dashboard-energiarenovable>
  </div>
  <div #MyDOMElement29 *ngIf="mystate.currentChart === 32">
    <app-dashboard-optimizacionbateria></app-dashboard-optimizacionbateria>
  </div>
  <div #MyDOMElement30 *ngIf="mystate.currentChart === 33">
    <app-charger-shared></app-charger-shared>
  </div>
  <div #MyDOMElement31 *ngIf="mystate.currentChart === 34">
    <app-dashboard-economico></app-dashboard-economico>
  </div>
  <div #MyDOMElement32 *ngIf="mystate.currentChart === 35">
    <app-dashboard-ingresos></app-dashboard-ingresos>
  </div>
</section>