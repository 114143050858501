<div class="tarjetaNueva">
    <div class="control-section">
        <ejs-dashboardlayout id='defaultLayout' #defaultLayout [columns]="8" [cellSpacing]='cellSpacing'
            [allowDragging]='allowDragging' style="height: 100%;">
            <div *ngIf="this.estaCompleto==true">
                <div id="one" class="e-panel" data-row="1" data-col="0" data-sizeX="2" data-sizeY="1">
                    <div class="e-panel-container cardcontenido">
                        <img src="../../../assets/gpsroute-blue.png"
                            style="margin-bottom: 10px; height: 40px;width: 44px;" alt="">
                        <div class="contendTitulos">
                            <div class="text-align titulocard">{{this.dataTRm.info1.ciudad[this.state.indiceTranslate]}}
                                :
                                {{this.state.currentCity}}</div>
                            <div class="text-align titulocard">{{this.dataTRm.info1.ruta[this.state.indiceTranslate]}} :
                                {{this.nuevaRuta}}</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info1.longitud[this.state.indiceTranslate]}} :
                                {{state.currentBusDistance.toFixed(0)}} km</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info1.operacion[this.state.indiceTranslate]}}
                                : {{this.operacionValor}}</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info1.distanciadiaria[this.state.indiceTranslate]}} : {{
                                this.converEndAuto.toFixed(0) }} km
                            </div>
                        </div>
                    </div>
                </div>
                <div id="two" class="e-panel" data-row="1" data-col="2" data-sizeX="2" data-sizeY="1">
                    <div class="e-panel-container cardcontenido ">
                        <img src="../../../assets/busLogios.png" style="margin-bottom: 20px;height: 30px;width: 50px;"
                            alt="">
                        <div class="contendTitulos">
                            <div class="text-align titulocard">
                                {{this.dataTRm.info2.medida1[this.state.indiceTranslate]}}:
                                {{this.flota}}</div>
                            <div class="text-align titulocard">{{this.dataTRm.info2.largo[this.state.indiceTranslate]}}:
                                {{
                                state.currentBusSize }} {{this.dataTRm.info2.medida2[this.state.indiceTranslate]}}
                            </div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info2.medida3[this.state.indiceTranslate]}} :
                                {{this.cargaMetodo}}</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info2.medida4[this.state.indiceTranslate]}} :
                                {{this.maxpasajeros}}</div>
                        </div>
                    </div>
                </div>
                <div id="three" class="e-panel" data-row="1" data-col="4" data-sizeX="2" data-sizeY="1">
                    <div class="e-panel-container cardcontenido ">
                        <img src="../../../assets/bateriaLogios.png"
                            style="margin-bottom: 20px;height: 30px;width: 30px;" alt="">
                        <div class="contendTitulos">
                            <div class="text-align titulocard">
                                {{this.dataTRm.info3.medida[this.state.indiceTranslate]}}: {{this.currentBateria}}
                                {{this.dataTRm.info3.medida1[this.state.indiceTranslate]}}</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info3.medida2[this.state.indiceTranslate]}}: {{state.SohCritico }}%
                            </div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info3.medida3[this.state.indiceTranslate]}}: {{this.tipobateria }} </div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info3.medida4[this.state.indiceTranslate]}}: {{this.ciclos }} </div>
                        </div>
                    </div>
                </div>
                <div id="four" class="e-panel" data-row="1" data-col="6" data-sizeX="2" data-sizeY="1">
                    <div class="e-panel-container cardcontenido ">
                        <img src="../../../assets/solar-panels-azul.png"
                            style="margin-bottom: 20px;height: 30px;width: 30px;" alt="">
                        <div class="contendTitulos">
                            <div class="text-align titulocard">
                                {{this.dataTRm.info4.medida[this.state.indiceTranslate]}}: {{this.potenciaInstalada}}
                                {{this.dataTRm.info4.medida1[this.state.indiceTranslate]}}</div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info4.medida2[this.state.indiceTranslate]}}: {{this.generacion }} MWh
                            </div>
                            <div class="text-align titulocard">
                                {{this.dataTRm.info4.medida3[this.state.indiceTranslate]}}: {{this.superficie }} m²</div>
                        </div>
                    </div>
                </div> 
                <!--<div id="five" class="e-panel" data-row="4" data-col="6" data-sizeX="2" data-sizeY="1">-->
                <!--    <div class="e-panel-container">                        -->
                <!--        <div class="div-padre">-->
                <!--            <img src="../../../assets/Mirati-azul.png" alt="">-->
                <!--          </div>-->
                <!--    </div>-->
                <!--</div>-->
                <div id="six" class="e-panel" data-row="4" data-col="6" data-sizeX="2" data-sizeY="1">
                    <div class="e-panel-container cardcontenido ">
                        <h2>{{this.dataTRm.info5.medida[this.state.indiceTranslate]}}</h2>
                        <div class="contendTitulos">
                           <table>
                            <tr>
                                <th></th>
                                <th>{{this.dataTRm.info5.header1[this.state.indiceTranslate]}}</th>
                                <th>{{this.dataTRm.info5.header2[this.state.indiceTranslate]}}</th>
                            </tr>
                            <tr>
                                <td style="text-align: left;">{{this.dataTRm.info5.data1[this.state.indiceTranslate]}}</td>
                                <td style="text-align: center;">{{this.COdiabus.toFixed(2)}} ton </td>
                                <td style="text-align: center;">{{this.COdiaflota.toFixed(2)}} ton </td>
                            </tr>
                            <tr>
                                <td style="text-align: left;">{{this.dataTRm.info5.data2[this.state.indiceTranslate]}}</td>
                                <td style="text-align: center;">{{this.COaniobus.toFixed(2)}} ton</td>
                                <td style="text-align: center;">{{this.COanioflota.toFixed(2)}} ton</td>
                            </tr>
                            <tr>
                                <td style="text-align: left;">{{this.dataTRm.info5.data3[this.state.indiceTranslate]}}</td>
                                <td style="text-align: center;">{{this.tokenbus.toFixed(0)}} iCAT</td>
                                <td style="text-align: center;">{{this.tokenflota.toFixed(0)}} iCAT</td>
                            </tr>
                           </table>
                        </div>
                    </div>
                </div>                
                <div id="five" class="e-panel" data-row="2" data-col="6" data-sizeX="2" data-sizeY="2">
                    <div class="e-panel-container cardcontenido ">
                        <!--<h2>Costos</h2>-->
                        <img src="../../../assets/costoresumen.png"
                            style="margin-bottom: 20px;height: 30px;width: 30px;" alt="">
                        <div class="contendTitulos">
                           <table>                            
                            <tr>
                                <td>Costo energía</td>
                                <td style="text-align: center;"> {{this.state.listaLabelTicketEconomico[0]}}</td>
                            </tr>
                            <tr>
                                <td>Precio boleto</td>
                                <td style="text-align: center;">{{this.state.listaIngresoLabel[0]}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Costos anules</td>
                            </tr>
                            <tr>
                                <td>Energía</td>
                                <td style="text-align: center;">{{this.state.listaIngresoMoneda[0]}} {{(this.state.dataTipoEconomico["data"][2]["ytotalanual"][0]/1000).toFixed(1)}} K</td>
                            </tr>
                            <tr>
                                <td>Mantenimiento</td>
                                <td style="text-align: center;">{{this.state.listaIngresoMoneda[0]}} {{(this.state.dataTipoEconomico["data"][2]["ytotalanual"][1]/1000).toFixed(1)}} K</td>
                            </tr>
                            <tr>
                                <td>Operación</td>
                                <td style="text-align: center;">{{this.state.listaIngresoMoneda[0]}} {{(this.state.dataTipoEconomico["data"][2]["ytotalanual"][2]/1000).toFixed(1)}} K</td>
                            </tr>
                            <tr>
                                <td>Legales</td>
                                <td style="text-align: center;">{{this.state.listaIngresoMoneda[0]}} {{(this.state.dataTipoEconomico["data"][2]["ytotalanual"][3]/1000).toFixed(1)}} K</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Ingreso anual</td>
                            </tr>
                            <tr>
                                <td>Neto</td>
                                <td style="text-align: center;">{{this.state.listaIngresoMoneda[0]}} {{(this.dataIngreso[0]["netofinalfindevida"]/1000000).toFixed(1)}} M</td>
                            </tr>                     
                           </table>                                                     
                        </div>
                    </div>
                </div>   
                <div id="seven" class="e-panel" data-row="2" data-col="0" data-sizeX="6" data-sizeY="3">
                    <div class="e-panel-container cardcontenido ordenrecuadrograficos">
                        <div class="" *ngIf="this.cargado">
                            <ejs-chart #myDOMElement id='chartcontainer' [primaryXAxis]='primaryXAxis'
                                [primaryYAxis]='primaryYAxis'
                                title={{this.dataTRm.infografico1.graficode[this.state.indiceTranslate]}} height="80%"
                                width="250px" (window:resize)="onResize($event)" [tooltip]='tooltipkwh'
                                [palettes]='colorScheme.domain'>
                                <e-series-collection>
                                    <e-series *ngFor="let estacion of this.dataParadas; index as i"
                                        [dataSource]='this.dataEnergia[i]' type='Polar' xName='x' yName='y'
                                        drawType='Line' [marker]='marker' name="{{estacion}}">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                        <div class="" *ngIf="this.cargado">
                            <ejs-chart #myDOMElement1 id='chartcontainer1' [primaryXAxis]='primaryXAxis'
                                [primaryYAxis]='primaryYAxis'
                                title={{this.dataTRm.infografico1.graficodp[this.state.indiceTranslate]}} height="80%"
                                width="250px" (window:resize)="onResize($event)" [tooltip]='tooltipkw'
                                [palettes]='colorScheme.domain'>
                                <e-series-collection>
                                    <e-series *ngFor="let estacion of this.dataParadas; index as i"
                                        [dataSource]='this.dataPotencia[i]' type='Polar' xName='x' yName='y'
                                        drawType='Line' [marker]='marker' name="{{estacion}}">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                        <div class="" *ngIf="this.cargado">
                            <ejs-chart #myDOMElement2 id='chartcontainer2' [primaryXAxis]='primaryXAxis'
                                [primaryYAxis]='primaryYAxis'
                                title={{this.dataTRm.infografico1.graficoc[this.state.indiceTranslate]}} height="80%"
                                width="250px" (window:resize)="onResize($event)" [tooltip]='tooltip'
                                [palettes]='colorScheme.domain'>
                                <e-series-collection>
                                    <e-series *ngFor="let estacion of this.dataParadas; index as i"
                                        [dataSource]='this.dataBus[i]' type='Polar' xName='x' yName='y' drawType='Line'
                                        [marker]='marker' name="{{estacion}}">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                        <div class="" *ngIf="this.cargado">
                            <ejs-chart #myDOMElement3 id='chartcontainer3' [primaryXAxis]='primaryXAxis'
                                [primaryYAxis]='primaryYAxis'
                                title={{this.dataTRm.infografico1.graficorc[this.state.indiceTranslate]}} height="80%"
                                width="250px" (window:resize)="onResize($event)" [tooltip]='tooltip'
                                [palettes]='colorScheme.domain'>
                                <e-series-collection>
                                    <e-series *ngFor="let estacion of this.dataParadas; index as i"
                                        [dataSource]='this.dataConectores[i]' type='Polar' xName='x' yName='y'
                                        drawType='Line' [marker]='marker' name="{{estacion}}">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
                 
            </div>

        </ejs-dashboardlayout>
    </div>

</div>