import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { StateService } from '../state/state.service';
import { AuthService } from '../auth/auth-service.service';
import { ConnectableObservable, throwError } from 'rxjs';
import { CollectionsService } from '../collections/collections-service.service';
import { DexieService } from '../dexie/dexie.service';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/overlay-directives';
import { element } from 'protractor';
import { IotaService } from '../iota/iota.service';
import { MapService } from 'src/app/shared/map/map.service';
import { LocalstorageService } from '../storage/localstorage.service';




@Injectable({
  providedIn: 'root',
})
export class DataService {
  private mainUrl = this.state.backendURL;
  public hours: any = [];
  public trayectos: any = [];
  private tiempo: any = [];
  private CompleteDay: any = [];
  // public AllRoutesHours: any = [];
  private LongitudeChargers: any = [];
  private LatitudeChargers: any = [];
  private NameChargers: any = [];
  private LongitudeStops: any = [];
  private LatitudeStops: any = [];
  private DistanceStops: any = [];
  public NameStops: any = [];
  private LatitudeCoordinates: any = [];
  private LongitudeCoordinates: any = [];
  private CyclesTime: any = [];
  private CyclesDistance: any = [];
  private AllRouteProperties: any = {};
  private AllCyclesInputs: any = {};
  private AllVehicleProperties: any = {};
  private AllChargersData: any = {};
  private AllChargingSites: any = {};
  private AllVehicles: any = [];
  private distanceDayCurrent: number = 0;
  intervalBusParadas = 0;
  chargersimpactpower: any = {};
  chargersimpactenergy: any = {};
  chargersimpactperhour: any = [];
  completedaydatanew: any = [];
  howmanyroutesincharger: any = {};
  lastconsumedcharger: any = {}
  chargermedium: any = {};
  chargersnominalpower: any = {};
  chargersmethods: any = {};
  chargersagregatepower: any = {};
  private DatosAccum: any = {};
  private allChargersAcum = {};
  allChargersBus = {};
  public listaCollection2: any = [];
  //Variable para BRT
  public drivingCycle: string = '';
  public isBRT = false;
  public isHVAC = false;
  public listBusConfiguration = [];
  public listSavedBusConfiguration = [];
  listPortfolio: any;
  listStartQuery: any = [];
  listEndQuery: any = [];
  public actualCollection: any;
  public datasourceriota = {}
  public proute: any
  public pbattery: any
  public pcollection: any
  public psize: any
  public ppax: any
  public phvac: any
  public cadena_busquedafiltro: any
  constructor(
    private http: HttpClient,
    private state: StateService,
    private auth: AuthService,
    private collec: CollectionsService,
    private lStore: LocalstorageService,
    private dexieDB: DexieService,
    private iota: IotaService,
    public mapser: MapService
  ) {
  }

  handleError(error: HttpErrorResponse): any {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  clean() {
    this.collec.limpiar();
  }

  public async setBusNames(cualColec = ''): Promise<void> {
    console.log("Registro Funciones - setBusNames", this.auth.userData.user.authCollections)
    this.chargersimpactperhour = [];
    const collections = this.auth.userData.user.authCollections;
    if (collections.length > 0) {
      let allColl: any;
      if (this.collec.collections !== null) {
        allColl = this.collec.collections;
      } else {
        try {
          allColl = await this.collec.getAllCollections().toPromise();
          this.collec.collections = allColl;
          this.state.datosCollection = this.collec.collections
          console.log("colecccion consultada", this.collec.collections, this.auth.userData.user.authCollections[0])
        } catch (error) {
          console.log('NO se pudieron obtener las colecciones');
          return;
        }
      }
      // console.log('allcoll: ', allColl);
      if (collections.length === 1) {
        for (let i = 0; i < allColl.length; i++) {
          console.log("coleccion a buscar", allColl[i].name)
          if (allColl[i].name === this.auth.userData.user.authCollections[0]) {
            this.state.currentCollection = allColl[i].name;
            this.state.busNames = allColl[i].buses;
            this.state.currentRoute = 'None';
            this.state.currentLocation = [
              allColl[i].longitud,
              allColl[i].latitud,
            ];
            this.state.currentChargersNames = allColl[i].namechargers;
            this.state.currentChargersNames.map((c: any, elindex: number) => {
              this.chargersimpactpower[c] = [];
              this.chargersimpactenergy[c] = [];
              this.howmanyroutesincharger[c] = 0;
              this.lastconsumedcharger[c] = 0;
              this.chargermedium[c] = allColl[i].chargersmedium[elindex];
              this.chargersnominalpower[c] = allColl[i].chargerspower[elindex];
              this.chargersmethods[c] = allColl[i].chargersmethod[elindex];
            });

            for (let i = 0; i < 24; i++) {
              this.chargersimpactperhour.push({
                hora: i + ''
              });
              this.state.currentChargersNames.forEach((cur: any) => {
                this.chargersimpactperhour[this.chargersimpactperhour.length - 1][cur] = 0;
              });
            }

            this.state.allLocations.push({
              coordinates: [allColl[i].longitud, allColl[i].latitud],
              colName: allColl[i].name,
              chargernames: allColl[i].namechargers,
              chargerlongitudes: allColl[i].longitudechargers,
              chargerlatitudes: allColl[i].latitudechargers,

              depotnames: allColl[i].namedepot,
              depotlongitudes: allColl[i].longitudedepot,
              depotlatitudes: allColl[i].latitudedepot,

            });
            // this.updateTHEDATA();
          }
        }
      } else {
        if (cualColec !== '') {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < allColl.length; i++) {
            if (allColl[i].name === cualColec) {
              this.state.currentCollection = cualColec;
              this.state.busNames = allColl[i].buses;
              this.state.currentRoute = 'None';
              this.state.currentLocation = [
                allColl[i].longitud,
                allColl[i].latitud,
              ];

              this.state.currentChargersNames = allColl[i].namechargers;
              this.state.currentChargersNames.map((c: any, elindex: number) => {
                this.chargersimpactpower[c] = [];
                this.chargersimpactenergy[c] = [];
                this.howmanyroutesincharger[c] = 0;
                this.lastconsumedcharger[c] = 0;
                this.chargermedium[c] = allColl[i].chargersmedium[elindex];
                this.chargersnominalpower[c] = allColl[i].chargerspower[elindex];
                this.chargersmethods[c] = allColl[i].chargersmethod[elindex];
                this.allChargersBus[c] = [];
              });
              // console.log('this.chargersimpactpower: ', this.chargersimpactpower);
              // for (let i = 0; i < allColl.length; i++) {
              for (let i = 0; i < 24; i++) {
                /* this.chargersimpactperhour[i+''] = {
                  hora: i+''
                }; */
                this.chargersimpactperhour.push({
                  hora: i + ''
                });
                this.state.currentChargersNames.forEach((cur: any) => {
                  this.chargersimpactperhour[this.chargersimpactperhour.length - 1][cur] = 0;
                });
              }
              // console.log('this.chargersimpactperhour: ', this.chargersimpactperhour);

              if (this.auth.userData.user.authCollections.includes(allColl[i].name)) {
                this.state.allLocations.push({
                  coordinates: [allColl[i].longitud, allColl[i].latitud],
                  colName: allColl[i].name,
                  chargernames: allColl[i].namechargers,
                  chargerlongitudes: allColl[i].longitudechargers,
                  chargerlatitudes: allColl[i].latitudechargers,

                  depotnames: allColl[i].namedepot,
                  depotlongitudes: allColl[i].longitudedepot,
                  depotlatitudes: allColl[i].latitudedepot,

                });
              }


              this.state.changeLocation([
                allColl[i].longitud,
                allColl[i].latitud,
              ]);
            }
          }
        } else {

          // console.log('this.auth.userData.user.authCollections: ', this.auth.userData.user.authCollections);
          for (let i = 0; i < allColl.length; i++) {

            if (this.auth.userData.user.authCollections.includes(allColl[i].name)) {
              this.state.allLocations.push({
                coordinates: [allColl[i].longitud, allColl[i].latitud],
                colName: allColl[i].name,
                chargernames: allColl[i].namechargers,
                chargerlongitudes: allColl[i].longitudechargers,
                chargerlatitudes: allColl[i].latitudechargers,

                depotnames: allColl[i].namedepot,
                depotlongitudes: allColl[i].longitudedepot,
                depotlatitudes: allColl[i].latitudedepot,

              });
            }
          }
          // this.state.changeAllLocations('');
        }
      }
      if (cualColec !== '') {
        // console.log('Ejecutando : getAllRoutesProperties: ', this.state.currentCollection);
        // await this.getAllRoutesProperties();
        await this.getAllRoutesPropertiesEvo(cualColec);
      }


      // });
    } else {
      this.state.busNames = ['None'];
      this.state.currentRoute = 'None';
    }
    // console.log('setbusesnames done');
    this.state.listaNameCollection = []
    for (let i = 0; i < this.auth.userData.user.authCollections.length; i++) {
      var element = this.auth.userData.user.authCollections[i];
      var objencontrado = this.collec.collections.find(item => item.name === element)
      this.state.listaNameCollection.push(objencontrado.collectiontitle)
    }
    console.log("state listaNameCollection", this.state.listaNameCollection)
  }

  public async setLabels(): Promise<void> {
    console.log("Registro Funciones - setLabels");

    if (this.state.currentRoute === 'None') {
      return;
    }
    const params = {
      type: this.state.currentType,
      bus: this.state.currentRoute,
      load: this.state.currentHVAC,
      pax: this.state.currentPax,
      size: this.state.currentBusSize,
      battery: this.state.currentBattery,
    };

    if (params.type === 'Opportunity') {
      /* const values1: any = [];
      const eoeles: any = [];
      const initialRange: any = []; */
      /* for (let j = 0; j < this.hours.length; j++) {
        try {
          const dataTmp1 = this.newStatistics[j];
          // const data2 = this.completeDay[j];
          // console.log('LABELS initialnbames, ', dataTmp1);
          values1.push(parseFloat(dataTmp1.kwhxkm));
          eoeles.push(parseFloat(dataTmp1.eolrange));
          initialRange.push(parseFloat(dataTmp1.initialrange));
        } catch (error) {
          console.log(
            'No pude obtener newstadistics para setLabels con ciclo: ',
            this.hours[j]
          );
          return;
        }

      } */
      // console.log('this.completeDay[0]: ', this.completeDay[0]);
      try {


        const data2 = this.completeDay[0];
        console.log("data2 : ", data2)
        this.state.SohCritico = data2["soh_min"].toFixed(0)
        this.state.PotenciaCarga = data2["chargingpower"]
        this.state.maximumConsumption = parseFloat(data2.kwhxkmday).toFixed(2);
        this.state.endAuto = parseFloat(data2.initialrangeday).toFixed(2);
        console.log("endAuto ", this.state.endAuto)
      } catch (error) {

      }
    } else {

      try {

        const data2 = this.completeDay[0];
        console.log("data2 : ", data2)
        this.state.SohCritico = data2["soh_min"].toFixed(0)
        console.log("endAuto 1")
        this.state.PotenciaCarga = data2["chargingpower"]
        console.log("endAuto 2")
        this.state.maximumConsumption = parseFloat(data2.kwhxkmday).toFixed(2);
        console.log("endAuto 3")
        this.state.endAuto = parseFloat(data2.initialrangeday).toFixed(2);
        console.log("endAuto ", this.state.endAuto)
      } catch (error) {
        // console.log('No stats: ', error);
      }
    }
  }
  get longitudeChargers(): any {
    return this.LongitudeChargers;
  }
  set longitudeChargers(ds: any) {
    this.LongitudeChargers = ds;
  }
  get latitudeChargers(): any {
    return this.LatitudeChargers;
  }
  set latitudeChargers(ds: any) {
    this.LatitudeChargers = ds;
  }
  get nameChargers(): any {
    return this.NameChargers;
  }
  set nameChargers(ds: any) {
    this.NameChargers = ds;
  }
  get longitudeStops(): any {
    return this.LongitudeStops;
  }
  set longitudeStops(ds: any) {
    this.LongitudeStops = ds;
  }
  get latitudeStops(): any {
    return this.LatitudeStops;
  }
  set latitudeStops(ds: any) {
    this.LatitudeStops = ds;
  }
  get distanceStops(): any {
    return this.DistanceStops;
  }

  set distanceStops(ds: any) {
    this.DistanceStops = ds;
  }
  get nameStops(): any {
    return this.NameStops;
  }
  set nameStops(ds: any) {
    this.NameStops = ds;
  }
  get alldatosaccum(): any {
    return this.DatosAccum;
  }
  set alldatosaccum(ds: any) {
    this.DatosAccum = ds;
  }
  get latitudeCoordinates(): any {
    return this.LatitudeCoordinates;
  }
  set latitudeCoordinates(ds: any) {
    this.LatitudeCoordinates = ds;
  }
  get longitudeCoordinates(): any {
    return this.LongitudeCoordinates;
  }
  set longitudeCoordinates(ds: any) {
    this.LongitudeCoordinates = ds;
  }
  get cyclesTime(): any {
    return this.CyclesTime;
  }
  set cyclesTime(ds: any) {
    this.CyclesTime = ds;
  }
  get cyclesDistance(): any {
    return this.CyclesDistance;
  }
  set cyclesDistance(ds: any) {
    this.CyclesDistance = ds;
  }
  get allRouteProperties(): any {
    return this.AllRouteProperties;
  }
  set allRouteProperties(ds: any) {
    this.AllRouteProperties = ds;
  }
  get allCyclesInputs(): any {
    return this.AllCyclesInputs;
  }
  set allCyclesInputs(ds: any) {
    this.AllCyclesInputs = ds;
  }
  get allVehicleProperties(): any {
    return this.AllVehicleProperties;
  }
  set allVehicleProperties(ds: any) {
    this.AllVehicleProperties = ds;
  }
  get allVehicles(): any {
    return this.AllVehicles;
  }
  set allVehicles(ds: any) {
    this.AllVehicles = ds;
  }
  get allChargersData(): any {
    return this.AllChargersData;
  }
  set allChargersData(ds: any) {
    this.AllChargersData = ds;
  }
  get allChargingSites(): any {
    return this.AllChargingSites;
  }
  set allChargingSites(ds: any) {
    this.AllChargingSites = ds;
  }


  public getRouteProperties = async (bus: string, collection: string) => {

    /* const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    }); */
    // const bus = this.state.currentRoute;
    const query =
      collection + '_' + bus;
    const ee = { STRID: query };
    // console.log('Query for backend routeProperties: ', this.allRouteProperties);

    try {
      console.log("getRouteProperties - data-service", this.allRouteProperties, bus, collection)
      const results = this.allRouteProperties[bus];
      this.latitudeStops = results.latitudestops;
      this.longitudeStops = results.longitudestops;
      this.distanceStops = results.distancestops;
      this.NameStops = results.namestops;
      this.LatitudeCoordinates = results.latitudecoordinates;
      this.LongitudeCoordinates = results.longitudecoordinates;
      this.hours = results.cycleshours.map((current: any) => {
        if (current.length < 4) {
          return '0' + current;
        }
        return current;
      });
      console.log("hours de routeproperties", this.hours)
      this.tiempo = results.cyclestime;
      this.cyclesDistance = results.cyclesdistance;
      this.state.busInterval = [];
      this.state.busInterval.push(0);
      results.cyclesdistance.forEach((value: any, i: number) => {
        this.state.busInterval.push(
          this.state.busInterval[this.state.busInterval.length - 1] + value
        );
      });
      this.trayectos = results.cyclestraject;

    } catch (error) {
      console.log(
        'Routeproperties : no se pudo obtener los datos de ',
        query
      );
    }

  };
  public async getAllRoutesPropertiesEvo(collection: string): Promise<void> {
    console.log("Registro Funciones - getAllRoutesPropertiesEvo", collection);
    this.allRouteProperties = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      const ee = { STRID: collection };
      let results: any;
        results = await this.http
          .post(this.mainUrl + 'routepropertiesmany/', ee, {
            responseType: 'json',
            headers,
          })
          .toPromise();
      console.log('results rpropertiesevo: ', results);
      console.log('results this.state.busNames: ', this.state.busNames);
      for (const ruta of this.state.busNames) {
        this.allRouteProperties[ruta] = results.results.find((current: any) => {
          return current.routeid === collection + '_' + ruta;
        });
      }
      console.log("AllRouteProperties", this.allRouteProperties)
      this.state.dataAllRouteProerties = this.allRouteProperties

    } catch (error) {
      console.log('getAllRoutesPropertiesMany: No se puede obtener datos de paradas para ruta ',
        collection);
    }

    if (this.auth.userData.user.authCollections.length > 1) {
      await this.getLogitudComparison();
    }

    // return salida2;
    return;
  }
  public async getAllCyclesInputsEvo(route: string, collection: string): Promise<void> {
    // console.log('getAllCyclesInputsEvo init');
    console.log("Registro Funciones - getAllCyclesInputsEvo", route);
    this.allCyclesInputs = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      //AGREGAR CONDICIONAL BRT PARA CONSULTA
      console.log("imprimiendo desde data-service drivingcycle ", this.drivingCycle)
      let query = '';
      switch (this.drivingCycle) {
        case 'Observado':
          query = collection + '_' + route;
          break;

        case 'BRT':
          query = collection + '_BRT_' + route;
          break;
      }
      //let query = collection + '_' + route;
      // let query = collection;
      const ee = { STRID: query };
      console.log("ee inputsmany : ", ee)
      let results: any;
      // if (this.state.rawInputs[query] === undefined) {

      const theinputs = await this.dexieDB.getInputs('Inputs_' + query);
      console.log("Inputs dexie :", theinputs)
      if (theinputs.length === 0) {
        // console.log('getting data from allcyclesinput');
        results = await this.http
          .post(this.mainUrl + 'inputsmany/', ee, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        console.log("Results de inputsmany getAllCyclesInputsEvo ", results)
        this.state.dataInputs = results
        // console.log('GOT data from allcyclesinput: ', results);
        // this.state.rawInputs[query] = results;
        console.log("El dexie esta vacio, se esta cargando dexie inputs")
        //this.dexieDB.addInputs('Inputs_' + query, results);
      } else {
        // console.log('input ya en cache!');
        // results = this.state.rawInputs[query];
        results = JSON.parse(theinputs[0].data);
      }

      // console.log('getAllCyclesInputs :', results);
      // this.allCyclesInputs[cycle] = results;
      for (let i = 0; i < this.hours.length; i++) {
        //AGREGAR BRT EN FUNCION
        let brt_chart = '';
        switch (this.drivingCycle) {
          case 'Observado':
            brt_chart = ''
            break;

          case 'BRT':
            brt_chart = 'BRT_'
            break;
        }
        try {
          const cycle = this.hours[i];

          const strid = collection + '_' + brt_chart + route + '_' + cycle + '_1';
          this.allCyclesInputs[cycle + '_1'] = results.results.find((current: any) => {
            return current.inputsid === strid
          });
          //console.log("this.allCyclesInputs[cycle + '_1']",this.allCyclesInputs[cycle + '_1'])  

          const strid2 = collection + '_' + brt_chart + route + '_' + cycle + '_2';
          this.allCyclesInputs[cycle + '_2'] = results.results.find((current: any) => {
            return current.inputsid === strid2

          });

          const strid3 = collection + '_' + brt_chart + route + '_' + cycle;
          this.allCyclesInputs[cycle] = results.results.find((current: any) => {
            return current.inputsid === strid3
          });
          //console.log('this.allCyclesInputs[cycle]: ', this.allCyclesInputs[cycle]);
        } catch (error) {
          console.log('Pailander: ', error);
        }
      }

      // console.log('this.allCyclesInputs: ', this.allCyclesInputs);
    } catch (error) {
      console.log('getAllCyclesInputs: No se puede obtener datos de paradas para ruta ', error);
    }

    console.log("this.allCyclesInputs : ", this.allCyclesInputs);
    console.log('getAllCyclesInputsEvo end');
    return;
  }

  get completeDay(): any {
    return this.CompleteDay;
  }
  set completeDay(ds: any) {
    this.CompleteDay = ds;
  }
  public getCompleteDayEvo = async (params: any) => {
    console.log("Entrando a GetCompleteDayEvo")
    this.completeDay = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let brt_chart = ''
    switch (this.drivingCycle) {
      case 'Observado':
        brt_chart = ''
        break;

      case 'BRT':
        brt_chart = '_BRT'
        break;
    }
    try {
      const strid1 =
        this.state.currentCollection + brt_chart +
        '_' +
        params.bus;
      const strid2 = params.type +
        '_' +
        params.size +
        'm_' +
        params.battery +
        'kWh_' +
        params.load +
        '_pass' +
        params.pax;
      const ee = {
        STRID1: strid1,
        STRID2: strid2
      };
      console.log('ee1: ', ee);
      let results: any;
      // if (this.state.rawData[strid1 + strid2] !== undefined) {
      const theoutputs = await this.dexieDB.getOutputs(strid1 + strid2);
      console.log("Outputs dexie  de getCompleteDayEvo: ", theoutputs)
      if (theoutputs.length > 0) {
        // console.log('Outputs ya en cache!: _1');
        // results = this.state.rawData[strid1 + strid2];
        results = JSON.parse(theoutputs[0].data);
      } else {
        results = await this.http
          .post(this.mainUrl + 'completedaymany/', ee, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        // this.state.rawData[strid1 + strid2] = results;
        console.log("results getCompleteDayEvo ", results)
        console.log("El dexie esta vacio, cargando dexie addOutputs")
        //this.dexieDB.addOutputs(strid1 + strid2, results);
      }
      // console.log('getCompleteDayEvo FROM : ', results);
      // this.completeDay.push(results);
      for (const entry of this.hours) {
        const strfound = this.state.currentCollection + brt_chart + '_' + params.bus + '_' + entry;
        // console.log("strfound getCompleteDayEvo",strfound)
        const found = results.results.find((current: any) => {
          return current.completedayid.includes(strfound)
        });
        //console.log("found getCompleteDayEvo",found)
        this.completeDay.push(found);
      }
    } catch (error) {
      console.log(
        'getCompleteDayEvo: no se pudo obtener los datos de ruta ',
      );
    }
    console.log('this.completeDay: ', this.completeDay);
  }
  //ESTA NO SE USA
  public getCompleteSelected = async (strid1, strid2, strid3) => {
    console.log("getCompleteSelected")
    // console.log('wtf1');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      const ee = {
        STRID1: strid1,
        STRID2: strid2
      };

      let results: any;
      const selecteds = await this.dexieDB.getOutputs(strid1 + strid2);
      console.log("Outputs dexie  de getCompleteSelected: ", selecteds)

      // if (this.state.rawData[strid1 + strid2] !== undefined) {
      if (selecteds.length > 0) {
        console.log('Datos ya en cache selected!: _1');
        // results = this.state.rawData[strid1 + strid2];
        results = JSON.parse(selecteds[0].data);
      } else {
        console.log("ee getCompleteSelected ", ee)
        results = await this.http
          .post(this.mainUrl + 'completedaymany/', ee, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        console.log("results getCompleteSelected ", results)
        // this.state.rawData[strid1 + strid2] = results;
        console.log("El dexie esta vacio, cargando dexie en addOutPuts de getCompleteSelected")
        //this.dexieDB.addOutputs(strid1 + strid2, results);

      }

    } catch (error) {
      console.log(
        'getCompleteDayEvo: no se pudo obtener los datos de ruta ',
      );
    }

    try {
      const ee = {
        STRID1: strid1,
        STRID2: strid3
      };
      let results: any;
      const selecteds = await this.dexieDB.getOutputs(strid1 + strid3);
      console.log("Outputs dexie  de getCompleteSelected: segundo llamado", selecteds)
      if (selecteds.length > 0) {
        // if (this.state.rawData[strid1 + strid3] !== undefined) {
        console.log('Datos ya en cache selected!: _2');
        // results = this.state.rawData[strid1 + strid3];
        results = JSON.parse(selecteds[0].data);
      } else {
        console.log("ee getCompleteSelected 2", ee)
        results = await this.http
          .post(this.mainUrl + 'completedaymany/', ee, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        // this.state.rawData[strid1 + strid3] = results;
        console.log("results getCompleteSelected 2", results)
        console.log("El dexie esta vacio, cargando dexie en addOutPuts de getCompleteSelected segundo opcion")
        //this.dexieDB.addOutputs(strid1 + strid3, results);

      }

    } catch (error) {
      console.log(
        'getCompleteDayEvo: no se pudo obtener los datos de ruta ',
      );
    }

    console.log('this.completeDay: ', this.completeDay);
  }
  public async getVehicleProperties(params: any): Promise<void> {
    console.log("Registro Funciones - getVehicleProperties");
    // console.log('getVehicleProperties');
    // this.allVehicleProperties = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      let results: any;
      const cadena = params.collection + '_' + params.route + '_' + params.chargingmethod + '_' + params.vehiclesize + '_' + params.batteryenergy;
      const vehicleprop = await this.dexieDB.getVehicleProperties(cadena);
      const cadenaApi = params.chargingmethod + "_" + params.vehiclesize + "m_" + params.batteryenergy + "kWh"
      console.log("cadena getVehicleProperties ", cadena)
      console.log("vehickeProperties dexie  de getVehicleProperties: ", vehicleprop)
      console.log("params de getVehicleProperties", params)
      if (vehicleprop.length === 0) {
        const entradas = {
          "STRID": cadenaApi
        };
        results = await this.http
          .post(this.mainUrl + 'searchvehicleproperties/', entradas, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addVehicleProperties(cadena, results);
        console.log("El dexie esta vacio, cargando dexie en getVehicleProperties de addVehicleProperties")

        console.log('Data for vehicle is: ', results);
      } else {
        results = JSON.parse(vehicleprop[0].data);
        console.log('getVehicleProperties already in cache: ', results);
      }
      this.allVehicleProperties[params.collection + '_' + params.route] = results[0];
      console.log("this.AllVehicleProperties getVehicleProperties", this.allVehicleProperties)
    } catch (error) {
      console.log('getVehicleProperties: No se puede obtener datos para vehículo ',
        params, ' error: ', error);
    }
    return;
  }
  public async getAllVehicles(): Promise<void> {
    console.log("Registro Funciones - getAllVechiles");
    // console.log('getAllVehicles');
    // this.allVehicleProperties = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      let results: any;
      // const cadena = params.collection + '_' + params.route+ '_' +params.chargingmethod + '_' + params.vehiclesize + '_' + params.batteryenergy;
      const vehicleprop = await this.dexieDB.getVehicleProperties('vehicleproperties');
      console.log("Outputs dexie  de getAllVehicles: ", vehicleprop)
      if (vehicleprop.length === 0) {
        results = await this.http
          .get(this.mainUrl + 'vehicleproperties/', {
            responseType: 'json',
            headers,
          })
          .toPromise();
        // this.dexieDB.addVehicleProperties(cadena, results);
        // console.log('Data for vehicle is: ', results);
      } else {
        // console.log('getVehicleProperties already in cache');
        results = JSON.parse(vehicleprop[0].data);
        // console.log('getVehicleProperties already in cache: ', results);
      }
      this.allVehicles = results;
      // console.log('getAllVehicles para ', params.collection + '_' + params.route, ' es: ', this.allVehicleProperties[params.collection + '_' + params.route]);
    } catch (error) {
      console.log('getAllVehicles: No se puede obtener datos para vehículo ', 'error: ', error);
    }

    // return salida2;
    return;
  }
  public async getChargersData(params: any): Promise<void> {
    //BRT CAMBIO
    console.log("Registro Funciones - getChargersData");
    // console.log('getVehicleProperties');
    // console.log('this.nameChargers: ', this.nameChargers);
    // this.allVehicleProperties = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let brt_chart = ''
    try {
      switch (this.drivingCycle) {
        case "Observado":
          brt_chart = ''
          break;

        case "BRT":
          brt_chart = 'BRT_'
          break;
      }
      let results: any;
      const inicio = params.currentCollection + '_' + brt_chart + params.bus;
      const fin = params.type + '_' + params.size + 'm_' + params.battery + 'kWh_' + params.load + '_pass' + params.pax;
      console.log('Cadena para backend: ', inicio, '-', fin);

      const chargesdateins = await this.dexieDB.getChargersData(inicio + fin);
      console.log("chargersdata dexie  de getChargersData: ", chargesdateins)
      if (chargesdateins.length === 0) {
        console.log("chargers length es cero")
        const entradas = {
          inicio,
          fin
        };
        console.log("chargers length es cero", entradas)
        results = await this.http
          .post(this.mainUrl + 'querychargersmany/', entradas, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addChargersData(inicio + fin, results);
        console.log("El dexie esta vacio, cargando dexie en getChargersData de addChargersData")

        // console.log('Data for ChargersData is: ', results);
      } else {
        console.log("chargers length no es cero")
        // console.log('ChargersData already in cache');
        results = JSON.parse(chargesdateins[0].data);
        console.log("Resultado de ChargersData :", results)
        //console.log('ChargersData already in cache: ', results);
      }
      console.log("Resultado de ChargersData :", results)
      this.allChargersData[inicio + fin] = results;
      //console.log('allVehicleProperties para ', params.collection + '_' + params.route, ' es: ', this.allVehicleProperties[params.collection + '_' + params.route]);
    } catch (error) {
      console.log('getChargersData: No se puede obtener datos para cargadores ',
        params, ' error: ', error);
    }

    // return salida2;
    return;
  }
  public async getChargingSitesFirst(params: any): Promise<any> {
    console.log("Registro Funciones - getChargingSitesFirst");
    const inicio = params.currentCollection + '_' + params.bus + '_' + params.type + '_' + params.size + 'm_' + + params.battery + 'kWh_1_2';
    this.state.processing = true;
    await this.getChargingSites(params, inicio);
    this.state.processing = false;
    // console.log('getChargingSitesFirst ', this.allChargingSites[inicio]);
    return this.allChargingSites[inicio].results[0].optimalchargingsites;
  }
  public async getChargingSitesOneStop(params: any, index: number): Promise<any> {
    console.log("Registro Funciones - getChargingSitesOneStop");
    const inicio = params.currentCollection + '_' + params.bus + '_' + params.type + '_' + params.size + 'm_' + + params.battery + 'kWh_' + index;
    // console.log('getChargingSitesOneStop inicio: ', inicio);
    this.state.processing = true;
    await this.getChargingSites(params, inicio);
    this.state.processing = false;
    console.log('getChargingSitesOneStop result: ', this.allChargingSites[inicio]);
    return this.allChargingSites[inicio].results;
  }
  public async getChargingSites(params: any, inicio: string): Promise<void> {
    console.log("Registro Funciones - getChargingSites");
    // console.log('getVehicleProperties');
    // console.log('this.nameChargers: ', this.nameChargers);
    // this.allVehicleProperties = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    try {
      let results: any;
      // GrupoPoloLima_107_Opportunity_12m_55kWh_1_21
      // const inicio = params.currentCollection + '_' + params.bus + '_' + params.type + '_' + params.size + 'm_' +  + params.load + 'kWh';
      // console.log('Cadena para backend: ', inicio, '-', fin);
      const chargingsites = await this.dexieDB.getChargingSites(inicio);
      console.log("chargingsites dexie  de getChargingSites: ", chargingsites)
      if (chargingsites.length === 0) {
        const entradas = {
          "STRID": inicio
        };
        results = await this.http
          .post(this.mainUrl + 'chargingsitemany/', entradas, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addChargingSites(inicio, results);
        console.log("El dexie esta vacio, cargando dexie en addChargingSites de getChargingSites")

        // console.log('Data for ChargersData is: ', results);
      } else {
        // console.log('ChargersData already in cache');
        results = JSON.parse(chargingsites[0].data);
        //console.log('ChargersData already in cache: ', results);
      }
      this.allChargingSites[inicio] = results;
      //console.log('allVehicleProperties para ', params.collection + '_' + params.route, ' es: ', this.allVehicleProperties[params.collection + '_' + params.route]);
    } catch (error) {
      console.log('chargingsitemany: No se puede obtener datos para cargadores ',
        params, ' error: ', error);
    }

    // return salida2;
    return;
  }
  militarHourWithDots(militar: string): string {
    // the input must be a four digit string
    if (militar[0] === '0') {
      return militar[1] + ':' + militar[2] + militar[3];
    } else {
      return militar[0] + militar[1] + ':' + militar[2] + militar[3];
    }
  }
  public async getLogitudComparison(): Promise<void> {
    console.log("Registro Funciones - getLogitudComparison");
    const params = {
      currentCollection: this.state.currentCollection,
    };
    try {
      this.state.THEDATA[6] = await this.getLongitudData(params);

    } catch (error) {
      console.log('No se pudo obtener los datos de las estadísticas long');
    }
  }
  public async getAllData(params): Promise<void> {
    console.log("Registro Funciones - getAllData", params);

    await this.getCompleteDayEvo(params);

    this.newGetStatsStacked(params).then(() => {

    });
    console.log("Antes del ChargerData")
    await this.getChargersData(params);

    console.log("Despues del ChargerData")


    /**
     * THEDATA ORDER:
     * 0 -> Energy consumption chart
     * 1 -> By Kilometer
     * 2 -> Available energy
     * 3 -> Velocity time
     * 4 -> Average velocity
     * 5 -> Statistics critical consumption
     * 6 -> Statistics longitude
     * 7 -> Discriminated
     * 8 -> Consumption Time
     * 0 -> Regeneration (same data as energy consumption plus topology)
     */

    // console.log('Start procesing all charts');

    console.log('this.completeDay', this.completeDay);
    if (this.state.currentChart !== 1) {
      try {
        // console.log('Start procesing getEnergyConsumption');
        //this.state.THEDATA[0] = this.getEnergyConsumption(params);
        this.state.THEDATA[0] = this.newGetEnergyConsumption(params);
        console.log("THEDATA[0] : ", this.state.THEDATA[0])
        // console.log('Ending procesing getEnergyConsumption\n');
      } catch (error) {
        console.log('No se pudo obtener los datos de consumo de energía');
      }
    }
    if (this.state.currentChart !== 2 && this.state.currentChart !== 9) {
      try {
        // console.log('Start procesing getConsumptionByKilometer');
        this.state.THEDATA[1] = this.getConsumptionByKilometer(params);
        // console.log('End procesing getConsumptionByKilometer\n');
      } catch (error) {
        console.log('No se pudo obtener los datos de consumo por kilómetro');
      }
    }
    if (this.state.currentChart !== 3) {
      try {
        this.state.THEDATA[2] = this.newGetAvailableEnergy_v2(params);
        console.log("THEDATA[2]", this.state.THEDATA[2])
      } catch (error) {
        console.log('No se pudo obtener los datos de energía disponible');
      }
    }
    if (this.state.currentChart !== 4) {
      try {
        this.state.THEDATA[3] = this.newGetVelocityVsTime();
        console.log("THEDATA[3]", this.state.THEDATA[3])
      } catch (error) {
        console.log('No se pudo obtener los datos de velocidad vs tiempo');
      }
    }
    if (this.state.currentChart !== 5) {
      try {
        this.state.THEDATA[4] = this.newGetAVGVelocity();
      } catch (error) {
        console.log('No se pudo obtener los datos de velocidad promedio');
      }
    }
    if (this.state.currentChart !== 8) {
      try {
        // console.log('Fetching and procesing discriminated');
        /* await this.getDiscrimitaed(params).then((res: any) => {
          // console.log('... discriminated fetched and processed');
          if (this.state.currentChart === 8) {
            this.state.changeBattery('');
          }
          this.state.setDataAtIndex(res, 7);
        }); */

        //await this.newGetDiscrimitaed(params).then((res: any) => {
        //  console.log(params)
        //  console.log('... discriminated fetched and processed');
        //  /* if (this.state.currentChart === 8) {
        //    this.state.changeBattery('');
        //  }*/
        //  console.log("res GetallData",res)
        //  this.state.setDataAtIndex(res, 7);
        //});
        await this.newGetDiscrimitaed(params)
        //console.log('End procesing getDiscrimitaed\n',this.state.THEDATA[7]);
      } catch (error) {
        console.log('No se pudo obtener los datos de discriminado');
      }
    }

    if (this.state.currentChart !== 9) {
      try {

        this.state.THEDATA[8] = this.newgetConsumptionTime(params);
        console.log("THEDATA[8]", this.state.THEDATA[8])
      } catch (error) {
        console.log('No se pudo obtener los datos de consumo vs tiempo: ', error);
      }
      try {
        this.state.THEDATA[11] = this.newgetPowerTime(params);
        console.log('THEDATA[11]:', this.state.THEDATA[11]);
      } catch (error) {
        console.log('No se pudo obtener los datos de Potencia vs tiempo: ', error);
      }
      try {
        this.state.THEDATA[12] = this.newgetEnergyTime(params);
      } catch (error) {
        console.log('No se pudo obtener los datos de Energía red vs tiempo: ', error);
      }
      try {
        this.state.THEDATA[10] = this.getConsumptionPerBusStop();
        console.log("THEDATA[10] getallData", this.state.THEDATA[10])
      } catch (error) {
        console.log('No se pudo obtener los datos de Energía red vs parada: ', error);
      }
      try {
        this.state.THEDATA[13] = this.newGetChargingTime(params);
        console.log("THEDATA[13]", this.state.THEDATA[13])
      } catch (error) {
        console.log('Falló el cálculo de tiempo de carga: ', error);
      }

    }
    if (this.state.currentChart !== 13) {
      try {
        this.getChargerData(params);
      } catch (error) {
        console.log('No se pudo obtener los datos de cargadores: ', error);
      }
    }

    try {
      this.getAnimationData(params);
    } catch (error) {
      console.log('Falló datos de animación', error);
    }

    return;
  }
  public async updateTHEDATA2(): Promise<void> {
    console.log("Registro Funciones - updateTHEDATA2");
    if (this.state.currentRoute !== 'None') {
      this.state.processing = true;
      // this.state.THEDATA = [];
      const params = {
        type: this.state.currentType,
        bus: this.state.currentRoute,
        load: this.state.currentHVAC,
        pax: this.state.currentPax,
        size: this.state.currentBusSize,
        battery: this.state.currentBattery,
        currentCollection: this.state.currentCollection,
      };
      console.log("params en updateTHEDATA2", params)
      try {
        await this.getAllData(params);
      } catch (error) {
        console.log('Could not get the data...');
        this.state.processing = false;
      }
      this.state.processing = false;
    }
  }
  public async updateTHEDATAPotencia(data: any): Promise<void> {
    console.log("Registro Funciones - updateTHEDATAPotencia");
    // this.state.THEDATA = [];
    const params = {
      type: data.type,
      bus: data.route,
      load: data.hvac,
      pax: data.pax,
      size: data.size,
      battery: data.battery,
      currentCollection: data.collection,
    };
    console.log("params updateTHEDATA", params)
    try {
      await this.getChargerDataPotencia(params);
      console.log("Consulta a getChargerDataPotencia")
    } catch (error) {
      console.log('Could not get the data...');

    }


  }
  public getStops = async () => {
    const salida = this.distanceStops.map(
      (current: any, index: number) => {
        const parada = {
          nombre: this.nameStops[index],
          lat: parseFloat(this.latitudeStops[index]),
          lon: parseFloat(this.longitudeStops[index]),
          sec: index + 1,
          distancia: current,
        };
        return parada;
      }
    );
    this.state.currentBusDistance = parseFloat(
      (this.distanceStops[salida.length - 1] / 1000).toFixed(2)
    );
    return salida; // */

  };
  /* public getChargers = async () => {
    const salida = this.nameChargers.map(
      (current: any, index: number) => {
        const parada = {
          nombre: current,
          lat: this.latitudeChargers[index],
          lon: this.longitudeChargers[index],
        };
        return parada;
      }
    );

    return salida;

  }; */

  public getAllStops = async () => {
    console.log("Registro Funciones - getAllStops");
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    const resultado = [];
    for (const ruta of this.state.busNames) {
      /* let paradasQuery = this.state.currentCollection + '_' + ruta;
      if (this.state.currentCollection === 'Panama') {
        paradasQuery = 'Paradas-' + ruta;
      }
      const entradas = {
        STRID: paradasQuery,
      }; */
      try {

        const dataTmp = this.allRouteProperties[ruta];
        // console.log('STOPS FROM BACKEND: ', dataTmp);
        const salida = dataTmp.distancestops.map(
          (current: any, index: number) => {
            const parada = {
              nombre: dataTmp.namestops[index],
              lat: parseFloat(dataTmp.latitudestops[index]),
              lon: parseFloat(dataTmp.longitudestops[index]),
              sec: index + 1,
              distancia: current,
            };
            return parada;
          }
        );
        this.state.currentBusDistance = parseFloat(
          (dataTmp.distancestops[salida.length - 1] / 1000).toFixed(2)
        );
        resultado.push(salida); // */
      } catch (error) {
        console.log('getAllStops: No se pudo obtener datos de : ', ruta);
        // this.state.allStops = [];
        // return;
      }
    }
    this.state.allStops = resultado;
    return;
  };
  public getCoordinates = async (params: any) => {
    const salida = this.latitudeCoordinates.map((current: any, index: number) => {
      if (this.longitudeCoordinates[index] > 0) {
        //return [this.longitudeCoordinates[index] * -1, current];
        return [this.longitudeCoordinates[index], current];
      } else {
        return [this.longitudeCoordinates[index], current];
      }
    });
    return salida;
  };
  public getAllCoordinates = () => {
    console.log("Entrando a getAllCoordinates")
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    }); // */
    const resultado = [];
    console.log("state.busnames", this.state.busNames)
    for (const ruta of this.state.busNames) {
      //console.log("rutas para coord",ruta)
      const energyQuery1 =
        this.state.currentCollection +
        '_' +
        ruta;
      const ee = { STRID: energyQuery1 }; // */
      // console.log('Query for backend ALLCOORDI consumption: ', energyQuery1);
      try {
        // console.log("routeproperties en getAllCoordinates", this.allRouteProperties)
        const results = this.allRouteProperties[ruta];
        // console.log('Result 2 from ALLCOORDI: ', results);
        //console.log("Ruta por coordenadas", results)
        if (results === null) {
          console.log('No hay coordenadas para ', energyQuery1);
          return;
        } else {
          const salida = results.latitudecoordinates.map((current: any, index: number) => {
            if (results.longitudecoordinates[index] > 0) {
              //return [results.longitudecoordinates[index] * -1, current];
              return [results.longitudecoordinates[index], current];
            } else {
              return [results.longitudecoordinates[index], current];
            }
          });
          //console.log("salida de datos", salida)
          resultado.push(salida);
        }
      } catch (error) {
        console.log('No se pudo obtener onedaydata en coordinates: ', error);
        // this.state.allCoordinates = [];
        // return;
      }
    }
    // return resultado;
    this.state.allCoordinates = resultado;
    console.log("allCoordinates", this.state.allCoordinates)
    return;
    // }
  };
  float2int(value: any): any {
    // tslint:disable-next-line: no-bitwise
    return value | 0;
  }
  public changeAxisConsumption(): void {
    console.log("Registro Funciones - changeAxisConsumption");
    /* const maxxx =
      this.float2int(this.float2int(this.state.maxDistConsumption / 10) + 1) *
      10; */
    // let myIntervalo = 10;
    let myIntervalo = 0;
    if (this.state.maxEneConsumption <= 55) {
      myIntervalo = 10;
    } else if (this.state.maxEneConsumption <= 348) {
      myIntervalo = 70;
    } else if (this.state.maxEneConsumption <= 652) {
      myIntervalo = 100;
    } // */
    this.state.axis1[0] = {
      valueType: 'Double',
      title: this.state.dictTranslate.DashboardsGraph.rendimiento.graficoce.ejex[this.state.indiceTranslate],//'Distancia (km)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      maximum: this.state.maxDistConsumption + 3,
      /*interval: maxxx / 10, */
    };
    this.state.axis1[1] = {
      labelFormat: '{value}',
      title: this.state.dictTranslate.DashboardsGraph.rendimiento.graficoce.ejey[this.state.indiceTranslate],//'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      maximum: this.state.maxEneConsumption + 10,
      /* interval: myIntervalo, */
    };
  }

  public getEnergyConsumption(params: any): any {
    console.log("Registro Funciones - getEnergyConsumption");
    this.state.THEDATA[0] = [];
    this.state.maxDistConsumption = 0;
    this.state.maxEneConsumption = 0;
    this.state.chartMapLongitudeCycles = [];
    this.state.chartMapLatitudeCycles = [];

    /* const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    }); */
    const finalOutput: any = [];
    /*console.log('completeDay: ', this.completeDay);
    console.log('AllCyclesInputs: ', this.AllCyclesInputs); // */
    let countCP = 1;
    let countPC = 1;
    for (let j = 0; j < this.hours.length; j++) {
      // console.log('Query for backend energy consumption: ', energyQuery1);
      const endings = ['_1', '_2'];

      let outputAltitud: any = [];
      let outputRegen: any = [];
      const redPoints: any = [];
      const orangePoints: any = [];
      let totales: any = [];
      let results2: any;
      let theavailablearray: any = [];
      let lastvalue: number = 0;
      let lastconsumption: number = 0;
      // let lastAltitude: number = 0;
      let lastRegen: number = 0;
      let latArray: any = [];
      let lonArray: any = [];
      for (const ending of endings) {
        try {
          if (ending === '_1')
            results2 = this.completeDay[j];
          else
            results2 = this.completeDay[j + (this.completeDay.length / 2)];

          theavailablearray = theavailablearray.concat(results2.batteryavailablearray);

          // const results = this.oneDayData[j];

          /* if (results2.batteryoff > 0) {
            redPoints.push({
              x: this.allCyclesInputs[this.hours[j] + ending].distancekm[results2.batteryoff - 1],
              y: results2.energyarray[results2.energyarray.length - 1]
            });
          } */
          if (results2.batterymin > 0 && results2.energyregenarray[results2.batterymin - 1] !== undefined) {
            orangePoints.push({
              x: this.allCyclesInputs[this.hours[j] + ending].distancekm[results2.batterymin - 1],
              y: results2.energyarray[results2.batterymin - 1]
            });
          }
          // console.log('ENERGYCONSUMP FROM ', ': ', results2.energyarray);
          // if (results2.energyarray.length > 1) {

          // const output = results.distancekm.map((current: any, index: number) => {
          // if (this.allCyclesInputs[this.hours[j] + ending].distancekm.length > 1 && results2.energyregenarray !== undefined) {
          let output: any = [];
          // console.log(this.allCyclesInputs[this.hours[j] + ending].distancekm.length, ' = ', results2.energyregenarray.length);
          this.allCyclesInputs[this.hours[j] + ending].distancekm.forEach((current: any, index: number) => {
            if (results2.energyregenarray[index] !== undefined) {
              const suma = results2.energyarray[index] + lastconsumption;

              if (
                suma >
                this.state.maxEneConsumption
              ) {
                // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                this.state.maxEneConsumption = suma + 10;
              }

              if (parseInt(current + lastvalue, 10) / 1 > this.state.maxDistConsumption) {
                this.state.maxDistConsumption = parseInt(current + lastvalue, 10) / 1;
              }

              outputAltitud.push({
                name: current + lastvalue,
                value: this.allCyclesInputs[this.hours[j] + ending].altitude[index]
              });

              outputRegen.push({
                name: current + lastvalue,
                value: results2.energyregenarray[index] + lastRegen
              });


              output.push({
                name: current + lastvalue,
                value: suma,
              });
            }
          });


          //
          // console.log('output: ', output, ' this.hours[j] + ending: ', this.hours[j] + ending);
          totales = totales.concat(output);
          lastvalue = output[output.length - 1].name;
          lastconsumption = output[output.length - 1].value;
          // lastAltitude = parseFloat(outputAltitud[outputAltitud.length - 1].value);
          lastRegen = parseFloat(outputRegen[outputRegen.length - 1].value);
          lonArray = lonArray.concat(this.allCyclesInputs[this.hours[j] + ending].longitude);
          latArray = latArray.concat(this.allCyclesInputs[this.hours[j] + ending].latitude);
          // }
          /* this.state.chartMapLongitudeCycles.push(results.longitude);
          this.state.chartMapLatitudeCycles.push(results.latitude); */

          // }

        } catch (error) {
          console.log(
            'Consumo de Energía: no se pudo obtener los datos de ciclo ',
            this.hours[j]
          );
        }
      }
      this.state.chartMapLongitudeCycles.push(lonArray);
      this.state.chartMapLatitudeCycles.push(latArray);
      //console.log('totales: ', totales);
      try {

        let theName = this.militarHourWithDots(this.hours[j]);
        if (this.trayectos[j] === 'PC') {
          theName = 'PC' + countPC;
        } else if (this.trayectos[j] === 'CP') {
          theName = 'CP' + countCP;
        }

        finalOutput.push({
          nombre: theName,
          datos: totales,
          altitud: outputAltitud,
          regen: outputRegen,
          redpoints: redPoints,
          orangepoints: orangePoints,
          available: theavailablearray
        });
      } catch (error) {
        console.log('No se puede adicionar objeto en consumo; ', error)
      }
      // }
      //console.log('Fin de ciclo.');
    }
    // console.log('Finaloutput: ', finalOutput);
    this.changeAxisConsumption();
    return finalOutput;
  }
  public newGetEnergyConsumption(params: any): any {
    console.log("Registro Funciones - newGetEnergyConsumption");
    this.state.THEDATA[0] = [];
    this.state.maxDistConsumption = 0;
    this.state.maxEneConsumption = 0;
    this.state.chartMapLongitudeCycles = [];
    this.state.chartMapLatitudeCycles = [];

    /* const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    }); */
    const finalOutput: any = [];
    /*console.log('completeDay: ', this.completeDay);
    console.log('AllCyclesInputs: ', this.AllCyclesInputs); // */
    let countCP = 1;
    let countPC = 1;
    for (let j = 0; j < this.hours.length; j++) {
      // console.log('Query for backend energy consumption: ', energyQuery1);
      //const endings = ['_1', '_2'];

      let outputAltitud: any = [];
      let outputSlope: any = [];
      let outputRegen: any = [];
      const redPoints: any = [];
      const orangePoints: any = [];
      let totales: any = [];
      let results2: any;
      let theavailablearray: any = [];
      let lastvalue: number = 0;
      let lastconsumption: number = 0;
      // let lastAltitude: number = 0;
      let lastRegen: number = 0;
      let latArray: any = [];
      let lonArray: any = [];
      //Lo que estaba dentro del for de Endings
      try {
        results2 = this.completeDay[j];
        //console.log('Estos son los results2 : ', results2);

        theavailablearray = theavailablearray.concat(results2.batteryavailablearray);

        // const results = this.oneDayData[j];

        /* if (results2.batteryoff > 0) {
          redPoints.push({
            x: this.allCyclesInputs[this.hours[j] + ending].distancekm[results2.batteryoff - 1],
            y: results2.energyarray[results2.energyarray.length - 1]
          });
        } */
        if (results2.batterymin > 0 && results2.energyregenarray[results2.batterymin - 1] !== undefined) {
          orangePoints.push({
            x: this.allCyclesInputs[this.hours[j]].distancekm[results2.batterymin - 1],
            y: results2.energyarray[results2.batterymin - 1]
          });
        }
        // console.log('ENERGYCONSUMP FROM ', ': ', results2.energyarray);
        // if (results2.energyarray.length > 1) {

        // const output = results.distancekm.map((current: any, index: number) => {
        // if (this.allCyclesInputs[this.hours[j] + ending].distancekm.length > 1 && results2.energyregenarray !== undefined) {
        let output: any = [];
        // console.log(this.allCyclesInputs[this.hours[j] + ending].distancekm.length, ' = ', results2.energyregenarray.length);
        this.allCyclesInputs[this.hours[j]].distancekm.forEach((current: any, index: number) => {
          if (results2.energyregenarray[index] !== undefined) {
            const suma = results2.energyarray[index] + lastconsumption;

            if (
              suma >
              this.state.maxEneConsumption
            ) {
              // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
              this.state.maxEneConsumption = suma + 10;
            }

            if (parseInt(current + lastvalue, 10) / 1 > this.state.maxDistConsumption) {
              this.state.maxDistConsumption = parseInt(current + lastvalue, 10) / 1;
            }

            outputAltitud.push({
              name: current + lastvalue,
              value: this.allCyclesInputs[this.hours[j]].altitude[index]
            });

            outputSlope.push({
              name: current + lastvalue,
              value: this.allCyclesInputs[this.hours[j]].slope[index]
            });

            outputRegen.push({
              name: current + lastvalue,
              value: results2.energyregenarray[index] + lastRegen
            });


            output.push({
              name: current + lastvalue,
              value: suma,
            });
          }
        });


        //
        // console.log('output: ', output, ' this.hours[j] + ending: ', this.hours[j] + ending);
        totales = totales.concat(output);
        lastvalue = output[output.length - 1].name;
        lastconsumption = output[output.length - 1].value;
        // lastAltitude = parseFloat(outputAltitud[outputAltitud.length - 1].value);
        lastRegen = parseFloat(outputRegen[outputRegen.length - 1].value);
        lonArray = lonArray.concat(this.allCyclesInputs[this.hours[j]].longitude);
        latArray = latArray.concat(this.allCyclesInputs[this.hours[j]].latitude);
        // }
        /* this.state.chartMapLongitudeCycles.push(results.longitude);
        this.state.chartMapLatitudeCycles.push(results.latitude); */

        // }

      } catch (error) {
        console.log(
          'Consumo de Energía: no se pudo obtener los datos de ciclo ',
          this.hours[j]
        );
      }


      this.state.chartMapLongitudeCycles.push(lonArray);
      this.state.chartMapLatitudeCycles.push(latArray);
      //console.log('totales: ', totales);
      try {

        let theName = this.militarHourWithDots(this.hours[j]);
        if (this.trayectos[j] === 'PC') {
          theName = 'PC' + countPC;
        } else if (this.trayectos[j] === 'CP') {
          theName = 'CP' + countCP;
        }

        finalOutput.push({
          nombre: theName,
          datos: totales,
          altitud: outputAltitud,
          slope: outputSlope,
          regen: outputRegen,
          redpoints: redPoints,
          orangepoints: orangePoints,
          available: theavailablearray
        });
      } catch (error) {
        console.log('No se puede adicionar objeto en consumo; ', error)
      }
      // }
      //console.log('Fin de ciclo.');
    }
    // console.log('Finaloutput: ', finalOutput);
    this.changeAxisConsumption();
    ///
    var valorComparar = 0
    var indexMayor = 0
    for (let i = 0; i < finalOutput.length; i++) {
      var elementouno = finalOutput[i];
      for (let j = 0; j < elementouno["datos"].length; j++) {
        var elementodos = elementouno["datos"][j];
        if (elementodos.value >= valorComparar) {
          valorComparar = elementodos.value
          indexMayor = i
        }
      }
    }
    this.state.listaConsumoMayorPendiente[0] = finalOutput[indexMayor]
    console.log("Mayor Pendiente", this.state.listaConsumoMayorPendiente)
    ///
    return finalOutput;
  }

  /*  private getSpanishBusType(busType: string): string {
    if (busType === 'Opportunity') {
      return 'Oportunidad';
    } else {
      return 'Nocturno';
    }
  } */

  changeAxisKilometer(): void {
    console.log("Registro Funciones - changeAxisKilometer");
    this.state.axis5[1] = {
      //labelFormat: '{value}',
      labelFormat: 'n2',
      title: this.state.dictTranslate.DashboardsGraph.rendimiento.graficock.ejey[this.state.indiceTranslate],//'Consumo (kWh/km)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0.1,
      //maximum: this.state.maxKilometer,
      valueType: 'Double',
      rangePadding: 'Round'
    };
    this.state.axis5[0] = {
      valueType: 'Category',
      labelFormat: 'n1',
      title: this.state.dictTranslate.DashboardsGraph.rendimiento.graficock.ejex[this.state.indiceTranslate],//'Servicio',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },

    };
  }
  public getConsumptionByKilometer = (params: any) => {
    this.state.THEDATA[1] = [];
    this.state.maxKilometer = 0;
    const salida: any = [];
    for (const h of this.hours) {
      salida.push({
        hora: this.militarHourWithDots(h),
        type: 0,
      });
    }
    this.state.busIntervalTime = [];
    this.state.busIntervalTime.push(0);
    let countCP = 1;
    let countPC = 1;
    for (let j = 0; j < this.hours.length; j++) {
      try {
        const results2 = this.completeDay[j];
        if (this.trayectos[j] === 'PC') {
          salida[j].hora = 'PC' + countPC++;
        } else if (this.trayectos[j] === 'CP') {
          salida[j].hora = 'CP' + countCP++;
        }
        salida[j].type = results2.kwhxkmcycle.toFixed(2);
        if (parseFloat(salida[j].type) > this.state.maxKilometer) {
          this.state.maxKilometer =
            this.float2int(parseFloat(salida[j].type)) + (this.float2int(parseFloat(salida[j].type)) * 0.25);
        }
        const valores = results2.chargingtime === -1 ? 0 : this.float2int(results2.chargingtime);
        this.state.busIntervalTime.push(
          this.state.busIntervalTime[this.state.busIntervalTime.length - 1] +
          (valores + this.tiempo[j])
        );
      } catch (error) {
        console.log(
          'Energía vs km: no se pudo obtener los datos de ciclo ',
          this.hours[j]
        );
        // const valores = results2.chargingtime === -1 ? 0 : this.float2int(results2.chargingtime);
        this.state.busIntervalTime.push(
          this.state.busIntervalTime[this.state.busIntervalTime.length - 1] +
          (this.tiempo[j])
        );
      }
    }

    this.changeAxisKilometer();
    console.log("Consumo por kilometro THEDATA[1]", salida)
    return salida;
  };
  changeAxisAvailable(): void {
    console.log("Registro Funciones - changeAxisAvailable");
    let elIntervaloY = 0;
    if (this.state.currentType !== 'Opportunity') {
      if (this.state.currentBattery === '348' || this.state.currentBattery === '324') {
        elIntervaloY = 70;
      }
      else {
        elIntervaloY = 100;
      }
    } else {
      elIntervaloY = 15;
    }
    /* const strips: any = [];
    for (let i = 0; i < this.state.busInterval.length; i++) {
      const texto = '' + (i + 1);
      if (i !== 0) {
        strips.push({
          start: this.state.busInterval[i],
          end: this.state.busInterval[i] + 0.00001,
          // size: 0.00001,
          zIndex: 'Behind',
          opacity: 0.5,
          visible: true,
          border: { width: 2, color: '#04dbcb' },
          horizontalAlignment: 'Middle',
          verticalAlignment: 'Start',
          rotation: 0,
          dashArray: '5,5',
        });
      }
      if (i < this.state.busInterval.length - 1) {
        strips.push({
          start: this.state.busInterval[i],
          end: this.state.busInterval[i + 1],
          // size: 0.00001,
          zIndex: 'Behind',
          opacity: 0.5,
          visible: true,
          border: { width: 2, color: 'rgba(255,255,255, 0)' },
          text: texto,
          horizontalAlignment: 'Middle',
          verticalAlignment: 'Start',
          rotation: 0,
          color: 'rgba(255,255,255, 0)',
          textStyle: { size: 15, fontFamily: 'Poppins' },
          dashArray: '5,5',
        });
      }
    } // */
    this.state.axis3[0] = {
      valueType: 'Double',
      title: this.state.dictTranslate.DashboardsGraph.estrategiacarga.graficoed100.ejex[this.state.indiceTranslate],//'Distancia (km)',
      // stripLines: strips,
      //rangePadding: 'Auto',
      minimum: 0,
      //maximum: this.state.busInterval[this.state.busInterval.length - 1],
      maximum: 200.0,
      /* interval: this.state.busInterval.length, */
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };

    // const maxEnergy =
    this.state.axis3[1] = {
      labelFormat: '{value}',
      title: this.state.dictTranslate.DashboardsGraph.estrategiacarga.graficoed100.ejey[this.state.indiceTranslate],//'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      maximum: 400,
      /* interval: elIntervaloY, */
    };
  }
  public getConsumptionPerBusStop() {
    console.log("Registro Funciones - getConumptionPerBusStop");
    console.log("this.hours.length", this.hours.length)
    let consumedperstop: any = [];
    for (let jota = 0; jota < this.hours.length; jota++) {
      let theindex = jota;
      //console.log("this.completeDay", this.completeDay)
      this.completeDay[theindex].numberstop.forEach((cur: any, index: number) => {
        try {
          const estaaqui = consumedperstop.find((current2: any) => {
            return current2.name === cur + '';
          });
          if (estaaqui === undefined && this.trayectos[jota] === 'CC') {
            consumedperstop.push({
              name: cur + '',
              // value: this.completeDay[theindex].energyconsumekwhstop[index]
            });
            consumedperstop[consumedperstop.length - 1][this.hours[jota]] = this.completeDay[theindex].energyconsumekwhstop[index];
          } else if (this.trayectos[jota] === 'CC') {
            estaaqui[this.hours[jota]] = this.completeDay[theindex].energyconsumekwhstop[index];
          }
        } catch (error) {
          console.log('No se pudo mapear energyconsumekwhstop en ciclo ', this.hours[jota], ' and ', theindex);
        }
      });
    }
    //this.state.THEDATA[10] = consumedperstop;
    this.changeAxisConsumTime();
    return consumedperstop;
  }
  public newGetAvailableEnergy(params: any): any {
    console.log("Registro Funciones - newGetAvailableEnergy");
    const nuevoDataBus2: any = [];
    const nuevodataFinalLife2: any = [];
    const nuevodataSOC202: any = [];

    this.state.maxEneAvailable = 0;
    this.state.chartMapLatitude[1] = [];
    this.state.chartMapLongitude[1] = [];
    this.state.chartMapLatitude[0] = [];
    this.state.chartMapLongitude[0] = [];


    let lastdistance = 0;
    let lonarray: any = [];
    let latarray: any = [];

    for (let jota = 0; jota < this.hours.length; jota++) {

      const lashoras = this.hours[jota];
      let theindex = jota;

      try {

        this.allCyclesInputs[lashoras].distancekm.forEach((current: any, index: number) => {
          /* if (this.hours[jota] === '0425'){
            console.log('avail this.completeDay[theindex] para 0425: ', this.completeDay[theindex]);
            console.log('avail this.allCyclesInputs[lashoras]: ', this.allCyclesInputs[lashoras]);
          } */
          if (index < this.completeDay[theindex].batteryavailablearray.length) {
            nuevoDataBus2.push({
              name: current + lastdistance,
              value: this.completeDay[theindex].batteryavailablearray[index],
            });



            nuevodataFinalLife2.push({
              name: current + lastdistance,
              value: this.completeDay[theindex].batteryavailableeolarray[index],
            });

            nuevodataSOC202.push({
              name: current + lastdistance,
              value: this.completeDay[theindex].socmin,
            });
          }

          if (this.completeDay[theindex].batteryavailablearray[index] > this.state.maxEneAvailable) {
            this.state.maxEneAvailable = this.completeDay[theindex].batteryavailablearray[index];
          }

        });
        lastdistance = nuevoDataBus2[nuevoDataBus2.length - 1].name;

        this.completeDay[theindex].chargingenergyarray.forEach((current: any) => {
          nuevoDataBus2.push({
            name: lastdistance,
            value: current,
          });


        });

        this.completeDay[theindex].chargingenergyeolarray.forEach((current: any) => {

          nuevodataFinalLife2.push({
            name: lastdistance,
            value: current,
          });

        });



      } catch (error) {
        // console.log('Available: ', error);
      }
      try {


        /* this.state.chartMapLongitude[0].push(this.allCyclesInputs[lashoras].longitude);
        this.state.chartMapLatitude[0].push(this.allCyclesInputs[lashoras].latitude); */
        lonarray = lonarray.concat(this.allCyclesInputs[lashoras].longitude);
        latarray = latarray.concat(this.allCyclesInputs[lashoras].latitude);
        this.completeDay[theindex].chargingenergyarray.forEach((current: any) => {
          lonarray.push(lonarray[lonarray.length - 1]);
          latarray.push(latarray[latarray.length - 1]);
        });

        /* this.state.chartMapLongitude[0].push(this.allCyclesInputs[lashoras].longitude);
        this.state.chartMapLatitude[0].push(this.allCyclesInputs[lashoras].latitude); */
        // console.log('this.state.chartMapLatitude[0]: ', this.state.chartMapLatitude[0]);
      } catch (error) {
        // console.log('Error obteniendo longitudes: ', error);
      }


    }
    this.state.chartMapLongitude[0].push(lonarray);
    this.state.chartMapLatitude[0].push(latarray);
    // console.log('this.state.chartMapLongitude : ', this.state.chartMapLongitude);

    /* if (nuevodataSOC20[nuevodataSOC20.length - 1].name < this.state.busInterval[this.state.busInterval.length - 1]) {
      nuevodataSOC20.push({
        name: this.state.busInterval[this.state.busInterval.length - 1],
        value: nuevodataSOC20[nuevodataSOC20.length - 1].value,
      });
    } */
    // console.log('Segundo if');
    if (nuevodataSOC202[nuevodataSOC202.length - 1].name < this.state.busInterval[this.state.busInterval.length - 1]) {
      nuevodataSOC202.push({
        name: this.state.busInterval[this.state.busInterval.length - 1],
        value: nuevodataSOC202[nuevodataSOC202.length - 1].value,
      });
    }
    // console.log('Ssalida segundo if');
    /* console.log('los trayectos: ', this.trayectos);*/
    // console.log('los this.state.busInterval length: ', this.state.busInterval.length);


    // console.log('lastdistance: ', lastdistance);
    this.distanceDayCurrent = lastdistance;
    this.changeAxisAvailable();
    const sa = [
      {
        nombre: '100% SOH',
        datos: nuevoDataBus2,
      },
      {
        nombre: 'Fin de vida',
        datos: nuevodataFinalLife2,
      },
      {
        nombre: '20% SOC',
        datos: nuevodataSOC202,
      }
    ];
    return sa;
  }
  public newGetAvailableEnergy_v2(params: any): any {
    console.log("Registro Funciones - newGetAvailableEnergy_v2");
    //console.log("this.allCyclesInputs newGetAvailableEnergy_v2", this.allCyclesInputs)
    //console.log("this.completeDay newGetAvailableEnergy_v2", this.completeDay)
    //console.log("this.allCyclesInputs newGetAvailableEnergy_v2", this.allCyclesInputs)
    // this.state.THEDATA[8]
    const nuevoDataBus2: any = [];
    const nuevodataFinalLife2: any = [];
    const nuevodataSOC202: any = [];
    const tamanioBateria: any = []
    //Inicialización necesaria para que funcione la función pointClick() de las gráficas syncfusion
    this.state.chartMapLatitude[1] = [];
    this.state.chartMapLongitude[1] = [];
    this.state.chartMapLatitude[0] = [];
    this.state.chartMapLongitude[0] = [];



    const newdataBusManyBuses: any = {};
    const newdataFinalLifeManyBuses: any = {};
    const busesid = this.allRouteProperties[params.bus].busesid;
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;

    /* for (const busnumber of busesid) {
      newdataBusManyBuses[busnumber] = [ultimoDato, []];
      newdataFinalLifeManyBuses[busnumber] = [ultimoDato, []];
    } */

    for (const busnumber of busesid) {
      newdataBusManyBuses[busnumber] = [];
      newdataFinalLifeManyBuses[busnumber] = [];
    }



    for (const busnumber of busesid) {
      let result_distance: any[] = [];
      let result_distance_eol: any[] = [];
      let result_energy: any[] = [];
      let result_energy_eol: any[] = [];
      let ultimo_valor = 0;
      let ultimo_valor_eol = 0;
      let lonarray: any = [];
      let latarray: any = [];
      const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);

      for (const jota of losindicesbus) {

        // for (let jota = 0; jota < this.hours.length; jota++) {
        const lashoras = this.hours[jota];
        let theindex = jota;
        try {
          //Partimos por indices cada arreglo
          //Tamaño de la partes
          var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
          //Vector de tiempo de todo el día
          for (let i = 0; i < tamano_de_partes; i++) {
            //declaración
            let distanceDay: any;
            let distanceDay_nuevo: any;
            let distanceDay_eol_nuevo: any;
            let energy: any;
            let energy_eol: any;
            let charging_time: any;
            let charging_time_eol: any;
            let charging_energy_eol: any;
            let charging_energy: any;
            let latitudeDay: number[] = [];
            let longitudeDay: number[] = [];

            if (i > 0) {
              distanceDay = this.allCyclesInputs[lashoras].distancekm.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
              charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
              distanceDay_nuevo = distanceDay.map((distance) => distance + (ultimo_valor - distanceDay[0]));
              distanceDay_eol_nuevo = distanceDay.map((distance) => distance + (ultimo_valor_eol - distanceDay[0]));

            }
            else {
              distanceDay = this.allCyclesInputs[lashoras].distancekm.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
              charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
              distanceDay_nuevo = distanceDay.map((distance) => distance + (ultimo_valor - distanceDay[0]));
              distanceDay_eol_nuevo = distanceDay.map((distance) => distance + (ultimo_valor_eol - distanceDay[0]));
            }

            latarray = latarray.concat(latitudeDay);
            lonarray = lonarray.concat(longitudeDay);
            result_distance = result_distance.concat(distanceDay_nuevo);
            result_distance_eol = result_distance_eol.concat(distanceDay_eol_nuevo);
            result_energy = result_energy.concat(energy);
            result_energy_eol = result_energy_eol.concat(energy_eol);
            ultimo_valor = result_distance[result_distance.length - 1];
            ultimo_valor_eol = result_distance_eol[result_distance_eol.length - 1]
            let charging_distance_nuevo = Array(charging_time.length).fill(result_distance[result_distance.length - 1]);
            let charging_latitude = Array(charging_time.length).fill(latitudeDay[latitudeDay.length - 1]);
            let charging_longitude = Array(charging_time.length).fill(longitudeDay[longitudeDay.length - 1]);

            let charging_distance_eol_nuevo = Array(charging_time_eol.length).fill(result_distance_eol[result_distance_eol.length - 1]);
            result_distance = result_distance.concat(charging_distance_nuevo);
            result_distance_eol = result_distance_eol.concat(charging_distance_eol_nuevo);
            latarray = latarray.concat(charging_latitude);
            lonarray = lonarray.concat(charging_longitude);

            result_energy_eol = result_energy_eol.concat(charging_energy_eol);
            result_energy = result_energy.concat(charging_energy);
            ultimo_valor = result_distance[result_distance.length - 1];
            ultimo_valor_eol = result_distance_eol[result_distance_eol.length - 1];
          }

        } catch (error) {
          console.error('Available: ', error);
        }
      }
      //Armamos el array de latitudes y longitudes
      this.state.chartMapLongitude[0].push(lonarray);
      this.state.chartMapLatitude[0].push(latarray);

      result_distance.forEach((element, index: number) => {
        newdataBusManyBuses[busnumber].push({
          name: element,
          value: result_energy[index],
        });
        /* nuevodataSOC202.push({
          name: element,
          value: this.completeDay[0].socmin,
        }); */
      });

      result_distance_eol.forEach((element, index: number) => {
        newdataFinalLifeManyBuses[busnumber].push({
          name: element,
          value: result_energy_eol[index],
        });
      });
    }

    nuevodataSOC202.push({
      name: 0,
      value: this.completeDay[0].socmin,
    });
    console.log();

    nuevodataSOC202.push({
      name: newdataBusManyBuses[busesid[busesid.length - 1]][newdataBusManyBuses[busesid[busesid.length - 1]].length - 1].name,
      value: this.completeDay[0].socmin,
    });

    tamanioBateria.push({
      name: 0,
      value: this.state.listaDatosBateriaTabla["capacidadpack"]
    })
    tamanioBateria.push({
      name: newdataBusManyBuses[busesid[busesid.length - 1]][newdataBusManyBuses[busesid[busesid.length - 1]].length - 1].name,
      value: this.state.listaDatosBateriaTabla["capacidadpack"]
    })

    // console.log('result_distance_eol: ', result_distance_eol);

    //Creación de los arreglos finales del gráfico
    /* result_distance.forEach((element, index: number) => {
      nuevoDataBus2.push({
        name: element,
        value: result_energy[index],
      });
      nuevodataSOC202.push({
        name: element,
        value: this.completeDay[0].socmin,
      });
    });

    result_distance_eol.forEach((element, index: number) => {
      nuevodataFinalLife2.push({
        name: element,
        value: result_energy_eol[index],
      });
    }); */

    var aux = nuevodataSOC202[0]["value"] / this.state.tamaniobateriabus * 100
    var stringleyendasoc = aux.toString() + '% SoC'
    this.changeAxisAvailable();
    const sa = [
      {
        nombre: '100% SOH',
        datos: nuevoDataBus2,
      },
      {
        nombre: 'Fin de vida',
        datos: nuevodataFinalLife2,
      },
      {
        nombre: stringleyendasoc,
        datos: nuevodataSOC202,
      },
      {
        datos: newdataBusManyBuses
      },
      {
        datos: newdataFinalLifeManyBuses
      },
      {
        nombre: this.state.dictTranslate.Menu.Coleccion.tamanio[this.state.indiceTranslate],
        datos: tamanioBateria,
      },
    ];
    this.state.tamanioBateriaValue = tamanioBateria
    this.state.axis3[1]["maximum"] = this.state.tamaniobateriabus + (this.state.tamaniobateriabus * 0.20),
      console.log("sa", sa)
    return sa;
  }
  changeAxisConsumTime(): void {
    console.log("Registro Funciones - changeAxisConsumTime");

    /*  let elIntervaloY = 0;
     if (this.state.currentType !== 'Opportunity') {
       if (this.state.currentBattery === '348' || this.state.currentBattery === '324') {
         elIntervaloY = 70;
       }
       else {
         elIntervaloY = 100;
       }
     } else {
       elIntervaloY = 15;
     } */

    const hoy = new Date();
    this.state.axis8[0] = {
      // valueType: 'Double',
      valueType: 'DateTime',
      intervalType: 'Hours',
      title: 'Hora del día',
      // stripLines: strips,
      rangePadding: 'Auto',
      /* minimum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0 ,0),
      maximum: new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 23, 59 ,59), */
      // interval: this.state.busInterval.length,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      labelFormat: 'HH:mm'
    };
    // const maxEnergy =
    this.state.axis8[1] = {
      labelFormat: '{value}',
      title: 'Energía (kWh)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      // maximum: this.state.maxEneAvailable + 20,
      /*interval: elIntervaloY, */
    };
    this.state.axis8[2] = {
      labelFormat: '{value}',
      title: 'Potencia (kW)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      // maximum: this.state.maxEneAvailable + 20,
      /*interval: elIntervaloY, */
    };
  }
  //Esta es la función de animaciones verificada y funcionando
  public getAnimationData(params: any): any {
    console.log("Registro Funciones - getAnimationData");
    const bus_id = this.allRouteProperties[params.bus].busesid[0];
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;
    const losindicesbus = this.getAllIndexes(cyclesbusid, bus_id);
    //Aqui se debe iterar
    this.allRouteProperties[params.bus].busesid.forEach((value: any, index: number) => { });
    //Nueva Función
    //Variables para la animación
    const inicio = params.currentCollection + '_' + params.bus;
    const fin = params.type + '_' + params.size + 'm_' + params.battery + 'kWh_' + params.load + '_pass' + params.pax;
    let lonarray: any = [];
    let latarray: any = [];
    let timearray: any = [];
    let bAvailable: any = [];
    let chargingpower: any = [];
    let chargedenergy: any = [];
    let distanciakm: any = [];
    let visitingarray: any = ["None"];
    let visitingindex: any = [0];
    let visitingtimeinit: any = [];
    let visitingtimeend: any = [];
    let autonomy: any = [];
    let evse: any = [];
    let totalautonomy = 0;
    let totalEnergy = 0;
    let finalescargadores: any[] = [];
    let lostenergy: any[] = [];
    let distanceleft2charge: any[] = [];
    let distanceleft2charge_aux: any[] = [];

    let result_latitude: any[] = [];
    let result_longitude: any[] = [];
    let result_time: any[] = [];
    let result_energy_avail: any[] = [];
    let result_distance: any[] = [];
    let result_chargingpower: any[] = [];
    let result_chargedenergy: any[] = [];
    let result_autonomy: any[] = [];
    let result_distance2charge: any[] = [];
    let result_powert1: any[] = [];
    let result_powert2: any[] = [];
    let result_powerdepot: any[] = [];
    let result_energyt1: any[] = [];
    let result_energyt2: any[] = [];
    let result_energydepot: any[] = [];
    let result_range: number[] = [];

    let chargingsitearray: any[] = [];
    const thenamechargers = this.state.allLocations.filter((c: any) => {
      return c.colName == this.state.currentCollection;
    })[0];
    let ultimo_valor: number;
    let ultimo_valor_time = 0;
    let ultimo_valor_latitude = 0;
    let ultimo_valor_longitude = 0;
    let ultimo_valor_energy = 0;
    let ultimo_valor_distance = 0;
    let ultimo_valor_distance2charge = 0;
    let ultimo_valor_distanceleft2charge = 0;
    let ultimo_valor_distanceleft2charge_aux = 0;

    let horainicio: any;
    let initialrange: number = 0.0;
    let distance2charge: any[] = [];

    //for (let jota = 0; jota < this.hours.length; jota++) {
    for (const jota of losindicesbus) {
      const lashoras = this.hours[jota];
      if (jota == 0) {
        horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
          parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);

      }
      let theindex = jota;
      let powert1: any;
      let powert2: any;
      let powerdepot: any;
      let energyt1: any;
      let energyt2: any;
      let energydepot: any;

      try {

        //Partimos por indices cada arreglo

        //Tamaño de la partes

        var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;

        //Vector de tiempo de todo el día

        for (let i = 0; i < tamano_de_partes; i++) {

          //declaración
          let latitudeday: any;
          let longitudeday: any;
          let distanceday: any;
          let latitudeday_nuevo: any;
          let longitudeday_nuevo: any;
          let distanceday_nuevo: any;
          let timeday: any;
          let timeday_nuevo: any;
          let energy_avail: any;
          let charging_time: any;
          let charging_energy_avail: any;

          if (i > 0) {
            latitudeday = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            longitudeday = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            distanceday = this.allCyclesInputs[lashoras].distancekm.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
            timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            energy_avail = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            charging_energy_avail = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
            timeday_nuevo = timeday.map((time) => time + (ultimo_valor_time - timeday[0]));
            latitudeday_nuevo = latitudeday;
            longitudeday_nuevo = longitudeday;
            distanceday_nuevo = distanceday.map((distance) => distance + (ultimo_valor_distance - distanceday[0]));
          }
          else {
            latitudeday = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            longitudeday = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            distanceday = this.allCyclesInputs[lashoras].distancekm.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            energy_avail = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
            charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
            charging_energy_avail = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
            timeday_nuevo = timeday.map((time) => time + (ultimo_valor_time - timeday[0]));
            latitudeday_nuevo = latitudeday;
            longitudeday_nuevo = longitudeday;
            distanceday_nuevo = distanceday.map((distance) => distance + (ultimo_valor_distance - distanceday[0]));
          }

          result_time = result_time.concat(timeday_nuevo);
          result_energy_avail = result_energy_avail.concat(energy_avail);
          result_latitude = result_latitude.concat(latitudeday_nuevo);
          result_longitude = result_longitude.concat(longitudeday_nuevo);
          result_distance = result_distance.concat(distanceday_nuevo);
          ultimo_valor_latitude = result_latitude[result_latitude.length - 1];
          ultimo_valor_longitude = result_longitude[result_longitude.length - 1];
          ultimo_valor_time = result_time[result_time.length - 1];
          ultimo_valor_energy = result_energy_avail[result_energy_avail.length - 1];
          ultimo_valor_distance = result_distance[result_distance.length - 1];
          let charging_latitude_nuevo = Array(charging_time.length).fill(result_latitude[result_latitude.length - 1]);
          let charging_longitude_nuevo = Array(charging_time.length).fill(result_longitude[result_longitude.length - 1]);
          let charging_time_nuevo = charging_time.map((time) => time + (ultimo_valor_time - charging_time[0]));
          let charging_distance_nuevo = Array(charging_time.length).fill(result_distance[result_distance.length - 1]);
          let charging_distance_day_nuevo = Array(charging_time.length).fill(distanceday_nuevo[distanceday_nuevo.length - 1]);
          result_latitude = result_latitude.concat(charging_latitude_nuevo);
          result_longitude = result_longitude.concat(charging_longitude_nuevo);
          result_time = result_time.concat(charging_time_nuevo);
          result_energy_avail = result_energy_avail.concat(charging_energy_avail);
          result_distance = result_distance.concat(charging_distance_nuevo);

          ultimo_valor_latitude = result_latitude[result_latitude.length - 1];
          ultimo_valor_longitude = result_longitude[result_longitude.length - 1];
          ultimo_valor_time = result_time[result_time.length - 1];
          ultimo_valor_energy = result_energy_avail[result_energy_avail.length - 1];
          ultimo_valor_distance = result_distance[result_distance.length - 1];
          distanceday_nuevo = distanceday_nuevo.concat(charging_distance_day_nuevo)

          if (this.state.currentType === 'Opportunity') {
            if (this.completeDay[theindex].chargingsitearray[i] != 'NONE') {
              distanceday_nuevo.forEach((element) => {
                distance2charge.push(element - distanceday_nuevo[0] + ultimo_valor_distance2charge);
                distanceleft2charge_aux.push(element - distanceday_nuevo[0] + ultimo_valor_distanceleft2charge_aux);
              });
              ultimo_valor_distanceleft2charge_aux = distanceleft2charge_aux[distanceleft2charge_aux.length - 1];
              distanceleft2charge_aux.forEach((element) => {
                distanceleft2charge.push(ultimo_valor_distanceleft2charge_aux - element);
              });
              ultimo_valor_distance2charge = 0;
              ultimo_valor_distanceleft2charge = 0;
              ultimo_valor_distanceleft2charge_aux = 0;
              distanceleft2charge_aux = [];
            }
            else {
              distanceday_nuevo.forEach((element) => {
                distance2charge.push(element - distanceday_nuevo[0] + ultimo_valor_distance2charge);
                distanceleft2charge_aux.push(element - distanceday_nuevo[0]);
              });
              ultimo_valor_distance2charge = distance2charge[distance2charge.length - 1];
              ultimo_valor_distanceleft2charge = distanceday_nuevo[distanceday_nuevo.length - 1];
              ultimo_valor_distanceleft2charge_aux = distanceleft2charge_aux[distanceleft2charge_aux.length - 1];
            }
          }
        }
        if (params.type === 'Opportunity') {
          totalautonomy +=
            this.completeDay[theindex].initialrangecycle -
            this.allCyclesInputs[lashoras].distancekm[this.allCyclesInputs[lashoras].distancekm.length - 1] + 20;
        } else {
          totalautonomy =
            this.completeDay[theindex].initialrangeday;
        }

        this.completeDay[theindex].chargingsitearray.forEach((element) => {
          if (element != 'NONE') {
            chargingsitearray.push(element);
          }
        });

        //Potencia
        powert1 = this.completeDay[theindex].chargingpowerarrayt1
        powert2 = this.completeDay[theindex].chargingpowerarrayt2
        powerdepot = this.completeDay[theindex].chargingpowerarraydepot
        result_powert1 = result_powert1.concat(powert1);
        result_powert2 = result_powert2.concat(powert2);
        result_powerdepot = result_powerdepot.concat(powerdepot);

        //Energía
        energyt1 = this.completeDay[theindex].charginggridenergyarrayt1
        energyt2 = this.completeDay[theindex].charginggridenergyarrayt2
        energydepot = this.completeDay[theindex].charginggridenergyarraydepot
        result_energyt1 = result_energyt1.concat(energyt1);
        result_energyt2 = result_energyt2.concat(energyt2);
        result_energydepot = result_energydepot.concat(energydepot);


        initialrange += this.completeDay[theindex].initialrangecycle;
        /*
        Revisar chargingpower, visitingarray, visiting index, visitingtimeinit y visitingtimeend.
        */

      }
      catch (error) {
        console.log('Available: ', error);
      }
    }

    if (this.state.currentType === 'Overnight') {
      result_distance.forEach((element) => {
        result_range.push(this.completeDay[0].initialrangeday - element);
        distanceleft2charge.push(result_distance[result_distance.length - 1] - element);
      });
    }
    if (this.state.currentType === 'Opportunity') {
      distance2charge.forEach((element) => {
        result_range.push(this.completeDay[0].initialrangecycle - element);
      });
    }

    let unique_charging_sites: string[] = [...new Set(chargingsitearray)];

    unique_charging_sites.forEach((element) => {
      const found1 = this.allChargersData[inicio + fin].chargersdata.find((current: any) => {
        return current.chargersid === inicio + '_' + element + '_' + fin;
      });
      found1.chargingtimestampstart.forEach(element => {
        visitingtimeinit.push(element);
      });
      found1.chargingtimestampfinish.forEach(element => {
        visitingtimeend.push(element);
      });
      found1.charginggridenergy.forEach(element => {
        finalescargadores.push(element);
      });
    });

    finalescargadores.forEach(element => {
      totalEnergy += element;
    });

    latarray = result_latitude;
    lonarray = result_longitude;
    result_time.forEach((element) => {
      const horatmp = new Date(horainicio);
      horatmp.setSeconds(horatmp.getSeconds() + element);
      timearray.push(new Date(horatmp));
    });
    bAvailable = result_energy_avail;
    distanciakm = result_distance;
    chargingsitearray.forEach((element) => {
      visitingindex.push(thenamechargers.chargernames.indexOf(element));
    });
    visitingindex.push(0);

    visitingarray = visitingarray.concat(chargingsitearray);

    visitingtimeinit = visitingtimeinit.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    visitingtimeend = visitingtimeend.sort(function (a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });

    visitingtimeinit = visitingtimeinit.map((date) => new Date(date));
    visitingtimeend = visitingtimeend.map((date) => new Date(date));
    //agregar al inicio
    visitingtimeinit.unshift(new Date());
    visitingtimeend.unshift(new Date());
    //agregar al final
    visitingtimeinit.push(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1,
      0, 0, 0));
    visitingtimeend.push(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1,
      0, 0, 0));

    //Suma de resultados de potencia
    var power_sum = 0;
    var energy_sum = 0;
    for (let j = 0; j < result_powert1.length; j++) {
      power_sum = result_powert1[j] + result_powert2[j] + result_powerdepot[j];
      energy_sum = result_energyt1[j] + result_energyt2[j] + result_energydepot[j];
      chargingpower.push([power_sum, timearray[j]]);
      chargedenergy.push([energy_sum, timearray[j]]);
      lostenergy.push([energy_sum * 0.05, timearray[j]]);
    }
    autonomy = result_range;
    evse = distanceleft2charge;
    console.log('Route Properties, antes de animación: ', this.allRouteProperties[params.bus].busesid);

    //Armamos el json
    this.state.routesAnimationData[params.currentCollection + params.bus] = {
      route: params.bus,
      type: params.type,
      latitude: latarray,
      longitude: lonarray,
      datearray: timearray,
      available: bAvailable,
      battery: params.battery,
      chargingpower: chargingpower,
      distancekm: distanciakm,
      initialrange,
      namechargers: this.allRouteProperties[params.bus].namechargers,
      whichcharger: 1,
      visiting: visitingarray,
      visitingindex,
      visitingtimeinit,
      visitingtimeend,
      whichvisiting: 1,
      autonomy,
      totalautonomy: parseInt(totalautonomy.toFixed(0)),
      evse,
      trajects: this.allRouteProperties[params.bus].cyclestraject,
      lostenergy,
      chargedenergy,
      totalEnergy: parseFloat(totalEnergy.toFixed(2)),
      finalescargadores
    }
    console.log('RoutesAnimationData: ', this.state.routesAnimationData);
  }
  public newgetPowerTime(params: any) {
    console.log("Registro Funciones - newgetPowerTime");
    const chargingpowerarrayt1: any = [];
    const chargingpowerarrayt2: any = [];
    const chargingpowerarraydepot: any = [];


    const busesid = this.allRouteProperties[params.bus].busesid;
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;

    const rpowert1: any = {};
    const rpowert2: any = {};
    const rpowertdepot: any = {};
    let fechaultima: Date = new Date();
    fechaultima.setHours(0, 0, 0);
    let primerafecha: Date = new Date();

    primerafecha = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      parseInt(this.hours[0][0] + '' + this.hours[0][1]), parseInt(this.hours[0][2] + '' + this.hours[0][3]), 0)

    for (const busnumber of busesid) {
      rpowert1[busnumber] = [0, []];
      rpowert2[busnumber] = [0, []];
      rpowertdepot[busnumber] = [0, []];
      let result_time: any[] = [];
      let ultimo_valor = 0;
      let result_powert1: any[] = [];
      let result_powert2: any[] = [];
      let result_powerdepot: any[] = [];
      let horainicio: any;
      let fechaactual: Date = new Date();
      fechaactual.setHours(0, 0, 0);

      const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);
      // console.log('inidices de ', busnumber, ' son ', losindicesbus);

      //for (let jota = 0; jota < this.hours.length; jota++) {
      for (const jota of losindicesbus) {
        const lashoras = this.hours[jota];
        let theindex = jota;
        //Declaramos la hora de inicio
        if (losindicesbus.indexOf(jota) === 0) {
          horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
            parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
        }
        try {
          //Partimos por indices cada arreglo
          //Tamaño de la partes
          var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
          //Vector de tiempo de todo el día
          let powert1: any;
          let powert2: any;
          let powerdepot: any;
          for (let i = 0; i < tamano_de_partes; i++) {
            //declaración
            let timeday: any;
            let charging_time: any;
            let timeday_nuevo: any;
            if (i > 0) {
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
            }
            else {
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
            }
            result_time = result_time.concat(timeday_nuevo);
            ultimo_valor = result_time[result_time.length - 1];
            let charging_time_nuevo = charging_time.map((time) => time + (ultimo_valor - charging_time[0]));
            result_time = result_time.concat(charging_time_nuevo);
            ultimo_valor = result_time[result_time.length - 1];
          }

          powert1 = this.completeDay[theindex].chargingpowerarrayt1
          powert2 = this.completeDay[theindex].chargingpowerarrayt2
          powerdepot = this.completeDay[theindex].chargingpowerarraydepot
          result_powert1 = result_powert1.concat(powert1);
          result_powert2 = result_powert2.concat(powert2);
          result_powerdepot = result_powerdepot.concat(powerdepot);

        } catch (error) {
          console.log('Available: ', error);
        }
      }
      /* result_time.shift();
      result_powert1.shift();
      result_time.shift();
      result_powert1.shift(); */
      /* result_powert2.shift();
      result_powerdepot.shift(); */
      // console.log('result_time: ', result_time);
      result_time.forEach((element, index: number) => {
        const horatmp = new Date(horainicio);
        horatmp.setSeconds(horatmp.getSeconds() + element + 3);
        if (horatmp > fechaultima) {
          fechaultima = new Date(horatmp);
        }
        if (horatmp < primerafecha) {
          primerafecha = new Date(horatmp);
        }
        if (horatmp > fechaactual) {
          fechaactual = new Date(horatmp);
          if (result_powert1[index] === undefined) {
            result_powert1[index] = result_powert1[index - 1];
            result_powert2[index] = result_powert2[index - 1];
            result_powerdepot[index] = result_powerdepot[index - 1];
          }
          rpowert1[busnumber][1].push({
            name: new Date(horatmp),
            value: result_powert1[index],
          });
          rpowert2[busnumber][1].push({
            name: new Date(horatmp),
            value: result_powert2[index],
          });
          rpowertdepot[busnumber][1].push({
            name: new Date(horatmp),
            value: result_powerdepot[index],
          });
        }
      });
      /* const horatmp = new Date(horainicio);
      horatmp.setSeconds(horatmp.getSeconds() + result_time[result_time.length - 1]);
      rpowert1[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      });
      rpowert2[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      });
      rpowertdepot[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      }); */

      /* horatmp.setHours(24,0,0);
      rpowert1[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      });
      rpowert2[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      });
      rpowertdepot[busnumber][1].push({
        name: new Date(horatmp),
        value: 0,
      }); */
    }

    /* for (const busname of busesid) {
      rpowert1[busname].shift();
    } */
    console.log('rpowert1 ', rpowert1);
    /*console.log('la fecha primera es ', primerafecha);
    console.log('la fecha ultima es ', fechaultima); */
    // console.log('valor en rpower', rpowert1[busesid[0]][rpowert1[busesid[0]].findIndex(cur => cur.name.getTime() === primerafecha.getTime()) ]);
    fechaultima.setHours(24, 0, 0);
    for (let horaactual = new Date(primerafecha); horaactual < fechaultima; horaactual.setSeconds(horaactual.getSeconds() + 3)) {
      // for (let horaactual = new Date(primerafecha); horaactual < fechaultima; horaactual.setSeconds(horaactual.getSeconds() + 3)) {
      let powernow1 = 0;
      let powernow2 = 0;
      let powernowdepot = 0;
      for (const busname of busesid) {
        if (rpowert1[busname][0] < rpowert1[busname][1].length && rpowert1[busname][1][rpowert1[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          powernow1 = powernow1 + rpowert1[busname][1][rpowert1[busname][0]].value;
          rpowert1[busname][0] = rpowert1[busname][0] + 1;

        }
        if (rpowert2[busname][0] < rpowert2[busname][1].length && rpowert2[busname][1][rpowert2[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          powernow2 = powernow2 + rpowert2[busname][1][rpowert2[busname][0]].value;
          rpowert2[busname][0] = rpowert2[busname][0] + 1;
        }
        if (rpowertdepot[busname][0] < rpowertdepot[busname][1].length && rpowertdepot[busname][1][rpowertdepot[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          powernowdepot = powernowdepot + rpowertdepot[busname][1][rpowertdepot[busname][0]].value;
          rpowertdepot[busname][0] = rpowertdepot[busname][0] + 1;
        }

        // console.log('valor de powernow es ', powernow1, ' en hora ', horaactual);
      }
      chargingpowerarrayt1.push({
        name: new Date(horaactual),
        value: powernow1
      });
      chargingpowerarrayt2.push({
        name: new Date(horaactual),
        value: powernow2
      });
      chargingpowerarraydepot.push({
        name: new Date(horaactual),
        value: powernowdepot
      });
    }

    // const primerafecha = rpowert1[busesid] 05:20:12

    //Creación de los arreglos finales del gráfico
    /* result_time.forEach((element, index: number) => {
      const horatmp = new Date(horainicio);
      horatmp.setSeconds(horatmp.getSeconds() + element);
      chargingpowerarrayt1.push({
        name: new Date(horatmp),
        value: result_powert1[index],
      });
      chargingpowerarrayt2.push({
        name: new Date(horatmp),
        value: result_powert2[index],
      });
      chargingpowerarraydepot.push({
        name: new Date(horatmp),
        value: result_powerdepot[index],
      });
    }); */

    this.changeAxisConsumTime();
    const sa = [
      {
        nombre: this.allRouteProperties[params.bus].namechargers[0],
        datos: chargingpowerarrayt1
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[1],
        datos: chargingpowerarrayt2
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[2],
        datos: chargingpowerarraydepot
      }
    ];
    console.log('Salida de Power vs Time');
    console.log(sa);
    return sa;
  }

  public newgetEnergyTime(params: any) {
    console.log("Registro Funciones - newgetEnergyTime");
    //Todo lo que dice power ahora es energy
    const chargingenergyarrayt1: any = [];
    const chargingenergyarrayt2: any = [];
    const chargingenergyarraydepot: any = [];

    const busesid = this.allRouteProperties[params.bus].busesid;
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;

    const renergy1: any = {};
    const renergy2: any = {};
    const renergydepot: any = {};
    let fechaultima: Date = new Date();
    fechaultima.setHours(0, 0, 0);
    let primerafecha: Date = new Date();

    primerafecha = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
      parseInt(this.hours[0][0] + '' + this.hours[0][1]), parseInt(this.hours[0][2] + '' + this.hours[0][3]), 0)

    for (const busnumber of busesid) {
      renergy1[busnumber] = [0, []];
      renergy2[busnumber] = [0, []];
      renergydepot[busnumber] = [0, []];

      let result_time: any[] = [];
      let ultimo_valor = 0;
      let result_energyt1: any[] = [];
      let result_energyt2: any[] = [];
      let result_energydepot: any[] = [];
      let horainicio: any;
      let ultimo_valor_t1 = 0;
      let ultimo_valor_t2 = 0;
      let ultimo_valor_Depot = 0;
      let fechaactual: Date = new Date();
      fechaactual.setHours(0, 0, 0);

      const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);
      // console.log('inidices de ', busnumber, ' son ', losindicesbus);
      //for (let jota = 0; jota < this.hours.length; jota++) {
      for (const jota of losindicesbus) {
        const lashoras = this.hours[jota];
        let theindex = jota;
        //Declaramos la hora de inicio
        if (losindicesbus.indexOf(jota) === 0) {
          horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
            parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
        }
        try {
          //Partimos por indices cada arreglo
          //Tamaño de la partes
          var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
          //Vector de tiempo de todo el día
          /* let energyt1: any;
          let energyt2: any;
          let energydepot: any; */

          for (let i = 0; i < tamano_de_partes; i++) {
            //declaración
            let timeday: any;
            let charging_time: any;
            let timeday_nuevo: any;
            let partial_energy_t1: any;
            let partial_energy_t2: any;
            let partial_energy_depot: any;
            let energy_t1_nuevo: any;
            let energy_t2_nuevo: any;
            let energy_depot_nuevo: any;

            if (i > 0) {
              partial_energy_t1 = this.completeDay[theindex].charginggridenergyarrayt1.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              // MUCHOS CEROS
              //console.log('partial_energy_t1:', partial_energy_t1);
              partial_energy_t2 = this.completeDay[theindex].charginggridenergyarrayt2.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              partial_energy_depot = this.completeDay[theindex].charginggridenergyarraydepot.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              energy_t1_nuevo = partial_energy_t1.map(((energy) => energy + ultimo_valor_t1));
              energy_t2_nuevo = partial_energy_t2.map(((energy) => energy + ultimo_valor_t2));
              energy_depot_nuevo = partial_energy_depot.map(((energy) => energy + ultimo_valor_Depot));
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
            }
            else {
              partial_energy_t1 = this.completeDay[theindex].charginggridenergyarrayt1.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1 + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              //console.log('partial_energy_t1:', partial_energy_t1);
              partial_energy_t2 = this.completeDay[theindex].charginggridenergyarrayt2.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1 + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              partial_energy_depot = this.completeDay[theindex].charginggridenergyarraydepot.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1 + this.completeDay[theindex].indexestimecharging[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1] + this.completeDay[theindex].indexestimecharging[i + 1] - 1);
              energy_t1_nuevo = partial_energy_t1.map(((energy) => energy + ultimo_valor_t1));
              energy_t2_nuevo = partial_energy_t2.map(((energy) => energy + ultimo_valor_t2));
              energy_depot_nuevo = partial_energy_depot.map(((energy) => energy + ultimo_valor_Depot));
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
            }
            result_time = result_time.concat(timeday_nuevo);
            ultimo_valor = result_time[result_time.length - 1];
            let charging_time_nuevo = charging_time.map((time) => time + (ultimo_valor - charging_time[0]));
            result_time = result_time.concat(charging_time_nuevo);
            result_energyt1 = result_energyt1.concat(energy_t1_nuevo);
            result_energyt2 = result_energyt2.concat(energy_t2_nuevo);
            result_energydepot = result_energydepot.concat(energy_depot_nuevo);
            ultimo_valor_t1 = result_energyt1[result_energyt1.length - 1];
            ultimo_valor_t2 = result_energyt2[result_energyt2.length - 1];
            ultimo_valor_Depot = result_energydepot[result_energydepot.length - 1];
            ultimo_valor = result_time[result_time.length - 1];
            //console.log('ultimo_valor_t1', ultimo_valor_t1);
          }

          //energyt1 = this.completeDay[theindex].charginggridenergyarrayt1
          //energyt2 = this.completeDay[theindex].charginggridenergyarrayt2
          //energydepot = this.completeDay[theindex].charginggridenergyarraydepot
          //result_energyt1 = result_energyt1.concat(energyt1);
          //result_energyt2 = result_energyt2.concat(energyt2);
          //result_energydepot = result_energydepot.concat(energydepot);

        } catch (error) {
          console.log('Available: ', error);
        }
      }


      //Creación de los arreglos finales del gráfico
      result_time.forEach((element, index: number) => {
        const horatmp = new Date(horainicio);
        horatmp.setSeconds(horatmp.getSeconds() + element);
        if (horatmp > fechaultima) {
          fechaultima = new Date(horatmp);
        }
        if (horatmp < primerafecha) {
          primerafecha = new Date(horatmp);
        }
        if (horatmp > fechaactual) {
          fechaactual = new Date(horatmp);
          if (result_energyt1[index] === undefined) {
            result_energyt1[index] = result_energyt1[index - 1];
            result_energyt2[index] = result_energyt2[index - 1];
            result_energydepot[index] = result_energydepot[index - 1];
          }
          renergy1[busnumber][1].push({
            name: new Date(horatmp),
            value: result_energyt1[index],
          });

          renergy2[busnumber][1].push({
            name: new Date(horatmp),
            value: result_energyt2[index],
          });
          renergydepot[busnumber][1].push({
            name: new Date(horatmp),
            value: result_energydepot[index],
          });
        }
      });


    }
    console.log('renergy1: ', renergy1);
    for (let horaactual = new Date(primerafecha); horaactual < fechaultima; horaactual.setSeconds(horaactual.getSeconds() + 3)) {
      let energy1 = 0;
      let energy2 = 0;
      let energydepot = 0;
      for (const busname of busesid) {
        if (renergy1[busname][0] < renergy1[busname][1].length && renergy1[busname][1][renergy1[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          energy1 = energy1 + renergy1[busname][1][renergy1[busname][0]].value;
          renergy1[busname][0] = renergy1[busname][0] + 1;

        }
        else if (renergy1[busname][0] >= renergy1[busname][1].length) {
          // console.log('terminó el bus ', busname, ' y su últma energy es ', renergy1[busname][1]   [renergy1[busname][1].length - 1].value);
          energy1 = energy1 + renergy1[busname][1][renergy1[busname][1].length - 1].value;
        }


        if (renergy2[busname][0] < renergy2[busname][1].length && renergy2[busname][1][renergy2[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          energy2 = energy2 + renergy2[busname][1][renergy2[busname][0]].value;
          renergy2[busname][0] = renergy2[busname][0] + 1;
        } else if (renergy2[busname][0] >= renergy2[busname][1].length) {
          // console.log('terminó el bus ', busname, ' y su últma energy es ', renergy2[busname][1]   [renergy2[busname][1].length - 1].value);
          energy2 = energy2 + renergy2[busname][1][renergy2[busname][1].length - 1].value;
        }



        if (renergydepot[busname][0] < renergydepot[busname][1].length && renergydepot[busname][1][renergydepot[busname][0]].name.getTime() === horaactual.getTime()) {
          // console.log('Sí es igual ', busname);
          energydepot = energydepot + renergydepot[busname][1][renergydepot[busname][0]].value;
          renergydepot[busname][0] = renergydepot[busname][0] + 1;
        } else if (renergydepot[busname][0] >= renergydepot[busname][1].length) {
          // console.log('terminó el bus ', busname, ' y su últma energy es ', renergy2[busname][1]   [renergy2[busname][1].length - 1].value);
          energydepot = energydepot + renergydepot[busname][1][renergydepot[busname][1].length - 1].value;
        }

        // console.log('valor de powernow es ', powernow1, ' en hora ', horaactual);
      }
      chargingenergyarrayt1.push({
        name: new Date(horaactual),
        value: energy1
      });
      chargingenergyarrayt2.push({
        name: new Date(horaactual),
        value: energy2
      });
      chargingenergyarraydepot.push({
        name: new Date(horaactual),
        value: energydepot
      });
    } // */

    const horatmp = new Date();
    horatmp.setHours(24, 0, 0);
    chargingenergyarrayt1.push({
      name: new Date(horatmp),
      value: chargingenergyarrayt1[chargingenergyarrayt1.length - 1].value
    });
    chargingenergyarrayt2.push({
      name: new Date(horatmp),
      value: chargingenergyarrayt2[chargingenergyarrayt2.length - 1].value
    });
    chargingenergyarraydepot.push({
      name: new Date(horatmp),
      value: chargingenergyarraydepot[chargingenergyarraydepot.length - 1].value
    });




    this.changeAxisConsumTime();
    const sa = [
      {
        nombre: this.allRouteProperties[params.bus].namechargers[0],
        datos: chargingenergyarrayt1
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[1],
        datos: chargingenergyarrayt2
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[2],
        datos: chargingenergyarraydepot
      }
    ];
    console.log('Salida de Energy vs Time');
    console.log(sa);
    return sa;
  }

  private getAllIndexes(arr: any, val: string): any {
    //console.log("Registro Funciones - getAllIndexes");
    let indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
      indexes.push(i);
    }
    return indexes;
  }
  public newgetConsumptionTime(params: any): any {
    console.log("Registro Funciones - newgetConsumptionTime");
    // this.state.THEDATA[8]
    const nuevoDataBus2: any = [];
    const nuevodataFinalLife2: any = [];
    const nuevodataSOC202: any = [];
    //Inicialización necesaria para que funcione la función pointClick() de las gráficas syncfusion
    this.state.chartMapLatitude[1] = [];
    this.state.chartMapLongitude[1] = [];
    this.state.chartMapLatitude[0] = [];
    this.state.chartMapLongitude[0] = [];

    const dateparatime = new Date();
    dateparatime.setHours(0);
    dateparatime.setMinutes(0);
    dateparatime.setSeconds(0);
    let ultimoDato = new Date();
    ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), 0, 0, 0);

    const newdataBusManyBuses: any = {};
    const newdataFinalLifeManyBuses: any = {};
    const busesid = this.allRouteProperties[params.bus].busesid;
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;

    /* for (const busnumber of busesid) {
      newdataBusManyBuses[busnumber] = [ultimoDato, []];
      newdataFinalLifeManyBuses[busnumber] = [ultimoDato, []];
    } */

    for (const busnumber of busesid) {
      newdataBusManyBuses[busnumber] = [];
      newdataFinalLifeManyBuses[busnumber] = [];
    }
    // console.log('this.hours: ', this.hours);
    for (const busnumber of busesid) {
      let result_time: any[] = [];
      let result_time_eol: any[] = [];
      let result_energy: any[] = [];
      let result_energy_eol: any[] = [];
      let ultimo_valor = 0;
      let ultimo_valor_eol = 0;
      let horainicio: any;
      let horafinal: any;
      let horainicio_eol: any;
      let horafinal_eol: any;
      let lonarray: any = [];
      let latarray: any = [];
      let datetime_pruba: any[] = [];
      let datetime_pruba_eol: any[] = [];

      const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);
      // console.log('inidices de ', busnumber, ' son ', losindicesbus);


      //for (let jota = 0; jota < this.hours.length; jota++) {
      for (const jota of losindicesbus) {

        const lashoras = this.hours[jota];
        let theindex = jota;
        //Declaramos la hora de inicio
        if (losindicesbus.indexOf(jota) === 0) {
          // console.log('procesando primer ciclo de ', busnumber, ' con jota ', jota, ' es decir ciclo ', this.hours[jota]);
          ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
            parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          horainicio_eol = horainicio;
          //console.log('El bus ', busnumber, ' inicia labores a las ', horainicio);
        } else {
          ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
            parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          horainicio_eol = horainicio;
          const max_hour: Date = horainicio > horafinal ? horainicio : horafinal;
          const max_hour_eol: Date = horainicio_eol > horafinal_eol ? horainicio_eol : horafinal_eol;
          if (horafinal == max_hour) {
            horainicio = horafinal;
          }
          if (horafinal_eol == max_hour_eol) {
            horainicio_eol = horafinal_eol;
          }
        }
        let new_ultimo_valor_partes = 0;
        let new_ultimo_valor_partes_eol = 0;
        let new_time: any[] = [];
        let new_time_eol: any[] = [];

        try {
          //Partimos por indices cada arreglo
          //Tamaño de la partes
          var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
          //Vector de tiempo de todo el día
          for (let i = 0; i < tamano_de_partes; i++) {
            //declaración
            let timeday: any;
            let timeday_nuevo: any;
            let timeday_eol_nuevo: any;
            let new_time_timeday: any;
            let new_time_timeday_eol: any;
            let energy: any;
            let energy_eol: any;
            let charging_time: any;
            let charging_time_eol: any;
            let charging_energy_eol: any;
            let charging_energy: any;
            let latitudeDay: number[] = [];
            let longitudeDay: number[] = [];

            if (i > 0) {
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
              charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
              new_time_timeday = timeday.map((time) => time + (new_ultimo_valor_partes - timeday[0]));
              timeday_eol_nuevo = timeday.map((time) => time + (ultimo_valor_eol - timeday[0]));
              new_time_timeday_eol = timeday.map((time) => time + (new_ultimo_valor_partes_eol - timeday[0]));
            }
            else {
              timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
              charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
              charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
              timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
              new_time_timeday = timeday.map((time) => time + (new_ultimo_valor_partes - timeday[0]));
              timeday_eol_nuevo = timeday.map((time) => time + (ultimo_valor_eol - timeday[0]));
              new_time_timeday_eol = timeday.map((time) => time + (new_ultimo_valor_partes_eol - timeday[0]));
            }

            latarray = latarray.concat(latitudeDay);
            lonarray = lonarray.concat(longitudeDay);
            result_time = result_time.concat(timeday_nuevo);
            result_time_eol = result_time_eol.concat(timeday_eol_nuevo);
            result_energy = result_energy.concat(energy);
            result_energy_eol = result_energy_eol.concat(energy_eol);
            ultimo_valor = result_time[result_time.length - 1];
            ultimo_valor_eol = result_time_eol[result_time_eol.length - 1]
            let charging_time_nuevo = charging_time.map((time) => time + (ultimo_valor - charging_time[0]));
            let charging_time_eol_nuevo = charging_time_eol.map((time) => time + (ultimo_valor_eol - charging_time_eol[0]));
            let charging_latitude = Array(charging_time.length).fill(latitudeDay[latitudeDay.length - 1]);
            let charging_longitude = Array(charging_time.length).fill(longitudeDay[longitudeDay.length - 1]);
            latarray = latarray.concat(charging_latitude);
            lonarray = lonarray.concat(charging_longitude);

            result_time = result_time.concat(charging_time_nuevo);
            result_time_eol = result_time_eol.concat(charging_time_eol_nuevo);
            result_energy_eol = result_energy_eol.concat(charging_energy_eol);
            result_energy = result_energy.concat(charging_energy);
            ultimo_valor = result_time[result_time.length - 1];
            ultimo_valor_eol = result_time_eol[result_time_eol.length - 1];

            new_time_eol = new_time_eol.concat(new_time_timeday_eol);
            new_ultimo_valor_partes_eol = new_time_eol[new_time_eol.length - 1];
            let new_charging_time_eol = charging_time_eol.map((time) => time + (new_ultimo_valor_partes_eol - charging_time_eol[0]));
            new_time_eol = new_time_eol.concat(new_charging_time_eol);
            new_ultimo_valor_partes_eol = new_time_eol[new_time_eol.length - 1];

            new_time = new_time.concat(new_time_timeday);
            new_ultimo_valor_partes = new_time[new_time.length - 1];
            let new_charging_time = charging_time.map((time) => time + (new_ultimo_valor_partes - charging_time[0]));
            new_time = new_time.concat(new_charging_time);
            new_ultimo_valor_partes = new_time[new_time.length - 1];
          }

          new_time.forEach((element, index: number) => {
            const horatmp_prueba = new Date(horainicio);
            horatmp_prueba.setSeconds(horatmp_prueba.getSeconds() + element);
            datetime_pruba.push(horatmp_prueba)
          });
          horafinal = datetime_pruba[datetime_pruba.length - 1];

          new_time_eol.forEach((element, index: number) => {
            const horatmp_prueba = new Date(horainicio_eol);
            horatmp_prueba.setSeconds(horatmp_prueba.getSeconds() + element);
            datetime_pruba_eol.push(horatmp_prueba)
          });
          horafinal_eol = datetime_pruba_eol[datetime_pruba_eol.length - 1];
        } catch (error) {
          console.error('Available: ', error);
        }
      }
      //Armamos el array de latitudes y longitudes
      this.state.chartMapLongitude[0].push(lonarray);
      this.state.chartMapLatitude[0].push(latarray);


      datetime_pruba.forEach((element, index: number) => {
        newdataBusManyBuses[busnumber].push({
          name: new Date(element),
          value: result_energy[index],
        });
      });
      /* result_time.forEach((element, index: number) => {
        const horatmp = new Date(horainicio);
        horatmp.setSeconds(horatmp.getSeconds() + element);
        newdataBusManyBuses[busnumber].push({
          name: new Date(horatmp),
          value: result_energy[index],
        });
      }); */

      datetime_pruba_eol.forEach((element, index: number) => {
        newdataFinalLifeManyBuses[busnumber].push({
          name: new Date(element),
          value: result_energy_eol[index],
        });
      });
      /* result_time_eol.forEach((element, index: number) => {
        const horatmp = new Date(horainicio);
        horatmp.setSeconds(horatmp.getSeconds() + element);
        newdataFinalLifeManyBuses[busnumber].push({
          name: new Date(horatmp),
          value: result_energy_eol[index],
        });
      });*/



    }


    // console.log('newdataBusManyBuses ', newdataBusManyBuses);
    nuevodataSOC202.push({
      name: new Date(newdataBusManyBuses[busesid[0]][0].name),
      value: this.completeDay[0].socmin,
    });
    console.log();
    nuevodataSOC202.push({
      name: new Date(newdataBusManyBuses[busesid[busesid.length - 1]][newdataBusManyBuses[busesid[busesid.length - 1]].length - 1].name),
      value: this.completeDay[0].socmin,
    });

    //Creación de los arreglos finales del gráfico
    /* result_time.forEach((element, index: number) => {
      const horatmp = new Date(newdataBusManyBuses[busesid[0]][0].name);
      horatmp.setSeconds(horatmp.getSeconds() + element);

      nuevodataSOC202.push({
        name: new Date(horatmp),
        value: this.completeDay[0].socmin,
      });
    }); */


    this.changeAxisConsumTime();
    const sa = [
      {
        nombre: '100% SOH',
        datos: nuevoDataBus2,
      },
      {
        nombre: 'Fin de vida',
        datos: nuevodataFinalLife2,
      },
      {
        nombre: '20% SOC',
        datos: nuevodataSOC202,
      },
      {
        datos: newdataBusManyBuses
      },
      {
        datos: newdataFinalLifeManyBuses
      }
    ];
    console.log('Datos de grafica consumo por tiempo:', sa);
    return sa;
  }
  //public newgetConsumptionTime(params: any): any {
  //  console.log("Registro Funciones - newgetConsumptionTime");
  //  // this.state.THEDATA[8]
  //  const nuevoDataBus2: any = [];
  //  const nuevodataFinalLife2: any = [];
  //  const nuevodataSOC202: any = [];
  //  //Inicialización necesaria para que funcione la función pointClick() de las gráficas syncfusion
  //  this.state.chartMapLatitude[1] = [];
  //  this.state.chartMapLongitude[1] = [];
  //  this.state.chartMapLatitude[0] = [];
  //  this.state.chartMapLongitude[0] = [];
  //
  //  const dateparatime = new Date();
  //  dateparatime.setHours(0);
  //  dateparatime.setMinutes(0);
  //  dateparatime.setSeconds(0);
  //  let ultimoDato = new Date();
  //  ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), 0, 0, 0);
  //
  //  const newdataBusManyBuses: any = {};
  //  const newdataFinalLifeManyBuses: any = {};
  //  const busesid = this.allRouteProperties[params.bus].busesid;
  //  const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;
  //
  //  /* for (const busnumber of busesid) {
  //    newdataBusManyBuses[busnumber] = [ultimoDato, []];
  //    newdataFinalLifeManyBuses[busnumber] = [ultimoDato, []];
  //  } */
  //
  //  for (const busnumber of busesid) {
  //    newdataBusManyBuses[busnumber] = [];
  //    newdataFinalLifeManyBuses[busnumber] = [];
  //  }
  //  // console.log('this.hours: ', this.hours);
  //  for (const busnumber of busesid) {
  //    let result_time: any[] = [];
  //    let result_time_eol: any[] = [];
  //    let result_energy: any[] = [];
  //    let result_energy_eol: any[] = [];
  //    let ultimo_valor = 0;
  //    let ultimo_valor_eol = 0;
  //    let horainicio: any;
  //    let lonarray: any = [];
  //    let latarray: any = [];
  //
  //
  //    const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);
  //    // console.log('inidices de ', busnumber, ' son ', losindicesbus);
  //
  //
  //    //for (let jota = 0; jota < this.hours.length; jota++) {
  //    for (const jota of losindicesbus) {
  //
  //      const lashoras = this.hours[jota];
  //      let theindex = jota;
  //      //Declaramos la hora de inicio
  //      if (losindicesbus.indexOf(jota) === 0) {
  //        // console.log('procesando primer ciclo de ', busnumber, ' con jota ', jota, ' es decir ciclo ', this.hours[jota]);
  //        ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
  //        horainicio = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
  //          parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
  //        // console.log('El bus ', busnumber, ' inicia labores a las ', horainicio);
  //      }
  //
  //      try {
  //        //Partimos por indices cada arreglo
  //        //Tamaño de la partes
  //        var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
  //        //Vector de tiempo de todo el día
  //        for (let i = 0; i < tamano_de_partes; i++) {
  //          //declaración
  //          let timeday: any;
  //          let timeday_nuevo: any;
  //          let timeday_eol_nuevo: any;
  //          let energy: any;
  //          let energy_eol: any;
  //          let charging_time: any;
  //          let charging_time_eol: any;
  //          let charging_energy_eol: any;
  //          let charging_energy: any;
  //          let latitudeDay: number[] = [];
  //          let longitudeDay: number[] = [];
  //
  //          if (i > 0) {
  //            timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
  //            charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
  //            charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);
  //            charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i], this.completeDay[theindex].indexestimechargingeol[i + 1]);
  //            timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
  //            timeday_eol_nuevo = timeday.map((time) => time + (ultimo_valor_eol - timeday[0]));
  //          }
  //          else {
  //            timeday = this.allCyclesInputs[lashoras].timeday.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            energy = this.completeDay[theindex].batteryavailablearray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            energy_eol = this.completeDay[theindex].batteryavailableeolarray.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
  //            charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
  //            charging_time_eol = this.completeDay[theindex].chargingtimeeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
  //            charging_energy = this.completeDay[theindex].chargingenergyarray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);
  //            charging_energy_eol = this.completeDay[theindex].chargingenergyeolarray.slice(this.completeDay[theindex].indexestimechargingeol[i] - 1, this.completeDay[theindex].indexestimechargingeol[i + 1]);
  //            timeday_nuevo = timeday.map((time) => time + (ultimo_valor - timeday[0]));
  //            timeday_eol_nuevo = timeday.map((time) => time + (ultimo_valor_eol - timeday[0]));
  //          }
  //
  //          latarray = latarray.concat(latitudeDay);
  //          lonarray = lonarray.concat(longitudeDay);
  //          result_time = result_time.concat(timeday_nuevo);
  //          result_time_eol = result_time_eol.concat(timeday_eol_nuevo);
  //          result_energy = result_energy.concat(energy);
  //          result_energy_eol = result_energy_eol.concat(energy_eol);
  //          ultimo_valor = result_time[result_time.length - 1];
  //          ultimo_valor_eol = result_time_eol[result_time_eol.length - 1]
  //          let charging_time_nuevo = charging_time.map((time) => time + (ultimo_valor - charging_time[0]));
  //          let charging_time_eol_nuevo = charging_time_eol.map((time) => time + (ultimo_valor_eol - charging_time_eol[0]));
  //          let charging_latitude = Array(charging_time.length).fill(latitudeDay[latitudeDay.length - 1]);
  //          let charging_longitude = Array(charging_time.length).fill(longitudeDay[longitudeDay.length - 1]);
  //          latarray = latarray.concat(charging_latitude);
  //          lonarray = lonarray.concat(charging_longitude);
  //
  //          result_time = result_time.concat(charging_time_nuevo);
  //          result_time_eol = result_time_eol.concat(charging_time_eol_nuevo);
  //          result_energy_eol = result_energy_eol.concat(charging_energy_eol);
  //          result_energy = result_energy.concat(charging_energy);
  //          ultimo_valor = result_time[result_time.length - 1];
  //          ultimo_valor_eol = result_time_eol[result_time_eol.length - 1];
  //        }
  //
  //      } catch (error) {
  //        console.log('Available: ', error);
  //      }
  //    }
  //
  //    //Armamos el array de latitudes y longitudes
  //    this.state.chartMapLongitude[0].push(lonarray);
  //    this.state.chartMapLatitude[0].push(latarray);
  //
  //
  //    result_time.forEach((element, index: number) => {
  //      const horatmp = new Date(horainicio);
  //      horatmp.setSeconds(horatmp.getSeconds() + element);
  //      newdataBusManyBuses[busnumber].push({
  //        name: new Date(horatmp),
  //        value: result_energy[index],
  //      });
  //    });
  //
  //    result_time_eol.forEach((element, index: number) => {
  //      const horatmp = new Date(horainicio);
  //      horatmp.setSeconds(horatmp.getSeconds() + element);
  //      newdataFinalLifeManyBuses[busnumber].push({
  //        name: new Date(horatmp),
  //        value: result_energy_eol[index],
  //      });
  //    });
  //
  //
  //
  //  }
  //
  //
  //  // console.log('newdataBusManyBuses ', newdataBusManyBuses);
  //  nuevodataSOC202.push({
  //    name: new Date(newdataBusManyBuses[busesid[0]][0].name),
  //    value: this.completeDay[0].socmin,
  //  });
  //  console.log();
  //  nuevodataSOC202.push({
  //    name: new Date(newdataBusManyBuses[busesid[busesid.length - 1]][newdataBusManyBuses[busesid[busesid.length - 1]].length - 1].name),
  //    value: this.completeDay[0].socmin,
  //  });
  //
  //  //Creación de los arreglos finales del gráfico
  //  /* result_time.forEach((element, index: number) => {
  //    const horatmp = new Date(newdataBusManyBuses[busesid[0]][0].name);
  //    horatmp.setSeconds(horatmp.getSeconds() + element);
  //
  //    nuevodataSOC202.push({
  //      name: new Date(horatmp),
  //      value: this.completeDay[0].socmin,
  //    });
  //  }); */
  //
  //
  //  this.changeAxisConsumTime();
  //  const sa = [
  //    {
  //      nombre: '100% SOH',
  //      datos: nuevoDataBus2,
  //    },
  //    {
  //      nombre: 'Fin de vida',
  //      datos: nuevodataFinalLife2,
  //    },
  //    {
  //      nombre: '20% SOC',
  //      datos: nuevodataSOC202,
  //    },
  //    {
  //      datos: newdataBusManyBuses
  //    },
  //    {
  //      datos: newdataFinalLifeManyBuses
  //    }
  //  ];
  //  console.log('Datos de grafica consumo por tiempo:', sa);
  //  return sa;
  //}
  public newGetChargingTime(params: any) {
    console.log("Registro Funciones - newGetChargingTime");
    console.log('newGetChargingTime params:', params);
    let chargingsitearray: any[] = [];
    const today = new Date();
    const todoslostiemposdecarga: any = [];
    let brt_chart = ''
    switch (this.drivingCycle) {
      case "Observado":
        brt_chart = ''
        break;

      case "BRT":
        brt_chart = 'BRT_'
        break;
    }
    const inicio = params.currentCollection + '_' + brt_chart + params.bus;
    const fin = params.type + '_' + params.size + 'm_' + params.battery + 'kWh_' + params.load + '_pass' + params.pax;
    const dateparatime = new Date();
    dateparatime.setHours(0);
    dateparatime.setMinutes(0);
    dateparatime.setSeconds(0);

    this.allRouteProperties[params.bus].namechargers.forEach((cur: any) => {
      todoslostiemposdecarga[cur] = [{
        time: dateparatime,
        value: 0
      }];
    });
    console.log('this.allRouteProperties[params.bus].namechargers:', this.allRouteProperties[params.bus].namechargers);
    console.log('todoslostiemposdecarga:', todoslostiemposdecarga);

    //for (let jota = 0; jota < this.hours.length; jota++) {
    //  let theindex = jota;
    //  console.log('this.completeDay[theindex].chargingsitearray', this.completeDay[theindex].chargingsitearray)
    //  this.completeDay[theindex].chargingsitearray.forEach((element) => {
    //    if (element != 'NONE') {
    //      chargingsitearray.push(element);
    //    }
    //  });
    //}
    for (let jota = 0; jota < this.hours.length; jota++) {
      let theindex = jota;
      try {
        this.completeDay[theindex].chargingsitearray.forEach((element) => {
          if (element != 'NONE') {
            chargingsitearray.push(element);
          }
        });
      } catch {
        console.log('Newgetchargingtime: Falló la consulta del complete day para el ciclo:', theindex + 1)
      }
    }

    let unique_charging_sites: string[] = [...new Set(chargingsitearray)];
    unique_charging_sites.forEach((element) => {
      let chargingsite = element;
      let visitingtimeinit: any = [];
      let visitingtimeend: any = [];
      let chargingtime: any = [];
      const found1 = this.allChargersData[inicio + fin].chargersdata.find((current: any) => {
        return current.chargersid === inicio + '_' + element + '_' + fin;
      });
      //Cambiar chargingtimestampstart por chargingtimestampstarteol y el chargingtimestampfinish por chargingtimestampfinisheol
      //console.log('found1.chargingtimestampstarteol.forEach', found1);
      found1.chargingtimestampstart.forEach(element => {
        visitingtimeinit.push(element);
      });
      found1.chargingtimestampfinish.forEach((element, index) => {
        let hour_init: any = Date.parse(visitingtimeinit[index]);
        let hour_finish: any = Date.parse(element);
        let diff = hour_finish - hour_init;
        const diffMinutes = Math.round(diff / 60000);
        chargingtime.push(diffMinutes);
      });
      visitingtimeinit.forEach((element, index) => {
        let element_hour = element.split('T')[1];
        todoslostiemposdecarga[chargingsite].push({
          time: new Date(today.getFullYear(), today.getMonth(), today.getDate(), element_hour.split(':')[0], element_hour.split(':')[1], element_hour.split(':')[2]),
          value: chargingtime[index]
        });
      });
    });


    this.changeAxisConsumTime();
    let sa = [
      {
        todoslostiemposdecarga,
        loscargadores: this.allRouteProperties[params.bus].namechargers
      }
    ]
    console.log("sa", sa);
    return sa;
  }

  public GetChargingTime(params: any): any {
    console.log("Registro Funciones - GetChargingTime");
    console.log('All cycles inputs:');
    console.log(this.allCyclesInputs);
    this.state.maxEneAvailable = 0;
    const nuevoDataBus2: any = [];
    const nuevodataFinalLife2: any = [];
    const nuevodataSOC202: any = [];

    const chargingpowerarrayt1: any = [];
    const chargingpowerarrayt2: any = [];
    const chargingpowerarraydepot: any = [];

    const charginggridenergyarrayt1: any = [];
    const charginggridenergyarrayt2: any = [];
    const charginggridenergyarraydepot: any = [];
    // let lastTime = 0;
    let ultimoDato = new Date();
    let ultimoEOL = new Date();

    let lastEnergyt1 = 0.0;
    let lastEnergyt2 = 0.0;
    let lastEnergyDepot = 0.0;
    ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), 0, 0, 0);

    chargingpowerarrayt1.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    // console.log('charginggridenergyarrayt1: ', charginggridenergyarrayt1);
    chargingpowerarrayt2.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    // console.log('charginggridenergyarrayt2: ', charginggridenergyarrayt2);
    chargingpowerarraydepot.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    ///////////////////////////////////////////////////////////
    charginggridenergyarrayt1.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    // console.log('charginggridenergyarrayt1: ', charginggridenergyarrayt1);
    charginggridenergyarrayt2.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    // console.log('charginggridenergyarrayt2: ', charginggridenergyarrayt2);
    charginggridenergyarraydepot.push(
      {
        name: new Date(ultimoDato),
        value: 0.0
      }
    );
    // console.log('charginggridenergyarraydepot: ', charginggridenergyarraydepot);
    let lonarray: any = [];
    let latarray: any = [];
    let bAvailable: any = [];
    let timearray: any = [];
    let chargingpower: any = [];
    let chargedenergy: any = [];
    let lostenergy: any = [];
    let autonomy: any = [];
    let totalautonomy: number = 0;
    let evse: any = [];
    let distanciakm: any = [];
    let totalEnergy: number = 0;
    let visitingarray: any = ["None"];
    let visitingindex: any = [0];
    let visitingtimeinit: any = [new Date()];
    let visitingtimeend: any = [new Date()];
    let initialrange: number = 0.0;
    let animationDate = new Date();
    let finalescargadores: any = [];
    // let consumekwhstops: any = [];
    const todoslostiemposdecarga: any = [];
    let numberofchargingevent = 0;
    const dateparatime = new Date();
    dateparatime.setHours(0);
    dateparatime.setMinutes(0);
    dateparatime.setSeconds(0);
    this.allRouteProperties[params.bus].namechargers.forEach((cur: any) => {
      todoslostiemposdecarga[cur] = [{
        time: dateparatime,
        value: 0
      }];
    });
    // const tiempodecarga: any = [];
    const thenamechargers = this.state.allLocations.filter((c: any) => {
      return c.colName == this.state.currentCollection;
    })[0];
    // console.log('thenamechargers: ', thenamechargers);
    let lastdistance = 0;
    let consumedperstop: any = [];
    // console.log('this.hours time: ', this.hours);
    for (let jota = 0; jota < this.hours.length; jota++) {
      // {     let jota = 0;
      // for (const ending of endings) {
      let indicePower = 0;
      // let indicePowerDepot = 0;
      {
        const lashoras = this.hours[jota];
        if (jota === 0) {
          ultimoDato = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          ultimoEOL = new Date(ultimoDato);
          animationDate = new Date(animationDate.getFullYear(), animationDate.getMonth(), animationDate.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
          // console.log('ultimoDato init: ', ultimoDato);
        }
        let theindex = jota;





        try {
          // const lahora = this.hours[jota];
          //const today = new Date();
          // const lad = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(lahora[0] + '' + lahora[1]), parseInt(lahora[2] + '' + lahora[3]), 0);
          /* console.log('this.allCyclesInputs[lashoras]: ', lashoras);
          console.log('this.allCyclesInputs : ', this.allCyclesInputs); */
          if (params.type === 'Opportunity') {
            totalautonomy +=
              this.completeDay[theindex].initialrangecycle -
              this.allCyclesInputs[lashoras].distancekm[this.allCyclesInputs[lashoras].distancekm.length - 1] + 20;
          } else {
            totalautonomy =
              this.completeDay[theindex].initialrangeday;
          }

          /* if (this.completeDay[theindex] !== undefined) {
            this.completeDay[theindex].numberstop.forEach((cur: any, index: number) => {
              consumedperstop.push({
                name: cur + '',
                value: this.completeDay[theindex].energyconsumekwhstop[index]
              });
            });
          } */

          if (this.completeDay[theindex] !== undefined) {



            this.completeDay[theindex].numberstop.forEach((cur: any, index: number) => {
              try {
                const estaaqui = consumedperstop.find((current2: any) => {
                  return current2.name === cur + '';
                });
                if (estaaqui === undefined && this.trayectos[jota] === 'CC') {
                  consumedperstop.push({
                    name: cur + '',
                    // value: this.completeDay[theindex].energyconsumekwhstop[index]
                  });
                } else if (this.trayectos[jota] === 'CC') {
                  estaaqui[this.hours[jota]] = this.completeDay[theindex].energyconsumekwhstop[index];
                }
              } catch (error) {
                console.log('No se pudo mapear energyconsumekwhstop en ciclo ', this.hours[jota], ' and ', theindex);
              }
            });

          }
          /* if (this.hours[jota] === '0425') {
            console.log('time this.completeDay[theindex] para 0425: ', this.completeDay[theindex]);
            console.log('time this.allCyclesInputs[lashoras]: ', this.allCyclesInputs[lashoras]);
          } */
          this.allCyclesInputs[lashoras].timeday.forEach((current: any, index: number) => {

            const otradate = new Date(ultimoDato);
            otradate.setSeconds(otradate.getSeconds() + current);
            const otradateEOL = new Date(ultimoEOL);
            otradateEOL.setSeconds(otradateEOL.getSeconds() + current);
            // console.log('otradate: ', otradate);

            if (this.completeDay[theindex] !== undefined && this.completeDay[theindex].batteryavailablearray !== undefined && index < this.completeDay[theindex].batteryavailablearray.length && this.completeDay[theindex].batteryavailablearray.length > 1) {
              nuevoDataBus2.push({
                // name: (current / 60) + lastTime,
                name: new Date(otradate),
                value: this.completeDay[theindex].batteryavailablearray[index],
              });
              bAvailable.push(this.completeDay[theindex].batteryavailablearray[index]);
              if (this.completeDay[theindex].batteryavailablearray[index] > this.state.maxEneAvailable) {
                this.state.maxEneAvailable = this.completeDay[theindex].batteryavailablearray[index];
              }
              distanciakm.push(this.allCyclesInputs[lashoras].distancekm[index] + lastdistance);
              let amy1 = 0;


              let disevse1 = 0;
              if (this.state.currentType === 'Opportunity') {
                disevse1 = this.allCyclesInputs[lashoras].distancekm[this.allCyclesInputs[lashoras].distancekm.length - 1] - this.allCyclesInputs[lashoras + '_1'].distancekm[index];
                amy1 = this.completeDay[theindex].initialrangecycle - this.allCyclesInputs[lashoras].distancekm[index];
              } else {
                // console.log('this.allCyclesInputs[lashoras + _1].distancekm[index]: ', this.allCyclesInputs[lashoras + '_1'].distancekm[index] - lastdistance);

                disevse1 = this.distanceDayCurrent - this.allCyclesInputs[lashoras].distancekm[index] - lastdistance;
                amy1 = this.completeDay[theindex].initialrangeday - this.allCyclesInputs[lashoras].distancekm[index] - lastdistance;
              }
              // console.log('amy1 p1: ', amy1);
              autonomy.push(amy1);
              evse.push(disevse1);
              const disIndicator = (amy1 - disevse1) / amy1;
              // console.log('disIndicator _1: ', disIndicator);
            }

            if (this.completeDay[theindex] !== undefined && this.completeDay[theindex].batteryavailableeolarray !== undefined && index < this.completeDay[theindex].batteryavailableeolarray.length && this.completeDay[theindex].batteryavailableeolarray.length > 1) {
              nuevodataFinalLife2.push({
                // name: (current / 60) + lastTime,
                name: new Date(otradateEOL),
                value: this.completeDay[theindex].batteryavailableeolarray[index],
              });
            }
            //if (this.completeDay[theindex] === undefined) {
            nuevodataSOC202.push({
              // name: (tiempo / 60) + accumTiempo,
              name: new Date(otradate),
              value: this.completeDay[0].socmin,
            });
            //////////// POTENCIA /////////////
            if (this.completeDay[theindex].chargingpowerarrayt1[index] !== undefined) {
              if (this.completeDay[theindex].chargingpowerarrayt1[index] > 0 &&
                visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[0]) {

                visitingarray.push(this.allRouteProperties[params.bus].namechargers[0]);
                visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[0]));
                visitingtimeinit.push(new Date(otradate));
              }

              chargingpowerarrayt1.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].chargingpowerarrayt1[index].toFixed(0))
                }
              );
              indicePower = index;
              //////////// Build chargingpower array

              chargingpower.push([this.completeDay[theindex].chargingpowerarrayt1[index], new Date(otradate)]);
              chargedenergy.push([
                0,
                new Date(otradate)
              ]);
              if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarrayt1[index]) {
                this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarrayt1[index].toFixed(0));
              }
            }
            if (this.completeDay[theindex].chargingpowerarrayt2[index] !== undefined) {
              if (this.completeDay[theindex].chargingpowerarrayt2[index] > 0 &&
                visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[1]) {
                visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[1]));
                visitingtimeinit.push(new Date(otradate));
              }
              chargingpowerarrayt2.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].chargingpowerarrayt2[index].toFixed(0))
                }
              );
              // chargingpowert2.push(this.completeDay[theindex].chargingpowerarrayt2[index]);
              //////////// Build chargingpower array
              if (chargingpower[chargingpower.length - 1][0] == 0 && this.completeDay[theindex].chargingpowerarrayt2[index] != 0) {
                // chargingpower[chargingpower.length - 1] = this.completeDay[theindex].chargingpowerarrayt2[index];
                chargingpower[chargingpower.length - 1][0] = this.completeDay[theindex].chargingpowerarrayt2[index];
                if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarrayt2[index]) {
                  this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarrayt2[index].toFixed(0));
                }
              }

            }
            if (this.completeDay[theindex].chargingpowerarraydepot[index] !== undefined) {
              if (this.completeDay[theindex].chargingpowerarraydepot[index] > 0 &&
                visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[2]) {
                visitingarray.push(this.allRouteProperties[params.bus].namechargers[2]);
                visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[2]));
                visitingtimeinit.push(new Date(otradate));
              }
              chargingpowerarraydepot.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].chargingpowerarraydepot[index].toFixed(0))
                }
              );
              //////////// Build chargingpower array
              if (chargingpower[chargingpower.length - 1] == 0 && this.completeDay[theindex].chargingpowerarraydepot[index] != 0) {
                // chargingpower[chargingpower.length - 1] = this.completeDay[theindex].chargingpowerarraydepot[index];
                chargingpower[chargingpower.length - 1][0] = this.completeDay[theindex].chargingpowerarraydepot[index];
                if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarraydepot[index]) {
                  this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarraydepot[index].toFixed(0));
                }
              }
            }
            ////////// energía ////////////////
            if (this.completeDay[theindex].charginggridenergyarrayt1[index] !== undefined) {

              charginggridenergyarrayt1.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].charginggridenergyarrayt1[index].toFixed(0)) + lastEnergyt1
                }
              );
              indicePower = index;
              lostenergy.push([parseFloat((this.completeDay[theindex].charginggridenergyarrayt1[index] * 0.05).toFixed(2)), new Date(otradate)]);
            }
            if (this.completeDay[theindex].charginggridenergyarrayt2[index] !== undefined) {

              charginggridenergyarrayt2.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].charginggridenergyarrayt2[index].toFixed(0)) + lastEnergyt2
                }
              );
              if (lostenergy[lostenergy.length - 1][0] == 0.0) {
                lostenergy[lostenergy.length - 1][0] = parseFloat((this.completeDay[theindex].charginggridenergyarrayt2[index] * 0.05).toFixed(2));
              }
            }
            if (this.completeDay[theindex].charginggridenergyarraydepot[index] !== undefined) {

              charginggridenergyarraydepot.push(
                {
                  name: new Date(otradate),
                  value: parseInt(this.completeDay[theindex].charginggridenergyarraydepot[index].toFixed(0)) + lastEnergyDepot
                }
              );
              if (lostenergy[lostenergy.length - 1][0] == 0.0) {
                lostenergy[lostenergy.length - 1][0] = parseFloat((this.completeDay[theindex].charginggridenergyarraydepot[index] * 0.05).toFixed(2));
              }
            }

            // chargedenergy.push([ 0, new Date(otradate)]);

          });
          initialrange += this.completeDay[theindex].initialrangecycle;

          ultimoDato = nuevodataSOC202[nuevodataSOC202.length - 1].name;
          ultimoEOL = nuevodataFinalLife2[nuevodataFinalLife2.length - 1].name;
          if (charginggridenergyarrayt1.length > 0) {
            lastEnergyt1 = charginggridenergyarrayt1[charginggridenergyarrayt1.length - 1].value;
            lastEnergyt2 = charginggridenergyarrayt2[charginggridenergyarrayt2.length - 1].value;
            lastEnergyDepot = charginggridenergyarraydepot[charginggridenergyarraydepot.length - 1].value;

          }
          indicePower += 1;
          // }
          // console.log('ultimoDato fin: ', ultimoDato);
          // lastTime = nuevoDataBus2[nuevoDataBus2.length - 1].name;
          /* lastEnergy = nuevoDataBus2[nuevoDataBus2.length - 1].value;
          lastEnergyFL = nuevodataFinalLife2[nuevodataFinalLife2.length - 1].value; */



          // if (params.type === 'Opportunity') {
          {


            if (this.completeDay[theindex] !== undefined && this.completeDay[theindex].chargingtimearray !== undefined && this.completeDay[theindex].chargingtimearray.length > 1) {
              this.completeDay[theindex].chargingtimearray.forEach((current: any, index: number) => {
                const otradate = new Date(ultimoDato);
                otradate.setSeconds(otradate.getSeconds() + current);
                nuevoDataBus2.push({
                  // name: (current / 60) + lastTime,
                  name: new Date(otradate),
                  value: this.completeDay[theindex].chargingenergyarray[index],
                });
                bAvailable.push(this.completeDay[theindex].chargingenergyarray[index]);

                chargedenergy.push([
                  this.completeDay[theindex].chargingenergyarray[index] - this.completeDay[theindex].chargingenergyarray[0],
                  new Date(otradate)
                ]);
                if (index === this.completeDay[theindex].chargingtimearray.length - 1) {
                  totalEnergy += this.completeDay[theindex].chargingenergyarray[index] - this.completeDay[theindex].chargingenergyarray[0];
                  finalescargadores.push(this.completeDay[theindex].chargingenergyarray[index] - this.completeDay[theindex].chargingenergyarray[0]);
                }

                /* nuevodataFinalLife2.push({
                  name: new Date(otradate),
                  value: this.completeDay[theindex].chargingenergyeolarray[index],
                }); */
                distanciakm.push(distanciakm[distanciakm.length - 1]);
                let amy1 = 0;

                let disevse1 = 0;
                if (this.state.currentType === 'Opportunity') {
                  disevse1 = 0;
                } else {
                  disevse1 = evse[evse.length - 1];
                  amy1 = autonomy[autonomy.length - 1];
                }
                // console.log('amy1 p2: ', amy1);
                autonomy.push(amy1);
                evse.push(disevse1);
                const disIndicator = 1;
                nuevodataSOC202.push({
                  // name: (tiempo / 60) + accumTiempo,
                  name: new Date(otradate),
                  value: this.completeDay[0].socmin,
                });
                //////// Potencia ///////////
                if (this.completeDay[theindex].chargingpowerarrayt1[index + indicePower] !== undefined) {
                  if (this.completeDay[theindex].chargingpowerarrayt1[index + indicePower] > 0 &&
                    visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[0]) {
                    visitingarray.push(this.allRouteProperties[params.bus].namechargers[0]);
                    visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[0]));
                    visitingtimeinit.push(new Date(otradate));
                    const odate = new Date(otradate);
                    odate.setSeconds(otradate.getSeconds() + this.completeDay[theindex].chargingtimearray[this.completeDay[theindex].chargingtimearray.length - 1]);
                    visitingtimeend.push(new Date(odate));
                  }
                  /* if (this.completeDay[theindex].chargingpowerarrayt1[index + indicePower] > 0){
                    console.log('chargingpowerarrayt1[index + indicePower]: ', this.completeDay[theindex].chargingpowerarrayt1[index + indicePower], ' para ', lashoras + '_1');
                  } */
                  chargingpowerarrayt1.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].chargingpowerarrayt1[index + indicePower].toFixed(0))
                    }
                  );

                  chargingpower.push([this.completeDay[theindex].chargingpowerarrayt1[index + indicePower], new Date(otradate)]);
                  if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarrayt1[index + indicePower]) {
                    this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarrayt1[index + indicePower].toFixed(0));
                  }

                }
                if (this.completeDay[theindex].chargingpowerarrayt2[index + indicePower] !== undefined) {
                  if (this.completeDay[theindex].chargingpowerarrayt2[index + indicePower] > 0 &&
                    visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[1]) {
                    visitingarray.push(this.allRouteProperties[params.bus].namechargers[1]);
                    visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[1]));
                    visitingtimeinit.push(new Date(otradate));
                    const odate = new Date(otradate);
                    odate.setSeconds(otradate.getSeconds() + this.completeDay[theindex].chargingtimearray[this.completeDay[theindex].chargingtimearray.length - 1]);
                    visitingtimeend.push(new Date(odate));
                  }
                  /* if (this.completeDay[theindex].chargingpowerarrayt2[index + indicePower] > 0){
                    console.log('chargingpowerarrayt2[index + indicePower]: ', this.completeDay[theindex].chargingpowerarrayt2[index + indicePower], ' para ', lashoras + '_1');
                  } */
                  chargingpowerarrayt2.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].chargingpowerarrayt2[index + indicePower].toFixed(0))
                    }
                  );
                  // chargingpowert2.push(this.completeDay[theindex].chargingpowerarrayt2[index + indicePower]);

                  //////////// Build chargingpower array
                  if (chargingpower[chargingpower.length - 1][0] == 0 && this.completeDay[theindex].chargingpowerarrayt2[index + indicePower] != 0) {
                    // chargingpower[chargingpower.length - 1] = this.completeDay[theindex].chargingpowerarrayt2[index + indicePower];
                    chargingpower[chargingpower.length - 1][0] = this.completeDay[theindex].chargingpowerarrayt2[index + indicePower];
                    if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarrayt2[index + indicePower]) {
                      this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarrayt2[index + indicePower].toFixed(0));
                    }
                  }


                }
                if (this.completeDay[theindex].chargingpowerarraydepot[index + indicePower] !== undefined) {
                  if (this.completeDay[theindex].chargingpowerarraydepot[index + indicePower] > 0 &&
                    visitingarray[visitingarray.length - 1] !== this.allRouteProperties[params.bus].namechargers[2]) {
                    visitingarray.push(this.allRouteProperties[params.bus].namechargers[2]);
                    visitingindex.push(thenamechargers.chargernames.indexOf(this.allRouteProperties[params.bus].namechargers[2]));
                    visitingtimeinit.push(new Date(otradate));
                    const odate = new Date(otradate);
                    odate.setSeconds(otradate.getSeconds() + this.completeDay[theindex].chargingtimearray[this.completeDay[theindex].chargingtimearray.length - 1]);
                    visitingtimeend.push(new Date(odate));
                  }
                  chargingpowerarraydepot.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].chargingpowerarraydepot[index + indicePower].toFixed(0))
                    }
                  );

                  //////////// Build chargingpower array
                  if (chargingpower[chargingpower.length - 1][0] == 0 && this.completeDay[theindex].chargingpowerarraydepot[index + indicePower] != 0) {
                    chargingpower[chargingpower.length - 1][0] = this.completeDay[theindex].chargingpowerarraydepot[index + indicePower];
                    if (this.state.chargersMaxValue < this.completeDay[theindex].chargingpowerarraydepot[index + indicePower]) {
                      this.state.chargersMaxValue = parseInt(this.completeDay[theindex].chargingpowerarraydepot[index + indicePower].toFixed(0));
                    }

                  }

                }
                /////// Energía /////////////

                if (this.completeDay[theindex].charginggridenergyarrayt1[index + indicePower] !== undefined) {

                  charginggridenergyarrayt1.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].charginggridenergyarrayt1[index + indicePower].toFixed(0)) + lastEnergyt1
                    }
                  );
                  lostenergy.push([parseFloat((this.completeDay[theindex].charginggridenergyarrayt1[index + indicePower] * 0.05).toFixed(2)), new Date(otradate)]);

                }
                if (this.completeDay[theindex].charginggridenergyarrayt2[index + indicePower] !== undefined) {

                  charginggridenergyarrayt2.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].charginggridenergyarrayt2[index + indicePower].toFixed(0)) + lastEnergyt2
                    }
                  );
                  if (lostenergy[lostenergy.length - 1][0] == 0.0) {
                    lostenergy[lostenergy.length - 1][0] = parseFloat((this.completeDay[theindex].charginggridenergyarrayt2[index + indicePower] * 0.05).toFixed(2));
                  }

                }
                if (this.completeDay[theindex].charginggridenergyarraydepot[index + indicePower] !== undefined) {

                  charginggridenergyarraydepot.push(
                    {
                      name: new Date(otradate),
                      value: parseInt(this.completeDay[theindex].charginggridenergyarraydepot[index + indicePower].toFixed(0)) + lastEnergyDepot
                    }
                  );
                  if (lostenergy[lostenergy.length - 1][0] == 0.0) {
                    lostenergy[lostenergy.length - 1][0] = parseFloat((this.completeDay[theindex].charginggridenergyarraydepot[index + indicePower] * 0.05).toFixed(2));
                  }

                }
              });
              /// Aqui ultimo dato
              // visitingtimeend.push();
              if (this.completeDay[theindex].chargingsite !== null) {
                try {
                  const eltime = new Date(ultimoDato);
                  const losminutos = eltime.getMinutes() + '';
                  let lacadena = '';
                  if (losminutos.length === 1) {
                    lacadena = eltime.getHours() + ':0' + eltime.getMinutes();
                  }
                  else {
                    lacadena = eltime.getHours() + ':' + eltime.getMinutes();
                  }

                  numberofchargingevent += 1;
                  todoslostiemposdecarga[this.completeDay[theindex].chargingsite].push({
                    time: eltime,
                    value: this.completeDay[theindex].chargingtime / 60
                  });
                } catch (error) {
                  console.log('error in part 1 of todoslostiemposdecarga for cicle ', this.hours[jota]);
                  console.log('with chargingsite: ', this.completeDay[theindex].chargingsite);
                }

              }
            }



            if (this.completeDay[theindex] !== undefined && this.completeDay[theindex].chargingtimeeolarray !== undefined && this.completeDay[theindex].chargingtimeeolarray.length > 1) {
              this.completeDay[theindex].chargingtimeeolarray.forEach((current: any, index: number) => {
                const otradate = new Date(ultimoEOL);
                otradate.setSeconds(otradate.getSeconds() + current);
                nuevodataFinalLife2.push({
                  name: new Date(otradate),
                  value: this.completeDay[theindex].chargingenergyeolarray[index],
                });
              });
            }
          }

          // ultimoDato = nuevoDataBus2[nuevoDataBus2.length - 1].name;
          lastdistance = distanciakm[distanciakm.length - 1];
          ultimoDato = nuevodataSOC202[nuevodataSOC202.length - 1].name;
          ultimoEOL = nuevodataFinalLife2[nuevodataFinalLife2.length - 1].name;

          if (charginggridenergyarrayt1.length > 0) {
            lastEnergyt1 = charginggridenergyarrayt1[charginggridenergyarrayt1.length - 1].value;
            lastEnergyt2 = charginggridenergyarrayt2[charginggridenergyarrayt2.length - 1].value;
            lastEnergyDepot = charginggridenergyarraydepot[charginggridenergyarraydepot.length - 1].value;

          }
          {
            // console.log('first');
            animationDate = new Date(animationDate.getFullYear(), animationDate.getMonth(), animationDate.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
            // console.log('la re primer fecha : ', animationDate);
            for (let i = 0; i < (this.allCyclesInputs[lashoras].longitude.length + this.completeDay[theindex].chargingenergyarray.length); i++) {

              timearray.push(new Date(animationDate));
              animationDate.setSeconds(animationDate.getSeconds() + 3);
            }
          }
          try {
            lonarray = lonarray.concat(this.allCyclesInputs[lashoras].longitude);
            latarray = latarray.concat(this.allCyclesInputs[lashoras].latitude);
            this.completeDay[theindex].chargingenergyarray.forEach((current: any) => {
              lonarray.push(lonarray[lonarray.length - 1]);
              latarray.push(latarray[latarray.length - 1]);
            });


            /* if (timearray.length !== 0){
              let lastTime = timearray[timearray.length - 1];
              animationDate = new Date(animationDate.getFullYear(), animationDate.getMonth(), animationDate.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);

            } else  */

          } catch (error) {
            // console.log('Error obteniendo longitudes: ', error);
          }

        } catch (error) {
          // console.log('Time: ', error);
        }
      }
    }
    // console.log('chargersmaxvalue: ', this.state.chargersMaxValue);
    visitingindex.push(0);
    const endtiming = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate() + 1, 0, 0, 0);
    visitingtimeinit.push(endtiming);
    visitingtimeend.push(endtiming);
    this.state.showAnimButtons = true;
    this.state.routesAnimationData[params.currentCollection + params.bus] = {
      route: params.bus,
      type: params.type,
      latitude: latarray,
      longitude: lonarray,
      datearray: timearray,
      available: bAvailable,
      battery: params.battery,
      chargingpower: chargingpower,
      distancekm: distanciakm,
      initialrange,
      namechargers: this.allRouteProperties[params.bus].namechargers,
      whichcharger: 1,
      visiting: visitingarray,
      visitingindex,
      visitingtimeinit,
      visitingtimeend,
      whichvisiting: 1,
      autonomy,
      totalautonomy: parseInt(totalautonomy.toFixed(0)),
      evse,
      trajects: this.allRouteProperties[params.bus].cyclestraject,
      lostenergy,
      chargedenergy,
      totalEnergy: parseFloat(totalEnergy.toFixed(2)),
      finalescargadores
    }


    // console.log('this.state.routesAnimationData: ', this.state.routesAnimationData);
    /*console.log('chargingpowerarrayt1: ', chargingpowerarrayt1);
    console.log('chargingpowerarrayt2: ', chargingpowerarrayt2);
    console.log('chargingpowerarraydepot: ', chargingpowerarraydepot);
    console.log('charginggridenergyarrayt1: ', charginggridenergyarrayt1);
    console.log('charginggridenergyarrayt2: ', charginggridenergyarrayt2); */
    // console.log('charginggridenergyarraydepot: ', charginggridenergyarraydepot);
    const cabeceras = [];
    const patios = [];


    // console.log('charginggridenergyarraydepot: ', charginggridenergyarraydepot);
    try {
      // console.log('los this.state.busIntervalTime length: ', this.state.busIntervalTime.length);
      const lashoras = this.hours[0];
      let otroDato = new Date();
      otroDato = new Date(otroDato.getFullYear(), otroDato.getMonth(), otroDato.getDate(), parseInt(lashoras[0] + '' + lashoras[1]), parseInt(lashoras[2] + '' + lashoras[3]), 0);
      this.state.busIntervalTime.forEach((value: any, index: number) => {

        //if (index > 0) {
        // ultimoDato.setSeconds(ultimoDato.getSeconds() + value);
        const otherdate = new Date(otroDato);
        otherdate.setSeconds(otherdate.getSeconds() + value);

        if (index < this.trayectos.length && this.trayectos[index][0] === 'C') {

          cabeceras.push({
            x: new Date(otherdate),
            y: 0,
            size: 0.1
          });
        } else {

          patios.push({
            x: new Date(otherdate),
            y: 0,
            size: 0.1
          });
        }

      });
    } catch (error) {
      console.log('Falló al obtener patios y cabeceras: ', error);
    } // */
    /* console.log('patios: ', patios);
    console.log('cabeceras: ', cabeceras); */
    const hoy = new Date();
    if (ultimoDato.getFullYear() === hoy.getFullYear() && ultimoDato.getMonth() === hoy.getMonth() && ultimoDato.getDate() === hoy.getDate()) {
      ultimoDato = new Date();
      const finaldate = new Date(ultimoDato.getFullYear(), ultimoDato.getMonth(), ultimoDato.getDate() + 1, 0, 0, 0);
      chargingpowerarrayt1.push(
        {
          name: new Date(finaldate),
          value: chargingpowerarrayt1[chargingpowerarrayt1.length - 1].value
        }
      );
      // console.log('charginggridenergyarrayt1: ', charginggridenergyarrayt1);
      chargingpowerarrayt2.push(
        {
          name: new Date(finaldate),
          value: chargingpowerarrayt2[chargingpowerarrayt2.length - 1].value
        }
      );
      // console.log('charginggridenergyarrayt2: ', charginggridenergyarrayt2);
      charginggridenergyarraydepot.push(
        {
          name: new Date(finaldate),
          value: charginggridenergyarraydepot[charginggridenergyarraydepot.length - 1].value
        }
      );
      ///////////////////////////////////////
      charginggridenergyarrayt1.push(
        {
          name: new Date(finaldate),
          value: charginggridenergyarrayt1[charginggridenergyarrayt1.length - 1].value
        }
      );
      // console.log('charginggridenergyarrayt1: ', charginggridenergyarrayt1);
      charginggridenergyarrayt2.push(
        {
          name: new Date(finaldate),
          value: charginggridenergyarrayt2[charginggridenergyarrayt2.length - 1].value
        }
      );
      // console.log('charginggridenergyarrayt2: ', charginggridenergyarrayt2);
      charginggridenergyarraydepot.push(
        {
          name: new Date(finaldate),
          value: charginggridenergyarraydepot[charginggridenergyarraydepot.length - 1].value
        }
      );
    }
    //////////////////
    //console.log('tiempodecarga ', tiempodecarga);
    const datefintime = new Date();
    datefintime.setHours(24, 0, 0, 0);
    todoslostiemposdecarga[this.allRouteProperties[params.bus].namechargers[0]].push({
      time: datefintime,
      value: 0
    });
    this.state.THEDATA[10] = consumedperstop;
    console.log("THEDATA[10]", this.state.THEDATA[10])
    this.changeAxisConsumTime();

    const sa = [
      {
        nombre: '100% SOH',
        datos: nuevoDataBus2,
      },
      {
        nombre: 'Fin de vida',
        datos: nuevodataFinalLife2,
      },
      {
        nombre: '20% SOC',
        datos: nuevodataSOC202,
      },
      {
        nombre: 'Cabeceras',
        datos: cabeceras,
      },
      {
        nombre: 'Patios',
        datos: patios,
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[0],
        datos: chargingpowerarrayt1
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[1],
        datos: chargingpowerarrayt2
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[2],
        datos: chargingpowerarraydepot
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[0],
        datos: charginggridenergyarrayt1
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[1],
        datos: charginggridenergyarrayt2
      },
      {
        nombre: this.allRouteProperties[params.bus].namechargers[2],
        datos: charginggridenergyarraydepot
      },
      {
        // tiempodecarga,
        todoslostiemposdecarga,
        loscargadores: this.allRouteProperties[params.bus].namechargers
      }

    ];
    return sa;
  }

  public changeAxisVelocity(): void {
    console.log("Registro Funciones - changeAxisVelocity");
    const hoy = new Date();
    //console.log("this.state.maxTime ", this.state.maxTime);
    this.state.axis4[0] = {
      valueType: 'DateTime',
      intervalType: 'Hours',
      // interval: 10,
      title: 'Hora del día',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      rangePadding: 'Auto',
      labelFormat: 'HH:mm'
    };
    console.log("this.state.maxVelocity ", this.state.maxVelocity);
    this.state.axis4[1] = {
      labelFormat: '{value}',
      title: 'Velocidad (km/h)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      maximum: this.state.maxVelocity,
      /* interval: elvalorin, */
    };
  }

  public newGetVelocityVsTime(): any {
    console.log("Registro Funciones - newGetVelocityVsTime");
    this.state.THEDATA[3] = [];

    /* this.state.maxDistConsumption = 0;
    this.state.maxEneConsumption = 0; */
    this.state.maxVelocity = 0;
    this.state.maxTime = 0;
    const salida: any = [];
    const resspeedstopsa: any = [];
    const newstopspeed: any = [];
    /* for (const h of this.hours) {
      salida.push({
        nombre: this.militarHourWithDots(h),
        datos: [],
      });
    } */
    let countCP = 1;
    let countPC = 1;
    for (let ho = 0; ho < this.hours.length; ho++) {
      const endings = ['_1', '_2'];
      let resultados: any = [];
      let averageresults: any = [];
      let stopspeed: any = [];
      // let ultimoDato = 0.0;
      let ultimoDato = new Date();
      const lahora = this.hours[ho];
      const today = new Date();
      const lad = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(lahora[0] + '' + lahora[1]), parseInt(lahora[2] + '' + lahora[3]), 0);
      try {

        const miaverage: any = [];
        const inputs_deltatime = this.allCyclesInputs[this.hours[ho]].timeday[1] - this.allCyclesInputs[this.hours[ho]].timeday[0];
        console.log('inputs_deltatime: ', inputs_deltatime)
        const output = this.allCyclesInputs[this.hours[ho]].timeday.map((current: any, index: number) => {
          if (current / 60 > this.state.maxTime) {
            this.state.maxTime = current / 60;
          }
          // if (results.speedkmh[index] > this.state.maxVelocity) {
          if (this.allCyclesInputs[this.hours[ho]].speedkmh[index] > this.state.maxVelocity) {
            this.state.maxVelocity = this.allCyclesInputs[this.hours[ho]].speedkmh[index];
          }
          //lad.setSeconds(lad.getSeconds() + 3);
          lad.setSeconds(lad.getSeconds() + inputs_deltatime);
          miaverage.push({
            name: new Date(lad),
            value: this.allCyclesInputs[this.hours[ho]].averagespeedarray[index]
          });
          // console.log(lad);
          return {
            // name: (current / 60) + ultimoDato,
            // value: results.speedkmh[index],
            name: new Date(lad),
            value: this.allCyclesInputs[this.hours[ho]].speedkmh[index]
          };
        });
        //console.log("outputs de velocity 1", output)
        //console.log("outputs de velocity 2", miaverage)
        resultados = resultados.concat(output);
        averageresults = averageresults.concat(miaverage);
        // ultimoDato = parseFloat(output[output.length - 1].name);
        ultimoDato = output[output.length - 1].name;
        // console.log('ultimo: ', ultimoDato);


        let datacompleteday: any = [];

      } catch (error) {
        /* console.log(
          'Perfil de velocidad: no se pudo obtener los datos de ciclo ',
          this.hours[ho]
        ); */
      }

      try {
        if (this.trayectos[ho] === 'CC') {
          this.allCyclesInputs[this.hours[ho]].numberstops.forEach((cur: any, index: number) => {
            const estaaqui = newstopspeed.find((current: any) => {
              return current.name === cur;
            });
            if (estaaqui === undefined) {
              newstopspeed.push({
                name: cur,
              });
              newstopspeed[newstopspeed.length - 1][this.hours[ho]] = parseFloat(this.allCyclesInputs[this.hours[ho]].averagespeedstops[index].toFixed(2));
            } else {
              estaaqui[this.hours[ho]] = parseFloat(this.allCyclesInputs[this.hours[ho]].averagespeedstops[index].toFixed(2))
            }
          });
          // console.log('Concatenando unaserie', unaserie);
          // stopspeed = stopspeed.concat(unaserie);
          // console.log('stopspeed concatenado para ', this.hours[ho], ' y es ', stopspeed);
        }
      } catch (error) {
        console.log(
          'Speedstops: no se pudo obtener los datos de ciclo ',
          this.hours[ho]
        );
      }
      // console.log('newstopspeed: ', newstopspeed);
      let theName = this.militarHourWithDots(this.hours[ho]);
      if (this.trayectos[ho] === 'PC') {
        theName = 'PC' + countPC++;
      } else if (this.trayectos[ho] === 'CP') {
        theName = 'CP' + countCP++;
      } /* else if (this.trayectos[ho] === 'CC') {
        // console.log('Encontrado trayecto CC ', stopspeed);

        resspeedstopsa.push({
          nombre: theName,
          datos: stopspeed
        });
        // console.log('resspeedstopsa: ', resspeedstopsa);
      } */
      // console.log('resspeedstopsa: ', resspeedstopsa);
      salida.push({
        nombre: theName,
        datos: [],
        averagespeed: [],
        // stopspeed: []
      });
      // salida[ho].nombre = this.militarHourWithDots(this.hours[ho]);
      /* const resultado = output.concat(datacompleteday);
      const resultado2 = resultado.concat(output2);
      const final = resultado2.concat(datacompleteday2);
      salida[ho].datos = final; */
      salida[ho].datos = resultados;
      salida[ho].averagespeed = averageresults;
    }
    // console.log('resspeedstopsa: ', resspeedstopsa);
    // this.changeAxisVelocity();
    this.state.dataForSpeedStops = newstopspeed;
    console.log("datos velocidad paradas",this.state.dataForSpeedStops)
    /* {
      nombre: 'SpeedStops',
      datos: resspeedstopsa
    }; */
    return salida;
  }

  changeAxisAVG(): void {
    console.log("Registro Funciones - changeAxisAVG");
    this.state.axis7[0] = {
      labelFormat: '{value}',
      valueType: 'Double',
      title: 'Velocidad media (km/h)',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      maximum: this.state.maxAvgVelocity,
    };
    this.state.axis7[1] = {
      valueType: 'Category',
      title: 'Servicio',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };
  }

  public newGetAVGVelocity(): any {
    console.log("Registro Funciones - newGetAVGVelocity");
    const salida: any = [];
    for (const h of this.hours) {
      salida.push({
        hora: this.militarHourWithDots(h),
        velocidad: 0,
      });
    }
    let countCP = 1;
    let countPC = 1;
    for (let ho = 0; ho < this.hours.length; ho++) {
      // console.log('Query for backend energy consumption: ', energyQuery1);
      if (this.trayectos[ho] === 'PC') {
        salida[ho].hora = 'PC' + countPC++;
      } else if (this.trayectos[ho] === 'CP') {
        salida[ho].hora = 'CP' + countCP++;
      }
      let results2: any;
      try {
        // const results = this.newStatistics[ho];
        results2 = this.allCyclesInputs[this.hours[ho]];

      } catch (error) {
        console.log(error);
        console.log(
          'AVG velocity: no se pudo obtener los datos de ciclo ',
          this.hours[ho] + '_1'
        );
      }

      salida[ho].velocidad = results2.averagespeed;
      if (this.state.maxAvgVelocity < results2.averagespeed) {
        this.state.maxAvgVelocity = results2.averagespeed;
      }
    }
    this.changeAxisAVG();
    return salida;
  }

  changeAxisStatistics3(): void {
    console.log("Registro Funciones - changeAxisStatistics3");
    let tituloX = '';
    if (this.state.currentStats === 4) {
      tituloX = 'Distancia (km)';
    } else {
      tituloX = 'Energía (kWh)';
    }
    this.state.axis6[0] = {
      valueType: 'Category',
      title: 'Ruta',
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
    };
    this.state.axis6[1] = {
      labelFormat: '{value}',
      title: tituloX,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      maximum: this.state.maxStatsConsumption,
    };
  }

  public async getLongitudData(params: any): Promise<any> {
    console.log("Registro Funciones - getLongitudData");

    let var1: any;
    let minimo = 100000;
    let bus1 = '';
    let maximo = -10;
    let bus2 = '';

    const salida2: any = [];
    console.log("longituddata", this.state.busNames)
    for (const ruta of this.state.busNames) {

      let longitudQuery1 = params.currentCollection + '_' + ruta;
      console.log('Longitud Query1:', longitudQuery1);
      const ee = { STRID: longitudQuery1 };
      // console.log('Query for backend LONGITUD consumption: ', longitudQuery1);
      try {
        //console.log('this.allRouteProperties:',this.allRouteProperties)
        const results = this.allRouteProperties[ruta];
        console.log('Busqueda de ruta en RouteProperties: ', results);

        if ( results.route.includes('Global') ) {
          console.log("Entro ruta", results.routename)
          //var nombreruta = ruta.length <= 3 || results.route.length >= 8 ? ruta : ruta.slice(0, 3)
          var nombreruta = results.routename
          var vueltas = results.cyclestraject.length
          const laDistancia = (results.distancestops[results.distancestops.length - 1] / 1000) * vueltas;
          console.log("datos a salida2", nombreruta, laDistancia)
          salida2.push({
            name: nombreruta,
            value: laDistancia,
          });
          if (laDistancia < minimo) {
            minimo = laDistancia;
            bus1 = ruta;
          }
          if (laDistancia > maximo) {
            maximo = laDistancia;
            bus2 = ruta;
          }
          if (ruta === params.bus) {
            var1 = laDistancia;
          }
        }
      } catch (error) {
        console.error('Comparativa consumo: No se puede obtener datos de paradas para ruta ', ruta);
      }
    }
    this.state.addMaxBusAtIndex(bus2, 3);
    this.state.addMinBusAtIndex(bus1, 3);
    this.state.updateStats = '3';
    this.state.changeUpdateStats('3');
    // this.state.THEDATA[6] = salida2;
    console.log('Salida de comparativa long THEDATA[6]: ', salida2);
    return salida2;
  }
  public changeAxisDiscriminated(): void {
    console.log("Registro Funciones - changeAxisDiscriminated");
    let legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexdiu[this.state.indiceTranslate]//'Distancia (km)';
    let legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeydiu[this.state.indiceTranslate]//'Energía (kWh)';
    if (this.state.currentType === 'Overnight') {
      legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexover[this.state.indiceTranslate]//'Distancia por día (km)';
      legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeyover[this.state.indiceTranslate]//'Energía por día (kWh)';
    }
    /* const maxxx =
      this.float2int(this.float2int(this.state.maxDistDiscriminated / 10) + 1) *
      10; */
    this.state.axis2[0] = {
      valueType: 'Double',
      title: legendaX,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      // maximum: this.state.maxDistDiscriminated,
      /*interval: maxxx / 10, */
    };
    let myIntervalo = 10;
    if (this.state.currentBattery === '348') {
      myIntervalo = 30;
    } else if (this.state.currentBattery === '652') {
      myIntervalo = 80;
    } else if (this.state.currentBattery === '324') {
      myIntervalo = 30;
    }
    /**this.state.axis2[1] = {
      labelFormat: '{value}',
      title: legendaY,
      titleStyle: {
        fontFamily: 'Poppins',
        size: '0.8vw'
      },
      labelStyle: {
        fontFamily: 'Poppins',
        size: '1.1vw'
      },
      minimum: 0,
      rangePadding: 'Auto',
      // maximum: this.state.maxEneDiscriminated,
      /* interval: myIntervalo, 
    };*/
  }

  public getDiscrimitaed = async (params: any) => {
    // console.log('Params in DISCRIMI consumption: ', params);
    this.state.THEDATA[7] = [];
    this.state.maxEneDiscriminated = 0;
    this.state.maxDistDiscriminated = 0;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    if (params.type === 'Opportunity') {
      /* const finalOutput: any = [];
      for (const h of this.hours) {
        finalOutput.push({
          nombre: h,
          datos: [],
        });
      } */
      // const criticHour = this.hours[this.dailyStatistics.maxconsumCycle - 1];
      const criticHour = this.hours[this.completeDay[0].maxconsumcycle - 1];

      const realqueryid =
        params.currentCollection +
        '_' +
        params.bus +
        '_' +
        criticHour +
        '_' +
        params.type +
        '_' +
        params.size +
        'm_' +
        params.battery +
        'kWh';
      const realqueryhvac: string = params.load;
      const realquerypass: string = 'pass' + params.pax;
      const realquery = {
        STRID: realqueryid,
        HVAC: realqueryhvac,
        PASS: realquerypass
      }
      // console.log('query: ', realquery);
      let output: any = [];
      const thediscri = await this.dexieDB.getOutputs(realqueryid + realqueryhvac + realquerypass);
      console.log("Discrimitaed dexie  de getDiscrimitaed: ", thediscri)
      if (thediscri.length > 0) {
        // console.log('Discriminated values already in cache');
        output = JSON.parse(thediscri[0].data);
      } else {
        output = await this.http
          .post(this.mainUrl + 'querydiscriminated/', realquery, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addOutputs(realqueryid + realqueryhvac + realquerypass, output);
        console.log("El dexie esta vacio, cargando dexie en addOutputs de getDiscrimitaed", output)

      }
      // console.log('Output querydiscriminated is: ', output);
      let salidaBothOne: any = [];
      let salidaPaxOne: any = [];
      let salidaBaseOne: any = [];
      let ultimadistancia = 0;
      let ultimoconsumoB = 0;
      let ultimoconsumoP = 0;
      let ultimoconsumoN = 0;
      // const salidaBothOne = output.output[0].energyarray.map(
      if (output.output !== undefined && output.output[0].energyarray !== undefined && output.output[0].energyarray.length > 1) {
        this.allCyclesInputs[criticHour + '_1'].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[0].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[0].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[0].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              if (parseInt(current, 10) / 1 > this.state.maxDistDiscriminated) {
                this.state.maxDistDiscriminated = parseInt(current, 10) / 1;
              }
              salidaBothOne.push({
                // name: this.allCyclesInputs[criticHour + '_1'].distancekm[index],
                name: current,
                value: output.output[0].energyarray[index],
              });
            }
          }
        );
        // console.log('salidaBothOne: ', salidaBothOne);
        //const salidaPaxOne = output.output[1].energyarray.map(
        this.allCyclesInputs[criticHour + '_1'].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[1].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[1].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[1].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              salidaPaxOne.push({
                // name: this.allCyclesInputs[criticHour + '_1'].distancekm[index],
                name: current,
                value: output.output[1].energyarray[index],
              });
            }
          }
        );
        // console.log('Output querydiscriminated salidaPax2: ', salidaPaxOne);
        this.allCyclesInputs[criticHour + '_1'].distancekm.forEach(
          // const salidaBaseOne = output.output[2].energyarray.map(
          (current: any, index: number) => {
            if (output.output[2].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[2].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[2].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              /* if (parseInt(current, 10) / 1 > this.state.maxDistDiscriminated) {
                this.state.maxDistDiscriminated = parseInt(current, 10) / 1;
              } */
              salidaBaseOne.push({
                name: current,
                value: output.output[2].energyarray[index],
              });
            }
          }
        );
        // console.log('Output querydiscriminated salidaBase2: ', salidaBaseOne);
        ultimadistancia = salidaBothOne[salidaBothOne.length - 1].name;
        // console.log('ultimoconsumoB: ' + ultimoconsumoB);

        ultimoconsumoB = salidaBothOne[salidaBothOne.length - 1].value;
        ultimoconsumoP = salidaPaxOne[salidaPaxOne.length - 1].value;
        ultimoconsumoN = salidaBaseOne[salidaBaseOne.length - 1].value;
        // console.log('ultimoconsumoB: ' + ultimoconsumoB);
      }

      let salidaBothTwo: any = [];
      let salidaBaseTwo: any = [];
      let salidaPaxTwo: any = [];
      if (output.output !== undefined && output.output[3].energyarray.length > 1 && this.allCyclesInputs[criticHour + '_2'].distancekm !== undefined) {
        // const salidaBothTwo = output.output[3].energyarray.map(
        this.allCyclesInputs[criticHour + '_2'].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[3].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[3].energyarray[index] + ultimoconsumoB) / 1 >
                this.state.maxEneDiscriminated
              ) {
                // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[3].energyarray[index] + ultimoconsumoB) / 10
                  ) +
                    1) *
                  10;
              }
              if (parseInt(current + ultimadistancia, 10) / 1 > this.state.maxDistDiscriminated) {
                this.state.maxDistDiscriminated = parseInt(current + ultimadistancia, 10);
              }
              salidaBothTwo.push({
                name: current + ultimadistancia,
                value: output.output[3].energyarray[index] + ultimoconsumoB,
              });
            }
          }
        );
        /* console.log('REEUltimadistancia: ' + salidaBothTwo[salidaBothTwo.length - 1].name);
        console.log('Output querydiscriminated salidaBoth2: ', salidaBothTwo); */

        // const salidaPaxTwo = output.output[4].energyarray.map(
        this.allCyclesInputs[criticHour + '_2'].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[4].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[4].energyarray[index] + ultimoconsumoP) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[4].energyarray[index] + ultimoconsumoP) / 10
                  ) +
                    1) *
                  10;
              }
              salidaPaxTwo.push({
                name: current + ultimadistancia,
                value: output.output[4].energyarray[index] + ultimoconsumoP,
              });
            }
          }
        );
        // console.log('Output querydiscriminated salidaPax2: ', salidaPaxTwo);

        // const salidaBaseTwo = output.output[5].energyarray.map(
        this.allCyclesInputs[criticHour + '_2'].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[5].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[5].energyarray[index] + ultimoconsumoN) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[5].energyarray[index] + ultimoconsumoN) / 10
                  ) +
                    1) *
                  10;
              }
              salidaBaseTwo.push({
                name: current + ultimadistancia,
                value: output.output[5].energyarray[index] + ultimoconsumoN,
              });
            }
          }
        );
      }
      // console.log('Output querydiscriminated salidaBase2: ', salidaBaseTwo);



      this.changeAxisDiscriminated();
      /* return [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: salidaBoth,
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: salidaPax,
        },
        {
          nombre: 'Baseline',
          datos: salidaBase,
        },
      ]; */
      return [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: salidaBothOne.concat(salidaBothTwo),
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: salidaPaxOne.concat(salidaPaxTwo),
        },
        {
          nombre: 'Baseline',
          datos: salidaBaseOne.concat(salidaBaseTwo),
        },
      ];
    } else {
      //////////////////////////////////// OVERNIGHT ////////////////////

      const id1 = params.currentCollection + '_' + params.bus;
      const id2 = params.type + '_' + params.size + 'm_' + params.battery + 'kWh';
      const realqueryhvac: string = params.load;
      const realquerypass: string = 'pass' + params.pax;
      const realquery = {
        ID1: id1,
        ID2: id2,
        HVAC: realqueryhvac,
        PASS: realquerypass
      }
      // console.log('query discriminatedo is: ', realquery);
      const theoutputs = await this.dexieDB.getOutputs(id1 + id2 + realqueryhvac + realquerypass);
      console.log("Outputs dexie  de getOutputs: segundo en discriminated", theoutputs)
      let output: any = [];
      if (theoutputs.length > 0) {
        // console.log('Discriminated already there!');
        output = JSON.parse(theoutputs[0].data);
      } else {
        output = await this.http
          .post(this.mainUrl + 'discriminatedo/', realquery, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addOutputs(id1 + id2 + realqueryhvac + realquerypass, output);
        console.log("El dexie esta vacio, cargando dexie en addOutPuts de getDiscrimitaed")

      }

      const salidaBase2: any = [];
      const salidaPax2: any = [];
      const salidaBoth2: any = [];
      let baselines = output.baseline1;
      let passengers = output.pass1;
      let both = output.both1;
      let lastdistance = 0;
      let lastenergybase = 0;
      let lastenergypass = 0;
      let lastenergyboth = 0;

      for (const lahora of this.hours) {
        const endings = ['_1', '_2'];
        for (const ending of endings) {


          try {

            let finalparte = '';
            if (ending === '_2') {

              finalparte = '_part2';
              baselines = output.baseline2;
              passengers = output.pass2;
              both = output.both2;
            } else {
              finalparte = '_part1';
              baselines = output.baseline1;
              passengers = output.pass1;
              both = output.both1;
            }
            const stridboth = id1 + '_' + lahora + '_' + id2 + '_' + realqueryhvac + '_' + realquerypass + finalparte;
            const stridpass = id1 + '_' + lahora + '_' + id2 + '_hvacOFF_' + realquerypass + finalparte;
            const stridnone = id1 + '_' + lahora + '_' + id2 + '_hvacOFF_passEMPTY' + finalparte;

            /* console.log('stridboth: ', stridboth);
            console.log('stridpass: ', stridpass);
            console.log('stridnone: ', stridnone); */
            const found = baselines.find((current: any) => {
              return current.completedayid === stridnone
            });
            const found2 = passengers.find((current: any) => {
              return current.completedayid === stridpass
            });
            const found3 = both.find((current: any) => {
              return current.completedayid === stridboth
            });
            // console.log('found: ', found);
            if (found !== undefined && this.allCyclesInputs[lahora + ending] !== undefined && found.energyarray.length > 1) {

              // found.energyarray.forEach((current: any, index: number) => {
              this.allCyclesInputs[lahora + ending].distancekm.forEach((current: any, index: number) => {
                // console.log('found.energyarray[index]: ', found.energyarray[index]);
                if (found.energyarray[index] !== undefined && found3 !== undefined && found3.energyarray[index] !== undefined) {
                  salidaBase2.push({
                    // name: this.allCyclesInputs[lahora + ending].distancekm[index] + lastdistance,
                    name: current + lastdistance,
                    value: found.energyarray[index] + lastenergybase,
                  });
                }
              });

            }


            // console.log('found2: ', found2);
            if (found2 !== undefined && this.allCyclesInputs[lahora + ending] !== undefined && found2.energyarray.length > 1) {
              this.allCyclesInputs[lahora + ending].distancekm.forEach((current: any, index: number) => {
                // found2.energyarray.forEach((current: any, index: number) => {
                if (found2.energyarray[index] !== undefined && found3 !== undefined && found3.energyarray[index] !== undefined) {
                  salidaPax2.push({
                    name: current + lastdistance,
                    value: found2.energyarray[index] + lastenergypass,
                  });
                }
              });
            }


            //  console.log('both discriminatedo is: ', both);
            // console.log('found3: ', found3); // */
            if (found3 !== undefined && this.allCyclesInputs[lahora + ending] !== undefined && found3.energyarray.length > 1) {
              // found3.energyarray.forEach((current: any, index: number) => {
              this.allCyclesInputs[lahora + ending].distancekm.forEach((current: any, index: number) => {
                if (found3.energyarray[index] !== undefined) {
                  if (
                    parseFloat(found3.energyarray[index] + lastenergyboth) / 1 >
                    this.state.maxEneDiscriminated
                  ) {
                    // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                    this.state.maxEneDiscriminated =
                      (this.float2int(
                        this.float2int(found3.energyarray[index] + lastenergyboth) / 10
                      ) +
                        1) *
                      10;
                  }
                  if (current + lastdistance > this.state.maxDistDiscriminated) {
                    this.state.maxDistDiscriminated = current + lastdistance;
                  }


                  salidaBoth2.push({
                    name: current + lastdistance,
                    value: found3.energyarray[index] + lastenergyboth,
                  });
                }
              });
              //if (found3.energyarray.length === 1 || found3.energyarray.length === 0)
              /* {
                console.log('found3.energyarray.length : ', found3.energyarray.length);
              } */
            }
            lastdistance = salidaBoth2[salidaBoth2.length - 1].name;
            // console.log('lastdistance: ', lastdistance + ', para hora ', (lahora + ending));
            // console.log('salidaBase2: ', salidaBase2, ' - ', salidaBase2.length);
            lastenergybase = salidaBase2[salidaBase2.length - 1].value;
            lastenergypass = salidaPax2[salidaPax2.length - 1].value;
            lastenergyboth = salidaBoth2[salidaBoth2.length - 1].value;
          } catch (error) {
            console.log('Overnight discriminated data failed: ');
          }
        }
      }


      this.changeAxisDiscriminated();
      /* return [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: salidaBoth,
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: salidaPax,
        },
        {
          nombre: 'Baseline',
          datos: salidaBase,
        },
      ]; */
      return [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: salidaBoth2,
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: salidaPax2,
        },
        {
          nombre: 'Baseline',
          datos: salidaBase2,
        },
      ];
    }
    // }
  };

  public newGetDiscrimitaed = async (params: any) => {
    console.log('Params in NEWDISCRIMI consumption: ', params, this.drivingCycle);
    //this.state.THEDATA[7] = [];
    this.state.maxEneDiscriminated = 0;
    this.state.maxDistDiscriminated = 0;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    // Opportunity
    if (params.type === 'XXXX') {
      /* const finalOutput: any = [];
      for (const h of this.hours) {
        finalOutput.push({
          nombre: h,
          datos: [],
        });
      } */
      // const criticHour = this.hours[this.dailyStatistics.maxconsumCycle - 1];
      const criticHour = this.hours[this.completeDay[0].maxconsumcycle - 1];
      // console.log('critichour: ', criticHour);
      var brt_chart = ''
      switch (this.drivingCycle) {
        case "Observado":
          brt_chart = ''
          break;

        case "BRT":
          brt_chart = 'BRT_'
          break;
      }
      const realqueryid =
        params.currentCollection +
        '_' +
        brt_chart +
        params.bus +
        '_' +
        criticHour +
        '_' +
        params.type +
        '_' +
        params.size +
        'm_' +
        params.battery +
        'kWh';
      const realqueryhvac: string = params.load;
      const realquerypass: string = 'pass' + params.pax;
      const realquery = {
        STRID: realqueryid,
        HVAC: realqueryhvac,
        PASS: realquerypass
      }
      console.log('query: ', realquery);
      let output: any = [];
      const thediscri = await this.dexieDB.getOutputs(realqueryid + realqueryhvac + realquerypass);
      console.log("thediscri dexie  de getOutputs: en newGetDiscrimitaed ", thediscri)
      if (thediscri.length > 0) {
        // console.log('Discriminated values already in cache');
        output = JSON.parse(thediscri[0].data);
      } else {
        output = await this.http
          .post(this.mainUrl + 'oppordiscriminated/', realquery, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addOutputs(realqueryid + realqueryhvac + realquerypass, output);
        console.log("El dexie esta vacio, cargando dexie en addOutPuts de newGetDiscrimitaed", output)


      }
      // console.log('Output querydiscriminated is: ', output);
      let salidaBothOne: any = [];
      let salidaPaxOne: any = [];
      let salidaBaseOne: any = [];
      let salidaBTMS: any = [];
      /* let ultimadistancia = 0;
      let ultimoconsumoB = 0;
      let ultimoconsumoP = 0;
      let ultimoconsumoN = 0; */
      // const salidaBothOne = output.output[0].energyarray.map(
      if (output.output !== undefined && output.output[0].energyarray !== undefined && output.output[0].energyarray.length > 1) {

        this.allCyclesInputs[criticHour].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[0].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[0].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[0].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              if (parseInt(current, 10) / 1 > this.state.maxDistDiscriminated) {
                this.state.maxDistDiscriminated = parseInt(current, 10) / 1;
              }
              salidaBothOne.push({
                // name: this.allCyclesInputs[criticHour + '_1'].distancekm[index],
                name: current,
                value: output.output[0].energyarray[index],
              });
            }
          }
        );
        // console.log('salidaBothOne: ', salidaBothOne);
        //const salidaPaxOne = output.output[1].energyarray.map(
        this.allCyclesInputs[criticHour].distancekm.forEach(
          (current: any, index: number) => {
            if (output.output[1].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[1].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[1].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              salidaPaxOne.push({
                // name: this.allCyclesInputs[criticHour + '_1'].distancekm[index],
                name: current,
                value: output.output[1].energyarray[index],
              });
            }
          }
        );
        // console.log('Output querydiscriminated salidaPax2: ', salidaPaxOne);
        this.allCyclesInputs[criticHour].distancekm.forEach(
          // const salidaBaseOne = output.output[2].energyarray.map(
          (current: any, index: number) => {
            if (output.output[2].energyarray[index] !== undefined) {
              if (
                parseFloat(output.output[2].energyarray[index]) / 1 >
                this.state.maxEneDiscriminated
              ) {
                this.state.maxEneDiscriminated =
                  (this.float2int(
                    this.float2int(output.output[2].energyarray[index]) / 10
                  ) +
                    1) *
                  10;
              }
              /* if (parseInt(current, 10) / 1 > this.state.maxDistDiscriminated) {
                this.state.maxDistDiscriminated = parseInt(current, 10) / 1;
              } */
              salidaBaseOne.push({
                name: current,
                value: output.output[2].energyarray[index],
              });
            }
          }
        );
        /**
        let both = output.both1;
        const id1 = params.currentCollection + '_' + brt_chart + params.bus;
        const id2 = params.type + '_' + params.size + 'm_' + params.battery + 'kWh';
        const stridBTMS = id1 + '_' + lahora + '_' + id2 + '_hvacON_passHIGH';

        const found4 = both.find((current: any) => {
          return current.completedayid === stridBTMS
        });

        if (found4 !== undefined && this.allCyclesInputs[lahora] !== undefined && found4.energyarray.length > 1) {

          // found.energyarray.forEach((current: any, index: number) => {
          this.allCyclesInputs[lahora].distancekm.forEach((current: any, index: number) => {
            // console.log('found.energyarray[index]: ', found.energyarray[index]);
            if (found4.energybtms[index] !== undefined) {
              salidaBTMS.push({
                name: current + ultimo_valor_salidaBTMS_distancekm,
                value: found4.energybtms[index] + ultimo_valor_salidaBTMS,
              });
            }
          });
          ultimo_valor_salidaBTMS_distancekm = salidaBTMS[salidaBTMS.length - 1].name;
          ultimo_valor_salidaBTMS = salidaBTMS[salidaBTMS.length - 1].value;
        }
        */
        // console.log('Output querydiscriminated salidaBase2: ', salidaBaseOne);
        /* ultimadistancia = salidaBothOne[salidaBothOne.length - 1].name;
        // console.log('ultimoconsumoB: ' + ultimoconsumoB);

        ultimoconsumoB = salidaBothOne[salidaBothOne.length - 1].value;
        ultimoconsumoP = salidaPaxOne[salidaPaxOne.length - 1].value;
        ultimoconsumoN = salidaBaseOne[salidaBaseOne.length - 1].value; */
        // console.log('ultimoconsumoB: ' + ultimoconsumoB);
      }



      this.changeAxisDiscriminated();
      //Resta  'Baseline + Pasajeros + HVAC'[salidaBothOne], - 'Baseline + Pasajeros'[salidaPaxOne]
      var resultadosBTMS = []
      for (let i = 0; i < salidaBTMS.length; i++) {
        resultadosBTMS.push({ name: salidaBTMS[i].name, value: salidaBTMS[i].value })

      }
      var resultadoRestaPB = []
      for (let i = 0; i < salidaBothOne.length; i++) {
        resultadoRestaPB.push({ name: salidaBothOne[i].name, value: salidaBothOne[i].value - salidaPaxOne[i].value })
      }
      // Resta  'Baseline + Pasajeros' -  'Baseline'
      var resultadoRestaBPB = []
      for (let i = 0; i < salidaPaxOne.length; i++) {
        resultadoRestaBPB.push({ name: salidaPaxOne[i].name, value: salidaPaxOne[i].value - salidaBaseOne[i].value })
      }
      var resultadoBaseLine = []
      for (let i = 0; i < salidaBaseOne.length; i++) {
        resultadoBaseLine.push({ name: salidaBaseOne[i].name, value: salidaBaseOne[i].value - salidaBTMS[i].value })
      }
      this.state.THEDATA[7] = [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: resultadoRestaPB //.concat(salidaBothTwo),
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: resultadoRestaBPB // .concat(salidaPaxTwo),
        },
        {
          nombre: 'Baseline',
          datos: resultadoBaseLine // .concat(salidaBaseTwo),
        },
        {
          nombre: 'BTMS',
          datos: resultadosBTMS
        }
      ];

      let legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexdiu[this.state.indiceTranslate]//'Distancia (km)';
      let legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeydiu[this.state.indiceTranslate]//'Energía (kWh)';
      if (this.state.currentType === 'Overnight') {
        legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexover[this.state.indiceTranslate]//'Distancia por día (km)';
        legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeyover[this.state.indiceTranslate]//'Energía por día (kWh)';
      }
      this.state.axis2[1] = {
        labelFormat: '{value}',
        title: legendaY,
        titleStyle: {
          fontFamily: 'Poppins',
          size: '0.8vw'
        },
        labelStyle: {
          fontFamily: 'Poppins',
          size: '1.1vw'
        },
        minimum: 0,
        rangePadding: 'Auto',
        maximum: salidaBaseOne[salidaBaseOne.length - 1].value + 100,
        /* interval: myIntervalo, */
      };
      return 0;
    } else {
      //////////////////////////////////// OVERNIGHT ////////////////////
      var brt_chart = ''
      switch (this.drivingCycle) {
        case "Observado":
          brt_chart = ''
          break;

        case "BRT":
          brt_chart = 'BRT_'
          break;
      }
      const id1 = params.currentCollection + '_' + brt_chart + params.bus;
      const id2 = params.type + '_' + params.size + 'm_' + params.battery + 'kWh';
      const realqueryhvac: string = params.load;
      const realquerypass: string = 'pass' + params.pax;

      const realquery = {
        ID1: id1,
        ID2: id2,
        HVAC: realqueryhvac,
        PASS: realquerypass
      }
      console.log('query discriminatedo is: ', realquery);
      const theoutputs = await this.dexieDB.getOutputs(id1 + id2 + realqueryhvac + realquerypass);
      console.log("theoutputs dexie  de getOutputs: newGetDiscrimitaed", theoutputs)
      let output: any = [];
      if (theoutputs.length > 0) {
        // console.log('Discriminated already there!');
        output = JSON.parse(theoutputs[0].data);
      } else {
        output = await this.http
          .post(this.mainUrl + 'overdiscriminated/', realquery, {
            responseType: 'json',
            headers,
          })
          .toPromise();
        //this.dexieDB.addOutputs(id1 + id2 + realqueryhvac + realquerypass, output);
        console.log("El dexie esta vacio, cargando dexie en addOutputs de newGetDiscrimitaed", output)

      }
      console.log('Respuesta de discriminatedoqueryv2', output);
      console.log('Query realizada:', realquery);
      const salidaBase2: any = [];
      const salidaPax2: any = [];
      const salidaBoth2: any = [];
      const salidaBTMS: any = [];
      let baselines = output.baseline1;
      let passengers = output.pass1;
      let both = output.both1;
      /* let lastdistance = 0;
      let lastenergybase = 0;
      let lastenergypass = 0;
      let lastenergyboth = 0; */
      let ultimo_valor_salidaBoth2 = 0;
      let ultimo_valor_salidaPax2 = 0;
      let ultimo_valor_salidaBase2 = 0;
      let ultimo_valor_salidaBTMS = 0;

      let ultimo_valor_salidaBoth2_distancekm = 0;
      let ultimo_valor_salidaPax2_distancekm = 0;
      let ultimo_valor_salidaBase2_distancekm = 0;
      let ultimo_valor_salidaBTMS_distancekm = 0;
      console.log("newDiscriminated 1")
      for (const lahora of this.hours) {
        // const endings = ['_1', '_2'];
        // for (const ending of endings) {


        try {

          // let finalparte = '';

          const stridboth = id1 + '_' + lahora + '_' + id2 + '_' + realqueryhvac + '_' + realquerypass;
          const stridpass = id1 + '_' + lahora + '_' + id2 + '_hvacOFF_' + realquerypass;
          const stridnone = id1 + '_' + lahora + '_' + id2 + '_hvacOFF_passEMPTY';
          const stridBTMS = id1 + '_' + lahora + '_' + id2 + '_hvacON_passHIGH';

          /* console.log('stridboth: ', stridboth);
          console.log('stridpass: ', stridpass);
          console.log('stridnone: ', stridnone); */
          const found = baselines.find((current: any) => {
            return current.completedayid === stridnone
          });
          const found2 = passengers.find((current: any) => {
            return current.completedayid === stridpass
          });
          const found3 = both.find((current: any) => {
            return current.completedayid === stridboth
          });
          const found4 = both.find((current: any) => {
            return current.completedayid === stridBTMS
          });

          if (found4 !== undefined && this.allCyclesInputs[lahora] !== undefined && found4.energyarray.length > 1) {

            // found.energyarray.forEach((current: any, index: number) => {
            this.allCyclesInputs[lahora].distancekm.forEach((current: any, index: number) => {
              // console.log('found.energyarray[index]: ', found.energyarray[index]);
              if (found4.energybtms[index] !== undefined) {
                salidaBTMS.push({
                  name: current + ultimo_valor_salidaBTMS_distancekm,
                  value: found4.energybtms[index] + ultimo_valor_salidaBTMS,
                });
              }
            });
            ultimo_valor_salidaBTMS_distancekm = salidaBTMS[salidaBTMS.length - 1].name;
            ultimo_valor_salidaBTMS = salidaBTMS[salidaBTMS.length - 1].value;
          }

          // console.log('found: ', found);
          if (found !== undefined && this.allCyclesInputs[lahora] !== undefined && found.energyarray.length > 1) {

            // found.energyarray.forEach((current: any, index: number) => {
            this.allCyclesInputs[lahora].distancekm.forEach((current: any, index: number) => {
              // console.log('found.energyarray[index]: ', found.energyarray[index]);
              if (found.energyarray[index] !== undefined && found3 !== undefined && found3.energyarray[index] !== undefined) {
                salidaBase2.push({
                  // name: this.allCyclesInputs[lahora + ending].distancekm[index] + lastdistance,
                  name: current + ultimo_valor_salidaBase2_distancekm,
                  value: found.energyarray[index] + ultimo_valor_salidaBase2,
                });
              }
            });
            ultimo_valor_salidaBase2_distancekm = salidaBase2[salidaBase2.length - 1].name;
            ultimo_valor_salidaBase2 = salidaBase2[salidaBase2.length - 1].value;
          }


          // console.log('found2: ', found2);
          if (found2 !== undefined && this.allCyclesInputs[lahora] !== undefined && found2.energyarray.length > 1) {
            this.allCyclesInputs[lahora].distancekm.forEach((current: any, index: number) => {
              // found2.energyarray.forEach((current: any, index: number) => {
              if (found2.energyarray[index] !== undefined && found3 !== undefined && found3.energyarray[index] !== undefined) {
                salidaPax2.push({
                  name: current + ultimo_valor_salidaPax2_distancekm,
                  value: found2.energyarray[index] + ultimo_valor_salidaPax2,
                });
              }
            });
            ultimo_valor_salidaPax2_distancekm = salidaPax2[salidaPax2.length - 1].name;
            ultimo_valor_salidaPax2 = salidaPax2[salidaPax2.length - 1].value;
          }


          //  console.log('both discriminatedo is: ', both);
          // console.log('found3: ', found3); // */
          if (found3 !== undefined && this.allCyclesInputs[lahora] !== undefined && found3.energyarray.length > 1) {
            // found3.energyarray.forEach((current: any, index: number) => {
            this.allCyclesInputs[lahora].distancekm.forEach((current: any, index: number) => {
              if (found3.energyarray[index] !== undefined) {
                if (
                  parseFloat(found3.energyarray[index]) / 1 >
                  this.state.maxEneDiscriminated
                ) {
                  // this.state.maxEneConsumption = parseFloat(row[7]) / 1000;
                  this.state.maxEneDiscriminated =
                    (this.float2int(
                      this.float2int(found3.energyarray[index]) / 10
                    ) +
                      1) *
                    10;
                }
                if (current > this.state.maxDistDiscriminated) {
                  this.state.maxDistDiscriminated = current;
                }


                salidaBoth2.push({
                  name: current + ultimo_valor_salidaBoth2_distancekm,
                  value: found3.energyarray[index] + ultimo_valor_salidaBoth2,
                });
              }
            });
            ultimo_valor_salidaBoth2_distancekm = salidaBoth2[salidaBoth2.length - 1].name;
            ultimo_valor_salidaBoth2 = salidaBoth2[salidaBoth2.length - 1].value;
            //if (found3.energyarray.length === 1 || found3.energyarray.length === 0)
            /* {
              console.log('found3.energyarray.length : ', found3.energyarray.length);
            } */
          }
          /* lastdistance = salidaBoth2[salidaBoth2.length - 1].name;
          // console.log('lastdistance: ', lastdistance + ', para hora ', (lahora + ending));
          // console.log('salidaBase2: ', salidaBase2, ' - ', salidaBase2.length);
          lastenergybase = salidaBase2[salidaBase2.length - 1].value;
          lastenergypass = salidaPax2[salidaPax2.length - 1].value;
          lastenergyboth = salidaBoth2[salidaBoth2.length - 1].value; */
        } catch (error) {
          console.log('Overnight discriminated data failed: ');
        }
        // }
      }

      console.log("newDiscriminated 2")
      this.changeAxisDiscriminated();
      //Resta  'Baseline + Pasajeros + HVAC'[salidaBothOne], - 'Baseline + Pasajeros'[salidaPaxOne]

      var resultadosBTMS = []
      for (let i = 0; i < salidaBTMS.length; i++) {
        resultadosBTMS.push({ name: salidaBTMS[i].name, value: salidaBTMS[i].value })

      }
      var resultadoRestaPB = []
      for (let i = 0; i < salidaBoth2.length; i++) {
        resultadoRestaPB.push({ name: salidaBoth2[i].name, value: salidaBoth2[i].value - salidaPax2[i].value })
      }
      // Resta  'Baseline + Pasajeros' -  'Baseline'
      var resultadoRestaBPB = []
      for (let i = 0; i < salidaPax2.length; i++) {
        resultadoRestaBPB.push({ name: salidaPax2[i].name, value: salidaPax2[i].value - salidaBase2[i].value })
      }
      var resultadoBaseLine = []
      for (let i = 0; i < salidaBase2.length; i++) {
        resultadoBaseLine.push({ name: salidaBase2[i].name, value: salidaBase2[i].value - salidaBTMS[i].value })
      }
      this.state.THEDATA[7] = [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: resultadoRestaPB //.concat(salidaBothTwo),
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: resultadoRestaBPB // .concat(salidaPaxTwo),
        },
        {
          nombre: 'Baseline',
          datos: resultadoBaseLine // .concat(salidaBaseTwo),
        },
        {
          nombre: 'BTMS',
          datos: resultadosBTMS
        }
      ];
      let legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexdiu[this.state.indiceTranslate]//'Distancia (km)';
      let legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeydiu[this.state.indiceTranslate]//'Energía (kWh)';
      if (this.state.currentType === 'Overnight') {
        legendaX = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejexover[this.state.indiceTranslate]//'Distancia por día (km)';
        legendaY = this.state.dictTranslate.DashboardsGraph.rendimiento.graficocd.ejeyover[this.state.indiceTranslate]//'Energía por día (kWh)';
      }
      this.state.axis2[1] = {
        labelFormat: '{value}',
        title: legendaY,
        titleStyle: {
          fontFamily: 'Poppins',
          size: '0.8vw'
        },
        labelStyle: {
          fontFamily: 'Poppins',
          size: '1.1vw'
        },
        minimum: 0,
        rangePadding: 'Auto',
        maximum: salidaBase2[salidaBase2.length - 1].value + 100,
        /* interval: myIntervalo, */
      };
      /*this.state.THEDATA[7] = [
        {
          nombre: 'Baseline + Pasajeros + HVAC',
          datos: salidaBoth2,
        },
        {
          nombre: 'Baseline + Pasajeros',
          datos: salidaPax2,
        },
        {
          nombre: 'Baseline',
          datos: salidaBase2,
        },
      ];**/
      //console.log("THEDATA[7] console", this.state.THEDATA[7])
      return 0;
    }
    // }
  };

  public getStatsStacked = async (params: any) => {
    // let var1: any;:
    this.state.THEDATA[5] = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });

    const salida2: any = [];
    const realqueryhvac: string = params.load;
    const realquerypass: string = params.pax;
    const realquery = {
      COLLECTION: params.currentCollection,
      STRID: params.type + '_' + params.size + 'm_' + params.battery + 'kWh',
      HVAC: realqueryhvac,
      PASS: 'pass' + realquerypass
    }
    // console.log('query getStatsStacked is: ', realquery);
    const output: any = await this.http
      .post(this.mainUrl + 'querystacked/', realquery, {
        responseType: 'json',
        headers,
      })
      .toPromise();
    // console.log('THe output getStatsStacked: ', output);

    for (let jota = 0; jota < this.state.busNames.length; jota++) {
      const laruta = this.state.busNames[jota];
      if (params.type === 'Overnight') {

        //const endings = ['_1', '_2'];

        /* const found = output.both1.find((current: any) => {
          const inicio = current.completedayid.split('_');
          return inicio[0] === params.currentCollection && inicio[1] === laruta
        }); */

        let strid1 = '';
        let strid2 = '';
        if (this.allRouteProperties[laruta].cycleshours[0].length === 3) {
          strid1 = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass + '_part1';
          strid2 = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass + '_part2';

        } else {
          strid1 = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass + '_part1';
          strid2 = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass + '_part2';

        }

        const found2 = output.both1.find((current: any) => {
          return current.completedayid === strid1;
        });
        /* const foundsecond = output.both2.find((current: any) => {
          return current.completedayid === strid2;
        }); */
        // console.log('found2 over: ', found2);
        if (found2 !== undefined) {
          salida2.push({
            ruta: laruta,
            value1: (found2.baselineday | 0),
            value2: (found2.impactopassday | 0),
            value3: (found2.impactohvacday | 0),
          });
        }

      } else {
        //// OPPORTUNITY /////////////////////////////
        const found = output.both1.find((current: any) => {
          const inicio = current.completedayid.split('_');
          return inicio[0] === params.currentCollection && inicio[1] === laruta
        });
        if (found !== undefined) {
          // console.log('maxcycle of route ', laruta + ' es ' + found.maxconsumcycle);
          let maxCs: number = found.maxconsumcycle - 1;
          let strid = '';
          if (this.allRouteProperties[laruta].cycleshours[maxCs].length === 3) {
            strid = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[maxCs] +
              '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

          } else {
            strid = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[maxCs] +
              '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

          }
          // console.log('strid: ', strid);
          let found2: any = {};
          try {

            found2 = output.both1.find((current: any) => {
              return current.completedayid === strid + '_part1';
            });
          } catch (error) {
            console.log('Error haciendo found2: ', error);
          }

          /* let foundSecond: any = {};
          try {
            foundSecond = output.both2.find((current: any) => {
              return current.completedayid === strid + '_part2';
            });
          } catch (error) {
            console.log('Error haciendo foundsecond: ', error);
          } */

          // console.log('found2 oppor: ', found2);
          try {
            if (maxCs !== undefined) {
              salida2.push({
                ruta: laruta,
                value1: (found2.baselinecycle | 0),
                value2: (found2.impactopasscycle | 0),
                value3: (found2.impactohvaccycle | 0),
              });
            }
          } catch (error) {
            console.log('Error haciendo push: ', error);
          }

        }
      }
    }

    this.state.THEDATA[5] = salida2;
    return;
    //return salida2;
  };

  public newGetStatsStacked = async (params: any) => {
    // let var1: any;:
    //const realqueryhvac: string = params.load;
    //const realquerypass: string = params.pax;
    //const realquery = {
    //  COLLECTION: params.currentCollection,
    //  STRID: params.type + '_' + params.size + 'm_' + params.battery + 'kWh',
    //  HVAC: realqueryhvac,
    //  PASS: 'pass' + realquerypass
    //}
    //limagrupopolo_Opportunity_12m_55kWh_hvacOFF_passEMPTY
    // BRT TRABAJAR
    let brt_chart = '';
    switch (this.drivingCycle) {
      case "Observado":
        brt_chart = ''
        break;
      case "BRT":
        brt_chart = 'BRT_'
        break;
    }
    const query = params.currentCollection + '_' + brt_chart + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + params.load + '_pass' + params.pax
    console.log("ESTE ES EL QUERY", query)
    const ee = { STRID: query };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    console.log("Realizando la peticion collections stadistics")
    const result = await this.http
      .post(this.mainUrl + 'querycollectionstatistics', ee, {
        responseType: 'json',
        headers,
      }).toPromise();
    console.log("newchart 1 result", result)
    var salidacollection: any = [];
    for (let j = 0; j < result[0]["routes"].length; j++) {

      salidacollection.push({
        ruta: result[0]["routes"][j],
        baselineconsumption: (result[0]["baselineconsumption"][j]),
        hvacconsumption: (result[0]["hvacconsumption"][j]),
        passconsumption: (result[0]["passconsumption"][j]),
        impactbtms: (result[0]["impactbtms"][j]),
      });
    }
    this.state.CollectionStatistics = salidacollection;
    console.log("nueva estructura listacollection2", this.state.CollectionStatistics)
    //    * 5 -> Statistics critical consumption
    this.state.THEDATA[5] = [];
    //const headers = new HttpHeaders({
    //  'Content-Type': 'application/json; charset=utf-8',
    //  Authorization: 'Token ' + this.auth.getToken(),
    //});

    const salida2: any = [];
    const realqueryhvac: string = params.load;
    const realquerypass: string = params.pax;
    brt_chart = ''
    switch (this.drivingCycle) {
      case "Observado":
        brt_chart = ''
        break;

      case "BRT":
        brt_chart = '_BRT'
        break;
    }
    const realquery = {
      COLLECTION: params.currentCollection + brt_chart,
      STRID: params.type + '_' + params.size + 'm_' + params.battery + 'kWh',
      HVAC: realqueryhvac,
      PASS: 'pass' + realquerypass
    }
    // console.log('query getStatsStacked is: ', realquery);
    const output: any = await this.http
      .post(this.mainUrl + 'stackedqueryv2/', realquery, {
        responseType: 'json',
        headers,
      })
      .toPromise();
    console.log('=======The output NewgetStatsStacked: =========', output);
    var lista_final_ciclos_mayores;
    console.log("Esta el la lista de Buses", this.state.busNames)
    for (let jota = 0; jota < this.state.busNames.length; jota++) {
      //console.log("Este es el jota", jota);
      const laruta = this.state.busNames[jota];
      //console.log("Esta son los buses", this.state.busNames[jota]);
      //console.log("Esta son los buses", this.state.busNames[0]);
      //console.log("Esta son los buses", this.state.busNames[1]);
      //console.log("Esta son los buses", this.state.busNames[2]);
      //console.log("Esta son los buses", this.state.busNames[3]);
      if (params.type === 'Overnight') {
        let strid1 = '';
        let strid2 = '';
        if (this.allRouteProperties[laruta].cycleshours[0].length === 3) {
          strid1 = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;
          strid2 = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

        } else {
          strid1 = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;
          strid2 = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[0] +
            '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

        }

        const found2 = output.both1.find((current: any) => {
          return current.completedayid === strid1;
        });
        /* const foundsecond = output.both2.find((current: any) => {
          return current.completedayid === strid2;
        }); */
        // console.log('found2 over: ', found2);
        if (found2 !== undefined) {
          salida2.push({
            ruta: laruta,
            value1: (found2.baselineday | 0),
            value2: (found2.impactopassday | 0),
            value3: (found2.impactohvacday | 0),
          });
        }

      } else {
        //// OPPORTUNITY /////////////////////////////
        //thisSer.completeDay.forEach(
        var highvaluecycle = 0;
        var indexhighvalue = 0;
        const lista_rutas_rutas = ["Marina201", "Ceres201", "Art201", "107"]
        const lstaux = [];
        const lst = []
        var lstconsume = []
        var lstindex = []
        var energia_acumulada = 0;
        var ciclo_mayor;

        console.log("output", output)
        // console.log("AABB LA RUTA ES :..", laruta)
        var contador = 0;
        output.both1.forEach(
          (e, index) => {
            //console.log("por cada foreach  AABB", e)            
            var rst = e.completedayid.includes(laruta)
            if (rst) {

              //console.log("por cada foreach  AABB", e)
              //console.log("AABB =========")
              var aux = e.baselinecycle + e.impactohvaccycle + e.impactopasscycle;
              if (aux > energia_acumulada) {
                //console.log("Elemento a buscar AABB", laruta)
                //console.log("Calculando datos...AABB", energy_comsume_cycle, index)
                //console.log("AABB Actualizando valor")
                energia_acumulada = aux;
                lista_final_ciclos_mayores = e;
              }
            }
          },

        );
        //console.log("Lista final de cyclos altos e indices AABB", lista_final_ciclos_mayores)
        //console.log("highvaluecylce.....", highvaluecycle)
        //console.log("indexhighvalue.....", indexhighvalue)
        var current_aux;
        var max_consume_cycle;
        const arrciclofinal = lista_final_ciclos_mayores.completedayid.split('_')
        //console.log("Estos son los arrciclofinal", arrciclofinal)

        var hora_del_ciclo = arrciclofinal[2];

        if (hora_del_ciclo[0] == '0') {
          console.log("Primera letra de hora del ciclo", hora_del_ciclo[0])
          hora_del_ciclo = hora_del_ciclo.substring(1);
        }
        //console.log("Hora del ciclo CCDD", hora_del_ciclo)
        this.allRouteProperties[laruta].cycleshours.forEach((element, index) => {
          //console.log("Elemento de All route properties CCDD", element)
          if (element == hora_del_ciclo) {
            //console.log("Este en el index de la lista  de All route properties ", index)
            max_consume_cycle = index
          }
        });
        console.log("Este es el max consume cycle ", max_consume_cycle)
        const found = output.both1.find((current: any) => {
          //console.log(" Sacando ruta", current)
          const inicio = current.completedayid.split('_')
          return inicio[0] === params.currentCollection && inicio[1] === laruta
        });
        console.log(" current", current_aux)
        console.log("found", found)
        if (found !== undefined) {

          // console.log('maxcycle of route ', laruta + ' es ' + found.maxconsumcycle);

          //let maxCs: number = found.maxconsumcycle - 1;
          let maxCs: number = max_consume_cycle;
          console.log("El maxCS es..", maxCs)
          let strid = '';
          //console.log("Comprobando maxCS", this.allRouteProperties[laruta])
          //console.log("Comprobando maxCS", this.allRouteProperties[laruta].cycleshours[maxCs])
          //console.log("Comprobando maxCS", this.allRouteProperties[laruta].cycleshours[maxCs].length)

          if (this.allRouteProperties[laruta].cycleshours[maxCs].length == 3) {
            strid = params.currentCollection + '_' + laruta + '_0' + this.allRouteProperties[laruta].cycleshours[maxCs] +
              '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

          } else {
            strid = params.currentCollection + '_' + laruta + '_' + this.allRouteProperties[laruta].cycleshours[maxCs] +
              '_' + params.type + '_' + params.size + 'm_' + params.battery + 'kWh' + '_' + realqueryhvac + '_pass' + realquerypass;

          }
          console.log('strid: ', strid);
          let found2: any = {};
          try {

            found2 = output.both1.find((current: any) => {
              return current.completedayid === strid;
            });
          } catch (error) {
            console.log('Error haciendo found2: ', error);
          }

          // console.log('found2 oppor: ', found2);
          try {
            if (maxCs !== undefined) {
              salida2.push({
                ruta: laruta,
                value1: (found2.baselinecycle | 0),
                value2: (found2.impactopasscycle | 0),
                value3: (found2.impactohvaccycle | 0),
              });
            }
          } catch (error) {
            console.log('Error haciendo push: ', error);
          }

        }
      }
      //console.log("Aqui termina el jota..", jota)
    }
    console.log("salida2", salida2)
    this.state.THEDATA[5] = salida2;
    return;
    //return salida2;
  };

  public getChargerData = async (params: any) => {
    console.log('Llamando getChargerData');
    //console.log('getChargerData params ', params); */
    //// let var1: any;:
    //")

    //Inicialización necesaria para que funcione la función pointClick() de las gráficas syncfusion
    this.state.chartMapLatitude[1] = [];
    this.state.chartMapLongitude[1] = [];
    this.state.chartMapLatitude[0] = [];
    this.state.chartMapLongitude[0] = [];

    console.log('loscharges son: ', this.state.currentChargersNames);
    const salida2: any = [];
    var brt_chart = ''
    switch (this.drivingCycle) {
      case 'Observado':
        brt_chart = ''
        break;

      case 'BRT':
        brt_chart = 'BRT_'
        break;
    }
    const inicio = params.currentCollection + '_' + brt_chart + params.bus;
    const fin = params.type + '_' + params.size + 'm_' + params.battery + 'kWh_' + params.load + '_pass' + params.pax;
    console.log("this.allChargersData[inicio + fin] : ", this.allChargersData[inicio + fin])
    const output = this.allChargersData[inicio + fin];
    console.log('El output:', output);
    // console.log('getChargerData output: ', output.chargersdata);
    this.state.currentChargersNames.map((c: any, i: number) => {
      let query1 = inicio + '_' + c + '_' + fin;
      // console.log('Buscando dato para cargador ', c);
      const found1 = output.chargersdata.find((current: any) => {
        return current.chargersid === query1;
      });
      //console.log('Este es el found1:', found1);
      if (!found1) {
        // console.log('No se encontró impacto de ruta en cargador ', c);
        ;
      } else {
        // this.chargermedium[c] = found1.chargersmedium[i];
        // console.log('El impacto de ruta en cargador ', c, ' es ', found1);
        let elindex = -1;
        //Aqui esta la modificacion
        //

        this.chargersimpactenergy[c] = []
        this.allChargersBus[c] = []
        this.chargersimpactpower[c].forEach((c: any, index: number) => {
          //params.bus es la ruta
          if (c.nombre === params.bus) {
            elindex = index;
          }
        });
        //this.allChargersBus[c].forEach((c: any, index: number) => {
        //  //params.bus es la ruta
        //  if (c.nombre === params.bus) {
        //    elindex = index;
        //  }
        //});
        //console.log("this.allChargersBus 1: ", this.allChargersBus)
        if (elindex > -1) {
          this.chargersimpactpower[c].splice(elindex, 1);
          this.allChargersBus[c].splice(elindex, 1);
          this.howmanyroutesincharger[c] = this.howmanyroutesincharger[c] - 1;
        }
        elindex = -1;
        this.chargersimpactenergy[c].forEach((c: any, index: number) => {
          if (c.nombre === params.bus) {
            elindex = index;
          }
        });
        if (elindex > -1) {
          this.chargersimpactenergy[c].splice(elindex, 1);
        }
        //console.log("this.allChargersBus 2: ", this.allChargersBus)
        const siesta = this.chargersimpactpower[c].find((cur: any) => {
          return cur.nombre === params.bus;
        })

        if (siesta === undefined) {

          const datospower = [];
          const datosenergy = [];
          const datosperhour = [];
          const datosbusescargadores = [];
          //const start_timestamp = new Date(Date.parse(found1.timestamparray[0]));
          const start_timestamp = new Date();
          start_timestamp.setHours(0, 0, 0)
          let segundo_inicial = 0;
          let segundos_acum = 0;
          let minuto_inicial = 0;
          let minutos_acum = 0;
          let hora_inicial = 0;
          let horas_acum = 0;

          let dias_acum = 0;
          let eldia_inicialcrudo = found1.timestamparray[0]
          const cadenadiainicial = eldia_inicialcrudo.substring(0, 10);
          const eldia_inicialentero = cadenadiainicial.charAt(8) + cadenadiainicial.charAt(9)
          let eldia_inicial = parseInt(eldia_inicialentero, 10);

          //console.log("found1 : ",found1)
          found1.timestamparray.forEach((current: string, index: number) => {
            const lanuevacadena = current.substring(current.length - 8);
            const cadenafecha = current.substring(0, 10);
            //console.log("cadenafecha : ",cadenafecha)
            const eldia = cadenafecha.charAt(8) + cadenafecha.charAt(9)
            let eldia_int = parseInt(eldia, 10);
            const diferencia_dia = (eldia_int - eldia_inicial);

            //console.log("eldia :",eldia)
            const lahoritaes = lanuevacadena.charAt(0) + lanuevacadena.charAt(1);
            const losminutos = lanuevacadena.charAt(3) + lanuevacadena.charAt(4);
            const losegundos = lanuevacadena.charAt(6) + lanuevacadena.charAt(7);

            let lossegundos_int = parseInt(losegundos, 10);
            let diferencia_segundos = lossegundos_int - segundo_inicial;

            let losminutos_int = parseInt(losminutos, 10);
            let diferencia_minutos = losminutos_int - minuto_inicial;

            let lahora_int = parseInt(lahoritaes, 10);
            let diferencia_horas = lahora_int - hora_inicial;

            //La lógica que debo hacer es la de restar los segundos, y en caso de menor que 0, sumarle 60 y esos serían los segunddos transcurridos. Ejm: 2-57 = -55, pero +60 = +5
            segundo_inicial = lossegundos_int;
            segundos_acum = segundos_acum + diferencia_segundos;
            minuto_inicial = losminutos_int;
            minutos_acum = minutos_acum + diferencia_minutos;
            hora_inicial = lahora_int;
            horas_acum = horas_acum + diferencia_horas;
            eldia_inicial = eldia_int;
            dias_acum = dias_acum + diferencia_dia;

            let total_acumulado = segundos_acum + minutos_acum * 60 + horas_acum * 3600 + dias_acum * 24 * 3600;
            //console.log("getchargerdata time total_acumulado: ",total_acumulado)
            //let fyh = new Date(start_timestamp.getTime() + (segundos_acum * 1000));
            let fyh = new Date(start_timestamp.getTime() + (total_acumulado * 1000));
            //console.log("getchargerdata time fyh : ",fyh)

            //console.log("Esto es el fyh : ", fyh)
            datospower.push({
              name: fyh,
              value: found1.chargingpowerarray[index]
            });
            datosenergy.push({
              name: fyh,
              value: found1.charginggridenergyarray[index]
            });
            datosbusescargadores.push({
              name: fyh,
              value: found1.numberofbusescharging[index]
            });
          });



          this.chargersimpactpower[c].unshift(
            {
              nombre: params.bus,
              datos: datospower
            });

          this.chargersimpactenergy[c].unshift(
            {
              nombre: params.bus,
              datos: datosenergy
            }
          );
          this.allChargersBus[c].unshift(
            {
              nombre: params.bus,
              datos: datosbusescargadores
            }
          );
          //if (found1.chargingroute[0] !== '') {
          this.howmanyroutesincharger[c] = this.howmanyroutesincharger[c] + 1;
          // }

          found1.charginggridenergyperhourofday.forEach((cur: any, index: number) => {
            datosperhour.push({
              name: index + 1,
              value: cur
            });
            //this.chargersimpactperhour[index][c] = parseInt((this.chargersimpactperhour[index][c] + cur).toFixed(2));
            this.chargersimpactperhour[index][c] = 0;
            this.chargersimpactperhour[index][c] = parseInt((cur).toFixed(2));
          });

        }
      }
    });

    //Debo insertar el for de jota en esta parte
    const newdataBusManyBuses: any = {};
    const newdataFinalLifeManyBuses: any = {};
    const busesid = this.allRouteProperties[params.bus].busesid;
    const cyclesbusid = this.allRouteProperties[params.bus].cyclesbusid;
    for (const busnumber of busesid) {
      newdataBusManyBuses[busnumber] = [];
      newdataFinalLifeManyBuses[busnumber] = [];
    }



    for (const busnumber of busesid) {
      let result_distance: any[] = [];
      let result_distance_eol: any[] = [];
      let result_energy: any[] = [];
      let result_energy_eol: any[] = [];
      let ultimo_valor = 0;
      let ultimo_valor_eol = 0;
      let lonarray: any = [];
      let latarray: any = [];
      const losindicesbus = this.getAllIndexes(cyclesbusid, busnumber);

      for (const jota of losindicesbus) {

        // for (let jota = 0; jota < this.hours.length; jota++) {
        const lashoras = this.hours[jota];
        let theindex = jota;
        try {
          //Partimos por indices cada arreglo
          //Tamaño de la partes
          var tamano_de_partes = this.allCyclesInputs[lashoras].indexeschargingevents.length - 1;
          //Vector de tiempo de todo el día
          for (let i = 0; i < tamano_de_partes; i++) {
            //declaración
            let charging_time: any;
            let latitudeDay: number[] = [];
            let longitudeDay: number[] = [];

            if (i > 0) {
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i], this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i], this.completeDay[theindex].indexestimecharging[i + 1]);

            }
            else {
              latitudeDay = this.allCyclesInputs[lashoras].latitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              longitudeDay = this.allCyclesInputs[lashoras].longitude.slice(this.allCyclesInputs[lashoras].indexeschargingevents[i] - 1, this.allCyclesInputs[lashoras].indexeschargingevents[i + 1]);
              charging_time = this.completeDay[theindex].chargingtimearray.slice(this.completeDay[theindex].indexestimecharging[i] - 1, this.completeDay[theindex].indexestimecharging[i + 1]);

            }

            latarray = latarray.concat(latitudeDay);
            lonarray = lonarray.concat(longitudeDay);
            let charging_latitude = Array(charging_time.length).fill(latitudeDay[latitudeDay.length - 1]);
            let charging_longitude = Array(charging_time.length).fill(longitudeDay[longitudeDay.length - 1]);

            latarray = latarray.concat(charging_latitude);
            lonarray = lonarray.concat(charging_longitude);

          }

        } catch (error) {
          console.log('Available: ', error);
        }
      }
      //Armamos el array de latitudes y longitudes
      this.state.chartMapLongitude[0].push(lonarray);
      this.state.chartMapLatitude[0].push(latarray);
      console.log('this.state.chartMapLatitude[0]:', this.state.chartMapLongitude[0]);

    }

    // console.log('this.chargersimpactperhour: ', this.chargersimpactperhour);
    //this.buildAccumData(this.chargersimpactenergy);

    const salida: any = [];
    //const start_timestamp = new Date(Date.parse(output.chargersdata[0]["acumtimestamparray"][0]));
    const start_timestamp = new Date()
    start_timestamp.setHours(0, 0, 0)
    let segundo_inicial = 0;
    let segundos_acum = 0;
    let minuto_inicial = 0;
    let minutos_acum = 0;
    let hora_inicial = 0;
    let horas_acum = 0;

    let dias_acum = 0;
    let eldia_inicialcrudo = output.chargersdata[0]["acumtimestamparray"][0]
    const cadenadiainicial = eldia_inicialcrudo.substring(0, 10);
    const eldia_inicialentero = cadenadiainicial.charAt(8) + cadenadiainicial.charAt(9)
    let eldia_inicial = parseInt(eldia_inicialentero, 10);


    //console.log("found1 : ",found1)
    output.chargersdata[0]["acumtimestamparray"].forEach((current: string, index: number) => {
      const lanuevacadena = current.substring(current.length - 8);
      const cadenafecha = current.substring(0, 10);
      //console.log("cadenafecha : ",cadenafecha)
      const eldia = cadenafecha.charAt(8) + cadenafecha.charAt(9)
      let eldia_int = parseInt(eldia, 10);
      const diferencia_dia = (eldia_int - eldia_inicial);

      //console.log("eldia :",eldia)
      const lahoritaes = lanuevacadena.charAt(0) + lanuevacadena.charAt(1);
      const losminutos = lanuevacadena.charAt(3) + lanuevacadena.charAt(4);
      const losegundos = lanuevacadena.charAt(6) + lanuevacadena.charAt(7);

      let lossegundos_int = parseInt(losegundos, 10);
      let diferencia_segundos = lossegundos_int - segundo_inicial;

      let losminutos_int = parseInt(losminutos, 10);
      let diferencia_minutos = losminutos_int - minuto_inicial;

      let lahora_int = parseInt(lahoritaes, 10);
      let diferencia_horas = lahora_int - hora_inicial;

      //La lógica que debo hacer es la de restar los segundos, y en caso de menor que 0, sumarle 60 y esos serían los segunddos transcurridos. Ejm: 2-57 = -55, pero +60 = +5
      segundo_inicial = lossegundos_int;
      segundos_acum = segundos_acum + diferencia_segundos;
      minuto_inicial = losminutos_int;
      minutos_acum = minutos_acum + diferencia_minutos;
      hora_inicial = lahora_int;
      horas_acum = horas_acum + diferencia_horas;
      eldia_inicial = eldia_int;
      dias_acum = dias_acum + diferencia_dia;

      let total_acumulado = segundos_acum + minutos_acum * 60 + horas_acum * 3600 + dias_acum * 24 * 3600;
      //console.log("getchargerdata time total_acumulado: ",total_acumulado)
      //let fyh = new Date(start_timestamp.getTime() + (segundos_acum * 1000));
      let fyh = new Date(start_timestamp.getTime() + (total_acumulado * 1000));
      //console.log("fyh accum :", fyh)
      salida.push({
        name: fyh,
        value: output.chargersdata[0]["charginggridaccumenergyarray"][index]
      });

    });

    this.allChargersAcum = salida


    //
    console.log("revision datos getChargerData", this.state.dataChargerEnergia, this.state.dataChargerPotencia, this.state.dataChargersInfo)
    this.state.THEDATA[9] = [this.chargersimpactpower, this.chargersimpactenergy, this.alldatosaccum, this.allChargersAcum, this.chargersimpactperhour, this.allChargersBus, this.chargersagregatepower];
    //console.log("this.chargersimpactperhour ",this.chargersimpactperhour)

    //console.log('this.chargersimpactpower:', this.chargersimpactpower);
    // console.log('terminó de pedir chargersdata');
    console.log('this.state.THEDATA[9]:', this.state.THEDATA[9])
    console.log('this.state.THEDATA[9][3]:', this.state.THEDATA[9][3])
    console.log('this.state.THEDATA[9][0]:', this.state.THEDATA[9][0])
    console.log('this.state.THEDATA[9][5] ChargersBus:', this.state.THEDATA[9][5])
    //intervalo THEDATA[9][5]
    /*var aux =0;
    this.state.currentChargersNames.forEach(element => {
      var len = this.state.THEDATA[9][5][element][0].datos.length
      if (len>aux) {
        aux = len
      }
    });
    this.intervalBusParadas = aux/4
    console.log("IntervalParadas ", this.intervalBusParadas)*/
    return;
    //return salida2;
  };

  buildAccumData(energyimpact: any): void {
    console.log("Registro Funciones - buildAccumData");
    // this.state.processing = true;//

    //

    for (const iterator of this.state.currentChargersNames) {

      let datosaccum = [];
      const salida: any = [];
      try {

        // console.log('energyimpact[iterator]: ', energyimpact[iterator]);
        const ordenado = energyimpact[iterator].sort((v1: any, v2: any) => {

          if (v1.datos.length < v2.datos.length) {
            return 1;
          }

          if (v1.datos.length > v2.datos.length) {
            return -1;
          }

          return 0;
        });
        const initial_datetime: any = ordenado[0].datos[0].name;
        const start_timestamp = new Date(initial_datetime);
        ordenado[0].datos.forEach((tiempo: any, index: number) => {
          const new_datetime = tiempo.name
          //Vamos aumentando el valor del datetime
          //const new_datetime = new Date(initial_datetime.getTime() + (index * 1000));
          // console.log('Fecha: ', tiempo.name);
          let accumulatedNow = 0;
          energyimpact[iterator].forEach((cname: any) => {
            if (cname.datos[index] !== undefined) {
              accumulatedNow += cname.datos[index].value
            } else {
              accumulatedNow += cname.datos[cname.datos.length - 1].value
            }
          });
          salida.push(
            {
              name: new_datetime,
              value: accumulatedNow
            }
          );
        });
        // console.log('accumulated data is: ', salida);
        datosaccum = [{
          nombre: 'Acumulado en cargador',
          datos: salida
        }];
        console.log('Son los datosaccum:', datosaccum);
      } catch (error) {
        // console.log('No se pudieron generar los datos acumulados');
        datosaccum = [];
      }
      this.alldatosaccum[iterator] = datosaccum;
      // console.log('this.alldatosaccum[iterator]: ', this.alldatosaccum[iterator]);
      if (this.alldatosaccum[iterator].length > 0) {
        // console.log('datosaccum[0].datos[datosaccum[0].datos.length - 1]. ', datosaccum[0].datos[datosaccum[0].datos.length - 1]);
        this.lastconsumedcharger[iterator] = datosaccum[0].datos[datosaccum[0].datos.length - 1].value.toFixed(2);
      }
      //
    }
    // return this.alldatosaccum;
    // this.state.processing = false;
    const ressuperacum = [];

    // if (this.alldatosaccum[this.state.currentChargersNames[0]][0] !== undefined) {
    for (let jota = 0; jota < this.alldatosaccum[this.state.currentChargersNames[0]][0].datos.length; jota++) {
      let theaccumnow = 0;
      for (const elcargador of this.state.currentChargersNames) {
        if (this.alldatosaccum[elcargador][0] !== undefined && this.alldatosaccum[elcargador][0].datos[jota] !== undefined) {
          theaccumnow += this.alldatosaccum[elcargador][0].datos[jota].value;
        }
      }
      ressuperacum.push(
        {
          name: this.alldatosaccum[this.state.currentChargersNames[0]][0].datos[jota].name,
          value: theaccumnow
        });
    }
    // }
    // console.log('ressuperacum: ', ressuperacum);
    this.allChargersAcum = [{
      nombre: 'Acumulado en toda la red',
      datos: ressuperacum
    }];
  }

  async methodCollection() {
    console.log("Registro Funciones - methodCollection");

  }
  //public getCollectionStadistics = async () =>{
  //  const results = this.methodCollection()
  //  console.log("resutls de collections stadistics newchart", results)
  //  this.listaCollection2 = results;
  //  this.state.CollectionStatistics.push(results)
  //  //console.log("newchart",this.state.CollectionStatistics[0].baselineconsumption)
  //}
  public async getPortfolio() {
    let results: any;
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Token ' + this.auth.getToken(),
      });
      results = await this.http
        .get(this.mainUrl + 'portfolio/', {
          responseType: 'json',
          headers,
        })
        .toPromise();
      this.listPortfolio = results;

    } catch (error) {
      console.error(error)
    }
  }


  public async getAllPotenciaAcum(): Promise<void> {
    console.log("Registro Funciones - getAllPotenciaAcum");
    this.state.dataChargersInfo = [];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    var listchargadores = ["BOLOGNESI",
      "CANTA CALLAO",
      "CONTROL",
      "Canta Callao - NS",
      "HIPÓDROMO",
      "Jose Olaya - NS",
      "PATIO BOLOGNESI",
      "PATIO PRIMERA",
      "PATIO VENTANILLA",
      "PRIMERA DE PRO",
      "TINOCO",
      "VENTANILLA"]
    try {
      const ee = {
        cargadores: listchargadores,
        chargersdatastart: this.listStartQuery,
        chargersdatasend: this.listEndQuery
      };
      console.log("ee Potencia : ", ee)
      let results: any;

      results = await this.http
        .post(this.mainUrl + 'totalenergy/', ee, {
          responseType: 'json',
          headers,
        })
        .toPromise();
      console.log("Resultado PotenciaTotal", results)

      var listResulta = [];
      listchargadores.forEach(element => {
        //console.log("element de Potencia", element)
        const salida: any = [];
        //console.log("length potencia",results[element].PotenciaTotal.length)
        if (results[element].PotenciaTotal.length != 1) {
          const primertime = results[element].TimesStampTotal[0]
          //console.log("potencia 1 ", results[element].TimesStampTotal[0])
          //console.log("potencia 1 primertime", primertime)
          const start_timestamp = new Date();
          start_timestamp.setHours(0, 0, 0)
          //console.log("potencia 1")
          let segundo_inicial = 0;
          let segundos_acum = 0;
          let minuto_inicial = 0;
          let minutos_acum = 0;
          let hora_inicial = 0;
          let horas_acum = 0;

          let dias_acum = 0;
          let eldia_inicialcrudo = primertime
          //console.log("potencia 1", eldia_inicialcrudo)
          const cadenadiainicial = eldia_inicialcrudo.substring(0, 10);
          //console.log("potencia 1", cadenadiainicial)
          const eldia_inicialentero = cadenadiainicial.charAt(8) + cadenadiainicial.charAt(9)
          //console.log("potencia 1", eldia_inicialentero)
          let eldia_inicial = parseInt(eldia_inicialentero, 10);
          //console.log("potencia 1", eldia_inicial)
          //console.log("potencia 2")

          //console.log("found1 : ",found1)

          results[element].TimesStampTotal.forEach((current: string, index: number) => {
            //onsole.log("potencia 3")
            const lanuevacadena = current.substring(current.length - 8);
            const cadenafecha = current.substring(0, 10);
            const eldia = cadenafecha.charAt(8) + cadenafecha.charAt(9)
            let eldia_int = parseInt(eldia, 10);
            const diferencia_dia = (eldia_int - eldia_inicial);
            const losegundos = current.charAt(17) + current.charAt(18);
            const losminutos = current.charAt(14) + current.charAt(15);
            const lahoritaes = current.charAt(11) + current.charAt(12);
            let lossegundos_int = parseInt(losegundos, 10);
            let diferencia_segundos = lossegundos_int - segundo_inicial;

            let losminutos_int = parseInt(losminutos, 10);
            let diferencia_minutos = losminutos_int - minuto_inicial;

            let lahora_int = parseInt(lahoritaes, 10);
            let diferencia_horas = lahora_int - hora_inicial;

            //La lógica que debo hacer es la de restar los segundos, y en caso de menor que 0,
            // sumarle 60 y esos serían los segunddos transcurridos. Ejm: 2-57 = -55, pero +60 = +5
            segundo_inicial = lossegundos_int;
            segundos_acum = segundos_acum + diferencia_segundos;
            minuto_inicial = losminutos_int;
            minutos_acum = minutos_acum + diferencia_minutos;
            hora_inicial = lahora_int;
            horas_acum = horas_acum + diferencia_horas;
            eldia_inicial = eldia_int;
            dias_acum = dias_acum + diferencia_dia;

            let total_acumulado = segundos_acum + minutos_acum * 60 + horas_acum * 3600 + dias_acum * 24 * 3600;
            //let fyh = new Date(start_timestamp.getTime() + (segundos_acum * 1000));
            let fyh = new Date(start_timestamp.getTime() + (total_acumulado * 1000));
            salida.push({
              name: fyh,
              value: results[element]["PotenciaTotal"][index]
            });
            //console.log("salida Potencia", salida)
          });
        } else {
          salida.push({
            name: 0,
            value: 0
          })
        }
        listResulta[element] = salida;
        //console.log("listResulta[element] Potencia", listResulta[element])

      });

      //this.state.setDataAtIndex(listResulta,20)
      this.state.dataChargersInfo = listResulta
      console.log("Salida allpotenicaAcum", this.state.dataChargersInfo)
    } catch (error) {
      console.log('getAllPotenciaAcum: No se puede obtener datos de acumulado ');
    }

    // return salida2;
    return;
  }

  public getChargerDataPotencia = async (params: any) => {
    console.log('Llamando getChargerDataPotencia');

    this.state.chartMapLatitude[1] = [];
    this.state.chartMapLongitude[1] = [];
    this.state.chartMapLatitude[0] = [];
    this.state.chartMapLongitude[0] = [];
    console.log("revision datos getChargerDataPotenca", this.chargersimpactpower)


    console.log('loscharges son: ', this.state.currentChargersNames, this.allChargersData);
    const salida2: any = [];
    var brt_chart = ''
    switch (this.drivingCycle) {
      case 'Observado':
        brt_chart = ''
        break;

      case 'BRT':
        brt_chart = 'BRT_'
        break;
    }
    const inicio = params.currentCollection + '_' + brt_chart + params.bus;
    const fin = params.type + '_' + params.size + 'm_' + params.battery + 'kWh_' + params.load + '_pass' + params.pax;
    console.log("this.allChargersData[inicio + fin] : ", this.allChargersData[inicio + fin])
    const output = this.allChargersData[inicio + fin];
    console.log('El output:', output);
    // console.log('getChargerData output: ', output.chargersdata);
    this.state.currentChargersNames.map((c: any, i: number) => {
      let query1 = inicio + '_' + c + '_' + fin;
      // console.log('Buscando dato para cargador ', c);
      const found1 = output.chargersdata.find((current: any) => {
        return current.chargersid === query1;
      });
      //console.log('Este es el found1:', found1);
      if (!found1) {
        console.log("No hay found")
          // console.log('No se encontró impacto de ruta en cargador ', c);
          ;
      } else {
        //console.log("Si hay found")
        // this.chargermedium[c] = found1.chargersmedium[i];
        // console.log('El impacto de ruta en cargador ', c, ' es ', found1);
        let elindex = -1;
        //Aqui esta la modificacion
        //

        this.chargersimpactenergy[c] = []
        this.allChargersBus[c] = []
        this.chargersimpactpower[c].forEach((c: any, index: number) => {
          //params.bus es la ruta
          if (c.nombre === params.bus) {
            elindex = index;
          }
        });
        //this.allChargersBus[c].forEach((c: any, index: number) => {
        //  //params.bus es la ruta
        //  if (c.nombre === params.bus) {
        //    elindex = index;
        //  }
        //});
        //console.log("this.allChargersBus 1: ", this.allChargersBus)
        if (elindex > -1) {
          this.chargersimpactpower[c].splice(elindex, 1);
          this.allChargersBus[c].splice(elindex, 1);
          this.howmanyroutesincharger[c] = this.howmanyroutesincharger[c] - 1;
        }
        elindex = -1;
        this.chargersimpactenergy[c].forEach((c: any, index: number) => {
          if (c.nombre === params.bus) {
            elindex = index;
          }
        });
        if (elindex > -1) {
          this.chargersimpactenergy[c].splice(elindex, 1);
        }
        //console.log("this.allChargersBus 2: ", this.allChargersBus)
        const siesta = this.chargersimpactpower[c].find((cur: any) => {
          return cur.nombre === params.bus;
        })

        if (siesta === undefined) {
          // console.log("siesta es indefindo")

          const datospower = [];
          const datosenergy = [];
          const datosperhour = [];
          const datosbusescargadores = [];
          //const start_timestamp = new Date(Date.parse(found1.timestamparray[0]));
          const start_timestamp = new Date();
          start_timestamp.setHours(0, 0, 0)
          let segundo_inicial = 0;
          let segundos_acum = 0;
          let minuto_inicial = 0;
          let minutos_acum = 0;
          let hora_inicial = 0;
          let horas_acum = 0;

          let dias_acum = 0;
          let eldia_inicialcrudo = found1.timestamparray[0]
          const cadenadiainicial = eldia_inicialcrudo.substring(0, 10);
          const eldia_inicialentero = cadenadiainicial.charAt(8) + cadenadiainicial.charAt(9)
          let eldia_inicial = parseInt(eldia_inicialentero, 10);

          //console.log("found1 : ",found1)
          found1.timestamparray.forEach((current: string, index: number) => {
            const lanuevacadena = current.substring(current.length - 8);
            const cadenafecha = current.substring(0, 10);
            //console.log("cadenafecha : ",cadenafecha)
            const eldia = cadenafecha.charAt(8) + cadenafecha.charAt(9)
            let eldia_int = parseInt(eldia, 10);
            const diferencia_dia = (eldia_int - eldia_inicial);

            //console.log("eldia :",eldia)
            const lahoritaes = lanuevacadena.charAt(0) + lanuevacadena.charAt(1);
            const losminutos = lanuevacadena.charAt(3) + lanuevacadena.charAt(4);
            const losegundos = lanuevacadena.charAt(6) + lanuevacadena.charAt(7);

            let lossegundos_int = parseInt(losegundos, 10);
            let diferencia_segundos = lossegundos_int - segundo_inicial;

            let losminutos_int = parseInt(losminutos, 10);
            let diferencia_minutos = losminutos_int - minuto_inicial;

            let lahora_int = parseInt(lahoritaes, 10);
            let diferencia_horas = lahora_int - hora_inicial;

            //La lógica que debo hacer es la de restar los segundos, y en caso de menor que 0, sumarle 60 y esos serían los segunddos transcurridos. Ejm: 2-57 = -55, pero +60 = +5
            segundo_inicial = lossegundos_int;
            segundos_acum = segundos_acum + diferencia_segundos;
            minuto_inicial = losminutos_int;
            minutos_acum = minutos_acum + diferencia_minutos;
            hora_inicial = lahora_int;
            horas_acum = horas_acum + diferencia_horas;
            eldia_inicial = eldia_int;
            dias_acum = dias_acum + diferencia_dia;

            let total_acumulado = segundos_acum + minutos_acum * 60 + horas_acum * 3600 + dias_acum * 24 * 3600;
            //console.log("getchargerdata time total_acumulado: ",total_acumulado)
            //let fyh = new Date(start_timestamp.getTime() + (segundos_acum * 1000));
            let fyh = new Date(start_timestamp.getTime() + (total_acumulado * 1000));
            //console.log("getchargerdata time fyh : ",fyh)

            //console.log("Esto es el fyh : ", fyh)
            datospower.push({
              name: fyh,
              value: found1.chargingpowerarray[index]
            });
            datosenergy.push({
              name: fyh,
              value: found1.charginggridenergyarray[index]
            });
            datosbusescargadores.push({
              name: fyh,
              value: found1.numberofbusescharging[index]
            });
          });




          //var estacionesAux = this.state.EstacionesxRuta[params.bus]
          //console.log("Esto es las estaciones AUX ", estacionesAux, params.bus)
          //for (let i = 0; i < estacionesAux.length; i++) {
          //  const element = estacionesAux[i];
          //  this.chargersimpactpower[element].unshift(
          //    {
          //      nombre: params.bus,
          //      datos: datospower
          //    });
          //}
          //console.log("Estaciones Potencia", c)
          this.chargersimpactpower[c].unshift(
            {
              nombre: params.bus,
              datos: datospower
            });

          this.chargersimpactenergy[c].unshift(
            {
              nombre: params.bus,
              datos: datosenergy
            }
          );
        }
      }
    });
    console.log("Resultado de getChargerDataPotencia", this.chargersimpactpower, this.chargersimpactenergy)
    return;
    //return salida2;
  };

  public async getAllDataFromEventsbyCollectionDataBase(coleccion: any) {
    console.log("===== Funcion iota - getAllDataFromEvents ===== ")
    var result;
    var auxlist = []
    var result2;

    const query = {
      "STRID": coleccion
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    result = this.http.post(this.mainUrl + '/eventchargerquery', query, {
      responseType: 'json',
      headers: headers,
    });

    console.log("result de collection data base event charger", result)
    result.subscribe(data => { auxlist.push(data) })
    result2 = auxlist
    console.log("Resultado primero iota", result2)
    return result2;
  }
  public async getAllDataIotaLocation() {
    var result;
    console.log("this.state.currentChargersNames.", this.state.currentChargersNames)
    //result = this.iota.getAllDataFromEventsbyLocationList(this.state.currentChargersNames)
    result = this.getAllDataFromEventsbyCollectionDataBase('LimaGrupoPolo')
    console.log("resultado iota post ", result)
    this.datasourceriota = result;
    return result;
  }

  public async getDataChargerAnalysis(cadena: any): Promise<void> {

    console.log("Entrando a querychargeranalysis", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token 789a70dc7e4aefcbc436c2307384e834604edaf3',
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'querychargeranalysis/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaComparacionesData = results
    console.log("listaComparacionesData", this.state.listaComparacionesData)
    this.state.listaPuntosCarga = results["results"]["puntoscarga"]
    // Condicional
    this.state.isChargerShared = this.state.listaComparacionesData["shared"]
    let resultsChargerShared: any;
    const queryShared = {
      "lista": this.state.listaComparacionesData["listasharedname"]
    }
    resultsChargerShared = await this.http.post(this.mainUrl + 'chargershared', queryShared, {
      responseType: 'json',
      headers,
    }).toPromise();
    console.log("charger shared", resultsChargerShared, queryShared)
    this.state.listaChargerShared = resultsChargerShared["results"]
    this.state.listaChargerSharedCritic = resultsChargerShared["resultscritic"]
    console.log("charger shared", this.state.listaChargerShared, this.state.listaChargerSharedCritic)
    //

    let resultsResumen: any;
    const queryResumen = {
      "STRID": cadena
    };
    resultsResumen = await this.http.post(this.mainUrl + 'queryresumen/', queryResumen, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaResumen = resultsResumen["results"]
    console.log("Resultado Resumen ", this.state.listaResumen, queryResumen)
    return;
  }
  public async getDataChargerAnalysisCritic(cadena: any): Promise<void> {

    console.log("Entrando a querychargeranalysiscritic", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token 789a70dc7e4aefcbc436c2307384e834604edaf3',
    });
    var results2: any;
    const query = {
      "STRID": cadena
    };
    results2 = await this.http.post(this.mainUrl + 'criticchargeranalysis/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaComparacionesDataCritic = results2
    console.log("listaComparacionesDataCritic", this.state.listaComparacionesDataCritic)
    this.state.listaPuntosCargaCritic = results2["results"]["puntoscarga"]
    return;
  }
  public async getDataEstrategiaCarga(cadena: any): Promise<void> {

    console.log("Entrando a Estrategia de Carga", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token 789a70dc7e4aefcbc436c2307384e834604edaf3',
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'queryestrategiacarga/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaEstrategiaCargaData = results
    console.log("Resultado estrategia de carga ", this.state.listaEstrategiaCargaData)
    return;
  }

  async newsetOvernightOption(): Promise<void> {
    //this.state.currentRoute = this.state.aux_route;
    console.log("Accion newsetOvernightOption")
    console.log("revision datos set Over", this.state.dataChargerEnergia, this.state.dataChargerPotencia, this.state.dataChargersInfo)
    this.state.metodoCarga = "Nocturno"
    if (this.proute === this.state.currentRoute && this.pbattery === this.state.currentBattery
      && this.pcollection === this.state.currentCollection && this.psize === this.state.currentBusSize
      && this.ppax === this.state.currentPax && this.phvac === this.state.currentHVAC) {
      return;
    } else {
      this.proute = this.state.currentRoute;
      this.pbattery = this.state.currentBattery;
      this.pcollection = this.state.currentCollection;
      this.psize = this.state.currentBusSize;
      this.ppax = this.state.currentPax;
      this.phvac = this.state.currentHVAC;
    }
    this.cadena_busquedafiltro = this.pcollection + '_' + this.proute + '_' + 'Overnight_' + this.psize + 'm_' + this.pbattery + 'kWh_' + this.phvac + '_pass' + this.ppax
    console.log('Cadena Nocturno', this.cadena_busquedafiltro);
    await this.getDataChargerAnalysisCritic(this.cadena_busquedafiltro)
    await this.getDataChargerAnalysis(this.cadena_busquedafiltro)
    await this.getDataEstrategiaCarga(this.cadena_busquedafiltro)
    await this.getDataMicroSimulacion(this.cadena_busquedafiltro)
    //console.log('index: ', index);
    // console.log('setOvernightOption: ', norefresh);
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    //if (this.previousOvernight !== 1) {
    //  if (this.previousOvernight === -1) {
    //    this.previousOvernight = 1;
    //  } else {
    //    this.nocturnos[this.previousOvernight].color = '#000096';
    //    this.previousOvernight = 1;
    //  }
    //}
    //if (this.previousOportunity !== -1) {
    //  this.oportunos[this.previousOportunity].color = '#000096';
    //}
    //this.changeMetersColors(this.state.currentBusSize);
    //this.nocturnos[1].color = '#4bc9ff';
    //this.nocturnosColor = '#000096';
    //this.nocturnosFontColor = 'white';
    //this.oportunosColor = 'white';
    //this.oportunosFontColor = '#000096';
    //this.state.currentType = 'Overnight';
    //if (this.state.currentBusSize !== this.stateofsize) {
    //  this.stateofsize = this.state.currentBusSize;
    //}
    //for (let i = 0; i < this.state.THEDATA.length; i++) {
    //  if (i !== 6 && i !== 3 && i !== 4)
    //    this.state.THEDATA[i] = [];
    //}
    this.state.changePax('nav');
    //this.state.endAuto = '';
    this.state.maximumConsumption = '';
    //if (this.state.currentHVAC !== '' && this.state.currentPax !== '' && 1 === 1) {
    //  this.selectedOpor = -1;
    //  this.selectedOver = 1;
    //  await this.updateTHEDATA2();
    //  this.setLabels();
    //  this.state.changeBattery(this.state.currentBattery);
    //  this.state.changePax('nav');
    //  this.setAllViableBuses();
    //  this.setNonViableBuses();
    //}
    //this.selectedOver = 1;
    console.log("THEDATA[9] busesoption", this.state.THEDATA[9])
    const entradas = {
      chargingmethod: 'Overnight',
      vehiclesize: parseInt(this.state.currentBusSize),
      batteryenergy: parseFloat(this.state.currentBattery),
      collection: this.state.currentCollection,
      route: this.state.currentRoute
    };
    this.getVehicleProperties(entradas);
    console.log("THEDATA[7] busesoption", this.state.THEDATA[7])
  }

  async newsetOpportunityOption(): Promise<void> {
    console.log("Accion SetOpportunity")
    console.log("revision datos set Opp", this.state.dataChargerEnergia, this.state.dataChargerPotencia, this.state.dataChargersInfo)
    // this.state.currentRoute = this.state.aux_route
    this.state.metodoCarga = "Oportunidad"
    if (this.proute === this.state.currentRoute && this.pbattery === this.state.currentBattery
      && this.pcollection === this.state.currentCollection && this.psize === this.state.currentBusSize
      && this.ppax === this.state.currentPax && this.phvac === this.state.currentHVAC) {
      // console.log('nada a cambiado');
      return;
    } else {
      this.proute = this.state.currentRoute;
      this.pbattery = this.state.currentBattery;
      this.pcollection = this.state.currentCollection;
      this.psize = this.state.currentBusSize;
      this.ppax = this.state.currentPax;
      this.phvac = this.state.currentHVAC;

    }
    //limagrupopolo_101op3_Overnight_12m_350kWh_hvacON_passHIGH_150_CANTA CALLAO
    //101op2_195_limagrupopolo_18_HIGH_hvacOFF
    this.cadena_busquedafiltro = this.pcollection + '_' + this.proute + '_' + 'Opportunity_' + this.psize + 'm_' + this.pbattery + 'kWh_' + this.phvac + '_pass' + this.ppax
    await this.getDataChargerAnalysis(this.cadena_busquedafiltro)
    await this.getDataChargerAnalysisCritic(this.cadena_busquedafiltro)
    await this.getDataEstrategiaCarga(this.cadena_busquedafiltro)
    await this.getDataMicroSimulacion(this.cadena_busquedafiltro)
    console.log('Cadena Oportunidad', this.cadena_busquedafiltro);
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();

    this.state.changePax('nav');
    this.state.maximumConsumption = '';

    //this.selectedOpor = index;
    const entradas = {
      chargingmethod: 'Opportunity',
      vehiclesize: parseInt(this.state.currentBusSize),
      batteryenergy: parseFloat(this.state.currentBattery),
      collection: this.state.currentCollection,
      route: this.state.currentRoute
    };
    this.getVehicleProperties(entradas);
    //await this.getDataChargerAnalysis(params)
    console.log("THEDATA[9] busesoption", this.state.THEDATA[9], this.state.dataChargerPotencia)
  }

  getPositionRute(ruta: string) {
    for (let i = 0; i < this.state.busNames.length; i++) {
      if (ruta === this.state.busNames[i]) {
        this.state.currentRoutePosition = i;
      }
    }
  }

  public newsetCurrentRoute = async () => {
    const datos = {
      whichone: 'clear',
    };
    //this.currentRouteSelected= route;
    //this.state.currentRoute = 'None'
    this.isBRT = false;
    this.isHVAC = false;
    this.state.changeDetailsEvent(datos);
    this.drivingCycle = 'Observado'
    /*console.log('setCurrentRoute: ', route);
    console.log('this.state.currentRoute: ', this.state.currentRoute); // */
    if (this.state.isAnimationON)
      this.state.eventStopAnimation();
    // this.state.currentType = '';
    // this.state.currentBusSize = '';
    // this.state.currentBattery = '';
    this.state.endAuto = '';
    this.state.maximumConsumption = '';

    this.getPositionRute(this.state.currentRoute);
    for (let i = 0; i < this.state.THEDATA.length; i++) {
      if (i !== 6)
        this.state.THEDATA[i] = [];
    }

    this.state.changePax('');
    this.state.processing = true;
    // console.log('getting route properties');
    var ruta = this.state.currentRoute
    console.log("ruta en data", ruta)
    await this.getRouteProperties(ruta, this.state.currentCollection);

    // console.log('done getting route properties');
    //this.setAllViableBuses();
    //if (this.state.currentRoute !== 'None') {
    //  this.setNonViableBuses();
    //}
    //await this.getAllCyclesInputs();
    // console.log('procesando getAllCyclesInputsEvo: ');
    await this.getAllCyclesInputsEvo(ruta, this.state.currentCollection);
    // console.log('ending procesando getAllCyclesInputsEvo: ');
    // console.log('this.auth.currentBusConfigurations: ', this.auth.currentBusConfigurations);
    // console.log(this.state.currentBattery, this.state.currentPax, this.state.currentBusSize, this.state.currentType);
    if (this.state.currentBattery !== '' && this.state.currentPax !== '' &&
      this.state.currentBusSize !== '' && this.state.currentType !== '') {
      /* if (this.auth.currentBusConfigurations === null ||
        this.auth.currentBusConfigurations[this.state.currentCollection + route] === undefined) {
        this.state.currentPax = 'MID';
      } */
      // console.log('LLamando datos updateTHEDATA2');
      // console.log('this.state.currentPax: ', this.state.currentPax);
      await this.updateTHEDATA2();
      this.setLabels();
      // console.log('done llamado updateTHEDATA2');
    }
    this.state.processing = false;
    this.state.changePax('nav');
    //}
    this.state.changeRoute(this.state.currentRoute);
    // this.onSidenavClose();
    this.state.cyclesListOnlyCC = this.hours.filter((c: any, index: number) => {
      return this.trayectos[index] === 'CC';
    });
    
    this.state.NameCyclesListOnlyCC= this.state.cyclesListOnlyCC.map(time => time.slice(0, 2) + ':' + time.slice(2));
    console.log('this.state.cyclesListOnlyCC: ', this.state.cyclesListOnlyCC);
    this.state.currentCycle = 0;
    //this.mapser.addMarkerTest();    //await this.getAllPotenciaAcum()
    //console.log("MostrarCargadoresMapa")
    this.mapser.deleteAllChargersToMap()
    console.log("newsetCurrentRoute ", this.allRouteProperties[ruta])
    var selectechargerroute = this.allRouteProperties[ruta]
    //console.log("MostrarCargadoresMapa", selectechargerroute)
    await this.getPortfolio()
    //if (this.state.activeGlobal == false) {
    this.mapser.addSelectedCharger(selectechargerroute.namechargers, selectechargerroute.latitudechargers, selectechargerroute.longitudechargers)
    //}
    this.datosPorcentajePasajeros()
  };
  datosPorcentajePasajeros() {
    //this.state.DataForPassCicle
    //this.allCyclesInputs
    //this.state.cyclesListOnlyCC

    var valoresporcentaje = []

    for (let i = 0; i < this.state.dataInputs["results"][0]["numberstops"].length; i++) {
      var name = this.state.dataInputs["results"][0]["numberstops"][i];
      var templatedata = {}
      for (let j = 0; j < this.state.cyclesListOnlyCC.length; j++) {
        var horaciclo = this.state.cyclesListOnlyCC[j];
        this.state.dataInputs["results"].forEach(element => {
          var ciclo = element["inputsid"].slice(-4)
          if (ciclo == horaciclo) {
            valoresporcentaje.push(element["pasajeroscant"][i])
          }
        });
        for (let k = 0; k < this.state.cyclesListOnlyCC.length; k++) {
          var cicloname = this.state.cyclesListOnlyCC[k];
          var valor = valoresporcentaje[k]
          templatedata[cicloname] = (valor * 100).toFixed(0)
        }
        templatedata["name"] = name
      }
      this.state.DataForPassCicle.push(templatedata)
      templatedata = {}
      valoresporcentaje = []

    }
    console.log("DataForPassCicle", this.state.DataForPassCicle)
  }
  public async dibujarTodosLosCargadores(collection: any) {
    console.log("Entrando a F - dibujarTodosLosCargadores", collection)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    const ee = { "STRID": collection };
    let results: any;
    results = await this.http.post(this.mainUrl + 'routepropertiesmany/', ee, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    console.log("routeproperties por coleccion", results)
    //armar lista dependiendo de cada variable para addSelectedCharger
    var listaNames = []
    var listaLatitud = []
    var listaLongitud = []
    for (let i = 0; i < results["results"].length; i++) {
      var element = results["results"][i];
      for (let j = 0; j < element["namechargers"].length; j++) {
        listaNames.push(element["namechargers"][j])
        listaLongitud.push(element["longitudechargers"][j])
        listaLatitud.push(element["latitudechargers"][j])
      }
    }
    var listaNamesParadas = []
    var listaLatitudParadas = []
    var listaLongitudParadas = []
    var listaDistanciaParadas = []
    for (let i = 0; i < results["results"].length; i++) {
      var element = results["results"][i];
      for (let j = 0; j < element["namestops"].length; j++) {
        listaNamesParadas.push(element["namestops"][j])
        listaLongitudParadas.push(element["longitudestops"][j])
        listaLatitudParadas.push(element["latitudestops"][j])
        listaDistanciaParadas.push(element["distancestops"][j])
      }
    }
    if (this.state.currentCollection == "LimaEBcorridors") {
      this.mapser.addSupuestoDepot()
    } else {
      this.mapser.deleteSupuestoDepot()
    }
    this.mapser.addCurrentRouteStopsServices(listaNamesParadas, listaLatitudParadas, listaLongitudParadas, listaDistanciaParadas)
    //this.mapser.addSelectedCharger(listaNames,listaLatitud,listaLongitud)
    console.log(results["results"][0]["longitudedepot"])
    if (results["results"][0]["longitudedepot"] != null && results["results"][0]["longitudedepot"].length != 0) {
      this.mapser.dibujarDepot(results["results"])
    }
    return;
  }

  public async dibujarRutaFiltrada(collection: any) {
    console.log("Entrando a F - dibujarRutaFiltrada", collection)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    const ee = { "STRID": collection };
    let results: any;
    results = await this.http.post(this.mainUrl + 'routepropertiesmany/', ee, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    console.log("routeproperties por coleccion", results)
    //armar lista dependiendo de cada variable para addSelectedCharger
    var listaNames = []
    var listaLatitud = []
    var listaLongitud = []
    for (let i = 0; i < results["results"].length; i++) {
      var element = results["results"][i];
      for (let j = 0; j < element["namechargers"].length; j++) {
        listaNames.push(element["namechargers"][j])
        listaLongitud.push(element["longitudechargers"][j])
        listaLatitud.push(element["latitudechargers"][j])
      }
    }
    var listaNamesParadas = []
    var listaLatitudParadas = []
    var listaLongitudParadas = []
    var listaDistanciaParadas = []
    for (let i = 0; i < results["results"].length; i++) {
      var element = results["results"][i];
      for (let j = 0; j < element["namestops"].length; j++) {
        listaNamesParadas.push(element["namestops"][j])
        listaLongitudParadas.push(element["longitudestops"][j])
        listaLatitudParadas.push(element["latitudestops"][j])
        listaDistanciaParadas.push(element["distancestops"][j])
      }
    }
    this.mapser.addCurrentRouteStopsServices(listaNamesParadas, listaLatitudParadas, listaLongitudParadas, listaDistanciaParadas)
    this.mapser.addSelectedCharger(listaNames, listaLatitud, listaLongitud)
    return;
  }


  public async getMenuConfiguracionBus(cadena: any): Promise<void> {

    console.log("Entrando a Configuracion Menu bus", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'querymenu/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaMenuConfiguracionBus = results
    console.log("Resultado Configuracion Menu Bus ", this.state.listaMenuConfiguracionBus)
    return;
  }
  public async getDataMicroSimulacion(cadena: any): Promise<void> {

    console.log("Entrando a Micro Simulacion", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'querymicrosim/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaMicroSimulacion = results
    console.log("Resultado Micro Simulacion ", this.state.listaMicroSimulacion)
    return;
  }

  public async getDataCronograma(cadena: any): Promise<void> {

    console.log("Entrando a Cronograma", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'querycronograma/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaDataCronograma = results[0]
    console.log("Resultado Cronograma", results, this.state.listaDataCronograma)
    return;
  }

  public async getDataAnimacionRuta(cadena: any): Promise<void> {
    console.log("Entrando a Animacion Ruta", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'queryanimacion/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaDatosAnimacionRuta = results[0]
    console.log("Resultado Animacion", results, this.state.listaDatosAnimacionRuta)
    return;
  }

  public async getDataAnimacionResumen(cadena: any): Promise<void> {
    console.log("Entrando a Animacion Ruta Resumen", cadena)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena
    };
    results = await this.http.post(this.mainUrl + 'queryinfoanimacionresumen/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaDatosAnimacionRutaResumen = results[0]
    console.log("Resultado Animacion Resumen", results, this.state.listaDatosAnimacionRutaResumen)
    return;
  }

  public async getEnergia(): Promise<void> {
    console.log("Entrando get Energia")
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    // "STRIDOne":"LimaEBcorridors_LaPuntaCercadoop5",
    // "STRIDTwo":"_Overnight_12m_350kWh_hvacON_passHIGH"
    var cadena1 = this.state.currentCollection + '_' + this.state.currentRoute
    var cadena2 = "_" + this.state.currentType + '_' + this.state.currentBusSize + 'm_' + this.state.currentBattery + 'kWh_' + this.state.currentHVAC + '_pass' + this.state.currentPax
    const query = {
      "STRIDOne": cadena1,
      "STRIDTwo": cadena2
    };
    console.log("query para Energia", query)
    results = await this.http.post(this.mainUrl + 'energiabateria/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaDatosEnergia = results["result"]
    console.log("Resultado Energia", results, this.state.listaDatosEnergia)
    return;
  }
  public async getBateriaTablaDatos(cadena1: string): Promise<void> {
    console.log("Entrando getBateriaTablaDatos", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    console.log("query para Datos Energia Tabla", query)
    results = await this.http.post(this.mainUrl + 'bateriaquery/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();
    this.state.listaDatosBateriaTabla = results[0]
    this.state.tamaniobateriabus = this.state.listaDatosBateriaTabla["capacidadpack"]
    console.log("Resultado Bateria", results, this.state.listaDatosBateriaTabla)
    return;
  }

  public async getAmbienteDatos(cadena1: string): Promise<void> {
    console.log("Entrando getAmbienteDatos", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'queryambiente/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaDatosAmbiente = results
    console.log("Resultado Ambiente", this.state.listaDatosAmbiente)
    return;
  }
  public async getContableDatos(cadena1: string): Promise<void> {
    console.log("Entrando getContableDatos", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'contablequery/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaDatosContable = results
    console.log("Resultado Contable", this.state.listaDatosContable)
    return;
  }
  public async getEnergiaRenovable(cadena1: string): Promise<void> {
    console.log("Entrando getEnergiaRenovable", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "STRID": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'energiarenovablequery/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.listaDatosEnergiaRenovable = results[0]
    console.log("Resultado getEnergiaRenovable", this.state.listaDatosEnergiaRenovable)
    return;
  }
  public async getUidModelBus(cadena1: string): Promise<void> {
    console.log("Entrando getUidModelBus", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "name": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'getuidmodel/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.uid_model_bus = results["data"]
    console.log("Resultado getUidModelBus", this.state.uid_model_bus)
    return;
  }
  public async getVehiclePlatform(cadena1: string): Promise<void> {
    console.log("Entrando getVehiclePlatform", cadena1)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Token ' + this.auth.getToken(),
    });
    let results: any;
    const query = {
      "id": cadena1,
    };
    results = await this.http.post(this.mainUrl + 'getvehicleplatform/', query, {
      responseType: 'json',
      headers,
    })
      .toPromise();

    this.state.vehicle_platform_data = results
    console.log("Resultado getVehiclePlatform", this.state.vehicle_platform_data)
    return;
  }
}
