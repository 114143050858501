<div class="tarjetaNueva">
    <div class="">
        <ejs-dashboardlayout id='control-section' #defaultLayout columns="6" [allowDragging]='false'>
            <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="5" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div *ngIf="this.estaCompleto==false" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <!--Imagen referencia Error-->
                            <div class="errorCarga">
                                <img src="../../../../assets/working-blue.png" alt="" class="estiloImgError">
                                <h1>Ups.. Vemos que faltan datos, estamos en ello!</h1>
                                <h3>Nuestro equipo esta trabajando en el procesamiento y carga de datos</h3>
                                <h4>En caso de consulta, contactar a soporte Logios Global</h4>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart==1 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-heatmap id='container' style="display:block;" [dataSource]='this.dataHeatmap' [xAxis]='xAxis'
                                [yAxis]='yAxis' [titleSettings]='titleSettings' [paletteSettings]='paletteSettings'
                                [legendSettings]='legendSettings' [cellSettings]='cellSettings'
                                [tooltipSettings]='tooltipSettings' (tooltipRender)='tooltipRender($event)' style="width:100%;height:100%; ">
                            </ejs-heatmap>
                            <!--<ejs-chart #myDOMElement1 id="estadobus" title={{this.dataTMS.graficoeb.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'-->
                            <!--    style="width:100%;height:100%; " [primaryXAxis]='axisxEstadoBus'-->
                            <!--    [primaryYAxis]="axisyEstadoBus" [zoomSettings]='zoom'>-->
                            <!--    <e-series-collection style="width:100%;height:100%;">-->
                            <!--        <e-series *ngFor="let estadobus of this.dataEstadoBus; index as i"-->
                            <!--            [dataSource]="this.dataEstadoBus[i]" type='MultiColoredLine' xName='x' yName='y'-->
                            <!--            style="width:100%;height:100%; " width=3.5 pointColorMapping="color"-->
                            <!--            [marker]="this.marker">-->
                            <!--        </e-series>-->
                            <!--    </e-series-collection>-->
                            <!--</ejs-chart>-->
                        </div>
                    </div>
                    <!-- Estado de Cargadores -->
                    <div *ngIf="this.seleccionchart==2 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart title={{this.dataTMS.graficoec.titulo[this.state.indiceTranslate]}}
                                [tooltip]='tooltip' style="width:100%;height:100%; " [primaryXAxis]='axisxCargadores'
                                [primaryYAxis]="axisyCargadores" [zoomSettings]='zoom'>
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let equipo of this.dataCargadores; index as i"
                                        [dataSource]="this.dataCargadores[i]" type='MultiColoredLine' xName='x'
                                        yName='y' style="width:100%;height:100%; " width=3.5 pointColorMapping="color"
                                        [marker]="this.marker">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                    <div *ngIf="this.seleccionchart==3 && this.estaCompleto==true" style="width:100%;height:100%; ">
                        <div class="e-panel-container">
                            <ejs-chart #myDOMElement3 id="usabilidadequipos"
                                title={{this.dataTMS.graficouc.titulo[this.state.indiceTranslate]}} [tooltip]='tooltip'
                                style="width:100%;height:100%; " [primaryXAxis]="axisxEquipos"
                                [primaryYAxis]="axisyEquipos" [tooltip]="tooltip" [zoomSettings]='zoom'
                                [palettes]="colorScheme.domain">
                                <e-series-collection style="width:100%;height:100%;">
                                    <e-series *ngFor="let equipos of this.listaEquipos; index as i"
                                        [dataSource]="this.dataUsabilidad" type='StackingColumn' xName="equipo"
                                        yName='{{equipos}}' style="width:100%;height:100%; " name="{{equipos}}"
                                        width="2" columnWidth="0.5" [marker]="this.markerEquipos">
                                    </e-series>
                                </e-series-collection>
                            </ejs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div id="two" class="e-panel" data-row="0" data-col="5" data-sizeX="1" data-sizeY="2">
                <div class="e-panel-container" style="width:100%;height:100%; ">
                    <div style="width:100%;height:100%; display: flex;
                        flex-direction: column; margin: 5px;">
                        <h2 style="text-align: center; margin-top: 5px">
                            {{this.dataTMS.menu.titulo[this.state.indiceTranslate]}}</h2>
                        <ejs-radiobutton label={{this.dataTMS.menu.estadobuses[this.state.indiceTranslate]}}
                            name="botonesGraficosMicroSim" style="margin-bottom: 10px;" (click)="irEstadoBus()"
                            cssClass="e-info" checked="true"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTMS.menu.estadocargadores[this.state.indiceTranslate]}}
                            name="botonesGraficosMicroSim" style="margin-bottom: 10px;" (click)="irEstadoCargadores()"
                            cssClass="e-info"></ejs-radiobutton>
                        <ejs-radiobutton label={{this.dataTMS.menu.utilizacioncargadores[this.state.indiceTranslate]}}
                            name="botonesGraficosMicroSim" style="margin-bottom: 10px;" (click)="irUsabilidadEquipos()"
                            cssClass="e-info"></ejs-radiobutton>
                        <div *ngIf="this.mostrarFiltroBoton">
                            <h2 style="text-align: center; margin-top: 5px">
                                {{this.dataTMS.menu.titulosecond[this.state.indiceTranslate]}}</h2>
                            <div style="display: flex;align-items: center;flex-direction: row;"
                                *ngFor="let potencia of this.listapotencia; index as i">
                                <ejs-radiobutton label="{{potencia}} kW" name="RadioPotencia"
                                    [checked]="this.selectedFiltroBoton==i? true : false " style="margin-bottom: 10px;"
                                    (click)="cambiarFiltroPotenciaSim(i)" cssClass="e-info"></ejs-radiobutton>
                            </div>
                        </div>
                        <div *ngIf="this.seleccionchart==1">
                            <h2 style="text-align: center; margin-top: 5px">
                                {{this.dataTMS.menu.titulothird[this.state.indiceTranslate]}}</h2>
                            <div style="display: flex;flex-direction: row;align-items: baseline; height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #000096;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.cargando[this.state.indiceTranslate]}}</p>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #4bc8fe;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.standby[this.state.indiceTranslate]}}</p>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #E1E0E1;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.enservicio[this.state.indiceTranslate]}}</p>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="  width: 22px;height: 7px;background-color: #FF0000;margin-right: 10px;">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.atraso[this.state.indiceTranslate]}}</p>
                            </div>
                        </div>
                        <div *ngIf="this.seleccionchart==2">
                            <h2 style="text-align: center; margin-top: 5px">
                                {{this.dataTMS.menu.titulothird[this.state.indiceTranslate]}}</h2>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #E1E0E1;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.standby[this.state.indiceTranslate]}}</p>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #000096;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.conector1[this.state.indiceTranslate]}}</p>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: baseline;height: 25px;">
                                <div style="width: 22px;height: 7px;background-color: #F5821E;margin-right: 10px">
                                </div>
                                <p style="margin-right: 10px; font-family: 'Roboto';font-weight: 500;font-size: 13px;">
                                    {{this.dataTMS.menu.listaleyenda.conector2[this.state.indiceTranslate]}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ejs-dashboardlayout>
    </div>
</div>