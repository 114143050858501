<div [ngClass]=" state.currentRoute === 'None' ? 'tarjetaNuevaAlter' : 'tarjetaNueva'">
  <div class="control-section">
    <div class="dashboard-container">
      <div class="flex-container">
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container" title={{this.dataT.consumoespe[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisautonomia'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["autonomia"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container2" title={{this.dataT.minimocargadores[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxiscargadores'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["cargadores"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container3" title={{this.dataT.vidautilbateria[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisdegradacion'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["degradacion"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container4" title={{this.dataT.demandaenergia[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisdemandaenergia'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["demandaenergia"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container5" title={{this.dataT.demandapotencia[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisdemandapotencia'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["demandapotencia"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container6" title={{this.dataT.porceenergirenova[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisenergiarenovableutilizada'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["energiarenovableutilizada"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container7" title={{this.dataT.eventocargar[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxiseventoscarga'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["eventoscarga"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container8" title={{this.dataT.sizebateria[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxistamaniobateria'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["tamaniobateria"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
        <div class="chart-box">
          <div class="chart">
            <ejs-chart id="chart-container9" title={{this.dataT.promusocarga[this.indice]}}
              style="background-color: white;width: 100%;height: 100%;  border-radius: 15px; box-shadow: 4px 3px 5px 3px #0e0e0e;"
              [tooltip]='tooltip' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxisusocargadores'>
              <e-series-collection>
                <e-series
                  [dataSource]='this.state.dataRating["datos"][this.selectedRuta][this.tipocargaSeleccionado]["usocargadores"]'
                  type='Column' xName='numeracion' yName='y' [pointColorMapping]='pointColorMapping'></e-series>
              </e-series-collection>
            </ejs-chart>
          </div>
        </div>
      </div>
      <div class="menu-panel">
          <h2 class="title-menu">{{this.dataT.titulomenu[this.indice]}}</h2>
          <div class="container-route-options">
          <ejs-radiobutton *ngFor="let ruta of this.state.dataRating['ruta']; index  as i " [label]="ruta"
            name="rutas" style="margin-bottom: 10px;" (click)="cambiarRuta(ruta)" cssClass="e-info"
            [checked]="i==0? true : false "></ejs-radiobutton>          
        </div>                                
        <h2 class="title-menu">{{this.dataT.titulotiposcarga[this.indice]}}</h2>
        <ejs-radiobutton *ngFor="let tipocarga of this.state.dataRating['tipocarga']; index  as i " [label]="tipocarga"
          name="graficos" style="margin-bottom: 10px;" (click)="cambiarTipoCarga(tipocarga)" cssClass="e-info"
          [checked]="i==0? true : false "></ejs-radiobutton>
        <h2 class="title-menu">{{this.dataT.titulolistabuse[this.indice]}}</h2>
        <div *ngFor="let bus of this.listaDeBuses" style="color: black;font-size: 12px;">
          <span class="color-box" [ngStyle]="{'background-color': bus.color}"></span>
          {{bus.numeracion}} - {{bus.x}}
        </div>
      </div>
    </div>
  </div>
</div>